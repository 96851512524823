import { useEffect, useState, useMemo } from "react";
import { tprConfig } from "../resources/tests_config/tprConfig";
import { tswConfig } from "../resources/tests_config/tswConfig";
import { trcConfig } from "../resources/tests_config/trcConfig";
import { tsdConfig } from "../resources/tests_config/tsdConfig";
import { tdwConfig } from "../resources/tests_config/tdwConfig";
import { trmsConfig } from "../resources/tests_config/trmsConfig";
import { tmsConfig } from "../resources/tests_config/tmsConfig";
import { tmfConfig } from "../resources/tests_config/tmfConfig";
import { trsConfig } from "../resources/tests_config/trsConfig";
import { asConfig } from "../resources/tests_config/asConfig";
import LouderQuieter from "./LouderQuieter";
import Test from "./TestClass";
import { testsWavFiles } from "../resources/testsWavFiles";
import {
  AGE_FROM_WHICH_ADULT_VERSIONS_START,
  wavFiles,
} from "../resources/wavFiles";
import { useApi } from "../Api";
import Loader from "../components/animations/Loader";
import ThresholdAudiometryManager from "./ThresholdAudiometryManager";
import TestManager from "./TestManager";
import AudiometryManager from "./AudiometryManager";
import { testNames, texts } from "../resources/texts";
import { lqConfig } from "../resources/tests_config/louderQuieterConfig";
import moment from "moment";
import { FailureReason, Result } from "./enums";
import bg from "../resources/images/bg.svg";

const TestCreator = ({
  testEnum,
  userAge = 21,
  onEvent,
  logoutFromTest,
  options = {},
  showCAPDDescription,
  error,
}) => {
  const [test, setTest] = useState();
  const [config, setConfig] = useState();
  const [tries, setTries] = useState([]);
  const [trainingTries, setTrainingTries] = useState([]);
  const [finishedCancelNoise, setFinishedCancelNoise] = useState(false);
  const api = useApi();
  const startDate = useMemo(() => moment().toISOString(), []);
  var wavFilesToOrder = [];
  var instructionsSounds = [];

  if (testEnum !== "AP" && testEnum !== "GC") {
    testsWavFiles[testEnum].wavFiles.forEach((wav) => {
      wavFilesToOrder.push(wavFiles[wav]);
    });

    testsWavFiles[testEnum].instructionsSounds.forEach((wav) => {
      instructionsSounds.push(wavFiles[wav]);
    });
  }

  useEffect(() => {
    document.body.style.backgroundImage = `url(${bg})`;
    api
      .cancelNoise()
      .then(() => {
        setFinishedCancelNoise(true);
      })
      .catch((e) => {
        console.log("catch w cancelNoise()");
        onEvent &&
          onEvent("FINISHED", {
            result: Result.AMB,
            failureReason: FailureReason.ERROR,
            testType: testEnum,
            error: e.message,
            startDate: startDate,
            endDate: moment().toISOString(),
          });
      });

    switch (testEnum) {
      case "TPR":
        setTries(Test.drawOrderTpr(wavFilesToOrder, tprConfig.numberOfTries));
        setTrainingTries(
          Test.drawOrder(wavFilesToOrder, tprConfig.trainingNumberOfTries)
        );
        setConfig(tprConfig);
        break;

      case "TSW":
        setTries(Test.drawOrder(wavFilesToOrder, tswConfig.numberOfTries));
        setTrainingTries(
          Test.drawOrder(wavFilesToOrder, tswConfig.trainingNumberOfTries)
        );
        setConfig(tswConfig);
        break;

      case "TRC":
        setTries(
          Test.drawOrderDichotic(wavFilesToOrder, trcConfig.numberOfTries)
        );
        setTrainingTries(
          Test.drawOrderDichotic(
            wavFilesToOrder,
            trcConfig.trainingNumberOfTries
          )
        );
        setConfig(trcConfig);
        break;

      case "TSD":
        setTries(Test.drawOrder(wavFilesToOrder, tsdConfig.numberOfTries));
        setTrainingTries(
          Test.drawOrder(wavFilesToOrder, tsdConfig.trainingNumberOfTries)
        );
        setConfig(tsdConfig);
        break;

      case "TDW":
        setTries(Test.drawOrder(wavFilesToOrder, tdwConfig.numberOfTries));
        setTrainingTries(
          Test.drawOrder(wavFilesToOrder, tdwConfig.trainingNumberOfTries)
        );
        setConfig(tdwConfig);
        break;

      case "TRMS":
        wavFilesToOrder = Test.drawPossibleAnswersForAllWavs(
          wavFilesToOrder,
          trmsConfig.numberOfPossibleAnswers
        );
        setTries(Test.drawOrder(wavFilesToOrder, trmsConfig.numberOfTries));
        setTrainingTries(
          Test.drawOrder(wavFilesToOrder, trmsConfig.trainingNumberOfTries)
        );
        setConfig(trmsConfig);
        break;

      case "TMS":
        wavFilesToOrder = Test.drawPossibleAnswersForAllWavs(
          wavFilesToOrder,
          tmsConfig.numberOfPossibleAnswers
        );
        setTries(Test.drawOrder(wavFilesToOrder, tmsConfig.numberOfTries));
        setTrainingTries(
          Test.drawOrder(wavFilesToOrder, tmsConfig.trainingNumberOfTries)
        );
        setConfig(tmsConfig);
        break;

      case "TMF":
        wavFilesToOrder = Test.drawPossibleAnswersForAllWavs(
          wavFilesToOrder,
          tmfConfig.numberOfPossibleAnswers
        );
        setTries(Test.drawOrder(wavFilesToOrder, tmfConfig.numberOfTries));
        setTrainingTries(
          Test.drawOrder(wavFilesToOrder, tmfConfig.trainingNumberOfTries)
        );
        setConfig(tmfConfig);
        break;

      case "TRS":
        const triesTmp = Test.drawOrderDichotic(
          wavFilesToOrder,
          trsConfig.numberOfTries,
          trsConfig.numberOfPossibleAnswers
        );
        const trainingTriesTmp = Test.drawOrderDichotic(
          wavFilesToOrder,
          trsConfig.trainingNumberOfTries,
          trsConfig.numberOfPossibleAnswers
        );
        setTries(triesTmp);
        setTrainingTries(trainingTriesTmp);
        setConfig(trsConfig);
        break;

      case "AS":
        var leftCorrect = true;
        var rightCorrect = true;
        if (options.leftCorrect !== undefined) {
          leftCorrect = options.leftCorrect;
        }
        if (options.rightCorrect !== undefined) {
          rightCorrect = options.rightCorrect;
        }
        wavFilesToOrder = Test.drawPossibleAnswersForAllWavs(
          wavFilesToOrder,
          asConfig.numberOfPossibleAnswers
        );
        const numberOfTries =
          leftCorrect && rightCorrect
            ? asConfig.numberOfTries
            : asConfig.numberOfTries / 2;
        const trainingNumberOfTries =
          leftCorrect && rightCorrect
            ? asConfig.trainingNumberOfTries
            : asConfig.trainingNumberOfTries / 2;
        const triesWithoutChannel = Test.drawOrder(
          wavFilesToOrder,
          numberOfTries
        );
        triesWithoutChannel.forEach((attempt, index) => {
          var channel =
            index < triesWithoutChannel.length / 2 ? "RIGHT" : "LEFT";
          if (!leftCorrect) {
            channel = "RIGHT";
          }
          if (!rightCorrect) {
            channel = "LEFT";
          }

          triesWithoutChannel[index].listOfPlayedSounds =
            attempt.listOfPlayedSounds.map((sound) => {
              return { ...sound, channel };
            });
        });
        const trainingTriesWithoutChannel = Test.drawOrder(
          wavFilesToOrder,
          trainingNumberOfTries
        );
        trainingTriesWithoutChannel.forEach((attempt, index) => {
          var channel =
            index < trainingTriesWithoutChannel.length / 2 ? "RIGHT" : "LEFT";
          if (!leftCorrect) {
            channel = "RIGHT";
          }
          if (!rightCorrect) {
            channel = "LEFT";
          }
          trainingTriesWithoutChannel[index].listOfPlayedSounds =
            attempt.listOfPlayedSounds.map((sound) => {
              return { ...sound, channel };
            });
        });

        setTries(triesWithoutChannel);
        setTrainingTries(trainingTriesWithoutChannel);
        setConfig(asConfig);
        break;

      default:
        break;
    }
  }, []);

  useEffect(() => {
    const createTest = async () => {
      setTest({
        tries: tries.map((attempt) => ({
          ...attempt,
          givenAnswers: [],
          isCorrect: null,
        })),
        numberOfTries: config.numberOfTries,
        intensity: config.intensity,
        testEnum,
        age: userAge,
        kk: Test.getkk(config.kk, userAge),
        wkk: Test.getkk(config.wkk, userAge),
        training: {
          tries: trainingTries.map((attempt) => ({
            ...attempt,
            givenAnswers: [],
            isCorrect: null,
          })),
          numberOfTries: config.trainingNumberOfTries,
        },
        instructionsSounds,
        name: testNames[testEnum],
        result: null,
        resultRight: null,
        resultLeft: null,
        correctPercent: 0,
        correctPercentRight: null,
        correctPercentLeft: null,
        earlyFinishedEnum: null,
      });
    };
    config && tries && createTest();
  }, [config, tries]);

  if (test && config && finishedCancelNoise) {
    // testy CAPD i audiometria słowna
    return (
      <TestManager
        test={test}
        config={config}
        onEvent={onEvent}
        automaticLogout={logoutFromTest}
        startDate={startDate}
        showCAPDDescription={showCAPDDescription}
        parentError={error}
      />
    );
  } else if ((testEnum === "AP" || testEnum === "GC") && finishedCancelNoise) {
    // audiometria przesiewowa i progowa
    switch (testEnum) {
      case "AP":
        return (
          <AudiometryManager
            forKids={userAge < AGE_FROM_WHICH_ADULT_VERSIONS_START}
            onEvent={onEvent}
            automaticLogout={logoutFromTest}
            startDate={startDate}
            parentError={error}
          />
        );

      case "GC":
        var frequenciesLeft = lqConfig.frequencies;
        var frequenciesRight = lqConfig.frequencies;
        if (
          options.excludedFrequenciesLeft &&
          Array.isArray(options.excludedFrequenciesLeft)
        ) {
          frequenciesLeft = frequenciesLeft.filter((freq) =>
            options.excludedFrequenciesLeft.find(
              (exclFreq) => exclFreq === freq
            )
              ? false
              : true
          );
        }

        if (
          options.excludedFrequenciesRight &&
          Array.isArray(options.excludedFrequenciesRight)
        ) {
          frequenciesRight = frequenciesRight.filter((freq) =>
            options.excludedFrequenciesRight.find(
              (exclFreq) => exclFreq === freq
            )
              ? false
              : true
          );
        }

        return userAge < AGE_FROM_WHICH_ADULT_VERSIONS_START ? (
          <ThresholdAudiometryManager
            onEvent={onEvent}
            automaticLogout={logoutFromTest}
            frequenciesLeft={frequenciesLeft}
            frequenciesRight={frequenciesRight}
            startDate={startDate}
            parentError={error}
          />
        ) : (
          <LouderQuieter
            onEvent={onEvent}
            automaticLogout={logoutFromTest}
            frequenciesLeft={frequenciesLeft}
            frequenciesRight={frequenciesRight}
            startDate={startDate}
            parentError={error}
          />
        );

      default:
        break;
    }
  } else {
    return <Loader text={texts.preparingTest} />;
  }
};

export default TestCreator;
