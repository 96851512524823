import { Stack, Typography, Box, Button, useTheme } from "@mui/material";
import ListenCarefullyAnimation from "../components/animations/ListenCarefullyAnimation";
import OkButton from "../components/animations/OkButton";
import LouderQuieterButton from "../components/animations/QuieterLouderButton";
import Volume from "../components/Volume";
import { ReactComponent as LeftEar } from "../resources/images/ear-left.svg";
import { ReactComponent as RightEar } from "../resources/images/ear-right.svg";
import { texts } from "../resources/texts";

const LQButtonLayout = ({
  channel,
  onLouderClick,
  onQuieterClick,
  louderDisabled,
  quieterDisabled,
  onSaveClick,
  saveDisabled,
  displayNoSoundButton,
  dontHearClick,
  dontHearDisabled,
}) => {
  const theme = useTheme();
  return (
    <Stack justifyContent="space-around" alignItems="center" spacing={5}>
      <Box>
        <Typography
          variant="body1"
          sx={{
            display: "inline",
            fontSize: 17,
            mr: 1,
            color: (theme) => theme.palette.text.secondary,
          }}
        >
          {`${texts.testedEar}: `.toUpperCase()}
        </Typography>
        <Typography
          variant="body1"
          sx={{
            display: "inline",
            fontSize: 17,
            color: channel === "HEAD_PHONES_RIGHT" ? "#E3400D" : "#0083CE",
          }}
        >
          {`${(channel === "HEAD_PHONES_RIGHT"
            ? texts.right
            : texts.left
          ).toUpperCase()}`}
        </Typography>
      </Box>
      <ListenCarefullyAnimation />
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ width: "100%" }}
      >
        {channel === "HEAD_PHONES_RIGHT" ? (
          <Box sx={{ width: "500px" }}></Box>
        ) : (
          <LeftEar />
        )}
        <LouderQuieterButton
          active={!quieterDisabled}
          onClick={onQuieterClick}
          text={texts.quieter}
          rightEar={channel === "HEAD_PHONES_RIGHT"}
        />
        <OkButton
          active={!saveDisabled}
          onClick={onSaveClick}
          rightEar={channel === "HEAD_PHONES_RIGHT"}
        />
        <Stack sx={{ position: "relative" }}>
          <LouderQuieterButton
            active={!louderDisabled}
            onClick={onLouderClick}
            text={texts.louder}
            rightEar={channel === "HEAD_PHONES_RIGHT"}
          />
          {displayNoSoundButton && (
            <Button
              variant="contained"
              onClick={dontHearClick}
              disabled={dontHearDisabled}
              sx={{
                position: "absolute",
                fontSize: "14px",
                fontWeight: 700,
                letterSpacing: "1px",
                color: (theme) => theme.palette.primary.main,
                height: "45px",
                backgroundColor: "#F1F1F1",
                top: "250px",
                left: "65px",
                border: "1px solid #707070",
                px: 3,
                "&:active": {
                  color: "white",
                  background:
                    channel === "HEAD_PHONES_RIGHT"
                      ? "linear-gradient(#FFAC92,#FF0063)"
                      : "linear-gradient(#0064E6,#86D7E3)",
                },
                "&:hover": {
                  color: "white",
                  background:
                    channel === "HEAD_PHONES_RIGHT"
                      ? "linear-gradient(#FFAC92,#FF0063)"
                      : "linear-gradient(#86D7E3,#0064E6)",
                },
              }}
            >
              {texts.dontHear}
            </Button>
          )}
        </Stack>
        {channel === "HEAD_PHONES_RIGHT" ? (
          <RightEar />
        ) : (
          <Box
            sx={{ width: "500px", display: "flex", justifyContent: "end" }}
          ></Box>
        )}
      </Stack>
      <Box sx={{ position: "relative", top: "-30px" }}>
        <Volume
          color={
            channel === "HEAD_PHONES_RIGHT"
              ? theme.palette.text.rightEar
              : theme.palette.text.leftEar
          }
        />
      </Box>
    </Stack>
  );
};

export default LQButtonLayout;
