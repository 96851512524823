import { createContext, useContext } from "react";
import { INSTRUCTIONS_INTENSITY, WITH_COVER } from "./resources/wavFiles";
import { texts } from "./resources/texts";

class Api {
  ssoPath = "/api/v1/sso";
  screeningPath = "/api/v1/screening";
  patientPath = "/api/v1/patient";

  headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };

  audio = null;//new Audio("/wavs/2.wav");

  async logout() {
    const response = await fetch(`${this.ssoPath}/logout`, {
      method: "POST",
      headers: this.headers
    });
    return response.json();
  }

  async login(data) { 
    const response = await fetch(`${this.ssoPath}/login`, {
      method: "POST",
      body: JSON.stringify(data),
      headers: this.headers
    });
    if (response.ok) {
      return response.json();
    } else {
      return null;
    }
  }

  async impersonateSubaccount(id) {
    const response = await fetch(`${this.ssoPath}/impersonate_subaccount/${id}`, {
      method: "PUT",
      headers: this.headers
    });
    if (response.ok) {
      return response.json();
    } else {
      throw Error("Unable to login");
    }
  }

  async getSubaccounts() {
    const response = await fetch(`${this.ssoPath}/subaccount`, {
      method: "GET",
      headers: this.headers
    });
    return response.json();
  }

  async getScreenings() {
    const response = await fetch(`${this.screeningPath}/screenings_for_patient`, {
      method: "GET",
      headers: this.headers
    });

    if (response.ok) {
      return response.json();
    } else {
      throw Error(texts.cantGetScreenings);
    }
  }

  async sendResults(screeningPayload) {
    const response = await fetch(`${this.patientPath}/screening/complete`, {
      method: "POST",
      body: JSON.stringify(screeningPayload),
      headers: this.headers
    });

    if (response.ok) {
      return response.json();
    } else {
      throw Error(texts.cantSendResults);
    }
  }

  async playWav(wavFiles, level, signal) {
    const response = await fetch(`/audiometr/play_wav`, {
      method: "POST",
      body: JSON.stringify({ wavFiles, level: level }),
      headers: this.headers,
      signal,
    })
    if (response.ok) {
      return response.json()
    } else if (response.status === 412) {
      const jsonResponse = await response.json()
        .then((data) => {
          return JSON.stringify(data);
        }).catch((e) => {
          return e
        })
      throw Error(jsonResponse)
    } else {
      const textResponse = await response.text()
        .then((data) => {
          return data
        }).catch((e) =>{
          return e
        })
      throw Error(textResponse)
    }
  }


  _oscillator = null;

  //AUDIOMETRY
  async audiometryPlay(sound) {
    const response = await fetch(`/audiometr/play_generated`, {
      method: "POST",
      body: JSON.stringify(sound),
      headers: this.headers,
    });
    if (response.ok) {
      return response.json()
    } else if (response.status === 412) {
      const jsonResponse = await response.json()
        .then((data) => {
          return JSON.stringify(data);
        }).catch((e) => {
          return e
        })
      throw Error(jsonResponse)
    } else {
      const textResponse = await response.text()
        .then((data) => {
          return data
        }).catch((e) =>{
          return e
        })
      throw Error(textResponse)
    }
  }


  //LOUDER-QUIETER
  async louderQuieterPlay(sound) {
    const response = await fetch(`/audiometr/play_louder_quieter`, {
      method: "POST",
      body: JSON.stringify(sound),
      headers: this.headers,
    });
    if (!response.ok) {
      if (response.status === 412) {
        const jsonResponse = await response.json()
          .then((data) => {
            return JSON.stringify(data);
          }).catch((e) => {
            return e
          })
        throw Error(jsonResponse)
      } else {
        const textResponse = await response.text()
          .then((data) => {
            return data
          }).catch((e) =>{
            return e
          })
        throw Error(textResponse)
      }
    }
  }


  async stopPlaying(signal) {
    const response = await fetch(`/audiometr/stop_playing`, {
      method: "POST",
      headers: this.headers,
      signal,
    });
    if (!response.ok) {
      if (response.status === 412) {
        const jsonResponse = await response.json()
          .then((data) => {
            return JSON.stringify(data);
          }).catch((e) => {
            return e
          })
        throw Error(jsonResponse)
      } else {
        const textResponse = await response.text()
          .then((data) => {
            return data
          }).catch((e) =>{
            return e
          })
        throw Error(textResponse)
      }
    }  
  }

  async cancelNoise() {
    const response = await fetch(`/audiometr/cancel_noise`, {
      method: "POST",
      headers: this.headers,
    });
    if (!response.ok){
      if (response.status === 412) {
        const jsonResponse = await response.json()
          .then((data) => {
            return JSON.stringify(data);
          }).catch((e) => {
            return e
          })
        throw Error(jsonResponse)
      } else {
        const textResponse = await response.text()
          .then((data) => {
            return data
          }).catch((e) =>{
            return e
          })
        throw Error(textResponse)
      }
    }  
  }

  //do grania instrukcji
  async playInstruction(wavFiles, signal) {
    const response = await fetch(`/audiometr/play_wav`, {
      method: "POST",
      body: JSON.stringify({ wavFiles, level: INSTRUCTIONS_INTENSITY }),
      headers: this.headers,
      signal,
    })
    if (response.ok) {
      return response.json()
    } else if (response.status === 412) {
      const jsonResponse = await response.json()
        .then((data) => {
          return JSON.stringify(data);
        }).catch((e) => {
          return e
        })
      throw Error(jsonResponse)
    } else {
      const textResponse = await response.text()
        .then((data) => {
          return data
        }).catch((e) =>{
          return e
        })
      throw Error(textResponse)
    }
  }

  async audiometrSetup(signal) {
    return await fetch(`/audiometr/setup`, {
      method: "POST",
      headers: this.headers,
      signal,
    });
  }

  async audiometrClose() {
    return await fetch(`/audiometr/close`, {
      method: "POST",
      headers: this.headers,
    });
  }

  async coverOpenAndWait() {
    if (WITH_COVER !== false) {
      const response = await fetch("/lock/openAndWait",{
        method: "POST",
        headers: this.headers,
      })
      if (response.ok) {
        return;
      }  else if (response.status === 412) {
        const jsonResponse = await response.json()
          .then((data) => {
            return JSON.stringify(data);
          }).catch((e) => {
            return e
          })
        throw Error(jsonResponse)
      } else {
        const textResponse = await response.text()
          .then((data) => {
            return data
          }).catch((e) =>{
            return e
          })
        throw Error(textResponse)
      }
    } else {
      return;
    }
  }

  async coverCloseAndWait() {
    if (WITH_COVER !== false) {
      const response = await fetch("/lock/closeAndWait",{
        method: "POST",
        headers: this.headers,
      })
      if (response.ok) {
        return;
      }  else if (response.status === 412) {
        const jsonResponse = await response.json()
          .then((data) => {
            return JSON.stringify(data);
          }).catch((e) => {
            return e
          })
        throw Error(jsonResponse)
      } else {
        const textResponse = await response.text()
          .then((data) => {
            return data
          }).catch((e) =>{
            return e
          })
        throw Error(textResponse)
      }
    } else {
      return;
    }
  }
}

class WebModeApi extends Api {
  playWav(wavFiles, level, signal) {
    const context = new AudioContext();
    let audios = new Array(0);

    let error = null;

    context.resume();

    wavFiles.forEach(wav => {
      let audio = new Audio(`/wavs/${wav.fileId}.wav`);

      let source = context.createMediaElementSource(audio);
  
      const pannerOptions = {pan: 0};
      if (wav.channel === "LEFT") {
        pannerOptions.pan = -1;
      } else if (wav.channel === "RIGHT") {
        pannerOptions.pan = 1;
      }
      const panner = new StereoPannerNode(context, pannerOptions);
      source.connect(panner).connect(context.destination);

      audio.pause();
      audio.currentTime = 0;

      audio.play().then(() => {
      }).catch(e => {
        error = e;
      });

      audios.push(audio);
    });
  
    this.audio = audios[0];

    return new Promise((resolve, reject) => {
      if (error) {
        reject(error);
      } else {
        audios[0].onended = resolve;
      }
    });
  }

  audiometryPlay(sound) {
    try {
      this._oscillator && this._oscillator.stop() 
    } catch {};

    const context = new AudioContext();
    context.resume();

    const oscillator = context.createOscillator();
    this._oscillator = oscillator;

    oscillator.type = 'sine';
    oscillator.frequency.setValueAtTime(sound.freqInt, context.currentTime);

    const pannerOptions = {pan: 0};
    if (sound.channel === "HEAD_PHONES_LEFT") {
      pannerOptions.pan = -1;
    } else if (sound.channel === "HEAD_PHONES_RIGHT") {
      pannerOptions.pan = 1;
    }
    const panner = new StereoPannerNode(context, pannerOptions);

    const gainNode = context.createGain();
    gainNode.gain.setValueAtTime((sound.levelFloat.slice ? sound.levelFloat.slice(0, -1) : sound.levelFloat) / 100, context.currentTime);

    oscillator.connect(gainNode).connect(panner).connect(context.destination);

    const start = Date.now();

    return new Promise((resolve, reject) => {
      oscillator.start();
      oscillator.stop(context.currentTime + (sound.timeGenInt / 1000));

      oscillator.onended = () => {
        const stop = Date.now();
        resolve({start, stop})
      }
    });
  }

  async louderQuieterPlay(sound) {
    this.audiometryPlay(sound); 
  }

  stopPlaying() {
    return new Promise(resolve => {
      this.audio && this.audio.pause();
      this._oscillator && this._oscillator.stop();
      resolve();
    });
  }

  playInstruction(wavFiles, signal) {
    var wav = {
      levelFloat: `${INSTRUCTIONS_INTENSITY}f`,
      wavNoLeft: wavFiles,
      wavNoRight: wavFiles,
    };

    let audio = new Audio(`/wavs/${wavFiles[0].fileId}.wav`);

    audio.pause();
    audio.currentTime = 0;

    this.audio = audio;

    return new Promise((resolve, reject) => {
      audio.onended = resolve;
      audio.play().then(() => {
      }).catch(e => {
        reject(e);
      });
    });
  }
  async cancelNoise() {}
  async audiometrSetup() { return new Response(null, {status: 200}) }
  async audiometrClose() { return new Response(null, {status: 200}) }
}

const ApiContext = createContext(new Api());
const ApiProvider = ApiContext.Provider;

const useApi = () => {
  return useContext(ApiContext);
}

export { ApiProvider, useApi, Api, WebModeApi };
