import { Stack, Typography, Box } from "@mui/material";
import HearButton from "../components/animations/HearButton";
import ListenCarefullyAnimation from "../components/animations/ListenCarefullyAnimation";
import { ReactComponent as LeftEar } from "../resources/images/ear-left.svg";
import { ReactComponent as RightEar } from "../resources/images/ear-right.svg";
import { texts } from "../resources/texts";

const AudiometryHearButtonLayout = ({
  channel,
  displayHearButton,
  handleClick,
}) => {
  return (
    <Stack
      justifyContent="space-around"
      alignItems="center"
      spacing={5}
      sx={{ mt: 1 }}
    >
      <Box>
        <Typography
          variant="body1"
          sx={{
            display: "inline",
            fontSize: 17,
            mr: 1,
            color: (theme) => theme.palette.text.secondary,
          }}
        >
          {`${texts.testedEar}: `.toUpperCase()}
        </Typography>
        <Typography
          variant="body1"
          sx={{
            display: "inline",
            fontSize: 17,
            color: channel === "HEAD_PHONES_RIGHT" ? "#E3400D" : "#0083CE",
          }}
        >
          {`${(channel === "HEAD_PHONES_RIGHT"
            ? texts.right
            : texts.left
          ).toUpperCase()}`}
        </Typography>
      </Box>
      <ListenCarefullyAnimation />
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ width: "100%" }}
      >
        {channel === "HEAD_PHONES_RIGHT" ? (
          <Box sx={{ width: "500px" }}>
            <Typography
              variant="h4"
              sx={{ width: "300px", fontSize: 22, textAlign: "left" }}
            >
              {texts.audiometry.clickHearButtonInstruction}
            </Typography>
          </Box>
        ) : (
          <LeftEar />
        )}
        <HearButton
          handleClick={handleClick}
          ear={channel}
          active={displayHearButton}
        />
        {channel === "HEAD_PHONES_RIGHT" ? (
          <RightEar />
        ) : (
          <Box sx={{ width: "500px", display: "flex", justifyContent: "end" }}>
            <Typography
              variant="h4"
              sx={{ width: "300px", fontSize: 22, textAlign: "right" }}
            >
              {texts.audiometry.clickHearButtonInstruction}
            </Typography>
          </Box>
        )}
      </Stack>
    </Stack>
  );
};

export default AudiometryHearButtonLayout;
