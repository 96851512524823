import { styled, Button } from "@mui/material";

export const FinishButton = styled(Button)(({ theme }) => ({
  borderRadius: "50px",
  fontFamily: "Montserrat",
  fontSize: "14px",
  fontWeight: 700,
  letterSpacing: "1px",
  color: theme.palette.primary.main,
  width: "200px",
  height: "45px",
  backgroundColor: "#F1F1F1",
  border: "1px solid #86D7E3",
  position: "fixed",
  top: 40,
  right: 40,
  "&:active": {
    backgroundColor: theme.palette.secondary.main,
  },
  "&:hover": {
    backgroundColor: theme.palette.secondary.main,
  },
}));
