import { useState } from "react";
import { IconButton } from "@mui/material";
import { ReactComponent as OkRight } from "../../resources/images/ok-button-right.svg";
import { ReactComponent as OkLeft } from "../../resources/images/ok-button-left.svg";
import { ReactComponent as OkInactive } from "../../resources/images/ok-button-inactive.svg";
import { ReactComponent as OkActive } from "../../resources/images/ok-button-active.svg";
import okRim from "../../resources/images/ok-button-rim.svg";

const OkButton = ({ active, onClick, rightEar }) => {
  const [clicked, setClicked] = useState("");
  return (
    <IconButton
      disableRipple
      disabled={!active}
      onPointerDown={() => {
        onClick && onClick();
        setClicked("clicked");
        setTimeout(() => {
          setClicked("");
        }, 500);
      }}
    >
      {clicked ? (
        rightEar ? (
          <OkRight />
        ) : (
          <OkLeft />
        )
      ) : active ? (
        <OkActive />
      ) : (
        <OkInactive />
      )}
      <img src={okRim} className={"quieter-louder " + clicked} />
    </IconButton>
  );
};

export default OkButton;
