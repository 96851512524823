import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  palette: {
    primary: {
      main: "#124795",
    },
    secondary: {
      main: "#86D7E3",
    },
    text: {
      primary: "#124795",
      secondary: "#61C3D2",
      disabled: "#AFAEAE",
      rightEar: "#E3400D",
      leftEar: "#0083CE",
      options: "#393939",
    },
  },

  typography: {
    fontFamily: ["Montserrat", "Raleway", "sans-serif"].join(","),
    color: "#124795",
    fontSize: 12,
    align: "center",
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    answers: {
      fontFamily: "Montserrat",
      fontSize: 25,
      fontWeight: 500,
    },
    h1: {
      fontFamily: "Raleway",
      fontSize: 28,
      fontWeight: "bold",
      letterSpacing: 1,
    },
    h2: {
      fontFamily: "Montserrat",
      fontSize: 17,
      fontWeight: 600,
    },
    h3: {
      fontFamily: "Raleway",
      fontSize: 18,
      fontWeight: "bold",
    },
    h4: {
      fontFamily: "Montserrat",
      fontSize: 25,
    },
    h5: {
      fontFamily: "Raleway",
      fontSize: 20,
      fontWeight: "bold",
    },
    h6: {
      fontFamily: "Raleway",
      fontSize: 15,
      fontWeight: 600,
      letterSpacing: 1,
    },
    body1: {
      fontFamily: "Montserrat",
      fontSize: 17,
      color: "#707070",
      fontWeight: 600,
    },
    title: {
      fontFamily: "Montserrat",
      fontSize: 45,
      fontWeight: 600,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        contained: {
          borderRadius: "80px",
          fontFamily: "Montserrat",
          fontSize: "19px",
          fontWeight: "bold",
          color: "#ffffff",
          height: 56,
        },
        root: {
          "&.Mui-disabled": {
            color: "white",
          },
        },
      },
    },
    MuiTypography: {
      defaultProps: {
        align: "center",
        color: "#124795",
      },
    },

    MuiInputLabel: {
      styleOverrides: {
        root: {
          textAlign: "center",
          transformOrigin: "center",
          width: "390px",
          color: "#393939",
        },
      },
    },

    MuiInput: {
      styleOverrides: {
        underline: {
          "&:before": {
            borderBottomColor: "#61C3D2",
          },
          "&:hover:not(.Mui-focused):before": {
            borderBottomColor: "#61C3D2",
          },
          "&:after": {
            // focused
            borderBottomColor: "#61C3D2",
          },
        },
        input: {
          textAlign: "center",
          "&:-webkit-autofill": {
            WebkitBoxShadow: "0 0 0 100px #fcfcfc inset",
            // WebkitTextFillColor: "#000",
          },
        },
      },
    },
  },
});
