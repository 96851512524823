import { Box, Typography, Stack,  Paper } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Api, ApiProvider, useApi, WebModeApi } from "./Api";
import LoginPage from "./pages/LoginPage";
import PackagesPage from "./pages/PackagesPage";
import Header from "./components/Header";
import TestCreator from "./tests/TestCreator";
import PackageFinishedInfo from "./components/PackageFinishedInfo";
import moment from "moment"
import ScreeningsPage from "./pages/ScreeningsPage";
import UserSelectionPage from "./pages/UserSelectionPage";
import Loader from "./components/animations/Loader";
import { WebsocketConnectionProvider } from "./Notifications";
import Test from "./tests/TestClass";
import { texts } from "./resources/texts";
import { AudiometrConnectionStatus, FailureReason, Result } from "./tests/enums";
import HeadphonesInstruction from "./pages/HeadphonesInstruction";
import TestSummary from "./components/TestSummary";
import { AGE_FROM_WHICH_ADULT_VERSIONS_START } from "./resources/wavFiles";
import { useNotificationSubject } from "./Notifications";
import { OpenCoverInfo, CloseCoverInfo } from "./components/CoverInfo";
import bg from "./resources/images/bg.svg";

const defaultScreeningData = {
  testsData: [],
  result: null,
};

const LoginState = ({onStateChange}) => {
  const api = useApi();

  const handleLogin = (user) => {
    onStateChange("user-selection", {user, account: user});
  }

  const handleError = (e) => {
    onStateChange("error", { error: e } );
  }

  useEffect(() => {
    api.logout();
  }, [api]);

  return <Header><LoginPage onLogin={handleLogin} onError={handleError} /></Header>
}

const ErrorState = ({error, onStateChange, code}) => {

  useEffect(() => {
    document.body.style.backgroundImage = `url(${bg})`;
  }, []);

  const handleLogout = () => {
    onStateChange("close-cover", {});
  }

  return (
    <Header onLogout={handleLogout}>
      <Box
        sx={{
          pt: 16,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Paper
          sx={{
            borderRadius: "10px",
            p:8,
          }}
        >
          <Stack
            alignItems="center"
            justifyContent="center"
            spacing={4}
            direction="column"
          >
            {error === "no-examinations" && (
              <Typography variant="h4" sx={{ fontWeight: 600 }}>
                {texts.noExaminationsFoundInfo}
              </Typography>
            )}
              <Typography variant="h4" maxWidth="sm">
                {error instanceof Error 
                  ? `${texts.errorOccured} ${error.message}` 
                  : error==="no-examinations" 
                    ? texts.activateVoucherOrBuyInfo
                    : error}
              </Typography>
              {code && <Typography variant="h4" sx={{fontSize: 20}}>{`${texts.errorCode} ${code}`}</Typography>}
          </Stack>
        </Paper>
      </Box>
    </Header>
  );
};

const UserSelectionState = ({user, onStateChange}) => {
  const api = useApi();
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);

  const handleUserSelection = (userId) => {
    if (userId === user.id) {
      onStateChange("screenings", {user, account: user});
    } else {
      const subaccount = users.find((u) => u.id === userId);
      if (subaccount) {
        setLoading(true);
        api.impersonateSubaccount(subaccount.id)
          .then(() => {
            onStateChange("screenings", {user: subaccount, account: user});
          })
          .catch((e) => {
            onStateChange("error", { error: e } );
          })
          .finally(() => {
            setLoading(false);
          })
      } else {
        //shouldn't happen
        onStateChange("error", new Error(texts.cantLogin))
      }
    }
  }

  const handleLogout = () => {
    onStateChange("close-cover", {});
  }

  useEffect(() => {
    setLoading(true);
    api.getSubaccounts()
      .then((data) => {
        const subordinates = data.content;
        if (subordinates.length > 0) {
          setUsers([user, ...subordinates]);
        } else {
          onStateChange("screenings", {user, account: user});
        }
      })
      .catch((e) => {
        onStateChange("error", { error: e } );
      })
      .finally(() => {
        setLoading(false);
      });
  }, [user, onStateChange, api]);

  return <Header account={user} onLogout={handleLogout}><UserSelectionPage loading={loading} users={users} onUserSelection={handleUserSelection} /></Header>
}

const ScreeningsState = ({ user, onStateChange, account }) => {
  const api = useApi();
  const [screenings, setScreenings] = useState();
  const [userScreenings, setUserScreenings] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleLogout = () => {
    onStateChange("close-cover", {});
  }

  const handleScreeningsSelection = (patientScreeningId) => {
    const screening = userScreenings.find(us => us.patientScreeningId === patientScreeningId);
    onStateChange("packages", { user, screenings, screening, account });
  }

  const handleUndo = () => {
    onStateChange("user-selection", {user: account, account});
  }

  useEffect(() => {
    setLoading(true);
    api.getScreenings()
      .then((screenings) => {
        setScreenings(screenings);
        const userScreenings = screenings.patientAvailableScreenings
          .map(pas => {
            const screeningDetails = screenings.screenings.find(s => s.screeningId === pas.screeningId);
            if (!screeningDetails) return null;
            const patientPackages = pas.requiredExaminationPackages
              .map(rep => screenings.examinationPackages
                .find(ep => ep.hearBoxTestId === rep))
                .filter(ep => ep !== null && !pas.examinationResults.find(er => er.hearBoxTestId === ep.hearBoxTestId && er.finished));
            
            if (patientPackages.length === 0) return null;    
            return {
              ...pas,
              screeningDetails,
              patientPackages,
            }
          })
          .filter(pas => pas !== null);
        
        if (userScreenings.length > 0) {
          setUserScreenings(userScreenings);
        } else {
          onStateChange("error", { error: "no-examinations" } );
        }
      })
      .catch((e) => {
        onStateChange("error", { error: e } )
      })
      .finally(() => {
        setLoading(false);
      })
  }, [user, onStateChange]);

  return (
    <Header account={account} onLogout={handleLogout}>
      <ScreeningsPage
        user={user}
        screenings={userScreenings}
        onScreeningSelection={handleScreeningsSelection}
        loading={loading}
        onUndo={user && account && user.id === account.id ? handleUndo : undefined}
      />
    </Header>
  )
}

const PackagesState = ({user, screenings, screening, onStateChange, account}) => {

  const handleLogout = () => {
    onStateChange("close-cover", {});
  }

  const packages = screening.patientPackages;
  if (packages.length === 0) {
    onStateChange("error", {error: "no-examinations"})
    return null;
  }

  const handlePackageSelection = (packageId) => {
    const examinationPackage = packages.find(p => p.hearBoxTestId === packageId);
    onStateChange("test", {user, screenings, screening, examinationPackage, account});
  }

  const handleUndo = () => {
    onStateChange("screenings", {user, account});
  }

  return (
    <Header onLogout={handleLogout} account={account} user={user}>
      <PackagesPage
        screening={screening}
        packages={packages}
        onPackageSelection={handlePackageSelection}
        loading={false}
        onUndo={handleUndo}
      />
    </Header>
  );
}

const isCAPD = (testType) => {
  return ["TDW","TSW","TSD","TPR","TRC","TRS","TRMS","TMS","TMF"].includes(testType);
}

const TestState = ({
  user,
  screenings,
  screening,
  examinationPackage,
  onStateChange,
  account
}) => {
  const api = useApi();
  const [examination, setExamination] = useState({ 
    index: 0, 
    options: {} 
  });
  const [nextExamination, setNextExamination] = useState();
  const [screeningData, setScreeningData] = useState({...defaultScreeningData, ts: moment().toISOString()});
  const [logoutFromTest, setLogoutFromTest] = useState(false);
  const [setupBTConnectionsFinished, setSetupBTConnectionsFinished] = useState(false);
  const [startExamination, setStartExamination] = useState(false);
  const [displayedCAPDDescription, setDisplayedCAPDDescription] = useState(isCAPD(examinationPackage.examinations[0]));
  const [showCAPDDescription, setShowCAPDDescription] = useState(isCAPD(examinationPackage.examinations[0]));
  const [coverOpened, setCoverOpened] = useState(false);
  const userAge = useMemo(() => {
    return user.dateOfBirth ? moment().diff(user.dateOfBirth, "years") : 21
  }, [user]);
  const notificationSubject = useNotificationSubject();
  const [error, setError] = useState();
  const [audiometrDisconnected, setAudiometrDisconnected] = useState(false);
  
  const abortController = useMemo(() => new AbortController(), []);

  useEffect(() => {
    document.body.style.backgroundImage = `url(${bg})`;

    return () => {
      abortController.abort();
    };
  }, []);

  useEffect(() => {
    const notificationObserver = (notification) => {
      switch (notification.type) {
        case "ExceptionWithCodeAndTimeStamp":
          if (notification.interfaceGroupingExceptions?.startsWith("OperatorActionRequired") 
          || notification.interfaceGroupingExceptions?.startsWith("ExaminationImpossible")) {
            setError(JSON.stringify(notification));
          } else if (notification.exceptionClassName === AudiometrConnectionStatus.CONNECTED) {
            setAudiometrDisconnected(false);
          } else if (notification.exceptionClassName === AudiometrConnectionStatus.DISCONNECTED) {
            setAudiometrDisconnected(true);
          }
          break;
        default:
          break;
      }
    };
    notificationSubject.attach(notificationObserver);

    return () => {
      notificationSubject.detach(notificationObserver);
    };
  }, [notificationSubject]);

  useEffect(()=>{
    api.coverOpenAndWait(abortController.signal).then(()=> {
      setCoverOpened(true);
    }).catch((e)=> {
      if (!abortController.signal.aborted) {
        var errorCode;
        try {
          errorCode = JSON.parse(e.message).code;
        } catch (e) {}
        var error = errorCode ? errorCode === 30001 ? texts.headphonesNotRemoved : errorCode : e.message;
        
        onStateChange("error", {error: new Error(error)});
      }
    })
  },[api])

  useEffect(() => {
    coverOpened && api.audiometrSetup(abortController.signal).then((response) => {
      if (response.status === 200) {
        setSetupBTConnectionsFinished(true);
      } else if (response.status === 412) {
        response.json()
          .then((data) => {
            console.error(data.code);
            onStateChange("error", { error: new Error(data.code) });
          })
          .catch((e) => {
            console.error(e);
            onStateChange("error", { error: texts.cantSetupAudiometr });
          });
      } else {
        onStateChange("error", {error: texts.cantSetupAudiometr});
      }
    }).catch((e) => {
      if(!abortController.signal.aborted) {
        console.error(e);
        onStateChange("error", { error: texts.cantSetupAudiometr });
      }
    });
  }, [api, coverOpened]);

  const sendResults = (screeningData, onSuccess) => {
    const payload = {
      header: {
        patientScreeningId: screening.patientScreeningId,
        // hearBoxId: ,
        hearBoxScreeningId: screening.examinationResults.find(er => er.hearBoxTestId === examinationPackage.hearBoxTestId)?.hearBoxScreeningId,
        screeningId: screening.screeningId,
        hearBoxTestId: examinationPackage.hearBoxTestId,
        tenantId: screening.tenantId,
        patient: {
          id: user.id,
          yearOfBirth: user.dateOfBirth ? moment(user.dateOfBirth).year() : moment().year() - userAge,
          monthOfBirth: user.dateOfBirth ? moment(user.dateOfBirth).month() + 1 : 1,
          firstName: user.firstName,
          lastName: user.surname
        },
        ts: screeningData.ts,
        result: screeningData.result,
        finished: screeningData.finished !== undefined ? screeningData.finished : true
      },
      data: {
        testsData: screeningData.testsData,
        startTs: screeningData.ts,
        finishTs: moment().toISOString()
      }
    }
    api.sendResults(payload)
      .then(() => {
        onSuccess && onSuccess();
      })
      .catch((e) => {
        onStateChange("error", { error: new Error(e) });
      })
  }

  const updateScreeningData = (data) => {
    const calculateResult = (curRes, newRes) => {
      if (!curRes) {
        return newRes;
      } else if (curRes === Result.OK && newRes === Result.OK) {
        return Result.OK;
      } else if (curRes === Result.NOK || newRes === Result.NOK) {
        return Result.NOK;
      } else {
        return Result.AMB;
      }
    }

    const newScreeningData = {
      ...screeningData,
      testsData: [
        ...screeningData.testsData.filter((d) => d.testType !== data.testType),
        data
      ],
      result: calculateResult(screeningData.result, data.result)
    };
    setScreeningData(newScreeningData);

    return newScreeningData;
  }

  const handleEvent = (event, data) => {
    switch (event) {
      case "FINISHED": {
        const updatedData = updateScreeningData(data);
        if (data.failureReason === FailureReason.ERROR) {
          sendResults({...updatedData, finished: false}, () => {
            var errorCode;
            try {
              errorCode = JSON.parse(data.error).code;
            } catch (e) {}

            onStateChange("error", { error: texts.unexpectedErrorInfo, code: errorCode});
          });
        } else if (data.failureReason !== FailureReason.INACTIVITY_LOGOUT && data.failureReason !== FailureReason.CANCELLED) {
          prepareNextExamination(updatedData);
        } else {
          sendResults(updatedData, () => {
            onStateChange("close-cover", {account});
          });
        }
        break;
      }
      default: {
        break;
      }
    }
  }

  const prepareNextExamination = (screeningData) => {
    const options = {};
    let nextExIndex = examination.index + 1;
    const foundAP = screeningData.testsData.find(({testType}) => testType === "AP");

    if (foundAP && foundAP.data) {
      do {
        const nextExaminationId = examinationPackage.examinations[nextExIndex];
        if (nextExaminationId === "GC") {
          if (foundAP.data.result !== Result.OK) {
            options.excludedFrequenciesRight = 
              foundAP.data.frequenciesResultRight
                .filter(({answer}) => !isNaN(answer) && answer !== "")
                .map(({frequency}) => frequency);
            options.excludedFrequenciesLeft = 
              foundAP.data.frequenciesResultLeft
                .filter(({answer}) => !isNaN(answer) && answer !== "")
                .map(({frequency}) => frequency);
            break;
          }
        } else if (nextExaminationId === "AS") {
          const foundGC = screeningData.testsData.find(({testType}) => testType === "GC");

          const rightEarOK = Boolean(
            foundAP.data.frequenciesResultRight.find(r => r.frequency === 1000 && !isNaN(r.answer) && r.answer !== "")
            || (foundGC?.data && Test.checkThresholdFor1000PerOneEar(
              foundGC.data.frequenciesResultRight
            ) === Result.OK)
          );
          const leftEarOK = Boolean(
            foundAP.data.frequenciesResultLeft.find(r => r.frequency === 1000 && !isNaN(r.answer) && r.answer !== "")
            || (foundGC?.data && Test.checkThresholdFor1000PerOneEar(
              foundGC.data.frequenciesResultLeft
            ) === Result.OK)
          );

          if (rightEarOK || leftEarOK) {
            options.rightCorrect = rightEarOK;
            options.leftCorrect = leftEarOK;
            break;
          }
        } else if (foundAP.data.result === Result.OK) {
          break;
        }
        nextExIndex++;
      } while (examinationPackage.examinations.length > nextExIndex);
    }
    if (!displayedCAPDDescription && isCAPD(examinationPackage.examinations[nextExIndex])) {
      setShowCAPDDescription(true);
      setDisplayedCAPDDescription(true);
    } else {
      setShowCAPDDescription(false);
    }

    setNextExamination({
      index: nextExIndex,
      options
    });
  }

  const handleNextExamination = () => {
    if (examinationPackage.examinations.length > nextExamination?.index) {
      setExamination(nextExamination);
      setNextExamination();
    } else {
      sendResults(screeningData, () => {
        onStateChange("package-finished", { examinationPackage, user, account });
      });
    }
  }

  const handleLogout = () => {
    setLogoutFromTest(true)
  }

  const handleError = (e) => {
    onStateChange("error", { error: e } );
  }

  const handleErrorInTestSummary = (e) => {
    setError(JSON.stringify(e));
  }

  return (
    <Header
      onLogout={() => startExamination && setupBTConnectionsFinished ? handleLogout() : onStateChange("close-cover", {account})}
      progress={examination.index + 1}
      examinations={examinationPackage.examinations}
      startedExamination={startExamination && setupBTConnectionsFinished}
      user={user}
      account={account}
      forKids={userAge < AGE_FROM_WHICH_ADULT_VERSIONS_START}
      audiometrDisconnected={audiometrDisconnected}
    >
      {setupBTConnectionsFinished && startExamination ? (
        <Box sx={{height: "100%"}}>
          <TestSummary
            open={Boolean(nextExamination)}
            currentTestData={screeningData.testsData.find(
              (td) =>
                td.testType === examinationPackage.examinations[examination.index]
            )}
            nextTestId={
              nextExamination && examinationPackage.examinations.length > nextExamination.index
                ? examinationPackage.examinations[nextExamination.index]
                : null
            }
            onNextTest={handleNextExamination}
            onError={handleErrorInTestSummary}
          />
          <TestCreator
            key={examination.index}
            testEnum={examinationPackage.examinations[examination.index]}
            userAge={userAge}
            onEvent={handleEvent}
            logoutFromTest={logoutFromTest}
            onError={handleError}
            options={examination.options}
            showCAPDDescription={showCAPDDescription}
            error={error}
          />
        </Box>
      ) : !coverOpened ? (
        <OpenCoverInfo />
      ) : !startExamination ? (
          <HeadphonesInstruction handleNext={() => setStartExamination(true)} />
      ) : (
        <Loader text={texts.connectingToDevices} />
      )}
    </Header>
  );
}

const PackageFinishedState = ({examinationPackage, onStateChange, user, account}) => {
  const handleLogout = () => {
    onStateChange("close-cover", {account});
  }

  const returnToMainPage = () => {
    onStateChange("user-selection", {user, account});
  }

  useEffect(() => {
    document.body.style.backgroundImage = `url(${bg})`;
  }, []);

  return (
    <Header user={user} account={account}>
      <PackageFinishedInfo 
        packageName={examinationPackage.label} 
        onLogout={handleLogout} 
        returnToMainPage={user && account && user.id === account.id ? returnToMainPage : undefined}
      />
    </Header>
  );
}

const CloseCoverState = ({onStateChange, account}) => {
  const api = useApi();
  const notificationSubject = useNotificationSubject();

  useEffect(() => {
    const notificationObserver = (notification) => {
      switch (notification.type) {
        case "ExceptionWithCodeAndTimeStamp":
          if (notification.exceptionClassName === "CoverClosed") {
            onStateChange("login", {});
          }
          break;
        default:
          break;
      }
    };
    notificationSubject.attach(notificationObserver);

    return () => {
      notificationSubject.detach(notificationObserver);
    };
  }, [notificationSubject]);

  useEffect(()=>{
    api.coverCloseAndWait().then(()=> {
      onStateChange("login", {});
    }).catch((e)=> {
      //jeśli w danym czasie klapka nie zostanie zamknięta, wtedy czekamy na wiadomość websocketem o zamknięciu klapki
    })
  },[api]);

  return <Header account={account}><CloseCoverInfo /></Header>
}

const HearboxManager = () => {
  const [pageContext, setPageContext] = useState({state: "login", context: {}});

  const handleStateChange = useCallback((state, context = {}) => {
    setPageContext({state, context});
  }, []);

  //TODO: Provide Api based on config
  const api = useMemo(() => new WebModeApi(), []);

  const stateComponent = useMemo(() => {
    switch (pageContext.state) {
      case "login": {
        return <LoginState onStateChange={handleStateChange} {...pageContext.context} />;
      }
      case "user-selection": {
        return <UserSelectionState onStateChange={handleStateChange} {...pageContext.context} />;
      }
      case "error": {
        return <ErrorState onStateChange={handleStateChange}  {...pageContext.context} />;
      }
      case "screenings": {
        return <ScreeningsState onStateChange={handleStateChange}  {...pageContext.context} />;
      }
      case "packages": {
        return <PackagesState onStateChange={handleStateChange} {...pageContext.context} />;
      }
      case "test": {
        return <TestState onStateChange={handleStateChange} {...pageContext.context} />;
      }
      case "package-finished": {
        return <PackageFinishedState onStateChange={handleStateChange} {...pageContext.context} />;
      }
      case "close-cover": {
        return <CloseCoverState onStateChange={handleStateChange} {...pageContext.context} />;
      }
      default: {
        return null;
      }
    }  
  }, [pageContext, handleStateChange]);

  return (
    <ApiProvider value={api}>
      <WebsocketConnectionProvider>
        {stateComponent}
      </WebsocketConnectionProvider>
    </ApiProvider>
  );
}

export default HearboxManager;
