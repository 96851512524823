import { useEffect, useState } from "react";
import { Box } from "@mui/material";
import SingleFreqAudiometry from "./SingleFreqAudiometry";
import ProgressSlider from "../components/ProgressSlider";
import AudiometryInfo from "../components/AudiometryInfo";
import CurrentStateInfo from "../components/CurrentStateInfo";
import { getEmptyFreq } from "../resources/exportedFunctions";
import { texts, testNames } from "../resources/texts";

//audiometria przesiewowa
const Audiometry = ({
  audiometry,
  currentChannel,
  changeCurrentChannel,
  updateAudiometry,
  frequencies,
  finished,
  changeFinished,
  stopped,
  setReadyToFinish,
  onError,
  trainingProp,
}) => {
  const [currentFreq, setCurrentFreq] = useState();
  const [training, setTraining] = useState(trainingProp);

  useEffect(() => {
    audiometry && changeFreq();
  }, [currentChannel, audiometry]);

  useEffect(() => {
    if (audiometry && currentFreq) {
      if (finished) {
        setReadyToFinish(true);
      }
    }
  }, [audiometry]);

  const changeFreq = async () => {
    var emptyFreq = getEmptyFreq(audiometry, currentChannel);
    if (emptyFreq === "end" && currentChannel === "HEAD_PHONES_RIGHT") {
      changeCurrentChannel("HEAD_PHONES_LEFT");
    } else if (emptyFreq !== "end") {
      setCurrentFreq(emptyFreq);
    } else {
      setCurrentFreq(emptyFreq);
      changeFinished(true);
      setReadyToFinish(true);
    }
  };

  return (
    <Box
    //sx={{ height: "70%" }}
    >
      <CurrentStateInfo
        firstLine={`${training ? texts.doingExcercise : texts.examination}:`}
        secondLine={testNames["AP"]}
      />
      {currentFreq && currentFreq !== "end" && !finished && (
        <Box sx={{ height: "100%" }}>
          <SingleFreqAudiometry
            frequency={currentFreq}
            channel={currentChannel}
            updateAudiometry={updateAudiometry}
            stopped={stopped}
            training={training}
            finishTraining={() => setTraining(false)}
            onError={onError}
          />
          <AudiometryInfo
            ear={
              currentChannel === "HEAD_PHONES_RIGHT" ? texts.right : texts.left
            }
            freq={currentFreq}
            sx={{ mb: 1 }}
          />
          {!training && (
            <ProgressSlider
              title={texts.testProgress}
              currentValue={
                currentChannel === "HEAD_PHONES_RIGHT"
                  ? frequencies.findIndex((freq) => freq === currentFreq) + 1
                  : frequencies.findIndex((freq) => freq === currentFreq) +
                    1 +
                    frequencies.length
              }
              max={frequencies.length * 2}
              sx={{ display: "inline" }}
            />
          )}
        </Box>
      )}
    </Box>
  );
};

export default Audiometry;
