import { Box } from "@mui/material";
import { ReactComponent as HeadphonesSVG } from "../../resources/images/headphones.svg";
import circles from "../../resources/images/circles.svg";
import "./index.css";

const Headphones = () => {
  return (
    <Box sx={{ position: "relative", left: "90px" }}>
      <HeadphonesSVG className={"slight-rotate"} sx={{ display: "inline" }} />
      <img className="circles" src={circles} />
    </Box>
  );
};
export default Headphones;
