import { Box, Stack, IconButton, Typography } from "@mui/material";
import { answerToText } from "../resources/texts";
import { imgSrc } from "./CircleAnswer";

const AnswerGapNoGap = ({ onClick, answer }) => {
  const [first, ...rest] = answerToText(answer.text).toUpperCase().split(" ");
  return (
    <Stack alignItems="center">
      <IconButton
        disableRipple
        onClick={() => {
          onClick && onClick();
        }}
      >
        <img src={imgSrc(answer.image)} />
      </IconButton>
      <Box>
        <Typography
          variant="answers"
          sx={{
            color: (theme) => theme.palette.text.secondary,
          }}
        >
          {first}
        </Typography>{" "}
        <Typography variant="answers">{rest.join(" ")}</Typography>
      </Box>
    </Stack>
  );
};

export default AnswerGapNoGap;
