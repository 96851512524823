const Volume = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="151.276"
      height="49.638"
      viewBox="0 0 151.276 49.638"
    >
      <g
        id="Group_1741"
        data-name="Group 1741"
        transform="translate(-873.639 -759.612)"
      >
        <g
          id="Group_1730"
          data-name="Group 1730"
          transform="translate(874.639 769.154)"
        >
          <path
            id="Path_1000"
            data-name="Path 1000"
            d="M2929.576,1988.955V2023.3c-.945-.644-1.6-1.072-2.238-1.529-2.789-2-5.681-3.88-8.329-6.056-3.11-2.555-6.332-4.3-10.548-3.669-1.766.264-2.8-.795-2.8-2.663,0-2.165-.013-4.33.006-6.5.015-1.718.961-2.9,2.618-2.617,4.719.821,8.083-1.579,11.49-4.225C2922.827,1993.677,2926.035,1991.5,2929.576,1988.955Z"
            transform="translate(-2905.654 -1988.955)"
            fill="none"
            stroke={color ? color : "#afaeae"}
            strokeMiterlimit="10"
            strokeWidth="2"
          />
          <path
            id="Path_1001"
            data-name="Path 1001"
            d="M2982.679,1989.143a20.616,20.616,0,0,1,.08,27.287"
            transform="translate(-2941.635 -1985.612)"
            fill="none"
            stroke={color ? color : "#afaeae"}
            strokeLinecap="round"
            strokeMiterlimit="10"
            strokeWidth="2"
          />
          <path
            id="Path_1002"
            data-name="Path 1002"
            d="M2977.726,1997.222a16.246,16.246,0,0,1,.063,21.5"
            transform="translate(-2941.919 -1990.799)"
            fill="none"
            stroke={color ? color : "#afaeae"}
            strokeLinecap="round"
            strokeMiterlimit="10"
            strokeWidth="2"
          />
          <path
            id="Path_1003"
            data-name="Path 1003"
            d="M2973.011,2007.805a11.2,11.2,0,0,1,.043,14.821"
            transform="translate(-2941.568 -1997.594)"
            fill="none"
            stroke={color ? color : "#afaeae"}
            strokeLinecap="round"
            strokeMiterlimit="10"
            strokeWidth="2"
          />
        </g>
        <line
          id="Line_129"
          data-name="Line 129"
          y2="8.729"
          transform="translate(952.086 799.27)"
          fill="none"
          stroke={color ? color : "#61c3d2"}
          strokeLinecap="round"
          strokeWidth="2.5"
        />
        <line
          id="Line_130"
          data-name="Line 130"
          y2="13.967"
          transform="translate(960.815 794.033)"
          fill="none"
          stroke={color ? color : "#61c3d2"}
          strokeLinecap="round"
          strokeWidth="2.5"
        />
        <line
          id="Line_131"
          data-name="Line 131"
          y2="18.331"
          transform="translate(970.417 789.668)"
          fill="none"
          stroke={color ? color : "#61c3d2"}
          strokeLinecap="round"
          strokeWidth="2.5"
        />
        <line
          id="Line_132"
          data-name="Line 132"
          y2="23.569"
          transform="translate(979.146 784.431)"
          fill="none"
          stroke="#bebebe"
          strokeLinecap="round"
          strokeWidth="2.5"
        />
        <line
          id="Line_133"
          data-name="Line 133"
          y2="28.806"
          transform="translate(987.875 779.193)"
          fill="none"
          stroke="#bebebe"
          strokeLinecap="round"
          strokeWidth="2.5"
        />
        <line
          id="Line_134"
          data-name="Line 134"
          y2="33.171"
          transform="translate(996.605 774.829)"
          fill="none"
          stroke="#bebebe"
          strokeLinecap="round"
          strokeWidth="2.5"
        />
        <line
          id="Line_135"
          data-name="Line 135"
          y2="37.536"
          transform="translate(1006.207 770.464)"
          fill="none"
          stroke="#bebebe"
          strokeLinecap="round"
          strokeWidth="2.5"
        />
        <line
          id="Line_136"
          data-name="Line 136"
          y2="41.9"
          transform="translate(1014.936 766.099)"
          fill="none"
          stroke="#bebebe"
          strokeLinecap="round"
          strokeWidth="2.5"
        />
        <line
          id="Line_137"
          data-name="Line 137"
          y2="47.138"
          transform="translate(1023.665 760.862)"
          fill="none"
          stroke="#bebebe"
          strokeLinecap="round"
          strokeWidth="2.5"
        />
      </g>
    </svg>
  );
};

export default Volume;
