import { useEffect, useState } from "react";
import { Box } from "@mui/material";
import SingleFreqThresholdAudiometry from "./SingleFreqThresholdAudiometry";
import Test from "./TestClass";
import ProgressSlider from "../components/ProgressSlider";
import { lqConfig } from "../resources/tests_config/louderQuieterConfig";
import { getEmptyFreq } from "../resources/exportedFunctions";
import { texts } from "../resources/texts";

const ThresholdAudiometry = ({
  audiometryProp,
  updateAudiometryGlobal,
  displayFinishTestConfirmation,
  onError,
}) => {
  const [audiometry, setAudiometry] = useState(audiometryProp);
  const [freqAndChannel, setFreqAndChannel] = useState({
    currentFreq: null,
    currentChannel: "HEAD_PHONES_RIGHT",
  });
  const [finished, setFinished] = useState(false);
  const [startIntensity, setStartIntensity] = useState();

  const changeFreq = async () => {
    setStartIntensity();
    var channelToChange = freqAndChannel.currentChannel;
    var freqToChange;
    var emptyFreq = getEmptyFreq(audiometry, freqAndChannel.currentChannel);
    if (
      emptyFreq === "end" &&
      freqAndChannel.currentChannel === "HEAD_PHONES_RIGHT"
    ) {
      channelToChange = "HEAD_PHONES_LEFT";
      freqToChange = getEmptyFreq(audiometry, channelToChange);
      if (freqToChange === "end") {
        setFinished(true);
      }
    } else if (emptyFreq !== "end") {
      freqToChange = emptyFreq;
    } else {
      freqToChange = emptyFreq;
      setFinished(true);
    }
    setFreqAndChannel({
      currentFreq: freqToChange,
      currentChannel: channelToChange,
    });
  };

  useEffect(() => {
    audiometry.frequenciesResultRight && changeFreq();
  }, [audiometry]);

  useEffect(() => {
    updateAudiometryGlobal(audiometry);
  }, [audiometry]);

  useEffect(() => {
    if (freqAndChannel.currentFreq) {
      if (freqAndChannel.currentFreq === 1000) {
        setStartIntensity(lqConfig.startIntensity);
      } else if (freqAndChannel.currentFreq === 500) {
        var thresholdFor1000;
        if (freqAndChannel.currentChannel === "HEAD_PHONES_RIGHT") {
          thresholdFor1000 = audiometry.frequenciesResultRight.find(
            ({ frequency }) => frequency === 1000
          )?.answer;
        } else {
          thresholdFor1000 = audiometry.frequenciesResultLeft.find(
            ({ frequency }) => frequency === 1000
          )?.answer;
        }
        !isNaN(thresholdFor1000)
          ? setStartIntensity(
              thresholdFor1000 + 15 > lqConfig.maxStartIntensity
                ? lqConfig.maxStartIntensity
                : thresholdFor1000 + 15
            )
          : setStartIntensity(lqConfig.startIntensity);
      } else if (freqAndChannel.currentFreq === "end") {
        setFinished(true);
      } else {
        const currentFreqIndex = (
          freqAndChannel.currentChannel === "HEAD_PHONES_RIGHT"
            ? audiometry.frequenciesResultRight
            : audiometry.frequenciesResultLeft
        ).findIndex(
          ({ frequency }) => frequency === freqAndChannel.currentFreq
        );
        var prevThreshold;
        if (currentFreqIndex > 0) {
          if (freqAndChannel.currentChannel === "HEAD_PHONES_RIGHT") {
            prevThreshold =
              audiometry.frequenciesResultRight[currentFreqIndex - 1].answer;
          } else {
            prevThreshold =
              audiometry.frequenciesResultLeft[currentFreqIndex - 1].answer;
          }
        }
        !isNaN(prevThreshold)
          ? setStartIntensity(
              prevThreshold + 15 > lqConfig.maxStartIntensity
                ? lqConfig.maxStartIntensity
                : prevThreshold + 15
            )
          : setStartIntensity(lqConfig.startIntensity);
      }
    }
  }, [freqAndChannel]);

  function updateAudiometry(audiometryAnswer, audiometryDetails) {
    if (freqAndChannel.currentChannel === "HEAD_PHONES_RIGHT") {
      setAudiometry((prevState) => {
        const frequenciesResultRight = prevState.frequenciesResultRight.map(
          (result) =>
            result.frequency === audiometryAnswer.frequency
              ? { ...audiometryAnswer }
              : { ...result }
        );
        const resultRight = Test.checkThresholdsResultsPerOneEar(
          frequenciesResultRight
        );

        const resultLeft = Test.checkThresholdsResultsPerOneEar(
          prevState.frequenciesResultLeft
        );

        const result = Test.determineOverallResult(resultRight, resultLeft);
        return {
          ...prevState,
          resultLeft,
          resultRight,
          result,
          frequenciesResultRight: frequenciesResultRight,
          audiometryDetailsListRight: [
            ...prevState.audiometryDetailsListRight,
            audiometryDetails,
          ],
        };
      });
    } else {
      setAudiometry((prevState) => {
        const frequenciesResultLeft = prevState.frequenciesResultLeft.map(
          (result) =>
            result.frequency === audiometryAnswer.frequency
              ? { ...audiometryAnswer }
              : { ...result }
        );
        const resultRight = Test.checkThresholdsResultsPerOneEar(
          prevState.frequenciesResultRight
        );

        const resultLeft = Test.checkThresholdsResultsPerOneEar(
          frequenciesResultLeft
        );

        const result = Test.determineOverallResult(resultRight, resultLeft);
        return {
          ...prevState,
          resultLeft,
          resultRight,
          result,
          frequenciesResultLeft: frequenciesResultLeft,
          audiometryDetailsListLeft: [
            ...prevState.audiometryDetailsListLeft,
            audiometryDetails,
          ],
        };
      });
    }
  }

  return (
    <Box>
      {freqAndChannel.currentFreq &&
        freqAndChannel.currentFreq !== "end" &&
        startIntensity &&
        !finished && (
          <Box sx={{ height: "100%" }}>
            <SingleFreqThresholdAudiometry
              frequency={freqAndChannel.currentFreq}
              channel={freqAndChannel.currentChannel}
              updateAudiometry={updateAudiometry}
              startTone={startIntensity}
              stopped={displayFinishTestConfirmation}
              onError={onError}
            />
            <ProgressSlider
              title={texts.testProgress}
              currentValue={
                freqAndChannel.currentChannel === "HEAD_PHONES_RIGHT"
                  ? audiometry.frequenciesResultRight.findIndex(
                      ({ frequency }) =>
                        frequency === freqAndChannel.currentFreq
                    ) + 1
                  : audiometry.frequenciesResultLeft.findIndex(
                      ({ frequency }) =>
                        frequency === freqAndChannel.currentFreq
                    ) +
                    1 +
                    audiometry.frequenciesResultRight.length
              }
              max={
                audiometry.frequenciesResultRight.length +
                audiometry.frequenciesResultLeft.length
              }
              sx={{ display: "inline" }}
              snail
              color={"#85DC68"}
            />
          </Box>
        )}
    </Box>
  );
};

export default ThresholdAudiometry;
