import { Square } from "@mui/icons-material";
import { Stack, Typography } from "@mui/material";
import { texts } from "../../resources/texts";

const ListenCarefullyAnimation = () => {
  return (
    <Stack justifyContent="center" alignItems={"center"} spacing={3}>
      <Typography variant="h4" sx={{ fontSize: 30, fontWeight: 500 }}>
        {texts.listenCarefully}
      </Typography>
      <Stack direction="row" spacing={2}>
        <Square className="sq1" fontSize="small" />
        <Square className="sq2" fontSize="small" />
        <Square className="sq3" fontSize="small" />
        <Square className="sq4" fontSize="small" />
      </Stack>
    </Stack>
  );
};

export default ListenCarefullyAnimation;
