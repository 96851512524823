import { Box, Button, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

function App() {
  const navigate = useNavigate();
  return (
    <Box m={4}>
      <Grid container direction="row" spacing={2}>
        <Grid
          item
          container
          direction="column"
          xs={6}
          spacing={2}
          alignItems="center"
        >
          <Grid item>
            <Typography variant="h5">Badania słuchu</Typography>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              size="large"
              onClick={() => navigate("/audiometry")}
            >
              Audiometria przesiewowa
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              size="large"
              onClick={() => navigate("/audiometry-kids")}
            >
              Audiometria przesiewowa dla dzieci
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              size="large"
              onClick={() => navigate("/prog")}
            >
              Audiometria progowa dzieci
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              size="large"
              onClick={() => navigate("/louder-quieter")}
            >
              Audiometria progowa - Głośniej/ciszej
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              size="large"
              onClick={() => navigate("/as")}
            >
              Audiometria słowna
            </Button>
          </Grid>
        </Grid>
        <Grid
          item
          container
          direction="column"
          xs={6}
          spacing={2}
          alignItems="center"
        >
          <Grid item>
            <Typography variant="h5">CAPD</Typography>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              size="large"
              onClick={() => navigate("/tdw")}
            >
              Test Dyskryminacji Wysokości
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              size="large"
              onClick={() => navigate("/tsw")}
            >
              Test Sekwencji Wysokości
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              size="large"
              onClick={() => navigate("/tsd")}
            >
              Test Sekwencji Długości
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              size="large"
              onClick={() => navigate("/tpr")}
            >
              Test Przerw Randomizowanych
            </Button>
          </Grid>

          <Grid item>
            <Button
              variant="contained"
              size="large"
              onClick={() => navigate("/trc")}
            >
              Test Rozdzielnouszny Cyfr
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              size="large"
              onClick={() => navigate("/trs")}
            >
              Test Rozdzielnouszny Słów
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              size="large"
              onClick={() => navigate("/trms")}
            >
              Test Rozumienia Mowy w szumie
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              size="large"
              onClick={() => navigate("/tms")}
            >
              Test Mowy Skompresowanej
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              size="large"
              onClick={() => navigate("/tmf")}
            >
              Test Mowy Filtrowanej
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}

export default App;
