import { Stack, Typography } from "@mui/material";
import { answerToText } from "../../resources/texts";
import { imgSrc } from "../../answers/CircleAnswer";

export const NoGapPresentation = () => {
  return (
    <Stack spacing={6}>
      <img
        draggable="false"
        src={imgSrc("no-gap-presentation.svg")}
        style={{ margin: "auto", display: "block" }}
      />
      <Typography variant="answers" component="h5">
        {answerToText("noGap").toUpperCase()}
      </Typography>
    </Stack>
  );
};

export const GapPresentation = () => {
  return (
    <Stack spacing={6}>
      <img
        draggable="false"
        src={imgSrc("gap-presentation.svg")}
        style={{ margin: "auto", display: "block" }}
      />
      <Typography variant="answers" component="h5">
        {answerToText("gap").toUpperCase()}
      </Typography>
    </Stack>
  );
};
