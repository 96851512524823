export const audiometryConfig = {
  frequencies: [1000, 2000, 4000, 8000, 500, 250], // częstotliwości badane w audiometrii przesiewowej dla dorosłych
  testTone: {
    1000: 20,
    2000: 20,
    4000: 20,
    8000: 20,
    500: 20,
    250: 20,
  }, //natężenie tonu testowego dla poszczególnych częstotliwości (musi zawierać natężenie dla wszystkich częstotliwości badanych w wersjach dla dzieci i dorosłych)
  frequenciesPairs: [
    [1000, 8000],
    [250, 2000],
    [500, 4000],
  ], //pary częstotliwości do audiometrii przesiewowej dla dzieci, 1000 i 8000 musi zostać razem, ponieważ na tym polega próba startowa
  soundDuration: 2000,
  minPauseDuration: 2000,
  maxPauseDuration: 4000,
  //audiometr nie zaczyna grac w momencie wyslania odpowiedzi (tak zapisujemy poczatek trwania dzwieku),
  //tylko około 0.3-0.9s po - trzeba to uwzględnić w  maxReactionTimeAfterSoundStart (podczas testów zaobserwowaliśmy,
  //że gdy zmienna maxReactionTimeAfterSoundStart wynosi 3000, wtedy kliknięcia minimalnie przed czasem zakończenia dźwięku
  //są uznawane za poprawne, ale minimalnie po zakończeniu dźwięku już nie)
  //maksymalny czas na reakcje musi byc mniejszy lub rowny soundDuration + minPauseDuration - 200ms
  //okno prawidłowej reakcji jest przesunięte w przód 200ms po starcie dźwięku
  maxReactionTimeAfterSoundStart: 3000, //[ms]

  numberOfFalseClicksBeforeInstruction: 2, //po tej liczbie fałszywych kliknięć użytkownikowi pokaże się instrukcja
  acceptableNumberOfFalseClicks: 4, //po przekroczeniu tej liczby fałszywych kliknięć zakończy się badanie tej częstotliwości z wynikiem "FP"
  numberOfFalseClicksBeforeInstructionKids: 2,
  acceptableNumberOfFalseClicksKids: 4,
  maxNumberOfBackgroundNoisesPerFreq: 4, //po przekroczeniu tej liczby prób z hałasami (które trzeba było powtórzyć) badanie tej częstotliwości zostanie zakończone z wynikiem "FANC"
  maxNumberOfFANCResult: 2, // po przekroczeniu tej liczby wyników "FANC" dla częstotliwości całe badanie zostanie zakończone (wyniki dla obu uszu liczone razem), czyli przykładowo dla liczby maxNumberOfFANCResult=2, gdy uzyskamy z trzech częstotliwości wynik "FANC", wtedy badanie się zakończy
};
