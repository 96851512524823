import { useEffect, useState, useRef, useMemo } from "react";
import { Box, Button, Typography, Grid, Container, Stack } from "@mui/material";
import { useApi } from "../../Api";
import { useNotificationSubject } from "../../Notifications";
import { sleep } from "../../resources/exportedFunctions";
import { wavFiles } from "../../resources/wavFiles";
import { SoundBox } from "../../answers/DraggableAnswers";
import { texts } from "../../resources/texts";
import cursorHand from "../../resources/images/cursor.svg";
import { ReactComponent as Square } from "../../resources/images/square.svg";
import bg from "../../resources/images/bg-with-circle.svg";
import {
  CARD_HEIGHT_LOW_HIGH,
  CARD_WIDTH_LOW_HIGH,
  CARD_WIDTH_SHORT_LONG,
  CARD_HEIGHT_SHORT_LONG,
} from "../../resources/constants";
import ListenCarefullyAnimation from "./ListenCarefullyAnimation";

const initialGivenAnswers = [
  { text: "", image: "", id: "" },
  { text: "", image: "", id: "" },
  { text: "", image: "", id: "" },
];

const AnimatedInstructionTswTsd = ({
  startTraining,
  startTest,
  intensity,
  testEnum,
  onError,
}) => {
  const api = useApi();
  const notificationSubject = useNotificationSubject();
  const [click, setClick] = useState(false);
  const [givenAnswers, setGivenAnswers] = useState(initialGivenAnswers);
  const lowLongRef = useRef([]);
  const highShortRef = useRef([]);
  const givenAnswersRefs = useRef([]);
  const [currentDrag, setCurrentDrag] = useState();
  const [started, setStarted] = useState(false);
  const [soundState, setSoundState] = useState(); //possible: null, "playing","afterSound"
  const [exampleFinished, setExampleFinished] = useState(false);
  const [finished, setFinished] = useState(false);
  const [givenAnswersPositions, setGivenAnswersPositions] = useState([]);
  const [lowLongAnswersPositions, setLowLongAnswersPositions] = useState([]);
  const [highShortAnswersPositions, setHighShortAnswersPositions] = useState(
    []
  );
  const [currentCursorPosition, setCurrentCursorPosition] = useState({
    x: 0,
    y: 0,
  });
  const [canClickNext, setCanClickNext] = useState(false);
  const [clicked, setClicked] = useState(false);
  const buttonRef = useRef();
  const wavFile =
    testEnum === "TSW" ? wavFiles.highHighLow : wavFiles.shortShortLong;
  const abortController = useMemo(() => new AbortController(), []);
  // function handleResizeAndScroll(event) {
  //   rightAnswersRefs &&
  //     setRightAnswersPositions(
  //       rightAnswersRefs.current.map((el) => {
  //         return {
  //           x: el.getBoundingClientRect().x,
  //           y: el.getBoundingClientRect().y,
  //         };
  //       })
  //     );
  //   lowLongRef &&
  //   setLowLongAnswersPositions([
  //     {
  //       x: lowLongRef.getBoundingClientRect().x,
  //       y: lowLongRef.getBoundingClientRect().y,
  //     },
  //     {
  //       x: highShortRef.getBoundingClientRect().x,
  //       y: highShortRef.getBoundingClientRect().y,
  //     },
  //   ]);
  // }

  // useEffect(() => {
  //   window.addEventListener("resize", handleResizeAndScroll);
  //   window.addEventListener("scroll", handleResizeAndScroll);

  //   return () => {
  //     window.removeEventListener("resize", handleResizeAndScroll);
  //     window.removeEventListener("scroll", handleResizeAndScroll);
  //   };
  // }, []);

  var allRightAnswers = wavFile.metadata.rightAnswers;

  function isAnswerFull() {
    return JSON.stringify(givenAnswers) === JSON.stringify(allRightAnswers);
  }
  const mounted = useRef(false);

  useEffect(() => {
    mounted.current = true;
    const play = async () => {
      if (mounted.current) {
        try {
          await api.playInstruction([wavFiles.example]);
        } catch (e) {
          console.error(e);
          onError(e.message);
        }
      }
      mounted.current && setSoundState("playing");
      if (mounted.current) {
        try {
          await api.playWav([wavFile], intensity);
        } catch (e) {
          console.error(e);
          onError(e.message);
        }
      }
      mounted.current && setSoundState("afterSound");
    };
    document.body.style.backgroundImage = `url(${bg})`;
    const timer = setTimeout(() => {
      play();
    }, 2000);
    return () => {
      clearTimeout(timer);
      mounted.current = false;
      abortController.abort();
    };
  }, []);

  useEffect(() => {
    const timer =
      soundState === "afterSound" &&
      setTimeout(() => {
        setStarted(true);
      }, 500);
    return () => clearTimeout(timer);
  }, [soundState]);

  useEffect(() => {
    const animation = async () => {
      for (const [index, ans] of wavFile.metadata.rightAnswers.entries()) {
        const currentAns =
          ans.text === "short" || ans.text === "high" ? "shortHigh" : "lowLong";
        setCurrentDrag(currentAns);
        setCurrentCursorPosition(
          calculateCenter(
            currentAns === "shortHigh"
              ? highShortAnswersPositions[index]
              : lowLongAnswersPositions[index]
          )
        );
        await sleep(1000);
        setClick(true);
        await sleep(250);
        setCurrentCursorPosition(calculateCenter(givenAnswersPositions[index]));
        currentAns === "shortHigh"
          ? setHighShortAnswersPositions((prevState) =>
              prevState.map((pos, ind) => {
                if (ind !== wavFile.metadata.rightAnswers.length - index - 1) {
                  return { ...pos };
                } else return { ...givenAnswersPositions[index] };
              })
            )
          : setLowLongAnswersPositions((prevState) =>
              prevState.map((pos, ind) => {
                if (ind !== 2) {
                  return { ...pos };
                } else return { ...givenAnswersPositions[index] };
              })
            );
        await sleep(1000);
        setClick(false);
        setGivenAnswers((prevState) =>
          prevState.map((given, ind) => {
            if (ind !== index) {
              return { ...given };
            } else
              return {
                ...wavFile.metadata.possibleAnswers[
                  currentAns === "shortHigh" ? 1 : 0
                ],
              };
          })
        );
      }

      function calculateCenter(xy) {
        if (lowLongRef.current) {
          return {
            x: xy.x + lowLongRef.current.clientWidth / 2,
            y: xy.y + lowLongRef.current.clientHeight / 2,
          };
        } else {
          return { x: 0, y: 0 };
        }
      }
    };
    started && animation();
  }, [started]);

  useEffect(() => {
    if (isAnswerFull()) {
      if (givenAnswers.length > allRightAnswers.length) {
        setTimeout(() => {
          setExampleFinished(true);
        }, 2000);
      } else {
        if (buttonRef.current) {
          setCurrentCursorPosition({
            x: buttonRef.current.getBoundingClientRect().x + 100,
            y: buttonRef.current.getBoundingClientRect().y + 20,
          });
        } else {
          setCurrentCursorPosition({ x: 0, y: 0 });
        }

        setTimeout(() => {
          setExampleFinished(true);
        }, 2000);
      }
    }
  }, [givenAnswers]);

  useEffect(() => {
    setGivenAnswersPositions(
      givenAnswersRefs.current.map((el) => {
        return {
          x: el.getBoundingClientRect().x,
          y: el.getBoundingClientRect().y,
        };
      })
    );
  }, [givenAnswersRefs]);

  useEffect(() => {
    setLowLongAnswersPositions(
      [...Array(3).keys()].map(() => {
        return {
          x: lowLongRef.current.getBoundingClientRect().x,
          y: lowLongRef.current.getBoundingClientRect().y,
        };
      })
    );
  }, [lowLongRef]);

  useEffect(() => {
    setHighShortAnswersPositions(
      [...Array(3).keys()].map(() => {
        return {
          x: highShortRef.current.getBoundingClientRect().x,
          y: highShortRef.current.getBoundingClientRect().y,
        };
      })
    );
  }, [highShortRef]);

  useEffect(() => {
    const notificationObserver = (notification) => {
      switch (notification.type) {
        case "ExceptionWithCodeAndTimeStamp":
          if (notification.exceptionClassName === "Playing") {
            exampleFinished && setCanClickNext(true);
          }
          break;
        default:
          break;
      }
    };
    notificationSubject.attach(notificationObserver);

    exampleFinished &&
      api
        .playInstruction([wavFiles.trainingInfo], abortController.signal)
        .then(() => {
          if (mounted.current) {
            setCanClickNext(true);
            setFinished(true);
          }
        })
        .catch((e) => {
          !abortController.signal.aborted && onError(e.message);
        });
    return () => {
      notificationSubject.detach(notificationObserver);
    };
  }, [exampleFinished]);

  const handleClick = (onNext) => {
    setClicked(true);
    if (!finished) {
      api
        .stopPlaying(abortController.signal)
        .then(() => {
          setTimeout(() => {
            onNext && onNext();
          }, [1000]); //musimy odczekać po wykonaniu api.stopPlaying() inaczej kod audiometrowy rzuca błędy przy następnej instrukcji
        })
        .catch((e) => {
          !abortController.signal.aborted && onError(e.message);
        });
    } else {
      onNext && onNext();
    }
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent={"center"}
      alignItems="center"
      sx={{ mt: 11 }}
    >
      <Typography
        variant="title"
        sx={{
          color: (theme) => theme.palette.text.secondary,
          fontSize: "40px",
        }}
      >
        {texts.example}
      </Typography>
      <Stack
        direction="row"
        spacing={8}
        alignItems="center"
        justifyContent="center"
        sx={{ mb: testEnum === "TSW" ? 7 : 9 }}
      >
        <Square />
        <Typography variant="title" sx={{ fontSize: "40px" }}>
          {texts.howTo}
        </Typography>
        <Square />
      </Stack>
      {soundState === "playing" && <ListenCarefullyAnimation />}
      {soundState === "afterSound" && currentCursorPosition && (
        <Box
          component="span"
          sx={{
            top: `${currentCursorPosition.y}px`,
            left: `${currentCursorPosition.x}px`,
            position: "absolute",
            transition: (theme) =>
              theme.transitions.create("all", {
                duration: "1s",
                easing: "ease-out",
              }),
            zIndex: 3,
          }}
        >
          <img
            src={cursorHand}
            style={{
              width: click ? "42.5px" : "34px",
              height: click ? "50px" : "40px",
            }}
          />
        </Box>
      )}

      <Container sx={{ height: "75%", opacity: exampleFinished ? 0.3 : 1 }}>
        <Grid
          container
          direction={"column"}
          justifyContent="center"
          alignItems="center"
          sx={{
            width: "100%",
            height: "100%",
            visibility: soundState === "afterSound" ? "visible" : "hidden",
          }}
        >
          <Grid
            container
            item
            justifyContent="space-evenly"
            sx={{
              width: "650px",
            }}
          >
            <Box
              ref={(el) => (lowLongRef.current = el)}
              sx={{
                position: "relative",
                display: "inline-block",
                justifyContent: "center",
                boxShadow: "none",
                border: "none",
              }}
            >
              <SoundBox
                answer={wavFile.metadata.possibleAnswers[0]}
                isTSW={testEnum === "TSW"}
              />
            </Box>
            {[...Array(3).keys()].map(
              (index) =>
                lowLongAnswersPositions.length > 0 && (
                  <Box
                    key={index}
                    sx={{
                      position: "fixed",
                      top: lowLongAnswersPositions[index].y,
                      left: lowLongAnswersPositions[index].x,
                      transition: (theme) =>
                        theme.transitions.create("all", {
                          duration: "1s",
                          easing: "ease-out",
                        }),
                      zIndex: currentDrag === "lowLong" ? 2 : 1,
                      boxShadow: "none",
                      border: "none",
                    }}
                  >
                    <SoundBox
                      answer={wavFile.metadata.possibleAnswers[0]}
                      isTSW={testEnum === "TSW"}
                    />
                  </Box>
                )
            )}
            <Box
              ref={(el) => (highShortRef.current = el)}
              sx={{
                position: "relative",
                display: "inline-block",
                justifyContent: "center",
                boxShadow: "none",
                border: "none",
              }}
            >
              <SoundBox
                answer={wavFile.metadata.possibleAnswers[1]}
                isTSW={testEnum === "TSW"}
              />
            </Box>
            {[...Array(3).keys()].map(
              (index) =>
                highShortAnswersPositions.length > 0 && (
                  <Box
                    key={index}
                    sx={{
                      position: "fixed",
                      top: highShortAnswersPositions[index].y,
                      left: highShortAnswersPositions[index].x,
                      transition: (theme) =>
                        theme.transitions.create("all", {
                          duration: "1s",
                          easing: "ease-out",
                        }),
                      zIndex: currentDrag === "highShort" ? 2 : 1,
                      boxShadow: "none",
                      border: "none",
                    }}
                  >
                    <SoundBox
                      answer={wavFile.metadata.possibleAnswers[1]}
                      isTSW={testEnum === "TSW"}
                    />
                  </Box>
                )
            )}
          </Grid>

          <Grid
            mt={4}
            container
            item
            justifyContent="space-evenly"
            sx={{ bottom: "4px", width: "1000px" }}
          >
            {givenAnswers.map((ans, index) => (
              <Stack spacing={1} key={index}>
                <Typography variant="answers">{texts.sound}</Typography>
                <Grid
                  item
                  sx={{ border: "1px solid #61C3D2", borderRadius: "7px" }}
                >
                  <Box
                    ref={(el) => (givenAnswersRefs.current[index] = el)}
                    sx={{
                      display: "inline-block",
                      width:
                        testEnum === "TSW"
                          ? CARD_WIDTH_LOW_HIGH
                          : CARD_WIDTH_SHORT_LONG,
                      height:
                        testEnum === "TSW"
                          ? CARD_HEIGHT_LOW_HIGH
                          : CARD_HEIGHT_SHORT_LONG,
                    }}
                  ></Box>
                </Grid>
                <Typography variant="answers" sx={{ fontSize: "60px" }}>
                  {index + 1}
                </Typography>
              </Stack>
            ))}
          </Grid>
        </Grid>

        {soundState === "afterSound" && (
          <Button
            variant="contained"
            size="small"
            ref={(el) => (buttonRef.current = el)}
            sx={{
              float: "right",
              mt: 1,
            }}
          >
            {texts.nextSounds}
          </Button>
        )}
      </Container>
      {startTraining && (
        <Button
          variant="contained"
          onClick={() => handleClick(startTraining)}
          disabled={clicked || !canClickNext}
          sx={{
            position: "fixed",
            bottom: 50,
            left: 40,
            fontSize: "14px",
            fontWeight: 700,
            letterSpacing: "1px",
            color: (theme) => theme.palette.primary.main,
            height: "45px",
            backgroundColor: "#F1F1F1",
            "&:active": {
              backgroundColor: (theme) => theme.palette.secondary.main,
            },
            "&:hover": {
              backgroundColor: (theme) => theme.palette.secondary.main,
            },
          }}
        >
          {texts.startTraining}
        </Button>
      )}
      {
        <Button
          variant="contained"
          onClick={() => handleClick(startTest)}
          disabled={clicked || !canClickNext}
          sx={{ position: "fixed", bottom: 50, right: 40, px: 4 }}
        >
          {texts.startTest}
        </Button>
      }
    </Box>
  );
};

export default AnimatedInstructionTswTsd;
