import { useEffect, useState } from "react";
import { Box, Typography, Stack, Button } from "@mui/material";
import { texts } from "../resources/texts";
import { wavFiles } from "../resources/wavFiles";
import { useApi } from "../Api";
import { useNotificationSubject } from "../Notifications";

const PackageFinishedInfo = ({ packageName, onLogout, returnToMainPage }) => {
  const api = useApi();
  const notificationSubject = useNotificationSubject();
  const [clicked, setClicked] = useState(false);
  const [instructionFinished, setInstructionFinished] = useState(false);
  const [canClickNext, setCanClickNext] = useState(false);

  useEffect(() => {
    const notificationObserver = (notification) => {
      switch (notification.type) {
        case "ExceptionWithCodeAndTimeStamp":
          if (notification.exceptionClassName === "Playing") {
            setCanClickNext(true);
          }
          break;
        default:
          break;
      }
    };
    notificationSubject.attach(notificationObserver);

    return () => {
      notificationSubject.detach(notificationObserver);
    };
  }, [notificationSubject]);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    api
      .playInstruction(
        [
          returnToMainPage
            ? wavFiles.examinationFinishedResultsInfoMainAccount
            : wavFiles.examinationFinishedResultsInfo,
        ],
        signal
      )
      .catch((e) => {})
      .finally(() => {
        setInstructionFinished(true);
        setCanClickNext(true);
      });

    return () => {
      controller.abort();
    };
  }, []);

  const handleLogout = () => {
    setClicked(true);
    if (!instructionFinished) {
      api.stopPlaying().catch((e) => {});
    }
    onLogout();
  };

  return (
    <Box
      sx={{
        pt: 8,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "895px",
          mihHeight: "250px",
          borderRadius: "10px",
          px: 11,
          py: 6,
          bgcolor: "rgba(255,255,255,1)",
          boxSizing: "border-box",
        }}
      >
        <Box sx={{ p: 3 }}>
          <Typography
            variant="title"
            sx={{
              color: (theme) => theme.palette.text.secondary,
              display: "block",
              textTransform: "uppercase",
              fontSize: 35,
            }}
          >
            {texts.packageFinishedInfo.toUpperCase()}
          </Typography>
          <Typography
            variant="title"
            sx={{
              display: "block",
              mb: 8,
              textTransform: "uppercase",
              fontSize: 35,
            }}
          >
            {packageName.toUpperCase()}
          </Typography>

          <Typography
            variant="answers"
            sx={{
              display: "block",
              fontWeight: 400,
              mb: 6,
            }}
          >
            {texts.resultAvailableInfo}
          </Typography>
          {returnToMainPage && (
            <Typography
              variant="answers"
              sx={{
                display: "block",
                fontWeight: 400,
                mb: 8,
              }}
            >
              {texts.continueToMainPage}
            </Typography>
          )}
        </Box>
        <Stack
          justifyContent={returnToMainPage ? "space-between" : "center"}
          direction="row"
        >
          {returnToMainPage && (
            <Button
              variant="contained"
              disabled={clicked || !canClickNext}
              onClick={() => {
                setClicked(true);
                if (!instructionFinished) {
                  api
                    .stopPlaying()
                    .catch((e) => {})
                    .finally(() => {
                      returnToMainPage();
                    });
                } else {
                  returnToMainPage();
                }
              }}
              sx={{ px: 4 }}
            >
              {texts.backToMainPage}
            </Button>
          )}
          <Button
            disabled={clicked || !canClickNext}
            variant="contained"
            sx={{ px: 4 }}
            onClick={handleLogout}
          >
            {texts.logout}
          </Button>
        </Stack>
      </Box>
    </Box>
  );
};

export default PackageFinishedInfo;
