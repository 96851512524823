import { useEffect, useState } from "react";
import { Slider, Box } from "@mui/material";
import thumb from "../resources/images/scroll-thumb.svg";

export default function Scroll(props) {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    let computeProgress = () => {
      const listEl = document.getElementById("list");
      if (listEl) {
        const scrolled = listEl.scrollTop;
        const scrollLength = listEl.scrollHeight - listEl.clientHeight;
        const progress = `${(100 * scrolled) / scrollLength}`;
        setProgress(progress);
      }
    };

    document.getElementById("list") &&
      document
        .getElementById("list")
        .addEventListener("scroll", computeProgress);

    return () =>
      document.getElementById("list") &&
      document
        .getElementById("list")
        .removeEventListener("scroll", computeProgress);
  });

  return (
    <Box {...props}>
      <Slider
        min={0}
        max={100}
        disabled
        orientation="vertical"
        value={progress}
        sx={{
          "& .MuiSlider-thumb": {
            background: `url(${thumb})`,
            height: "34px",
            width: "34px",
            "&:before": {
              width: "unset",
              height: "unset",
            },
          },
          "& .MuiSlider-track": {
            color: "#F1F1F1",
            height: 3,
          },
        }}
      />
    </Box>
  );
}
