import { wavFiles } from "../wavFiles";

export const trmsConfig = {
  numberOfTries: 10,
  intensity: 60,
  kk: [
    { ageStart: 0, ageStop: 6, kk: 0.13 },
    { ageStart: 7, ageStop: 7, kk: 0.2 },
    { ageStart: 8, ageStop: 8, kk: 0.32 },
    { ageStart: 9, ageStop: 9, kk: 0.35 },
    { ageStart: 10, ageStop: 10, kk: 0.43 },
    { ageStart: 11, ageStop: 11, kk: 0.44 },
    { ageStart: 12, ageStop: 100, kk: 0.56 },
  ], // kryterium kwalifikacji zalezne od wieku
  wkk: [{ ageStart: 1, ageStop: 100, kk: 1 }], // wczesne kryterium kwalifikacji zalezne od wieku
  trainingNumberOfTries: 3,
  numberOfPossibleAnswers: 10,
  wavsForAnimation: [wavFiles.snrkret],
  maxNumberOfBackgroundNoises: 4, //jeśli wystąpi większa liczba hałasów (więcej prób musiałoby być powtórzonych) wtedy wynik badania będzie równy "FANC"
};
