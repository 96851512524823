import { Modal, Paper, Stack, Typography, Button } from "@mui/material";
import { ReactComponent as InfoIcon } from "../resources/images/info-icon.svg";
import { texts } from "../resources/texts";

const InstructionModal = ({ open, width, instruction, onClick }) => {
  return (
    <Modal open={open}>
      <Paper
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: width,
          borderRadius: "10px",
          p: 3,
          bgcolor: "rgba(255,255,255,1)",
        }}
      >
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={2}
        >
          <InfoIcon
            sx={{
              float: "left",
            }}
          />
          <Typography
            variant="h4"
            sx={{
              maxWidth: "85%",
              display: "inline-block",
            }}
          >
            {instruction}
          </Typography>
        </Stack>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => onClick()}
          sx={{
            float: "right",
            px: 4,
          }}
        >
          {texts.ok}
        </Button>
      </Paper>
    </Modal>
  );
};

export default InstructionModal;
