import { Slider, Stack, Typography } from "@mui/material";
import { texts } from "../resources/texts";
import thumb from "../resources/images/scroll-thumb.svg";
import snailGreen from "../resources/images/snail-green.svg";
import snailBlue from "../resources/images/snail-blue.svg";

const ProgressSlider = ({ currentValue, max, title, snail, color }) => {
  const min = 1;

  return (
    <Stack
      spacing={3}
      direction="row"
      alignItems="center"
      sx={{
        position: "fixed",
        bottom: 45,
        left: 48,
        right: 80,
        height: "30px",
      }}
    >
      {title && (
        <Typography
          variant="h6"
          align="left"
          sx={{ color: color ? color : "#3DAABD", width: 210 }}
        >
          {title ? title : texts.testProgress}
        </Typography>
      )}
      <Slider
        id="progress-slider"
        aria-label="progress-slider"
        value={currentValue}
        step={1}
        max={max}
        min={min}
        sx={{
          "& .MuiSlider-thumb": {
            background: `url(${
              snail ? (color ? snailGreen : snailBlue) : thumb
            })`,
            height: snail ? "25.76px" : "34px",
            width: snail ? "38.5px" : "34px",
            "&:before": {
              width: "unset",
              height: "unset",
            },
            "&.Mui-active": {
              boxShadow: "none",
            },
            "&.Mui-focusVisible": {
              boxShadow: "none",
            },
            "&:hover": {
              boxShadow: "none",
            },
            borderRadius: 0,
            position: "relative",
            top: snail ? "-5px" : "0px",
          },
          "& .MuiSlider-track": {
            color: color ? color : "#9DDBD9",
            height: 3,
          },
          "& .MuiSlider-rail": {
            color: "white",
            height: 3,
          },
        }}
      />
    </Stack>
  );
};

export default ProgressSlider;
