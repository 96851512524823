import { useState, useEffect, useRef } from "react";
import { Box, Button, Typography, Stack } from "@mui/material";
import ConfirmationDialog from "../components/ConfirmationDialog";
import AudiometryKids from "./AudiometryKids";
import Audiometry from "./Audiometry";
import { audiometryConfig } from "../resources/tests_config/audiometryConfig";
import { isAnswerCorrect } from "./SingleFreqAudiometry";
import {
  calculateGlobalResults,
  determineEarlyFinishedEnum,
  determineFailureReason,
  wasTestInterrupted,
} from "../resources/exportedFunctions";
import moment from "moment";
import { Answer, FailureReason, Result } from "./enums";
import { texts } from "../resources/texts";
import { testNames } from "../resources/texts";
import { FinishButton } from "../components/FinishButton";
import TestDescription from "../pages/TestDescription";
import HeadphonesPulseAnimation from "../components/animations/HeadphonesPulseAnimation";
import { AnimalsKidsDescription } from "../components/animations/SheepHeader";
import CurrentStateInfo from "../components/CurrentStateInfo";
import bg from "../resources/images/bg.svg";
import bgKidsDesc from "../resources/images/bg-kids-description.svg";
import { useApi } from "../Api";
import { testsWavFiles } from "../resources/testsWavFiles";
import { wavFiles } from "../resources/wavFiles";
import { isErrorFromAudiometr } from "../resources/exportedFunctions";

const InstructionKids = ({ handleNext, handleError }) => {
  const api = useApi();
  const [instructionFinished, setInstructionFinished] = useState(false);

  useEffect(() => {
    document.body.style.backgroundImage = `url(${bgKidsDesc})`;
    const controller = new AbortController();
    const signal = controller.signal;

    api
      .playInstruction(
        [wavFiles[testsWavFiles.AP_KIDS.instructionsSounds[1]]],
        signal
      )
      .then(() => {
        setInstructionFinished(true);
      })
      .catch((e) => {
        handleError && !signal.aborted && handleError(e.message);
      });

    return () => controller.abort();
  }, []);

  return (
    <Box>
      <CurrentStateInfo
        firstLine={`${texts.examination}:`}
        secondLine={testNames["AP"]}
      />
      <Stack
        alignItems="center"
        sx={{
          pt: 12,
          ml: 7,
          width: 1200,
        }}
      >
        <Typography
          variant="h4"
          sx={{
            lineHeight: 1.5,
            whiteSpace: "pre-line",
            mb: 6,
            fontSize: 35,
          }}
        >
          {texts.audiometry.kidsInstruction}
        </Typography>
        <Typography
          variant="h4"
          sx={{ maxWidth: "1000px", lineHeight: 1.5, mb: 6, fontSize: 35 }}
        >
          {texts.audiometry.readyClickStart}
        </Typography>
        <Button
          variant="contained"
          size="large"
          disabled={!instructionFinished}
          sx={{ px: 10 }}
          onClick={() => {
            handleNext && handleNext();
          }}
        >
          {texts.start}
        </Button>
      </Stack>
      <Box sx={{ position: "fixed", left: "1055px", top: "555px" }}>
        <AnimalsKidsDescription />
      </Box>
    </Box>
  );
};

const InstructionAdults = ({ setTraining, handleError }) => {
  const api = useApi();
  const [instructionFinished, setInstructionFinished] = useState(false);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    api
      .playInstruction(
        [wavFiles[testsWavFiles.AP.instructionsSounds[1]]],
        signal
      )
      .then(() => {
        setInstructionFinished(true);
      })
      .catch((e) => {
        handleError && !signal.aborted && handleError(e.message);
      });

    return () => controller.abort();
  }, []);

  return (
    <Box sx={{ mt: 2 }}>
      <Stack justifyContent={"center"} alignItems="center">
        <Typography
          variant="h4"
          sx={{
            maxWidth: "820px",
            zIndex: 2,
            lineHeight: 1.5,
            whiteSpace: "pre-line",
          }}
        >
          {texts.audiometry.adultAndThresholdAlgorithmInstruction}
        </Typography>
        <HeadphonesPulseAnimation />
      </Stack>
      <Button
        variant="contained"
        disabled={!instructionFinished}
        onClick={() => {
          setTraining && setTraining(true);
        }}
        sx={{
          position: "fixed",
          bottom: 50,
          left: 40,
          fontSize: "14px",
          fontWeight: 700,
          letterSpacing: "1px",
          color: (theme) => theme.palette.primary.main,
          height: "45px",
          backgroundColor: "#F1F1F1",
          "&:active": {
            backgroundColor: (theme) => theme.palette.secondary.main,
          },
          "&:hover": {
            backgroundColor: (theme) => theme.palette.secondary.main,
          },
        }}
      >
        {texts.startTraining}
      </Button>
      <Button
        variant="contained"
        disabled={!instructionFinished}
        onClick={() => {
          setTraining && setTraining(false);
        }}
        sx={{ position: "fixed", bottom: 50, right: 40, px: 4 }}
      >
        {texts.startTest}
      </Button>
    </Box>
  );
};

const TestComponent = ({
  phase,
  forKids,
  audiometry,
  freqPairSequence,
  frequencies,
  finished,
  changeFinished,
  updateAudiometry,
  currentChannel,
  changeCurrentChannel,
  stopped,
  setReadyToFinish,
  onError,
  training,
}) => {
  if (phase === "test") {
    if (forKids) {
      return (
        <AudiometryKids
          audiometry={audiometry}
          freqPairSequence={freqPairSequence}
          finished={finished}
          changeFinished={changeFinished}
          updateAudiometry={updateAudiometry}
          currentChannel={currentChannel}
          changeCurrentChannel={changeCurrentChannel}
          stopped={stopped}
          setReadyToFinish={setReadyToFinish}
          onError={onError}
        />
      );
    } else {
      return (
        <Audiometry
          audiometry={audiometry}
          frequencies={frequencies}
          finished={finished}
          changeFinished={changeFinished}
          currentChannel={currentChannel}
          changeCurrentChannel={changeCurrentChannel}
          updateAudiometry={updateAudiometry}
          stopped={stopped}
          setReadyToFinish={setReadyToFinish}
          onError={onError}
          trainingProp={training}
        />
      );
    }
  }
};

const AudiometryManager = ({
  forKids,
  onEvent,
  automaticLogout,
  startDate,
  parentError,
}) => {
  const api = useApi();
  const [audiometry, setAudiometry] = useState();
  const [finish, setFinish] = useState({ finished: false, cancelled: false });
  const [phase, setPhase] = useState("description"); // mozliwe: description, instruction, test
  const [displayFinishTestConfirmation, setDisplayFinishTestConfirmation] =
    useState(false);
  const [currentChannel, setCurrentChannel] = useState("HEAD_PHONES_RIGHT");
  const [readyToFinish, setReadyToFinish] = useState(false);
  const [error, setError] = useState();
  const [training, setTraining] = useState();
  const freqPairSequence = audiometryConfig.frequenciesPairs;
  const frequencies = audiometryConfig.frequencies;

  const mounted = useRef(false);

  useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, []);

  useEffect(() => {
    const createAudiometry = async () => {
      var frequenciesResultRight = [];
      var frequenciesResultLeft = [];
      if (!forKids) {
        frequencies.forEach((freq) => {
          frequenciesResultRight.push({ frequency: freq, answer: "" });
          frequenciesResultLeft.push({ frequency: freq, answer: "" });
        });
      } else {
        freqPairSequence.forEach((freqPair) =>
          freqPair.forEach((freq) => {
            frequenciesResultRight.push({ frequency: freq, answer: "" });
            frequenciesResultLeft.push({ frequency: freq, answer: "" });
          })
        );
      }

      mounted.current &&
        setAudiometry({
          frequenciesResultRight,
          frequenciesResultLeft,
          testEnum: "AP",
          forKids,
          maxIntensity: null,
          minIntensity: null,
          resultRight: null,
          resultLeft: null,
          result: null,
          audiometryDetailsListRight: [],
          audiometryDetailsListLeft: [],
          earlyFinishedEnum: null,
          name: testNames["AP"],
          frequenciesAndIntensities: audiometryConfig.testTone,
        });
    };
    phase === "instruction" && createAudiometry();
    if (phase === "test") {
      document.body.style.backgroundImage = `url(${bg})`;
    }
  }, [phase]);

  useEffect(() => {
    !error && parentError && handleError(parentError);
  }, [parentError]);

  useEffect(() => {
    if (training !== undefined) {
      setPhase("test");
    }
  }, [training]);

  useEffect(() => {
    if (automaticLogout === true) {
      !(finish.finished && readyToFinish)
        ? setAudiometryEarlyFinished(FailureReason.INACTIVITY_LOGOUT)
        : onEvent &&
          onEvent("FINISHED", {
            result: Result.AMB,
            testType: "AP",
            failureReason: FailureReason.INACTIVITY_LOGOUT,
            error: error,
            data: {
              ...(({ incorrectStartingAttempt, ...o }) => o)(audiometry),
              earlyFinishedEnum: FailureReason.INACTIVITY_LOGOUT,
            },
            startDate: startDate,
            endDate: moment().toISOString(),
          });
    }
  }, [automaticLogout]);

  useEffect(() => {
    if (finish.finished && readyToFinish) {
      if (audiometry) {
        const failureReason = determineFailureReason(
          automaticLogout,
          finish.cancelled,
          error,
          audiometry.result
        );

        // console.log("finished", {
        //   result:
        //     audiometry.result === Result.FANC ||
        //     audiometry.result === Result.FP ||
        //     audiometry.result === null ||
        //     wasTestInterrupted(failureReason)
        //       ? Result.AMB
        //       : audiometry.result,
        //   testType: "AP",
        //   failureReason,
        //   error: error,
        //   data: {
        //     ...(({ incorrectStartingAttempt, ...o }) => o)(audiometry),
        //     earlyFinishedEnum: determineEarlyFinishedEnum(
        //       automaticLogout,
        //       finish.cancelled,
        //       error
        //     ),
        //   },
        //   startDate: startDate,
        //   endDate: moment().toISOString(),
        // });
        onEvent &&
          onEvent("FINISHED", {
            result:
              audiometry.result === Result.FANC ||
              audiometry.result === Result.FP ||
              audiometry.result === null ||
              wasTestInterrupted(failureReason)
                ? Result.AMB
                : audiometry.result,
            testType: "AP",
            failureReason,
            error: error,
            data: {
              ...(({ incorrectStartingAttempt, ...o }) => o)(audiometry),
              earlyFinishedEnum: determineEarlyFinishedEnum(
                automaticLogout,
                finish.cancelled,
                error
              ),
            },
            startDate: startDate,
            endDate: moment().toISOString(),
          });
      } else {
        onEvent &&
          onEvent("FINISHED", {
            result: Result.AMB,
            failureReason:
              automaticLogout === true
                ? FailureReason.INACTIVITY_LOGOUT
                : error !== undefined
                ? FailureReason.ERROR
                : FailureReason.CANCELLED,
            testType: "AP",
            error: error,
            startDate: startDate,
            endDate: moment().toISOString(),
          });
      }
    }
  }, [finish, readyToFinish]);

  function setAudiometryEarlyFinished(reason) {
    if (!finish.finished) {
      setFinish({
        finished: true,
        cancelled: reason === FailureReason.CANCELLED ? true : false,
      });
      //jeśli powodem zakończenia badania nie był błąd lub jeśli błąd nie pochodził od audiometru
      if (
        reason !== FailureReason.ERROR ||
        (parentError && !isErrorFromAudiometr(parentError))
      ) {
        api.stopPlaying().catch((e) => console.error(e));
      }
      setReadyToFinish(true);
    }
  }

  function countFANCResults(frequenciesResultsCurrent) {
    var fancResultCount = 0;
    audiometry.frequenciesResultRight.forEach(
      ({ answer }) => answer === Answer.FANC && fancResultCount++
    );
    audiometry.frequenciesResultLeft.forEach(
      ({ answer }) => answer === Answer.FANC && fancResultCount++
    );
    frequenciesResultsCurrent.forEach(
      ({ answer }) => answer === Answer.FANC && fancResultCount++
    );

    return fancResultCount;
  }

  function updateAudiometry(resultsAndDetails, globalFalse = false) {
    var globalResult = null;
    var count = 0;
    var finishedByFANC = false;
    if (!forKids) {
      if (resultsAndDetails.frequenciesResults[0].answer !== Answer.FANC) {
        resultsAndDetails.audiometryDetails[0].soundsClicksTimes.forEach(
          ({ clicks }) => {
            isAnswerCorrect(clicks) && count++;
          }
        );

        if (
          resultsAndDetails.frequenciesResults[0].frequency === 1000 &&
          count === 0
        ) {
          globalResult = Result.NOK;
        }
      }
    } else if (globalFalse === true) {
      //todo: zobaczyć jak wynik "FANC" na to wpływa
      globalResult = Result.NOK;
    }
    if (
      countFANCResults(resultsAndDetails.frequenciesResults) >
      audiometryConfig.maxNumberOfFANCResult
    ) {
      finishedByFANC = true;
    }

    if (currentChannel === "HEAD_PHONES_RIGHT") {
      setAudiometry((prevState) => {
        const frequenciesResultRight = prevState.frequenciesResultRight.map(
          (result) => {
            const newResults = resultsAndDetails.frequenciesResults.find(
              ({ frequency }) => result.frequency === frequency
            );
            return newResults !== undefined ? { ...newResults } : { ...result };
          }
        );
        const [resultRight, resultLeft, result] = calculateGlobalResults(
          frequenciesResultRight,
          prevState.frequenciesResultLeft
        );
        return {
          ...prevState,
          result,
          resultLeft,
          resultRight,
          frequenciesResultRight: frequenciesResultRight,
          audiometryDetailsListRight: [
            ...prevState.audiometryDetailsListRight,
            ...resultsAndDetails.audiometryDetails,
          ],
          incorrectStartingAttempt: globalFalse,
        };
      });
    } else {
      setAudiometry((prevState) => {
        const frequenciesResultLeft = prevState.frequenciesResultLeft.map(
          (result) => {
            const newResults = resultsAndDetails.frequenciesResults.find(
              ({ frequency }) => result.frequency === frequency
            );
            return newResults !== undefined ? { ...newResults } : { ...result };
          }
        );
        const [resultRight, resultLeft, result] = calculateGlobalResults(
          prevState.frequenciesResultRight,
          frequenciesResultLeft
        );
        return {
          ...prevState,
          result,
          resultLeft,
          resultRight,
          frequenciesResultLeft: frequenciesResultLeft,
          audiometryDetailsListLeft: [
            ...prevState.audiometryDetailsListLeft,
            ...resultsAndDetails.audiometryDetails,
          ],
          incorrectStartingAttempt: globalFalse,
        };
      });
    }
    if (globalResult === Result.NOK) {
      if (currentChannel === "HEAD_PHONES_RIGHT") {
        setCurrentChannel("HEAD_PHONES_LEFT");
      } else {
        setFinish((prevState) => ({ ...prevState, finished: true }));
      }
    }
    if (finishedByFANC) {
      setFinish((prevState) => ({ ...prevState, finished: true }));
    }
  }

  const handleError = (error) => {
    setError(error);
    setAudiometryEarlyFinished(FailureReason.ERROR);
  };

  const handleChangeFinish = (finished) => {
    setFinish((prevState) => ({ ...prevState, finished }));
  };

  return (
    <Box sx={{ height: "100%", pt: 4, boxSizing: "border-box" }}>
      {phase == "instruction" && !forKids && (
        <CurrentStateInfo
          firstLine={`${texts.examination}:`}
          secondLine={testNames["AP"]}
        />
      )}
      {!finish.finished && (
        <FinishButton
          variant="contained"
          onClick={() => setDisplayFinishTestConfirmation(true)}
        >
          {texts.finishTest}
        </FinishButton>
      )}
      <br />
      {displayFinishTestConfirmation && (
        <ConfirmationDialog
          question={texts.endTestConfirm}
          yesAction={() => {
            setAudiometryEarlyFinished(FailureReason.CANCELLED);
            setDisplayFinishTestConfirmation(false);
          }}
          noAction={() => {
            setDisplayFinishTestConfirmation(false);
          }}
          maxWidth="lg"
        />
      )}
      <TestComponent
        phase={phase}
        forKids={forKids}
        audiometry={audiometry}
        freqPairSequence={freqPairSequence}
        frequencies={frequencies}
        finished={finish.finished}
        changeFinished={handleChangeFinish}
        updateAudiometry={updateAudiometry}
        currentChannel={currentChannel}
        changeCurrentChannel={setCurrentChannel}
        stopped={displayFinishTestConfirmation}
        setReadyToFinish={setReadyToFinish}
        onError={handleError}
        training={training}
      />
      {phase === "description" && !finish.finished && (
        <TestDescription
          handleNext={() => setPhase("instruction")}
          testType="AP"
          forKids={forKids}
          instructionSound={
            forKids
              ? wavFiles[testsWavFiles.AP_KIDS.instructionsSounds[0]]
              : wavFiles[testsWavFiles.AP.instructionsSounds[0]]
          }
          handleError={handleError}
        />
      )}
      {phase === "instruction" &&
        !finish.finished &&
        (!forKids ? (
          <InstructionAdults
            setTraining={setTraining}
            handleError={handleError}
          />
        ) : (
          <InstructionKids
            handleNext={() => setPhase("test")}
            handleError={handleError}
          />
        ))}
    </Box>
  );
};

export default AudiometryManager;
