import { Stack, Typography } from "@mui/material";
import { ReactComponent as Av } from "../resources/images/avatar.svg";

const Avatar = ({ initials }) => {
  return (
    <Stack sx={{ width: "52px", position: "fixed", top: 37, right: 255 }}>
      <Av></Av>
      <Typography
        variant="h2"
        sx={{ position: "relative", top: -33, left: -2 }}
      >
        {initials}
      </Typography>
    </Stack>
  );
};

export default Avatar;
