import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import TestCreator from "./tests/TestCreator";
import LoginPage from "./pages/LoginPage";
import ScreeningsPage from "./pages/ScreeningsPage";
import PackagesPage from "./pages/PackagesPage";
import UserSelectionPage from "./pages/UserSelectionPage";
import Header from "./components/Header";
import HearboxManager from "./HearboxManager";
import { ThemeProvider } from "@mui/material";
import { theme } from "./theme";

const root = createRoot(document.getElementById("root"));

root.render(
  // <React.StrictMode>
  <ThemeProvider theme={theme}>
    <Router>
      <Routes>
        <Route exact path="/" element={<HearboxManager />}></Route>
        <Route
          exact
          path="/selection"
          element={
            <Header>
              <App />
            </Header>
          }
        ></Route>
        <Route
          path="/tsw"
          element={
            <Header>
              <TestCreator testEnum="TSW" />
            </Header>
          }
        />
        <Route
          path="/tsd"
          element={
            <Header>
              <TestCreator testEnum="TSD" />
            </Header>
          }
        />
        <Route
          path="/audiometry"
          element={
            <Header>
              <TestCreator testEnum="AP" userAge={21} />
            </Header>
          }
        />
        <Route
          path="/audiometry-kids"
          element={
            <Header>
              <TestCreator testEnum="AP" userAge={1} />
            </Header>
          }
        />
        <Route
          path="/prog"
          element={
            <Header>
              <TestCreator testEnum="GC" userAge={1} />
            </Header>
          }
        />
        <Route
          path="/louder-quieter"
          element={
            <Header>
              <TestCreator testEnum="GC" userAge={21} />
            </Header>
          }
        />
        <Route
          path="/tpr"
          element={
            <Header>
              <TestCreator testEnum="TPR" />
            </Header>
          }
        />
        <Route
          path="/trc"
          element={
            <Header>
              <TestCreator testEnum="TRC" />
            </Header>
          }
        />
        <Route
          path="/tdw"
          element={
            <Header>
              <TestCreator testEnum="TDW" />
            </Header>
          }
        />
        <Route
          path="/trms"
          element={
            <Header>
              <TestCreator testEnum="TRMS" />
            </Header>
          }
        />
        <Route
          path="/tms"
          element={
            <Header>
              <TestCreator testEnum="TMS" />
            </Header>
          }
        />
        <Route
          path="/tmf"
          element={
            <Header>
              <TestCreator testEnum="TMF" />
            </Header>
          }
        />
        <Route
          path="/trs"
          element={
            <Header>
              <TestCreator testEnum="TRS" />
            </Header>
          }
        />
        <Route
          path="/as"
          element={
            <Header>
              <TestCreator testEnum="AS" />
            </Header>
          }
        />

        <Route
          path="/login"
          element={
            <Header>
              <LoginPage />
            </Header>
          }
        />
        <Route
          path="/screenings"
          element={
            <Header>
              <ScreeningsPage />
            </Header>
          }
        />
        <Route
          path="/packages"
          element={
            <Header>
              <PackagesPage />
            </Header>
          }
        />
        <Route
          path="/users_selection"
          element={
            <Header>
              <UserSelectionPage />
            </Header>
          }
        />
      </Routes>
    </Router>
  </ThemeProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
