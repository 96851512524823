import { Box } from "@mui/material";
import { ReactComponent as Woman } from "../../resources/images/woman.svg";
import headphones from "../../resources/images/headphones-wire.svg";
import "./index.css";

const WearHeadphonesAnimation = () => {
  return (
    <Box
      sx={{
        display: "inline",
        position: "relative",
        top: "-70px",
      }}
    >
      <Woman />
      <img className="headphones-wire" src={headphones} />
    </Box>
  );
};

export default WearHeadphonesAnimation;
