export const portalURL = "HEARBOX.PL";

export const texts = {
  testProgress: "POSTĘP BADANIA",
  trainingProgress: "POSTĘP TRENINGU",
  globalProgress: "POSTĘP OGÓLNY",
  phaseProgress: "POSTĘP FAZY",
  right: "prawe",
  left: "lewe",
  finishTest: "Zakończ badanie",
  testFinished: "Koniec badania",
  startTest: "Rozpocznij badanie",
  startingTest: "Rozpoczynanie badania",
  startTraining: "Wykonaj ćwiczenie",
  ready: "GOTOWE",
  start: "START",
  continue: "Dalej",
  nextSounds: "Następne dźwięki",
  quieter: "CISZEJ",
  louder: "GŁOŚNIEJ",
  hear: "SŁYSZĘ",
  dontHearSound: "Nie słyszę dźwięku",
  dontHear: "nie słyszę",
  ok: "OK",
  yes: "TAK",
  no: "NIE",
  logout: "Wyloguj",
  checkingNoises:
    "System sprawdza czy podczas badania nie wystąpiły zakłócenia.",
  preparingTest: "Przygotowywanie badania",
  listenCarefully: "SŁUCHAJ UWAŻNIE",
  testedEar: "Badane ucho",
  testedPerson: "OSOBA BADANA",
  currentFrequency: "Aktualna częstotliwość",
  clickToStay: "Kliknij, aby pozostać na stronie lub wyloguj się.",
  finished: "Zakończono",
  allTests: "wszystkie badania pakietu",
  resultAvailableInfo: `Aby poznać szczegółowy wynik badania zaloguj się na stronie internetowej ${portalURL} na Twoim urządzeniu.`,
  loginTitle: "LOGOWANIE",
  scanQr: "Zeskanuj kod QR",
  codeInvalid: "Zeskanuj kod QR ponownie lub wpisz dane ręcznie.",
  credentialsToLong: "Wpisane dane są za długie, spróbuj ponownie.",
  or: "LUB",
  typeManually: "lub wpisz kod ręcznie",
  login: "Zaloguj się",
  wrongCredentials: "Nieprawidłowy identyfikator lub PIN, spróbuj ponownie.",
  incorrectLogin: "NIEPOPRAWNE LOGOWANIE.",
  chooseTest: "ZESTAW BADAŃ ZAWIERA",
  chosenScreening: "WYBRANY ZESTAW",
  loading: "Ładowanie...",
  chooseScreening: "TWOJE ZESTAWY BADAŃ",
  choose: "wybierz",
  choosePatient: "WYBIERZ OSOBĘ BADANĄ",
  id: "Identyfikator",
  pin: "PIN",
  undo: "COFNIJ",
  package: "PAKIET",
  examination: "BADANIE",
  doingExcercise: "WYKONUJESZ ĆWICZENIE",
  sound: "dźwięk",
  disorderAssessment: "OCENA PROCESÓW",
  auditoryProcessing: "PRZETWARZANIA SŁUCHOWEGO",

  automaticLogoutInfo: "Nastąpi automatyczne wylogowanie za",
  idleOccured: "Wykryto brak aktywności",
  stayOnSite: "Pozostań na stronie",
  backToMainPage: "WRÓĆ DO STRONY GŁÓWNEJ",
  continueToMainPage: "Jeżeli chcesz kontynuować wróć do strony głównej.",

  repeatSoundInfo:
    "Podczas wcześniejszego dźwięku otoczenie było za głośne, zostanie on zagrany ponownie.",
  endTestConfirm:
    "Zakończenie badania na tym etapie spowoduje zapisanie udzielonych dotychczas odpowiedzi, jednak ze względu na niedokończenie testu jego wynik jest niemiarodajny oraz może być niemożliwy do oceny dla specjalisty. Czy na pewno chcesz zakończyć wcześniej badanie?",
  notCompleteAnswerConfirm:
    "Udzielona odpowiedź nie jest pełna, czy chcesz zapisać niepełną odpowiedź?",
  notCompleteAnswerInfo:
    "Należy uzupełnić wszystkie odpowiedzi, aby przejść do kolejnych dźwięków.",
  testEndedBecauseOfNoise:
    "Badanie zostało przedwcześnie zakończone z powodu hałasu otoczenia.",
  testFinishedInfo: "WYKONANO BADANIE",
  moveToNextExamination: "Przejdź do następnego badania:",
  nextExamination: "NASTĘPNE BADANIE",
  finish: "Zakończ",
  cancel: "Anuluj",
  connectingToDevices: "Trwa łączenie z urządzeniem. To zajmie tylko chwilkę",

  unexpectedErrorInfo:
    "Wystąpił niespodziewany błąd. Proszę wykonać badanie w innym terminie.",
  errorOccured: "Wystąpił błąd:",
  errorCode: "Kod błędu:",
  headphonesNotRemoved: "Nie wyjęto słuchawek",
  takeOut: "WYCIĄGNIJ",
  headphones: "SŁUCHAWKI",
  putDown: "ODŁÓŻ",
  takeOutHeadphonesInfo:
    "Przed rozpoczęciem badań wyciągnij słuchawki z komory urządzenia HEAR BOX, znajdującej się przed Tobą po prawej stronie.",
  putHeadphonesInStorageCloseCover:
    "Po zakończeniu badań odłóż słuchawki do komory urządzenia HEAR BOX, znajdującej się przed Tobą po prawej stronie.",
  closeCoverInfo:
    "Następnie zamknij klapkę komory. Dopiero po tej czynności będziesz mógł się wylogować z systemu Hear Box.",
  cantLogin:
    "Nie mozna się tym razem zalogować na tego uytkownika. Spróbuj ponownie później",
  noExaminationsFoundInfo: "BRAK DOSTĘPNYCH BADAŃ.",
  cantGetScreenings: "Nie udało się pobrać przesiewów",
  cantSendResults: "Nie udało się wysłać wyników",
  cantSetupAudiometr: "Nie udało się połączyć z urządzeniem",
  activateVoucherOrBuyInfo: `Aktywuj darmowy voucher lub kup badania przez stronę internetową ${portalURL} na Twoim urządzeniu.`,
  packageFinishedInfo: "Zakończono badania pakietu",
  example: "PRZYKŁAD",
  howTo: "JAK WYKONAĆ BADANIE",
  bravo: "brawo!",
  wait: "CZEKAJ",
  connectingToDevicesHeader: "Odzyskiwanie połączenia z urządzeniem...",

  putOnHeadphones: "ZAŁÓŻ SŁUCHAWKI",
  headphonesInstruction:
    "Aby rozpocząć badania załóż słuchawki. Czerwona słuchawka powinna znaleźć się na prawym uchu, a niebieska na lewym. Dopasuj słuchawki tak, aby było Ci wygodnie. Jeśli założyłeś słuchawki naciśnij przycisk DALEJ.",
  clickNextToContinue: "Naciśnij przycisk DALEJ, aby kontynuować.",
  audiometry: {
    clickHearButtonInstruction:
      "Jeśli słyszysz dźwięk, naciśnij przycisk SŁYSZĘ",
    adultAndThresholdAlgorithmInstruction:
      "Za chwilę rozpoczniesz badanie. Twoim zadaniem jest nacisnąć przycisk SŁYSZĘ za każdym razem, kiedy usłyszysz dźwięk.\n Reaguj nawet wtedy, gdy dźwięk jest bardzo cichy. Jeśli nic nie usłyszysz, nie naciskaj przycisku.\n Dźwięki będziesz słyszał najpierw w PRAWYM, potem w LEWYM uchu.",
    adultAndThresholdAlgorithmInstructionRepeated:
      "Twoim zadaniem jest nacisnąć przycisk SŁYSZĘ za każdym razem, kiedy usłyszysz dźwięk. Reaguj nawet jeśli dźwięk jest bardzo cichy. Jeśli nic nie usłyszysz, nie naciskaj przycisku. Jeśli jesteś gotowy, wciśnij OK.",
    kidsInstruction:
      "Za chwilę zobaczysz zwierzątka.\nWybierz to, które wydało dźwięk.\nJeżeli żadne zwierzątko nie wydało dźwięku,\nnaciśnij przekreślone ucho.",
    lqInstruction:
      "Za chwilę rozpoczniesz badanie. Twoim zadaniem jest wskazanie najcichszego dźwięku jaki jesteś w stanie usłyszeć.\n Po naciśnięciu przycisku ROZPOCZNIJ BADANIE usłyszysz dźwięk.\n Za pomocą przycisków na ekranie zmieniaj głośność dźwięku do momentu, kiedy uznasz,  że głośność dźwięku jest najcichsza, którą jesteś w stanie usłyszeć.\n Naciśnij przycisk OK, aby zapisać dźwięk. ",
    adultAudiometryDescription:
      "Badanie przesiewowe służy do oceny czy Twój słuch jest w granicach normy.\n Podczas badania prezentowane będą dźwięki dla różnych częstotliwości.\n Badanie będzie wykonywane oddzielnie dla ucha PRAWEGO i LEWEGO.",
    kidsAudiometryDescription:
      "Badanie służy do oceny czy dobrze słyszysz. Podczas badania prezentowane będą różne dźwięki. Dźwięki będą słyszalne najpierw w jednym, a potem w drugim uchu.",
    thresholdAudiometryDescription:
      "Celem badania jest wyznaczenie progów słyszenia, czyli najcichszych, słyszalnych przez Ciebie dźwięków dla różnych częstotliwości.",
    kidsThresholdInstruction:
      "Za chwilę zobaczysz zwierzątko, które trzyma wiatraczek!\nKiedy pojawi się dźwięk naciśnij wiatraczek.",
    readyClickStart: "Jesteś gotowy? Naciśnij przycisk START.",
    kidsDescriptionThreshold:
      "Celem badania jest wyznaczenie najcichszych, słyszalnych przez dziecko dźwięków dla różnych częstotliwości. ",
  },
  capdDescription:
    "Celem badań jest sprawdzenie Twoich umiejętności słuchowych, czyli tego w jaki sposób przetwarzasz usłyszaną informację dźwiękową.",
  speachAudiometry: {
    description:
      "Celem badania jest sprawdzenie rozumienia mowy, które służy do oceny zdolności komunikowania się w codziennym życiu.\nBadanie będzie wykonywane oddzielnie dla ucha prawego i lewego.",
    instruction:
      "Za chwilę rozpoczniesz badanie. Usłyszysz w słuchawkach słowa.\n Twoim zadaniem jest wybrać spośród słów na ekranie to, które usłyszałeś. Słowa będziesz słyszał najpierw w PRAWYM, potem w LEWYM uchu.",
  },
  TPR: [
    "Za chwilę rozpoczniesz badanie.\nUsłyszysz dwa rodzaje szumów - szum ciągły i szum z przerwą.",
    "Wykonując badanie zdecyduj czy usłyszany dźwięk to szum ciągły czy szum z przerwą i zaznacz odpowiednią odpowiedź na ekranie.",
  ],
  TDW: [
    "Za chwilę rozpoczniesz badanie.\nUsłyszysz dwa dźwięki – NISKI i WYSOKI.",
    "Twoim zadaniem jest wybranie czy usłyszane dźwięki były takie same czy różne i zaznaczenie odpowiedzi na ekranie.",
  ],
  TRMS: [
    "Za chwilę rozpoczniesz badanie.\nUsłyszysz szum, w którym pojawi się słowo.",
    "Słuchaj uważnie i wybierz, które słowo usłyszałeś.",
  ],
  TMS: [
    "Za chwilę rozpoczniesz badanie.\nUsłyszysz słowo, które będzie zniekształcone.",
    "Słuchaj uważnie i wybierz, które słowo usłyszałeś.",
  ],
  TMF: [
    "Za chwilę rozpoczniesz badanie.\nUsłyszysz słowo, które będzie zniekształcone.",
    "Słuchaj uważnie i wybierz, które słowo usłyszałeś.",
  ],
  TRC: [
    "Za chwilę rozpoczniesz badanie.\n Usłyszysz równocześnie DWIE cyfry w prawym uchu\n i DWIE cyfry w lewym uchu.",
    "Twoim zadaniem jest wybrać na ekranie cyfry, które usłyszałeś. Kolejność nie ma znaczenia.",
  ],
  TRS: [
    "Za chwilę rozpoczniesz badanie.\n Usłyszysz równocześnie DWA SŁOWA w prawym uchu i DWA SŁOWA w lewym uchu. ",
    "Twoim zadaniem jest wybrać na ekranie słowa, które usłyszałeś. Kolejność nie ma znaczenia.",
  ],
  TSW: [
    "Za chwilę rozpoczniesz badanie.\nUsłyszysz sekwencję TRZECH dźwięków - będą to dźwięki NISKIE i WYSOKIE w różnej konfiguracji.",
    "Wykonując badanie zwróć uwagę na kolejność dźwięków. Twoim zadaniem jest przesunięcie pól z górnego rzędu na odpowiednie miejsca w dolnym rzędzie, zgodnie z kolejnością dźwięków, którą usłyszałeś.",
  ],
  TSD: [
    "Za chwilę rozpoczniesz badanie.\nUsłyszysz sekwencję TRZECH dźwięków - będą to dźwięki KRÓTKIE i DŁUGIE w różnej konfiguracji.",
    "Wykonując badanie zwróć uwagę na kolejność dźwięków. Po usłyszeniu dźwięku Twoim zadaniem jest przesunięcie pól z górnego rzędu na odpowiednie miejsca w dolnym rzędzie, zgodnie z kolejnością dźwięków, którą usłyszałeś.",
  ],
  AS: [
    "Usłyszysz słowo. Wsłuchaj się uważnie i wybierz, które słowo usłyszałeś.",
  ],
};

export const testNames = {
  AP: "AUDIOMETRIA PRZESIEWOWA",
  GC: "PRZESIEWOWA AUDIOMETRIA PROGOWA",
  AS: "PRZESIEWOWA AUDIOMETRIA SŁOWNA",
  TDW: "TEST DYSKRYMINACJI WYSOKOŚCI",
  TSW: "TEST SEKWENCJI WYSOKOŚCI",
  TSD: "TEST SEKWENCJI DŁUGOŚCI",
  TPR: "TEST PRZERW RANDOMIZOWANYCH",
  TRC: "TEST ROZDZIELNOUSZNY CYFR",
  TRS: "TEST ROZDZIELNOUSZNY SŁÓW",
  TRMS: "TEST ROZUMIENIA MOWY W SZUMIE",
  TMS: "TEST MOWY SKOMPRESOWANEJ",
  TMF: "TEST MOWY FILTROWANEJ",
};

export const answerToText = (answer) => {
  switch (answer) {
    case "gap":
      return "szum z przerwą";

    case "noGap":
      return "szum ciągły";

    case "low-low":
      return "takie same";

    case "high-high":
      return "takie same";

    case "high-low":
      return "różne";

    case "low-high":
      return "różne";

    case "low":
      return "niski";

    case "high":
      return "wysoki";

    case "short":
      return "krótki";

    case "long":
      return "długi";

    default:
      return answer;
  }
};
