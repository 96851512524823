import { List, ListItemButton, Box, Typography, Stack } from "@mui/material";
import { ReactComponent as ArrowDownIcon } from "../resources/images/down-arrow.svg";
import { texts } from "../resources/texts";
import Scroll from "./Scroll";

const Options = ({ options, selectedOption, selectOption }) => {
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#F1F1F1",
          borderRadius: "33px",
          width: "830px",
          height: "65px",
          mb: 1,
        }}
      >
        <Typography
          variant="title"
          align="center"
          sx={{
            width: "760px",
            fontSize: 25,
            color: (theme) => theme.palette.text.secondary,
            fontWeight: selectedOption ? "" : 500,
          }}
        >
          {options.find((opt) => opt.id === selectedOption)?.name ||
            texts.choose}
        </Typography>
        <ArrowDownIcon />
      </Box>
      <Stack
        alignItems="center"
        direction="row"
        sx={{
          width: "830px",
          position: "relative",
        }}
      >
        <List
          id="list"
          sx={{
            width: "830px",
            height: "250px",
            overflowY: "scroll",
            overflowX: "hidden",
            "::-webkit-scrollbar": { display: "none" },
          }}
        >
          {options.map((opt) => (
            <ListItemButton
              key={opt.id}
              sx={{
                justifyContent: "center",
                fontFamily: "Montserrat",
                fontSize: "22px",
                color:
                  opt.id === selectedOption
                    ? (theme) => theme.palette.text.secondary
                    : (theme) => theme.palette.text.options,
                textTransform: "none",
              }}
              onClick={() => selectOption(opt.id)}
            >
              {opt.name}
            </ListItemButton>
          ))}
        </List>
        <Scroll
          sx={{
            transform: "rotate(180deg)",
            height: "250px",
            position: "absolute",
            right: 10,
          }}
        />
      </Stack>
    </Box>
  );
};

export default Options;
