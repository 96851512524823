import { Box, Typography } from "@mui/material";

const CurrentStateInfo = ({ firstLine, secondLine }) => {
  return (
    <Box sx={{ maxWidth: "550px" }}>
      {firstLine && (
        <Typography
          variant="h4"
          sx={{
            color: (theme) => theme.palette.text.secondary,
            fontWeight: 600,
            textAlign: "left",
          }}
        >
          {`${firstLine}`}
        </Typography>
      )}
      {secondLine && (
        <Typography variant="h4" sx={{ fontWeight: 600, textAlign: "left" }}>
          {secondLine}
        </Typography>
      )}
    </Box>
  );
};

export default CurrentStateInfo;
