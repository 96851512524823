import { Box } from "@mui/material";
import { ReactComponent as Headphones } from "../../resources/images/headphones-pulse.svg";
import { ReactComponent as HeadphonesVol } from "../../resources/images/headphones-volume.svg";
import Volume from "../Volume";
import circles from "../../resources/images/circles.svg";
import "./index.css";

const HeadphonesPulseAnimation = () => {
  return (
    <Box
      sx={{
        position: "relative",
        display: "inline",
        //top: "-10px",
        zIndex: 1,
      }}
    >
      <Headphones />
      <img className="circles-pulse-translate2" src={circles} />
    </Box>
  );
};

export const HeadphonesPulseAnimationVolume = () => {
  return (
    <Box
      sx={{
        position: "relative",
        display: "inline",
        zIndex: 1,
      }}
    >
      <HeadphonesVol />
      <Box sx={{ position: "absolute", top: "360px", left: "205px" }}>
        <Volume />
      </Box>
      <img className="circles-pulse-translate3" src={circles} />
    </Box>
  );
};

export default HeadphonesPulseAnimation;
