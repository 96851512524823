import { useEffect, useState } from "react";
import { IconButton, Box } from "@mui/material";
import lama from "../../resources/images/lama.svg";
import lama2 from "../../resources/images/lama2.svg";
import fan from "../../resources/images/fan.svg";

const LamaFan = ({ onClick }) => {
  const [clicked, setClicked] = useState("");

  return (
    <Box sx={{ position: "relative" }}>
      <img className={"lama lama" + clicked} src={lama2} alt="" />
      <IconButton
        disableRipple
        onPointerDown={() => {
          onClick && onClick();
          setClicked("clicked");
          setTimeout(() => {
            setClicked("");
          }, 500);
        }}
        sx={{ position: "absolute", left: "200px", top: "-50px" }}
      >
        <img className={"fan fan" + clicked} src={fan} alt="" />
      </IconButton>
    </Box>
  );
};

export default LamaFan;
