import { Box, Stack, Typography, Button } from "@mui/material";
import WearHeadphonesAnimation from "../components/animations/WearHeadphonesAnimation";
import { texts } from "../resources/texts";
import { ReactComponent as Square } from "../resources/images/square.svg";
import Headphones from "../components/animations/Headphones";

const HeadphonesInstruction = ({ handleNext }) => {
  const [first, ...rest] = texts.putOnHeadphones.split(" ");

  return (
    <Box
      sx={{
        pt: 14,
        ml: 35,
      }}
    >
      <Stack direction="row">
        <Stack alignItems="center" justifyContent="center">
          <Stack
            direction="row"
            spacing={5}
            alignItems="center"
            justifyContent="center"
            sx={{ mb: 8 }}
          >
            <Square />
            <Box>
              <Typography
                variant="title"
                sx={{ color: (theme) => theme.palette.text.secondary }}
              >
                {`${first} `}
              </Typography>
              <Typography variant="title">{rest.join(" ")}</Typography>
            </Box>
            <Square />
          </Stack>
          <Typography
            variant="h4"
            sx={{ maxWidth: "800px", mb: 4, lineHeight: 1.5 }}
          >
            {texts.headphonesInstruction}
          </Typography>
          <Headphones />
        </Stack>
        <WearHeadphonesAnimation />
      </Stack>
      <Button
        variant="contained"
        size="large"
        sx={{ position: "fixed", right: "40px", bottom: "40px", px: 10 }}
        onClick={() => {
          handleNext && handleNext();
        }}
      >
        {texts.continue}
      </Button>
    </Box>
  );
};
export default HeadphonesInstruction;
