import React, { useState, useRef, useEffect } from "react";
import Draggable from "react-draggable";
import { Typography, Grid, Container, Stack, Box } from "@mui/material";
import {
  CARD_HEIGHT_LOW_HIGH,
  CARD_WIDTH_LOW_HIGH,
  CARD_WIDTH_SHORT_LONG,
  CARD_HEIGHT_SHORT_LONG,
} from "../resources/constants";
import { answerToText, texts } from "../resources/texts";
import { imgSrc } from "./CircleAnswer";

const numberOfDraggableCopies = 3;
const acceptableRange = 70;

export const SoundBox = ({ answer, isTSW }) => {
  return (
    <Stack
      sx={{
        width: isTSW ? CARD_WIDTH_LOW_HIGH : CARD_WIDTH_SHORT_LONG,
        height: isTSW ? CARD_HEIGHT_LOW_HIGH : CARD_HEIGHT_SHORT_LONG,
      }}
    >
      {answer?.image && (
        <img
          draggable="false"
          src={imgSrc(answer.image)}
          style={{ margin: "auto", display: "block" }}
        />
      )}
      <Typography variant="answers" component="h5">
        {answerToText(answer.text).toUpperCase()}
      </Typography>
    </Stack>
  );
};

const DraggableAnswers = ({
  possibleAnswers,
  givenAnswers,
  setGivenAnswers,
  isTSW,
}) => {
  const [allPossibleAnswers, setAllPossibleAnswers] = useState([]);
  const [actualDraggableAnswer, setActualDraggableAnswer] = useState({});
  const [answerIndexOnStart, setAnswerIndexOnStart] = useState();
  const givenAnswersRefs = useRef([]);
  const possibleAnswersRefs = useRef([]);

  const [answersFieldsPositions, setAnswersFieldsPositions] = useState([]);

  const [possibleAnswersInitialPositions, setPossibleAnswersInitialPositions] =
    useState([]);

  //nie dziala - mialo zapobiegac multitouch
  function touchHandler(event) {
    if (event.touches.length > 1) {
      event.preventDefault();
    }
  }

  function handleResizeAndScroll(event) {
    setAnswersFieldsPositions(
      givenAnswersRefs.current.map((el) => {
        return {
          x: el.getBoundingClientRect().x,
          y: el.getBoundingClientRect().y,
        };
      })
    );
    setPossibleAnswersInitialPositions(
      possibleAnswersRefs.current.map((el) => {
        return {
          x: el.getBoundingClientRect().x,
          y: el.getBoundingClientRect().y,
        };
      })
    );
  }

  useEffect(() => {
    window.addEventListener("touchstart", touchHandler, { passive: false });
    window.addEventListener("resize", handleResizeAndScroll);
    window.addEventListener("scroll", handleResizeAndScroll);

    return () => {
      window.removeEventListener("touchstart", touchHandler);
      window.removeEventListener("resize", handleResizeAndScroll);
      window.removeEventListener("scroll", handleResizeAndScroll);
    };
  }, []);

  function initAllPossibleAnswers() {
    const array = possibleAnswers.map((ans, index) => {
      let id;
      if (index === 0) {
        id = "first-";
      } else if (index === 1) {
        id = "second-";
      }
      var array = [];
      [...Array(numberOfDraggableCopies + 1).keys()].forEach((i) => {
        array.push({
          ...ans,
          id: id + i,
          position: { x: 0, y: 0 },
        });
      });
      return array;
    });
    return array.reduce(function (a, b) {
      return a.concat(b);
    });
  }

  useEffect(() => {
    setPossibleAnswersInitialPositions(
      possibleAnswersRefs.current.map((el) => {
        return {
          x: el.getBoundingClientRect().x,
          y: el.getBoundingClientRect().y,
        };
      })
    );
  }, [possibleAnswersRefs]);

  useEffect(() => {
    setAnswersFieldsPositions(
      givenAnswersRefs.current.map((el) => {
        return {
          x: el.getBoundingClientRect().x,
          y: el.getBoundingClientRect().y,
        };
      })
    );
  }, [givenAnswersRefs]);

  useEffect(() => {
    possibleAnswersInitialPositions &&
      setAllPossibleAnswers(() => initAllPossibleAnswers());
  }, [possibleAnswersInitialPositions]);

  useEffect(() => {
    setGivenAnswers(
      givenAnswers.map((ans, index) =>
        index === answerIndexOnStart
          ? {
              id: "",
              text: "",
              image: "",
            }
          : { ...ans }
      )
    );
  }, [answerIndexOnStart]);

  useEffect(() => {
    setAllPossibleAnswers((prevState) =>
      prevState.map((ans) => {
        if (
          !actualDraggableAnswer ||
          (actualDraggableAnswer && ans.id !== actualDraggableAnswer.id)
        ) {
          const foundedIndex = givenAnswers.findIndex(
            ({ id }) => ans.id === id
          );
          const ind = ans.id.startsWith("first") ? 0 : 1;
          if (foundedIndex !== -1) {
            return {
              ...ans,
              position: {
                x:
                  answersFieldsPositions[foundedIndex].x -
                  possibleAnswersInitialPositions[ind].x,
                y:
                  answersFieldsPositions[foundedIndex].y -
                  possibleAnswersInitialPositions[ind].y,
              },
            };
          } else {
            return {
              ...ans,
              position: {
                x: 0,
                y: 0,
              },
            };
          }
        } else return { ...ans };
      })
    );
  }, [givenAnswers, answersFieldsPositions, possibleAnswersInitialPositions]);

  const checkIfPositionInRangeOfAnyAnswerField = (x, y) => {
    var foundedAnswer = -1;
    answersFieldsPositions.forEach((el, index) => {
      if (
        x > el.x - acceptableRange &&
        x < el.x + acceptableRange &&
        y > el.y - acceptableRange &&
        y < el.y + acceptableRange
      ) {
        foundedAnswer = index;
      }
    });
    return foundedAnswer;
  };

  const onStartHandler = (e, data, id) => {
    var mouseOffset = { offsetX: 0, offsetY: 0 };

    const clientX = e.clientX || e.changedTouches[0].clientX;
    const clientY = e.clientY || e.changedTouches[0].clientY;

    const ans = allPossibleAnswers.find((ans) => ans.id === id);

    var initialPosition = id.startsWith("first")
      ? possibleAnswersInitialPositions[0]
      : possibleAnswersInitialPositions[1];

    mouseOffset = {
      offsetX: clientX - ans?.position.x - initialPosition.x,
      offsetY: clientY - ans?.position.y - initialPosition.y,
    };

    setAnswerIndexOnStart(
      checkIfPositionInRangeOfAnyAnswerField(
        clientX - mouseOffset.offsetX,
        clientY - mouseOffset.offsetY
      )
    );

    setActualDraggableAnswer({
      ...allPossibleAnswers.find((ans) => ans.id === id),
      ...mouseOffset,
    });
  };

  const onStopHandler = (e, data) => {
    const clientX = e.clientX || e.changedTouches[0].clientX;
    const clientY = e.clientY || e.changedTouches[0].clientY;
    const positionOfTopLeftCorner = {
      x: clientX - actualDraggableAnswer.offsetX,
      y: clientY - actualDraggableAnswer.offsetY,
    };

    const answerFieldIndex = checkIfPositionInRangeOfAnyAnswerField(
      positionOfTopLeftCorner.x,
      positionOfTopLeftCorner.y
    );

    if (answerFieldIndex !== -1) {
      const ind = actualDraggableAnswer.id.startsWith("first") ? 0 : 1;
      setGivenAnswers(
        givenAnswers.map((ans, index) =>
          index === answerFieldIndex
            ? {
                id: actualDraggableAnswer.id,
                text: actualDraggableAnswer.text,
                image: actualDraggableAnswer.image,
              }
            : index === answerIndexOnStart
            ? { id: "", text: "", image: "" }
            : { ...ans }
        )
      );
    } else {
      setGivenAnswers(
        givenAnswers.map((ans, index) =>
          index === answerIndexOnStart
            ? {
                id: "",
                text: "",
                image: "",
              }
            : { ...ans }
        )
      );
    }

    setActualDraggableAnswer(null);
    setAnswerIndexOnStart(null);
  };

  return (
    <Container sx={{ height: "85%" }}>
      <Grid
        container
        direction={"column"}
        justifyContent="center"
        alignItems="center"
        sx={{
          width: "100%",
          height: "100%",
        }}
      >
        <Grid
          container
          item
          justifyContent="space-evenly"
          sx={{
            width: "650px",
          }}
        >
          <Draggable
            axis="both"
            position={
              allPossibleAnswers.find(({ id }) => id === "first-0")?.position
            }
            onStart={(e, data) => onStartHandler(e, data, "first-0")}
            onStop={onStopHandler}
          >
            <Box
              ref={(el) => (possibleAnswersRefs.current[0] = el)}
              sx={{
                position: "relative",
                display: "inline-block",
                zIndex: actualDraggableAnswer?.id === "first-0" ? 5 : 1,
                justifyContent: "center",
                boxShadow: "none",
                border: "none",
              }}
            >
              <SoundBox answer={possibleAnswers[0]} isTSW={isTSW} />
            </Box>
          </Draggable>
          {[...Array(numberOfDraggableCopies).keys()].map(
            (i) =>
              possibleAnswersInitialPositions[0] && (
                <Draggable
                  key={i}
                  axis="both"
                  position={
                    allPossibleAnswers.find(({ id }) => id === `first-${i + 1}`)
                      ?.position
                  }
                  onStart={(e, data) =>
                    onStartHandler(e, data, `first-${i + 1}`)
                  }
                  onStop={onStopHandler}
                >
                  <Box
                    sx={{
                      position: "fixed",
                      top: possibleAnswersInitialPositions[0].y,
                      left: possibleAnswersInitialPositions[0].x,
                      zIndex:
                        actualDraggableAnswer?.id === `first-${i + 1}` ? 5 : 1,
                      justifyItems: "center",
                      boxShadow: "none",
                      border: "none",
                    }}
                  >
                    <SoundBox answer={possibleAnswers[0]} isTSW={isTSW} />
                  </Box>
                </Draggable>
              )
          )}
          <Draggable
            axis="both"
            position={
              allPossibleAnswers.find(({ id }) => id === `second-0`)?.position
            }
            onStart={(e, data) => onStartHandler(e, data, `second-0`)}
            onStop={onStopHandler}
          >
            <Box
              ref={(el) => (possibleAnswersRefs.current[1] = el)}
              sx={{
                position: "relative",
                display: "inline-block",
                zIndex: actualDraggableAnswer?.id === `second-0` ? 5 : 1,
                boxShadow: "none",
                border: "none",
              }}
            >
              <SoundBox answer={possibleAnswers[1]} isTSW={isTSW} />
            </Box>
          </Draggable>
          {[...Array(numberOfDraggableCopies).keys()].map(
            (i) =>
              possibleAnswersInitialPositions[1] && (
                <Draggable
                  key={i}
                  axis="both"
                  position={
                    allPossibleAnswers.find(
                      ({ id }) => id === `second-${i + 1}`
                    )?.position
                  }
                  onStart={(e, data) =>
                    onStartHandler(e, data, `second-${i + 1}`)
                  }
                  onStop={onStopHandler}
                >
                  <Box
                    sx={{
                      position: "fixed",
                      top: possibleAnswersInitialPositions[1].y,
                      left: possibleAnswersInitialPositions[1].x,
                      zIndex:
                        actualDraggableAnswer?.id === `second-${i + 1}` ? 5 : 1,
                      boxShadow: "none",
                      border: "none",
                    }}
                  >
                    <SoundBox answer={possibleAnswers[1]} isTSW={isTSW} />
                  </Box>
                </Draggable>
              )
          )}
        </Grid>
        <Grid
          mt={4}
          container
          item
          justifyContent="space-evenly"
          sx={{ bottom: "4px", width: "1000px" }}
        >
          {givenAnswers.map((ans, index) => (
            <Stack spacing={1} key={index}>
              <Typography variant="answers">{texts.sound}</Typography>
              <Grid
                item
                sx={{
                  border: "1px solid #61C3D2",
                  borderRadius: "7px",
                }}
              >
                <Box
                  ref={(el) => (givenAnswersRefs.current[index] = el)}
                  sx={{
                    display: "inline-block",
                    width: isTSW ? CARD_WIDTH_LOW_HIGH : CARD_WIDTH_SHORT_LONG,
                    height: isTSW
                      ? CARD_HEIGHT_LOW_HIGH
                      : CARD_HEIGHT_SHORT_LONG,
                  }}
                ></Box>
              </Grid>
              <Typography variant="answers" sx={{ fontSize: "60px" }}>
                {index + 1}
              </Typography>
            </Stack>
          ))}
        </Grid>
      </Grid>
    </Container>
  );
};
export default DraggableAnswers;
