import { Box, Stack, Typography } from "@mui/material";
import DateTimeDisplay from "./DateTimeDisplay";
import IdleTimeOutHandler from "./IdleTimeOutHandler";
import GlobalSlider from "./GlobalSlider";
import { texts } from "../resources/texts";
import Avatar from "./Avatar";
import { FinishButton } from "./FinishButton";
import SheepHeader from "./animations/SheepHeader";
import loader from "../resources/images/loader.svg";
import "./animations/index.css";

const Header = ({
  account,
  progress = 1,
  examinations,
  onLogout,
  children,
  startedExamination,
  user,
  forKids = false,
  audiometrDisconnected,
}) => {
  const initials =
    account &&
    `${(account.firstName && account.firstName[0].toUpperCase()) || ""}${
      (account.surname && account.surname[0]?.toUpperCase()) || ""
    }`;
  const username = user && `${user.firstName || ""} ${user.surname || ""}`;
  return (
    <Box sx={{ width: "100vw", height: "100vh" }}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
        sx={{
          px: 5,
          height: "130px",
        }}
      >
        <Box>
          <DateTimeDisplay />
          {user && (
            <Box sx={{ mt: 4 }}>
              <Typography
                variant="body1"
                sx={{
                  display: "inline",
                  color: (theme) => theme.palette.text.secondary,
                }}
              >
                {`${texts.testedPerson}: `}
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  display: "inline",
                }}
              >
                {username.toUpperCase()}
              </Typography>
            </Box>
          )}
        </Box>
        {forKids && examinations[progress - 1] !== "GC" && <SheepHeader />}

        <Box sx={{ display: "block", mt: 5, justifyContent: "end" }}>
          {audiometrDisconnected && (
            <Stack
              direction="row"
              alignItems="center"
              spacing={1}
              sx={{ position: "fixed", right: 350 }}
            >
              <img
                src={loader}
                className={"rotate"}
                style={{ width: "30px", height: "30px" }}
              />
              <Typography>{texts.connectingToDevicesHeader}</Typography>
            </Stack>
          )}
          {initials && <Avatar initials={initials} />}
          <Box justifyContent={"end"} sx={{ width: "200px", display: "flex" }}>
            <FinishButton
              variant="contained"
              onClick={() => onLogout()}
              sx={{
                visibility:
                  onLogout && !startedExamination ? "visible" : "hidden",
              }}
            >
              {texts.logout}
            </FinishButton>
          </Box>
        </Box>
      </Stack>

      {onLogout && <IdleTimeOutHandler onLogout={onLogout} />}
      <Box sx={{ height: "calc(100% - 153px)" }}>
        <Box sx={{ height: "calc(100% - 90px)", px: 5 }}>{children} </Box>
        {startedExamination && (
          <Box>
            <GlobalSlider
              title={texts.globalProgress}
              currentValue={progress}
              max={examinations.length}
              sx={{ display: "inline" }}
              width={"800px"}
              snail={forKids}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default Header;
