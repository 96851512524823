import { shuffleArray } from "../resources/exportedFunctions";
import { lqConfig } from "../resources/tests_config/louderQuieterConfig";
import { allWords } from "../resources/wavFiles";
import { Answer, Result } from "./enums";
//metody: countRightAnswers(), countRightAnswersPercent() muszą być znane również na Portalu Lekarza

export default class Test {
  //dla tsw, tsd, tdw, trms, tms, tmf, as
  static drawOrder(wavFiles, numberOfTries) {
    var tries = [];
    var wavFilesCopy = Object.assign([], wavFiles);
    while (tries.length < numberOfTries) {
      const randomSound =
        wavFilesCopy[Math.floor(Math.random() * wavFilesCopy.length)];
      tries.push({ listOfPlayedSounds: [randomSound] });
      wavFilesCopy = wavFilesCopy.filter((sound) => sound !== randomSound);
      if (wavFilesCopy.length === 0) {
        wavFilesCopy = Object.assign([], wavFiles);
      }
    }
    return tries;
  }

  //dla tpr
  static drawOrderTpr(wavFiles, numberOfTries) {
    var tries = [];
    var wavFilesList = [];
    for (let i = 0; i < wavFiles.length; i++) {
      var counter = 0;
      while (
        wavFilesList.length <
        (numberOfTries / wavFiles.length) * (i + 1)
      ) {
        wavFilesList.push({ wavFile: wavFiles[i], id: `${i}${counter}` });
        counter++;
      }
    }
    while (tries.length < numberOfTries) {
      const randomSound =
        wavFilesList[Math.floor(Math.random() * wavFilesList.length)];
      tries.push({ listOfPlayedSounds: [randomSound.wavFile] });
      wavFilesList = wavFilesList.filter((sound) => sound !== randomSound);
    }
    return tries;
  }

  static drawOrderDichotic(
    wavFiles,
    numberOfTries,
    numberOfPossibleAnswers = 0
  ) {
    var tries = [];
    var wavFilesCopy = Object.assign([], wavFiles);

    while (tries.length < numberOfTries) {
      const randomPair =
        wavFilesCopy[Math.floor(Math.random() * wavFilesCopy.length)];

      const newPossibleAnswers = this.drawPossibleAnswers(
        [
          ...new Set([
            ...randomPair[0].metadata.possibleAnswers,
            ...randomPair[1].metadata.possibleAnswers,
          ]),
        ],
        numberOfPossibleAnswers,
        [
          ...randomPair[0].metadata.rightAnswers,
          ...randomPair[1].metadata.rightAnswers,
        ]
      );

      tries.push({
        listOfPlayedSounds: [
          {
            ...randomPair[0],
            metadata: {
              ...randomPair[0].metadata,
              possibleAnswers: newPossibleAnswers,
            },
          },
          {
            ...randomPair[1],
            metadata: {
              ...randomPair[1].metadata,
              possibleAnswers: [],
            },
          },
        ],
      });

      wavFilesCopy = wavFilesCopy.filter((sound) => sound !== randomPair);

      if (wavFilesCopy.length === 0) {
        wavFilesCopy = Object.assign([], wavFiles);
      }
    }

    return tries;
  }

  static checkIfAnswerIsCorrectOneSoundPlayed(attempt) {
    return (
      JSON.stringify(attempt.givenAnswers) ===
      JSON.stringify(attempt.listOfPlayedSounds[0].metadata.rightAnswers)
    );
  }

  static countRightAnswers(tries) {
    var score = 0;
    tries.forEach((attempt) => {
      attempt.isCorrect && score++;
    });
    return score;
  }

  static countRightAnswersPercent(tries) {
    if (tries.length === 0) {
      return "0";
    }
    return ((this.countRightAnswers(tries) * 100) / tries.length).toFixed(0);
  }

  static isWkkForWrongAnswersFulfilled(tries, wkk) {
    var correct = tries.some((attempt) => attempt.isCorrect === true);
    var wrongCount = 0;
    tries.forEach((attempt) => {
      attempt.isCorrect === false && wrongCount++;
    });
    return wrongCount / tries.length >= wkk && !correct;
  }

  static checkWkk(tries, wkk) {
    var notCorrect = tries.some((attempt) => attempt.isCorrect === false);
    return (
      (this.countRightAnswersPercent(tries) >= wkk * 100 && !notCorrect) ||
      this.isWkkForWrongAnswersFulfilled(tries, wkk)
    );
  }

  static checkWkkForTpr(tries, wkk) {
    var notCorrect = tries.some((attempt) => attempt.isCorrect === false);
    var gapCount = 0;
    if (!notCorrect) {
      tries.forEach((attempt) => {
        attempt.isCorrect &&
          attempt.listOfPlayedSounds[0].metadata.rightAnswers[0].text ===
            "gap" &&
          gapCount++;
      });
    }

    return (
      (this.countRightAnswersPercent(tries) >= wkk * 100 &&
        !notCorrect &&
        gapCount >= (wkk * tries.length) / 2) ||
      this.isWkkForWrongAnswersFulfilled(tries, wkk)
    );
  }

  static checkIfAnswerIsCorrectTwoSoundsPlayed(attempt) {
    var rightAnswers = [];
    attempt.listOfPlayedSounds.forEach(({ metadata }) => {
      rightAnswers.push(...metadata.rightAnswers);
    });
    const isCorrect = rightAnswers.every((element) => {
      return attempt.givenAnswers.some((ans) => ans.text === element.text);
    });
    return isCorrect;
  }

  static getkk(kkArray, age) {
    var kk = kkArray.find(
      (kkobj) => age >= kkobj.ageStart && age <= kkobj.ageStop
    )?.kk;
    if (kk === undefined) {
      //jeśli nie znaleziono to znaczy, że nie zdefiniowano kryterium dla danego wieku - bierzemy pod uwagę najbliższą kategorię
      var distancesFromStartStop = [];
      kkArray.forEach((kkObj, index) => {
        distancesFromStartStop.push({
          index,
          distanceFromStart: Math.abs(age - kkObj.ageStart),
          distanceFromStop: Math.abs(age - kkObj.ageStop),
        });
      });

      const minDistance = Math.min(
        ...distancesFromStartStop
          .map(({ distanceFromStart, distanceFromStop }) => [
            distanceFromStart,
            distanceFromStop,
          ])
          .reduce((a, b) => a.concat(b))
      );
      const minDistanceWithIndex = distancesFromStartStop.find(
        (distances) =>
          distances.distanceFromStart === minDistance ||
          distances.distanceFromStop === minDistance
      );
      kk = kkArray[minDistanceWithIndex.index].kk;
    }
    return kk;
  }

  //jak dostaniemy pogrupowane wyrazy, to zobaczyc czy szybciej bedzie odejmowac z pelnego zbioru czy dodawac do pustego
  static drawPossibleAnswers(
    allPossibleAnswers,
    numberOfPossibleAnswers,
    rightAnswers
  ) {
    if (allPossibleAnswers.length > 0) {
      var possibleAnswers = Object.assign([], rightAnswers);

      allPossibleAnswers = allPossibleAnswers.filter(
        ({ text }) => !rightAnswers.some((rightAns) => text === rightAns.text)
      );

      while (possibleAnswers.length < numberOfPossibleAnswers) {
        //w razie jakby podano za mala liczbe mozliwych odpowiedzi
        if (allPossibleAnswers.length === 0) {
          allPossibleAnswers = allWords.filter(
            ({ text }) =>
              !possibleAnswers.some((posAns) => text === posAns.text)
          );
        }
        const random =
          allPossibleAnswers[
            Math.floor(Math.random() * allPossibleAnswers.length)
          ];
        possibleAnswers.push(random);
        allPossibleAnswers = allPossibleAnswers.filter(
          ({ text }) => text !== random.text
        );
      }

      return shuffleArray(possibleAnswers);
    } else return [];
  }
  static drawPossibleAnswersForAllWavs(wavFiles, numberOfPossibleAnswers) {
    return wavFiles.map((wav) => {
      return {
        ...wav,
        metadata: {
          ...wav.metadata,
          possibleAnswers: this.drawPossibleAnswers(
            wav.metadata.possibleAnswers,
            numberOfPossibleAnswers,
            wav.metadata.rightAnswers
          ),
        },
      };
    });
  }

  //wynik dla ucha będzie prawidłowy, jeśli próg dla częstotliwości 1000Hz będzie <= correctThreshold z konfiguracji
  static checkThresholdFor1000PerOneEar(frequencyResults) {
    const answerFor1000 = frequencyResults.find(
      ({ frequency }) => frequency === 1000
    )?.answer;
    var result = null;
    if (answerFor1000 === Answer.FANC) {
      result = Result.FANC;
    } else if (answerFor1000 === Answer.FP) {
      result = Result.FP;
    } else if (
      answerFor1000 === Answer.MAXEXCEEDED ||
      answerFor1000 === "" ||
      answerFor1000 > lqConfig.correctThreshold
    ) {
      result = Result.NOK;
    } else if (
      answerFor1000 <= lqConfig.correctThreshold ||
      answerFor1000 === undefined
    ) {
      result = Result.OK;
    }
    return result;
  }

  static checkThresholdsResultsPerOneEar(frequenciesResult) {
    if (frequenciesResult.length === 0) {
      return Result.OK;
    }

    const falseFounded = frequenciesResult.some(
      ({ answer }) =>
        answer === Answer.MAXEXCEEDED ||
        answer === "" ||
        answer > lqConfig.correctThreshold
    );

    const fpFounded = frequenciesResult.some(
      ({ answer }) => answer === Answer.FP
    );

    const fANCFounded = frequenciesResult.some(
      ({ answer }) => answer === Answer.FANC
    );

    const allNullAnswers = frequenciesResult.every(
      ({ answer }) => answer === ""
    );
    const allCorrect = frequenciesResult.every(
      ({ answer }) => answer <= lqConfig.correctThreshold
    );

    var result = null;
    if (allNullAnswers) {
      result = null;
    } else if (fANCFounded) {
      result = Result.FANC;
    } else if (fpFounded) {
      result = Result.FP;
    } else if (falseFounded) {
      result = Result.NOK;
    } else if (allCorrect) {
      result = Result.OK;
    }

    return result;
  }

  static determineOverallResult(resultRight, resultLeft) {
    var result = null;
    if (resultRight !== null && resultLeft !== null) {
      if (resultRight === Result.FANC || resultLeft === Result.FANC) {
        result = Result.FANC;
      } else if (resultRight === Result.FP || resultLeft === Result.FP) {
        result = Result.FP;
      } else if (resultRight === Result.NOK || resultLeft === Result.NOK) {
        result = Result.NOK;
      } else if (resultRight === Result.OK && resultLeft === Result.OK) {
        result = Result.OK;
      }
    } else if (
      (resultRight === null && resultLeft !== null) ||
      (resultLeft === null && resultRight !== null)
    ) {
      if (resultRight === Result.FP || resultLeft === Result.FP) {
        result = Result.FP;
      } else if (resultRight === Result.FANC || resultLeft === Result.FANC) {
        result = Result.FANC;
      } else {
        result = Result.NOK;
      }
    }
    return result;
  }

  static determineOverallResultCAPD(resultRight, resultLeft) {
    if (resultRight === Result.NOK || resultLeft === Result.NOK) {
      return Result.NOK;
    } else if (resultRight === Result.OK && resultLeft === Result.OK) {
      return Result.OK;
    } else return Result.AMB;
  }

  static countRightAnswersPercentDichotic(tries) {
    if (tries.length === 0) {
      return "0";
    }
    var score = 0;
    var length = 0;
    tries.forEach((attempt) => {
      attempt.listOfPlayedSounds.forEach((sound) => {
        sound.metadata.rightAnswers.forEach((element) => {
          length++;
          attempt.givenAnswers.some((ans) => ans.text === element.text) &&
            score++;
        });
      });
    });
    if (length === 0) {
      return "0";
    }
    return ((score * 100) / length).toFixed(0);
  }

  static countRightAnswersPercentOneEar(tries, ear) {
    if (tries.length === 0) {
      return "0";
    }
    var score = 0;
    var length = 0;
    tries.forEach((attempt) => {
      attempt.listOfPlayedSounds
        .find(({ channel }) => channel === ear)
        .metadata.rightAnswers.forEach((element) => {
          length++;
          attempt.givenAnswers.some((ans) => ans.text === element.text) &&
            score++;
        });
    });
    if (length === 0) {
      return "0";
    }
    return ((score * 100) / length).toFixed(0);
  }
}
