import { useEffect, useState } from "react";
import { Box, Stack, Typography, Button } from "@mui/material";
import { texts, testNames } from "../resources/texts";
import { ReactComponent as Square } from "../resources/images/square.svg";
import WomanHeadphonesRightLeftAnimation from "../components/animations/WomanHeadphonesRightLeftAnimation";
import manWoman from "../resources/images/man-woman.svg";
import lamaBubbles from "../resources/images/lama-bubbles.svg";
import animalsDescriptionFirst from "../resources/images/animals-description-first.svg";
import SheepHeader from "../components/animations/SheepHeader";
import "./index.css";
import { useApi } from "../Api";
import { useNotificationSubject } from "../Notifications";

const TestDescription = ({
  handleNext,
  testType,
  marginLeft = 28,
  forKids,
  instructionSound,
  handleError,
}) => {
  const api = useApi();
  const notificationSubject = useNotificationSubject();
  const [instructionFinished, setInstructionFinished] = useState(false);
  const [clicked, setClicked] = useState(false);
  const [canClickNext, setCanClickNext] = useState(false);

  const description = (testType) => {
    switch (testType) {
      case "AP":
        return forKids
          ? texts.audiometry.kidsAudiometryDescription
          : texts.audiometry.adultAudiometryDescription;

      case "GC":
        return forKids
          ? texts.audiometry.kidsDescriptionThreshold
          : texts.audiometry.thresholdAudiometryDescription;

      case "AS":
        return texts.speachAudiometry.description;

      case "CAPD":
        return texts.capdDescription;
      default:
        return "";
    }
  };

  useEffect(() => {
    const notificationObserver = (notification) => {
      switch (notification.type) {
        case "ExceptionWithCodeAndTimeStamp":
          if (notification.exceptionClassName === "Playing") {
            setCanClickNext(true);
          }
          break;
        default:
          break;
      }
    };
    notificationSubject.attach(notificationObserver);

    return () => {
      notificationSubject.detach(notificationObserver);
    };
  }, [notificationSubject]);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    if (instructionSound) {
      api
        .playInstruction([instructionSound], signal)
        .then(() => {
          setInstructionFinished(true);
          setCanClickNext(true);
        })
        .catch((e) => {
          handleError && !signal.aborted && handleError(e.message);
        });
    } else {
      setCanClickNext(true);
      setInstructionFinished(true);
    }

    return () => {
      controller.abort();
    };
  }, []);

  return (
    <Box
      sx={{
        pt: forKids ? 10 : 18,
        ml: marginLeft,
      }}
    >
      <Stack direction="row">
        <Stack alignItems="center">
          <Typography
            variant="title"
            sx={{
              color: (theme) => theme.palette.text.secondary,
              fontSize: "40px",
            }}
          >
            {testType !== "CAPD" ? texts.examination : texts.disorderAssessment}
          </Typography>
          <Stack
            direction="row"
            spacing={8}
            alignItems="center"
            justifyContent="center"
            sx={{ mb: 8 }}
          >
            <Square />
            <Typography variant="title" sx={{ fontSize: "40px" }}>
              {testType !== "CAPD"
                ? testNames[testType]
                : texts.auditoryProcessing}
            </Typography>
            <Square />
          </Stack>
          <Typography
            variant="h4"
            sx={{
              maxWidth: "1000px",
              lineHeight: 1.5,
              whiteSpace: "pre-line",
              fontSize: forKids ? 35 : 25,
            }}
          >
            {description(testType)}
          </Typography>
          <br />
          <Typography
            variant="h4"
            sx={{
              maxWidth: "1000px",
              lineHeight: 1.5,
              fontSize: forKids ? 35 : 25,
            }}
          >
            {texts.clickNextToContinue}
          </Typography>
        </Stack>
        {testType === "CAPD" ? (
          <img src={manWoman} alt="" />
        ) : forKids ? (
          <img
            src={testType === "AP" ? animalsDescriptionFirst : lamaBubbles}
            className={
              testType === "AP" ? "animals-desc-first" : "lama-desc-first"
            }
            alt=""
          />
        ) : (
          <WomanHeadphonesRightLeftAnimation />
        )}
      </Stack>
      <Button
        variant="contained"
        size="large"
        sx={{ position: "fixed", right: "40px", bottom: "50px", px: 10 }}
        disabled={clicked || !canClickNext}
        onClick={() => {
          setClicked(true);
          if (!instructionFinished) {
            api
              .stopPlaying()
              .then(() => {
                setTimeout(() => {
                  handleNext && handleNext();
                }, [1000]); //musimy odczekać po wykonaniu api.stopPlaying() inaczej kod audiometrowy rzuca błędy przy następnej instrukcji
              })
              .catch((e) => {
                handleError && handleError(e.message);
              });
          } else {
            handleNext && handleNext();
          }
        }}
      >
        {texts.continue}
      </Button>
      {testType === "GC" && forKids && <SheepHeader />}
    </Box>
  );
};
export default TestDescription;
