import { useEffect, useState, useMemo, useRef } from "react";
import {
  Box,
  Button,
  Typography,
  Stack,
  Table,
  TableContainer,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  CircularProgress,
} from "@mui/material";
import { useApi } from "../Api";
import ConfirmationDialog from "../components/ConfirmationDialog";
import ProgressSlider from "../components/ProgressSlider";
import AnimatedInstruction from "../components/animations/AnimatedInstruction";
import AnimatedInstructionTswTsd from "../components/animations/AnimatedInstructionTswTsd";
import Test from "./TestClass";
import TswAndTsd from "./TswAndTsd";
import TrcAndTrs from "./TrcAndTrs";
import {
  AGE_FROM_WHICH_ADULT_VERSIONS_START,
  trcPossibleAnswers,
  wavFiles,
} from "../resources/wavFiles";
import OneRightAnswerTest from "./OneRightAnswerTest";
import {
  sleep,
  shuffleArray,
  wasTestInterrupted,
  isErrorFromAudiometr,
} from "../resources/exportedFunctions";
import BugReportIcon from "@mui/icons-material/BugReport";
import { useNotificationSubject } from "../Notifications";
import RepeatSoundInfoModal from "../components/RepeatSoundInfoModal";
import moment from "moment";
import { FailureReason, Result } from "./enums";
import { texts, testNames } from "../resources/texts";
import { FinishButton } from "../components/FinishButton";
import TestDescription from "../pages/TestDescription";
import CurrentStateInfo from "../components/CurrentStateInfo";
import bg from "../resources/images/bg.svg";
import bgTDW from "../resources/images/bg-tdw.svg";
import bgTSWTSD from "../resources/images/bg-tsw-tsd.svg";
import bgTPR from "../resources/images/bg-tpr.svg";
import ListenCarefullyAnimation from "../components/animations/ListenCarefullyAnimation";
import CAPDInstructionPresentation from "../components/CAPDInstructionPresentation";
import HeadphonesPulseAnimation from "../components/animations/HeadphonesPulseAnimation";
import CheckingNoises from "../components/animations/CheckingNoisesModal";
import { testsWavFiles } from "../resources/testsWavFiles";

const indexesWithSoundStartStop = [];
var bgNoises = [];
var lock = false;
var triedToSaveAnswer = false;

const initialAnswer = [{ text: "", image: "" }]; //dla testów jednokrotnego wyboru
const initialGivenAnswers = [
  { text: "", image: "", id: "" },
  { text: "", image: "", id: "" },
  { text: "", image: "", id: "" },
];

const isTRCorTRS = (testEnum) => ["TRC", "TRS"].includes(testEnum);
const isTSWorTSD = (testEnum) => ["TSW", "TSD"].includes(testEnum);
const isOneRightAnswerTest = (testEnum) =>
  ["TDW", "TPR", "TRMS", "TMS", "TMF", "AS"].includes(testEnum);

const fillWavsForAnimation = (wavs, testEnum, numberOfPossibleAnswers) => {
  if (wavs && Array.isArray(wavs) && wavs.length > 0) {
    if (["TMF", "TMS", "TRMS"].includes(testEnum)) {
      return [
        {
          ...wavs[0],
          metadata: {
            ...wavs[0].metadata,
            possibleAnswers: Test.drawPossibleAnswers(
              wavs[0].metadata.possibleAnswers,
              numberOfPossibleAnswers,
              wavs[0].metadata.rightAnswers
            ),
          },
        },
      ];
    } else if (testEnum === "AS") {
      return [
        {
          ...wavs[0],
          channel: "RIGHT",
          metadata: {
            ...wavs[0].metadata,
            possibleAnswers: Test.drawPossibleAnswers(
              wavs[0].metadata.possibleAnswers,
              numberOfPossibleAnswers,
              wavs[0].metadata.rightAnswers
            ),
          },
        },
      ];
    } else if (testEnum === "TRC") {
      if (wavs.length > 1) {
        return [
          {
            ...wavs[0],
            metadata: {
              ...wavs[0].metadata,
              possibleAnswers: trcPossibleAnswers,
            },
          },
          wavs[1],
        ];
      } else return [];
    } else if (testEnum === "TRS") {
      if (wavs.length > 1) {
        return [
          {
            ...wavs[0],
            metadata: {
              ...wavs[0].metadata,
              possibleAnswers: Test.drawPossibleAnswers(
                [
                  ...new Set([
                    ...wavs[0].metadata.possibleAnswers,
                    ...wavs[1].metadata.possibleAnswers,
                  ]),
                ],
                numberOfPossibleAnswers,
                [
                  ...wavs[0].metadata.rightAnswers,
                  ...wavs[1].metadata.rightAnswers,
                ]
              ),
            },
          },
          wavs[1],
        ];
      } else return [];
    } else {
      return wavs;
    }
  } else return [];
};

const getInitialGivenAnswers = (testEnum) => {
  if (isTSWorTSD(testEnum)) {
    return initialGivenAnswers;
  } else if (isTRCorTRS(testEnum)) {
    return [];
  } else if (isOneRightAnswerTest(testEnum)) {
    return initialAnswer;
  }
};

const checkCorrectness = (attempt, testEnum) => {
  if (isTRCorTRS(testEnum)) {
    return Test.checkIfAnswerIsCorrectTwoSoundsPlayed(attempt);
  } else {
    return Test.checkIfAnswerIsCorrectOneSoundPlayed(attempt);
  }
};

const TableForTestingPurposes = ({
  currentIndex,
  trainingTries,
  tries,
  retryCount,
  visible,
}) => {
  return (
    <Box
      sx={{
        position: "absolute",
        top: 170,
        left: 0,
        visibility: visible ? "visible" : "hidden",
      }}
    >
      <Typography>Debug Info</Typography>
      <TableContainer>
        <Table size="small">
          <TableBody>
            <TableRow>
              <TableCell>numer</TableCell>
              <TableCell>poprawna </TableCell>
              <TableCell>udzielona </TableCell>
            </TableRow>
            {trainingTries.map((attempt, index) => {
              var rightAnswers = [];
              attempt.listOfPlayedSounds.forEach((sound) =>
                rightAnswers.push(...sound.metadata.rightAnswers)
              );
              return (
                <TableRow
                  key={index}
                  sx={{
                    backgroundColor: currentIndex === index ? "lightgray" : "",
                  }}
                >
                  <TableCell>{`U${index + 1}`}</TableCell>
                  <TableCell>
                    {rightAnswers.map(({ text }) => text).join(" ")}
                  </TableCell>
                  <TableCell
                    sx={{ color: attempt.isCorrect ? "green" : "red" }}
                  >
                    {attempt.givenAnswers.map(({ text }) => text).join(" ")}
                  </TableCell>
                </TableRow>
              );
            })}
            {tries.map((attempt, index) => {
              var rightAnswers = [];
              attempt.listOfPlayedSounds.forEach((sound) =>
                rightAnswers.push(...sound.metadata.rightAnswers)
              );
              return (
                <TableRow
                  key={index}
                  sx={{
                    backgroundColor: currentIndex === index ? "lightgray" : "",
                  }}
                >
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>
                    {rightAnswers.map(({ text }) => text).join(" ")}
                  </TableCell>
                  <TableCell
                    sx={{ color: attempt.isCorrect ? "green" : "red" }}
                  >
                    {attempt.givenAnswers.map(({ text }) => text).join(" ")}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <Typography>{`liczba powtórzeń: ${retryCount}`}</Typography>
    </Box>
  );
};

const Instruction = ({ instructionToPlay, setDisplayAnimation, onError }) => {
  const api = useApi();

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    api
      .playInstruction([instructionToPlay], signal)
      .then(() => setDisplayAnimation(true))
      .catch((e) => {
        !signal.aborted && onError(e.message);
      });

    return () => controller.abort();
  }, []);

  return (
    <Box sx={{ mt: 2 }}>
      <CurrentStateInfo
        firstLine={`${texts.examination}:`}
        secondLine={testNames["AS"]}
      />
      <Stack justifyContent={"center"} alignItems="center">
        <Typography
          variant="h4"
          sx={{
            maxWidth: "820px",
            zIndex: 2,
            lineHeight: 1.5,
            whiteSpace: "pre-line",
          }}
        >
          {texts.speachAudiometry.instruction}
        </Typography>
        <HeadphonesPulseAnimation />
      </Stack>
    </Box>
  );
};

const InstructionsForAS = ({
  testPhase,
  changePhaseToInstruction,
  instructionsToPlay,
  setDisplayAnimation,
  onError,
}) => {
  if (testPhase === "description") {
    return (
      <TestDescription
        handleNext={changePhaseToInstruction}
        testType="AS"
        marginLeft={20}
        handleError={onError}
        instructionSound={instructionsToPlay[0]}
      />
    );
  } else if (testPhase === "instruction") {
    return (
      <Instruction
        instructionToPlay={instructionsToPlay[1]}
        setDisplayAnimation={setDisplayAnimation}
        onError={onError}
      />
    );
  } else {
    return null;
  }
};

const DisplayInstructionAndSoundsPresentation = ({
  testState,
  displayAnimation,
  testPhase,
  testEnum,
  answersToInstructionsSounds,
  playingSound,
  changePhase,
  instructionsToPlay,
  setDisplayAnimation,
  onError,
}) => {
  if (
    testState !== "finished" &&
    !displayAnimation &&
    testState !== "before-start"
  ) {
    if (testPhase === "learning") {
      return (
        <CAPDInstructionPresentation
          presentationSounds={answersToInstructionsSounds}
          testType={testEnum}
          playingSound={!["TSW", "TSD"].includes(testEnum) && playingSound}
          marginLeft={
            ["TRC", "TRS", "TRMS", "TMS", "TMF"].includes(testEnum) ? 28 : 20
          }
        />
      );
    } else if (["instruction", "description"].includes(testPhase)) {
      return (
        <InstructionsForAS
          testPhase={testPhase}
          changePhaseToInstruction={() => changePhase("instruction")}
          instructionsToPlay={instructionsToPlay}
          setDisplayAnimation={setDisplayAnimation}
          onError={onError}
        />
      );
    } else if (testPhase === "testing") {
      return (
        <Box>
          <br />
          <br />
        </Box>
      );
    } else if (testPhase === "capdDescription") {
      return (
        <TestDescription
          testType={"CAPD"}
          handleNext={() => changePhase("learning")}
          handleError={onError}
          instructionSound={wavFiles[testsWavFiles.CAPD.instructionsSounds[0]]}
        />
      );
    }
  }
};

const DisplayAnimatedInstruction = ({
  displayAnimation,
  testState,
  testEnum,
  startTraining,
  startTest,
  intensity,
  wavsForAnimation,
  onError,
}) => {
  if (displayAnimation && testState !== "finished") {
    if (isTSWorTSD(testEnum)) {
      return (
        <AnimatedInstructionTswTsd
          startTraining={startTraining}
          startTest={startTest}
          testEnum={testEnum}
          intensity={intensity}
          onError={onError}
        />
      );
    } else {
      return (
        <AnimatedInstruction
          wavFiles={wavsForAnimation}
          isNextButton={isTRCorTRS(testEnum)}
          startTraining={startTraining}
          startTest={startTest}
          intensity={intensity}
          onError={onError}
          testEnum={testEnum}
        />
      );
    }
  }
};

const TestComponent = ({
  testEnum,
  givenAnswers,
  saveAnswer,
  changeGivenAnswers,
  possibleAnswers,
  testPhase,
  currentTriesLength,
  currentIndex,
}) => {
  if (isTSWorTSD(testEnum)) {
    return (
      <TswAndTsd
        givenAnswers={givenAnswers}
        saveAnswer={saveAnswer}
        setGivenAnswers={changeGivenAnswers}
        possibleAnswers={possibleAnswers}
        testPhase={testPhase}
        currentTriesLength={currentTriesLength}
        currentIndex={currentIndex}
        isTSW={testEnum === "TSW"}
      />
    );
  } else if (isTRCorTRS(testEnum)) {
    return (
      <TrcAndTrs
        givenAnswers={givenAnswers}
        saveAnswer={saveAnswer}
        setGivenAnswers={changeGivenAnswers}
        possibleAnswers={possibleAnswers}
        testPhase={testPhase}
        currentTriesLength={currentTriesLength}
        currentIndex={currentIndex}
        isTRC={testEnum === "TRC"}
      />
    );
  } else if (isOneRightAnswerTest(testEnum)) {
    return (
      <OneRightAnswerTest
        givenAnswers={givenAnswers}
        setGivenAnswers={changeGivenAnswers}
        possibleAnswers={possibleAnswers}
        nextButton={["TRMS", "TMS", "TMF", "AS"].includes(testEnum)}
        testEnum={testEnum}
      />
    );
  }
};

const BeforeStartComponent = ({ startTest, onError }) => {
  const api = useApi();
  const notificationSubject = useNotificationSubject();
  const [canClickNext, setCanClickNext] = useState(false);
  const [clicked, setClicked] = useState(false);
  const [
    trainingFinishedInstructionPlaying,
    setTrainingFinishedInstructionPlaying,
  ] = useState(true);
  const abortController = useMemo(() => new AbortController(), []);

  useEffect(() => {
    const notificationObserver = (notification) => {
      switch (notification.type) {
        case "ExceptionWithCodeAndTimeStamp":
          if (notification.exceptionClassName === "Playing") {
            setCanClickNext(true);
          }
          break;
        default:
          break;
      }
    };
    notificationSubject.attach(notificationObserver);

    return () => {
      notificationSubject.detach(notificationObserver);
    };
  }, [notificationSubject]);

  useEffect(() => {
    api
      .playInstruction([wavFiles.trainingEnds], abortController.signal)
      .then(() => {
        setTrainingFinishedInstructionPlaying(false);
        setCanClickNext(true);
      })
      .catch((e) => {
        !abortController.signal.aborted && onError(e.message);
      });

    return () => {
      abortController.abort();
    };
  }, []);

  useEffect(() => {
    if (canClickNext && clicked) {
      if (trainingFinishedInstructionPlaying) {
        api
          .stopPlaying(abortController.signal)
          .then(() => {
            setTimeout(() => {
              startTest();
            }, [1000]); //musimy odczekać po wykonaniu api.stopPlaying() inaczej kod audiometrowy rzuca błędy przy następnej instrukcji
          })
          .catch((e) => {
            !abortController.signal.aborted && onError(e.message);
          });
      } else {
        startTest();
      }
    }
  }, [clicked, canClickNext]);

  return (
    <Button
      variant="contained"
      onClick={() => setClicked(true)}
      endIcon={
        clicked === true ? <CircularProgress sx={{ color: "white" }} /> : ""
      }
      sx={{
        position: "fixed",
        bottom: "100px",
        right: "40px",
        px: 4,
      }}
    >
      {clicked === true ? texts.startingTest : texts.startTest}
    </Button>
  );
};

const ComponentsToDisplayBasedOnTestState = ({
  testState,
  startTest,
  testEnum,
  givenAnswers,
  saveAnswer,
  changeGivenAnswers,
  possibleAnswers,
  testPhase,
  currentTriesLength,
  currentIndex,
  testName,
  onError,
}) => {
  switch (testState) {
    case "before-start":
      return testPhase === "training" ? (
        <BeforeStartComponent startTest={startTest} onError={onError} />
      ) : null;
    case "ready":
      return testPhase !== "learning" ? (
        <TestComponent
          testEnum={testEnum}
          givenAnswers={givenAnswers}
          saveAnswer={saveAnswer}
          changeGivenAnswers={changeGivenAnswers}
          possibleAnswers={possibleAnswers}
          testPhase={testPhase}
          currentTriesLength={currentTriesLength}
          currentIndex={currentIndex}
          testName={testName}
        />
      ) : null;

    case "playing":
      return (
        <Box mt={5}>
          <ListenCarefullyAnimation />
        </Box>
      );

    default:
      break;
  }
};

const TestManager = ({
  test,
  config,
  onEvent,
  automaticLogout,
  startDate,
  showCAPDDescription,
  parentError,
}) => {
  const api = useApi();
  const [playingSound, setPlayingSound] = useState("");
  const [testState, setTestState] = useState(); //mozliwe: before-start, playing, ready, finished
  const [testPhase, setTestPhase] = useState(
    test.testEnum !== "AS"
      ? showCAPDDescription
        ? "capdDescription"
        : "learning"
      : "description"
  ); //mozliwe: learning, training, testing, description, instruction, capdDescription
  const [currentIndex, setCurrentIndex] = useState(new Number(-1)); //Number użyty w celu możliwości powtórzenia próby o tym samym indeksie co jest aktualnie
  const [instructionIndex, setInstructionIndex] = useState(
    showCAPDDescription ? -1 : 0
  );
  const [givenAnswers, setGivenAnswers] = useState(
    getInitialGivenAnswers(test.testEnum)
  );
  const [possibleAnswers, setPossibleAnswers] = useState(
    test.testEnum === "TRC" ? trcPossibleAnswers : []
  );
  const [displayAnimation, setDisplayAnimation] = useState(false);
  const [displayFinishTestConfirmation, setDisplayFinishTestConfirmation] =
    useState(false);

  const [resultRightLeft, setResultRightLeft] = useState({
    right: "",
    left: "",
  });

  const [error, setError] = useState();
  const mounted = useRef(false);
  const [displayRepeatInfo, setDispayRepeatInfo] = useState(false);
  const [retryCount, setRetryCount] = useState(0);
  const [loaderDisplay, setLoaderDisplay] = useState(false);
  const [debugInfoDisplay, setDebugInfoDisplay] = useState(false);
  const wavsForAnimation = useMemo(
    () =>
      fillWavsForAnimation(
        config.wavsForAnimation,
        test.testEnum,
        config.numberOfPossibleAnswers
      ),
    []
  );

  const indexRef = useRef();
  indexRef.current = currentIndex;
  const phaseRef = useRef();
  phaseRef.current = testPhase;
  const retryCountRef = useRef();
  retryCountRef.current = retryCount;
  const triedToSkipTraining = useRef(false);

  const abortController = useMemo(() => new AbortController(), []);

  const startTest = (resultRight) => {
    setTestPhase("testing");
    setCurrentIndex(
      resultRightLeft.right === false || resultRight === false
        ? new Number(test.tries.length / 2)
        : new Number(0)
    );
  };

  const startTraining = () => {
    if (test.training.tries.length !== 0) {
      setTestPhase("training");
      setCurrentIndex(new Number(0));
    } else {
      startTest();
    }
  };

  const handleSkipTrainingClick = () => {
    if (triedToSkipTraining.current === false) {
      setGivenAnswers(getInitialGivenAnswers(test.testEnum));
      if (lock) {
        triedToSkipTraining.current = true;
      } else {
        startTest();
      }
    }
  };

  useEffect(() => {
    indexesWithSoundStartStop.length = 0;
    bgNoises = [];
    lock = false;
    triedToSaveAnswer = false;
  }, []);

  useEffect(() => {
    !error && parentError && handleError(parentError);
  }, [parentError]);

  useEffect(() => {
    if (testPhase === "learning") {
      setInstructionIndex(0);
    }
    if (testPhase === "testing" || testPhase === "training") {
      var bgImg = bg;
      if (test.testEnum === "TDW") {
        bgImg = bgTDW;
      } else if (test.testEnum === "TSW" || test.testEnum === "TSD") {
        bgImg = bgTSWTSD;
      } else if (test.testEnum === "TPR") {
        bgImg = bgTPR;
      }
      document.body.style.backgroundImage = `url(${bgImg})`;
    }
  }, [testPhase]);

  const notificationSubject = useNotificationSubject();
  useEffect(() => {
    const notificationObserver = (notification) => {
      //console.log(notification);
      switch (notification.type) {
        case "ExceptionWithCodeAndTimeStamp":
          if (
            notification.interfaceGroupingExceptions.startsWith(
              "ResetExaminationAttempt"
            )
          ) {
            //console.log("-----HAŁAS", notification.ts);
            bgNoiseHandler(new Date(notification.ts).getTime());
          }
          break;
        default:
          break;
      }
    };
    notificationSubject.attach(notificationObserver);

    return () => {
      notificationSubject.detach(notificationObserver);
    };
  }, [notificationSubject]);

  // useEffect(() => {
  //   console.log("@@@@@@@@@@@@@@@@@ currentIndex", currentIndex);
  // }, [currentIndex]);

  useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
      abortController.abort();
    };
  }, []);

  useEffect(() => {
    const play = async () => {
      //console.log("--------------------dźwięk gra");
      lock = true;
      triedToSaveAnswer = false;
      mounted.current && setTestState("playing");

      var sounds;
      var intensity = test.intensity;
      if (testPhase === "testing") {
        sounds = Array.from(
          test.tries[currentIndex.valueOf()].listOfPlayedSounds
        );
      } else {
        sounds = Array.from(
          test.training.tries[currentIndex.valueOf()].listOfPlayedSounds
        );
        if (test.testEnum === "AS") {
          intensity = config.trainingIntensity;
        }
      }

      if (mounted.current) {
        try {
          const time = await api.playWav(
            sounds,
            intensity,
            abortController.signal
          );
          //console.log("time.start", time.start, "time.stop", time.stop);
          if (mounted.current) {
            const currentIndexObj = indexesWithSoundStartStop.find(
              ({ index, phase }) =>
                index === currentIndex.valueOf() && phase === testPhase
            );
            if (currentIndexObj) {
              currentIndexObj.startTime = time.start;
              currentIndexObj.stopTime = time.stop;
              currentIndexObj.phase = testPhase;
            } else {
              indexesWithSoundStartStop.push({
                index: currentIndex.valueOf(),
                startTime: time.start,
                stopTime: time.stop,
                phase: testPhase,
              });
            }
            if (indexesWithSoundStartStop.length > 5) {
              //usunięcie czasów dźwięków dla prób starszych niż pięć ostatnich
              indexesWithSoundStartStop.splice(
                0,
                indexesWithSoundStartStop.length - 5
              );
            }
            // mounted.current &&
            //   console.log("dźwięk zakończony - sprawdzamy czy był hałas");
            if (mounted.current) {
              testPhase === "training" && triedToSkipTraining.current === true
                ? startTest()
                : handleAllNoisesAfterSoundStop();
            }

            //console.log("--------------------koniec dźwięku");

            mounted.current &&
              setTestState((prevState) =>
                prevState !== "finished" ? "ready" : "finished"
              );
          }
        } catch (e) {
          console.error(e);
          handleError(e.message);
        }
      }
    };

    const playLearning = async () => {
      const arrayLength = test.instructionsSounds.length;
      if (testState !== "finished") {
        if (arrayLength > instructionIndex) {
          if (instructionIndex === arrayLength - 1) {
            mounted.current && setPlayingSound("");
          }
          instructionIndex === 0 && (await sleep(500));

          if (mounted.current) {
            try {
              await api.playInstruction(
                [test.instructionsSounds[instructionIndex]],
                abortController.signal
              );
            } catch (e) {
              console.error(e);
              handleError(e.message);
            }
          }

          await sleep(500);
          if (
            arrayLength > instructionIndex + 2 &&
            test.instructionsSounds[instructionIndex + 2].metadata !== null
          ) {
            mounted.current &&
              setPlayingSound(
                test.instructionsSounds[instructionIndex + 2].metadata
                  .rightAnswers[0].text
              );
          }
          if (instructionIndex === arrayLength - 1) {
            if (isTSWorTSD(test.testEnum)) {
              mounted.current && setDisplayAnimation(true);
            } else {
              wavsForAnimation &&
                wavsForAnimation.length > 0 &&
                mounted.current &&
                setDisplayAnimation(true);
            }
            return;
          }
          mounted.current &&
            instructionIndex !== arrayLength - 1 &&
            setInstructionIndex((prevState) => prevState + 1);
        }
      }
    };

    if (test.testEnum === "TRS") {
      if (test && (testPhase === "testing" || testPhase === "training")) {
        var possibleAnswersTmp = [];
        (testPhase === "testing" ? test : test.training).tries[
          currentIndex.valueOf()
        ].listOfPlayedSounds.forEach((wav) => {
          possibleAnswersTmp.push(...wav.metadata.possibleAnswers);
        });

        setPossibleAnswers(shuffleArray(possibleAnswersTmp));
      }
    }

    if (testState !== "finished" && mounted.current) {
      (testPhase === "testing" || testPhase === "training") && play();
      testPhase === "learning" && playLearning();
    }
  }, [currentIndex, instructionIndex]);

  const saveAnswer = () => {
    //console.log("saveAnswer()");
    if (!lock) {
      lock = true;
      triedToSaveAnswer = false;
      //console.log("LOCK=FALSE - przechodzimy do następnej próby");

      setTestState();
      var attempt =
        testPhase === "testing"
          ? test.tries[currentIndex.valueOf()]
          : test.training.tries[currentIndex.valueOf()];
      if (givenAnswers.length > 0) {
        attempt.givenAnswers = givenAnswers.map(({ text, image }) => {
          return { text, image };
        });
      } else {
        attempt.givenAnswers = [{ text: "<brak>", image: "" }];
      }

      attempt.isCorrect = checkCorrectness(attempt, test.testEnum);

      const checkWkk =
        test.testEnum !== "TPR"
          ? Test.checkWkk(test.tries, test.wkk)
          : Test.checkWkkForTpr(test.tries, test.wkk);
      setGivenAnswers(getInitialGivenAnswers(test.testEnum));

      if (testPhase === "testing" && checkWkk === true) {
        //usunięcie dalszych prób
        test.tries = test.tries.filter(
          ({ givenAnswers }) => givenAnswers.length > 0
        );

        setTestState("finished");
      } else {
        nextAttempt();
      }
    } else {
      triedToSaveAnswer = true;
      //console.log("LOCK=TRUE w saveAnswer()");
    }
  };

  const nextAttempt = () => {
    if (
      (testPhase === "testing" &&
        currentIndex.valueOf() !== test.tries.length - 1) ||
      (testPhase === "training" &&
        currentIndex.valueOf() !== test.training.tries.length - 1)
    ) {
      if (
        test.testEnum === "AS" &&
        currentIndex.valueOf() === test.tries.length / 2 - 1 &&
        resultRightLeft.left === false
      ) {
        setTestState("finished");
      } else {
        setCurrentIndex((prevState) => new Number(prevState.valueOf() + 1));
      }
    } else {
      testPhase === "testing" && setTestState("finished");
      if (testPhase === "training") {
        if (test.testEnum === "AS") {
          var resultRight = true;
          var resultLeft = true;

          const rightTrainingAttempts = test.training.tries.filter(
            ({ listOfPlayedSounds }) =>
              listOfPlayedSounds[0].channel === "RIGHT"
          );
          if (rightTrainingAttempts.length > 0) {
            resultRight = !rightTrainingAttempts.every(
              ({ isCorrect }) => isCorrect === false
            );
          }

          const leftTrainingAttempts = test.training.tries.filter(
            ({ listOfPlayedSounds }) => listOfPlayedSounds[0].channel === "LEFT"
          );
          if (leftTrainingAttempts.length > 0) {
            resultLeft = !leftTrainingAttempts.every(
              ({ isCorrect }) => isCorrect === false
            );
          }
          if (resultLeft === false || resultRight === false) {
            if (rightTrainingAttempts.length === 0) {
              resultRight = false;
            }
            if (leftTrainingAttempts.length === 0) {
              resultLeft = false;
            }
          }
          setResultRightLeft({ right: resultRight, left: resultLeft });
          if (resultLeft === false && resultRight === false) {
            setLoaderDisplay(true);
            setTimeout(() => {
              handleAllNoisesAfterSoundStop(() => setTestState("finished"));
              setLoaderDisplay(false);
              return;
            }, 3000);
          } else {
            startTest(resultRight);
          }
        } else {
          setTestState("before-start");
        }
      }
    }
  };

  useEffect(() => {
    JSON.stringify(givenAnswers) !== JSON.stringify(initialAnswer) &&
      isOneRightAnswerTest(test.testEnum) &&
      saveAnswer();
  }, [givenAnswers]);

  useEffect(() => {
    if (automaticLogout === true) {
      testState !== "finished"
        ? earlyFinish(FailureReason.INACTIVITY_LOGOUT)
        : onEvent &&
          onEvent("FINISHED", {
            result: Result.AMB,
            testType: test.testEnum,
            failureReason: FailureReason.INACTIVITY_LOGOUT,
            data: {
              ...test,
              earlyFinishedEnum: FailureReason.INACTIVITY_LOGOUT,
            },
            error: error,
            startDate: startDate,
            endDate: moment().toISOString(),
          });
    }
  }, [automaticLogout]);

  useEffect(() => {
    if (testState === "finished") {
      test.correctPercent = ["TRC", "TRS"].includes(test.testEnum)
        ? Test.countRightAnswersPercentDichotic(test.tries)
        : Test.countRightAnswersPercent(test.tries);
      if (retryCount > config.maxNumberOfBackgroundNoises) {
        test.result = Result.FANC;
        test.earlyFinishedEnum = Result.FANC;
      } else {
        test.result =
          test.correctPercent >= test.kk * 100 ? Result.OK : Result.NOK;
      }

      if (["AS", "TRC", "TRS"].includes(test.testEnum)) {
        if (test.testEnum === "AS") {
          const triesRight = test.tries.filter(
            (attempt) => attempt.listOfPlayedSounds[0].channel === "RIGHT"
          );
          const triesLeft = test.tries.filter(
            (attempt) => attempt.listOfPlayedSounds[0].channel === "LEFT"
          );
          if (triesRight.length === 0 || triesLeft.length === 0) {
            test.correctPercent = (test.correctPercent / 2).toFixed(0);
          }
          if (triesRight.length === 0) {
            test.correctPercentRight = null;
            test.resultRight = null;
          } else {
            test.correctPercentRight =
              Test.countRightAnswersPercent(triesRight);
            test.resultRight =
              test.correctPercentRight >= test.kk * 100
                ? Result.OK
                : Result.NOK;
          }
          if (triesLeft.length === 0) {
            test.correctPercentLeft = null;
            test.resultLeft = null;
          } else {
            test.correctPercentLeft = Test.countRightAnswersPercent(triesLeft);
            test.resultLeft =
              test.correctPercentLeft >= test.kk * 100 ? Result.OK : Result.NOK;
          }
        } else {
          test.correctPercentRight = Test.countRightAnswersPercentOneEar(
            test.tries,
            "RIGHT"
          );
          test.correctPercentLeft = Test.countRightAnswersPercentOneEar(
            test.tries,
            "LEFT"
          );
          test.resultRight =
            test.correctPercentRight >= test.kk * 100 ? Result.OK : Result.NOK;

          test.resultLeft =
            test.correctPercentLeft >= test.kk * 100 ? Result.OK : Result.NOK;
        }

        if (test.result !== Result.FANC) {
          test.result = Test.determineOverallResultCAPD(
            test.resultRight,
            test.resultLeft
          );
        }
      }

      var failureReason = test.earlyFinishedEnum;

      if (
        failureReason !== FailureReason.CANCELLED &&
        failureReason !== FailureReason.ERROR &&
        failureReason !== FailureReason.INACTIVITY_LOGOUT
      ) {
        if (test.result === Result.FANC) {
          failureReason = FailureReason.FANC;
        } else if (test.result === Result.NOK || test.result === Result.AMB) {
          failureReason = FailureReason.NORMAL;
        } else if (test.result === Result.OK) {
          failureReason = null;
        }
      }
      // console.log("rezult", {
      //   result:
      //     test.result === Result.FANC || wasTestInterrupted(failureReason)
      //       ? Result.AMB
      //       : test.result,
      //   testType: test.testEnum,
      //   failureReason,
      //   data: test,
      //   error: error,
      //   startDate: startDate,
      //   endDate: moment().toISOString(),
      // });
      onEvent &&
        onEvent("FINISHED", {
          result:
            test.result === Result.FANC || wasTestInterrupted(failureReason)
              ? Result.AMB
              : test.result,
          testType: test.testEnum,
          failureReason,
          data: test,
          error: error,
          startDate: startDate,
          endDate: moment().toISOString(),
        });
    }
  }, [testState]);

  async function earlyFinish(reason) {
    if (testState !== "finished") {
      //jeśli powodem zakończenia badania nie był błąd lub jeśli błąd nie pochodził od audiometru
      if (
        reason !== FailureReason.ERROR ||
        (parentError && !isErrorFromAudiometr(parentError))
      ) {
        try {
          api.stopPlaying();
        } catch (e) {
          console.error(e);
        }
      }
      test.earlyFinishedEnum = reason;
      setTestState("finished");
    }
  }

  const handleError = (errorCode) => {
    setError(errorCode);
    earlyFinish(FailureReason.ERROR);
  };

  const checkIfSoundIsNotFuture = (
    soundWithNoise,
    currentIndex,
    currentPhase
  ) => {
    if (soundWithNoise.index <= currentIndex) {
      if (soundWithNoise.phase === "training") {
        return true;
      } else if (currentPhase === "training") {
        return false;
      } else {
        return true;
      }
    } else if (
      soundWithNoise.phase === "training" &&
      currentPhase === "testing"
    ) {
      return true;
    } else return false;
  };

  const handleAllNoisesAfterSoundStop = (callback) => {
    lock = true;
    var runCallback = true;
    var soundWithSmallestIndexFound = undefined;
    bgNoises.some((noise) => {
      const soundWithNoise = checkIfNoiseWasDuringAnySound(noise);
      //jeśli znaleziony dźwięk nie jest przyszły
      if (
        soundWithNoise !== undefined &&
        checkIfSoundIsNotFuture(
          soundWithNoise,
          indexRef.current.valueOf(),
          phaseRef.current
        )
      ) {
        // console.log(
        //   "currentIndex.valueOf()",
        //   indexRef.current.valueOf(),
        //   "testPhase ",
        //   testPhase,
        //   "phaseRef.current",
        //   phaseRef.current
        // );
        soundWithSmallestIndexFound = soundWithNoise;
        return true;
      } else {
        return false;
      }
    });

    if (soundWithSmallestIndexFound !== undefined) {
      runCallback = false;
      // console.log(
      //   "############# changed sound to ",
      //   soundWithSmallestIndexFound
      // );
      if (retryCountRef.current + 1 > config.maxNumberOfBackgroundNoises) {
        setRetryCount((prevState) => prevState + 1);
        setTestState("finished"); //liczba hałasów została przekroczona
      } else {
        setRetryCount((prevState) => prevState + 1);
        triedToSaveAnswer = false;
        setDispayRepeatInfo(true);
        setTimeout(() => {
          setDispayRepeatInfo(false);
          setCurrentIndex(new Number(soundWithSmallestIndexFound.index));
          setTestPhase(soundWithSmallestIndexFound.phase);
          setGivenAnswers(getInitialGivenAnswers(test.testEnum));
        }, 3000);
      }
    } else {
      lock = false;
      if (triedToSaveAnswer) {
        // console.log(
        //   "tried to save answer ale był lock, więc próbujemy jeszcze raz"
        // );
        saveAnswer();
      }
    }

    bgNoises = [];
    runCallback && callback && callback();
  };

  const bgNoiseHandler = (ts) => {
    bgNoises.push(ts);
    //console.log("hałas zapisany - bgNoises", bgNoises);
    if (!lock) {
      //lock = true;
      // console.log(
      //   "sprawdzamy czy był hałas podczas dźwięku, bo nie gra dźwięk"
      // );

      handleAllNoisesAfterSoundStop();
    } else {
      //console.log("LOCK=TRUE, NIE sprawdzamy czy był hałas");
    }
  };

  const checkIfNoiseWasDuringAnySound = (noiseTime) => {
    var soundWithNoise = undefined;

    indexesWithSoundStartStop.some((obj) => {
      if (noiseTime > obj.startTime) {
        if (noiseTime < obj.stopTime) {
          //console.log("znaleziono dźwięk podczas hałasu", obj);
          soundWithNoise = obj;
          return true;
        }
      }
      return false;
    });

    return soundWithNoise;
  };

  const [first, ...rest] = testNames[test.testEnum].split(" ");

  return (
    <Box sx={{ pt: 4, height: "100%", boxSizing: "border-box" }}>
      <IconButton
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
        }}
        onClick={() => setDebugInfoDisplay((prevState) => !prevState)}
      >
        <BugReportIcon fontSize="large" />
      </IconButton>
      <TableForTestingPurposes
        currentIndex={currentIndex.valueOf()}
        trainingTries={test.training.tries}
        tries={test.tries}
        retryCount={retryCount}
        visible={debugInfoDisplay}
      />
      {testPhase !== "learning" &&
        testPhase !== "description" &&
        testPhase !== "instruction" &&
        testPhase !== "capdDescription" && (
          <CurrentStateInfo
            firstLine={test.testEnum === "AS" ? `${texts.examination}:` : first}
            secondLine={
              test.testEnum === "AS" ? testNames[test.testEnum] : rest.join(" ")
            }
          />
        )}
      {testState !== "finished" && (
        <FinishButton
          variant="contained"
          onClick={() => setDisplayFinishTestConfirmation(true)}
        >
          {texts.finishTest}
        </FinishButton>
      )}

      {test.testEnum === "AS" &&
        (testPhase === "training" || testPhase === "testing") && (
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Typography
              variant="body1"
              sx={{
                display: "inline",
                fontSize: 17,
                mr: 1,
                color: (theme) => theme.palette.text.secondary,
              }}
            >
              {`${texts.testedEar}: `.toUpperCase()}
            </Typography>
            <Typography
              variant="body1"
              sx={{
                display: "inline",
                fontSize: 17,
                color:
                  (testPhase === "testing" ? test : test.training).tries[
                    currentIndex.valueOf()
                  ]?.listOfPlayedSounds[0]?.channel === "RIGHT"
                    ? "#E3400D"
                    : "#0083CE",
              }}
            >
              {` ${((testPhase === "testing" ? test : test.training).tries[
                currentIndex.valueOf()
              ]?.listOfPlayedSounds[0]?.channel === "RIGHT"
                ? texts.right
                : texts.left
              ).toUpperCase()}`}
            </Typography>
          </Box>
        )}
      <Box sx={{ height: test.testEnum === "TPR" ? "75%" : "81%" }}>
        <Box sx={{ height: "100%" }}>
          <DisplayInstructionAndSoundsPresentation
            testState={testState}
            displayAnimation={displayAnimation}
            testPhase={testPhase}
            testEnum={test.testEnum}
            answersToInstructionsSounds={
              ["TSW", "TSD"].includes(test.testEnum)
                ? test.instructionsSounds[2].metadata.rightAnswers
                : [
                    test.instructionsSounds[2]?.metadata?.rightAnswers[0],
                    test.instructionsSounds[4]?.metadata?.rightAnswers[0],
                  ]
            }
            playingSound={playingSound}
            startTraining={startTraining}
            changePhase={setTestPhase}
            instructionsToPlay={test.instructionsSounds}
            setDisplayAnimation={setDisplayAnimation}
            onError={handleError}
          />

          <DisplayAnimatedInstruction
            displayAnimation={
              displayAnimation &&
              testPhase !== "testing" &&
              testPhase !== "training"
            }
            testState={testState}
            testEnum={test.testEnum}
            intensity={test.intensity}
            wavsForAnimation={wavsForAnimation}
            onError={handleError}
            startTraining={test.testEnum !== "AS" ? startTraining : undefined}
            startTest={test.testEnum !== "AS" ? startTest : startTraining}
          />

          <ComponentsToDisplayBasedOnTestState
            testState={testState}
            startTest={startTest}
            testEnum={test.testEnum}
            givenAnswers={givenAnswers}
            saveAnswer={saveAnswer}
            changeGivenAnswers={setGivenAnswers}
            possibleAnswers={
              isTRCorTRS(test.testEnum)
                ? possibleAnswers
                : (testPhase === "testing" ? test : test.training).tries[
                    currentIndex.valueOf()
                  ]?.listOfPlayedSounds[0].metadata.possibleAnswers
            }
            testPhase={testPhase}
            currentTriesLength={
              testPhase === "training"
                ? test.training.tries.length
                : test.tries.length
            }
            currentIndex={currentIndex.valueOf()}
            testName={test.name}
            onError={handleError}
          />
        </Box>
        {testState !== "finished" &&
          (testPhase === "training" || testPhase === "testing") && (
            <ProgressSlider
              title={
                testPhase === "testing"
                  ? test.testEnum === "AS"
                    ? `${texts.phaseProgress} 2`
                    : texts.testProgress
                  : test.testEnum === "AS"
                  ? `${texts.phaseProgress} 1`
                  : texts.trainingProgress
              }
              currentValue={
                testPhase === "learning" ? 1 : currentIndex.valueOf() + 1
              }
              max={
                testPhase === "testing"
                  ? test.tries.length
                  : test.training.tries.length
              }
              snail={test.age < AGE_FROM_WHICH_ADULT_VERSIONS_START}
            />
          )}
      </Box>

      {testPhase === "training" &&
        test.testEnum !== "AS" &&
        testState !== "finished" &&
        testState !== "before-start" && (
          <Button
            variant="contained"
            onClick={handleSkipTrainingClick}
            sx={{ position: "fixed", bottom: 100, left: 40, px: 6 }}
          >
            {texts.startTest}
          </Button>
        )}
      {displayFinishTestConfirmation && (
        <ConfirmationDialog
          question={texts.endTestConfirm}
          yesAction={() => {
            earlyFinish(FailureReason.CANCELLED);
            setDisplayFinishTestConfirmation(false);
          }}
          noAction={() => {
            setDisplayFinishTestConfirmation(false);
          }}
          maxWidth="lg"
        />
      )}
      <CheckingNoises open={loaderDisplay} />
      {displayRepeatInfo && <RepeatSoundInfoModal />}
    </Box>
  );
};

export default TestManager;
