import { Box, Typography } from "@mui/material";
import { texts } from "../resources/texts";

const AudiometryInfo = ({ ear, freq }) => {
  return (
    <Box sx={{ position: "absolute", bottom: 120, left: 48 }}>
      <Typography
        variant="body1"
        sx={{
          display: "inline",
          fontSize: 17,
          color: (theme) => theme.palette.text.secondary,
        }}
      >
        {`${texts.testedEar}: `.toUpperCase()}
      </Typography>
      <Typography
        variant="body1"
        sx={{
          display: "inline",
          fontSize: 17,
        }}
      >
        {`${ear.toUpperCase()}`}
      </Typography>
      {/* <Typography
        variant="body1"
        sx={{
          display: "inline",
          fontWeight: "bold",
          fontSize: 16,
          color: "#707070",
          ml: 3,
        }}
      >
        {`${texts.currentFrequency}: `.toUpperCase()}
      </Typography>
      <Typography
        variant="body1"
        sx={{
          display: "inline",
          fontWeight: "bold",
          fontSize: 16,
          color: "#0898AE",
        }}
      >{`${freq}Hz`}</Typography> */}
    </Box>
  );
};

export default AudiometryInfo;
