import sheep from "../../resources/images/sheep-head.svg";
import lama from "../../resources/images/lama-description.svg";
import animalsDesc from "../../resources/images/animals-description.svg";
import "./index.css";

const SheepHeader = () => {
  return (
    <img
      className="sheep"
      src={sheep}
      style={{ position: "absolute", zIndex: 0, left: "680px", top: 17 }}
    />
  );
};

export const LamaKidsDescription = () => {
  return <img className="lama-desc" src={lama} alt="" />;
};

export const AnimalsKidsDescription = () => {
  return <img className="animals-desc" src={animalsDesc} alt="" />;
};

export default SheepHeader;
