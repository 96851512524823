import { useEffect, useState, useRef, useMemo } from "react";
import { Box, Button, Typography, Stack, Grid } from "@mui/material";
import { texts } from "../../resources/texts";
import { useApi } from "../../Api";
import { useNotificationSubject } from "../../Notifications";
import bg from "../../resources/images/bg-with-circle.svg";
import { ReactComponent as Square } from "../../resources/images/square.svg";
import cursorHand from "../../resources/images/cursor.svg";
import { CircleAnswer, CircleAnswerTRC } from "../../answers/CircleAnswer";
import ListenCarefullyAnimation from "./ListenCarefullyAnimation";
import { wavFiles as wavs } from "../../resources/wavFiles";

const AnimatedInstruction = ({
  wavFiles,
  isNextButton,
  intensity,
  onError,
  startTraining,
  startTest,
  testEnum,
}) => {
  const api = useApi();
  const notificationSubject = useNotificationSubject();
  const [click, setClick] = useState(false);
  const [givenAnswers, setGivenAnswers] = useState([]);
  const rightAnswersRefs = useRef([]);
  const [currentRightAnswerIndex, setCurrentRightAnswerIndex] = useState(-1);
  const [started, setStarted] = useState(false);
  const [soundState, setSoundState] = useState(); //possible: null, "playing","afterSound"
  const [exampleFinished, setExampleFinished] = useState(false);
  const [finished, setFinished] = useState(false);
  const [currentCursorPosition, setCurrentCursorPosition] = useState({
    x: 0,
    y: 0,
  });
  const [canClickNext, setCanClickNext] = useState(false);
  const [clicked, setClicked] = useState(false);
  const buttonRef = useRef();

  var allRightAnswers = [];
  wavFiles.forEach(({ metadata }) => {
    allRightAnswers.push(...metadata.rightAnswers);
  });

  function isAnswerFull() {
    return allRightAnswers.every((element) => {
      return givenAnswers.some((ans) => ans.text === element.text);
    });
  }

  const mounted = useRef(false);
  const abortController = useMemo(() => new AbortController(), []);

  useEffect(() => {
    mounted.current = true;
    const play = async () => {
      if (mounted.current) {
        try {
          await api.playInstruction([wavs.example]);
        } catch (e) {
          console.error(e);
          onError(e.message);
        }
      }
      mounted.current && setSoundState("playing");
      if (mounted.current) {
        try {
          await api.playWav(wavFiles, intensity);
        } catch (e) {
          console.error(e);
          onError(e.message);
        }
      }
      mounted.current && setSoundState("afterSound");
    };
    const timer = setTimeout(() => {
      play();
    }, 2000);
    document.body.style.backgroundImage = `url(${bg})`;

    return () => {
      clearTimeout(timer);
      mounted.current = false;
      abortController.abort();
    };
  }, []);

  useEffect(() => {
    const timer =
      soundState === "afterSound" &&
      setTimeout(() => {
        setStarted(true);
      }, 500);
    return () => clearTimeout(timer);
  }, [soundState]);

  useEffect(() => {
    currentRightAnswerIndex !== -1 &&
      rightAnswersRefs.current[currentRightAnswerIndex] &&
      setCurrentCursorPosition({
        x: !started
          ? 0
          : rightAnswersRefs.current[
              currentRightAnswerIndex
            ].getBoundingClientRect().x +
            rightAnswersRefs.current[currentRightAnswerIndex].clientWidth / 2,
        y:
          rightAnswersRefs.current[
            currentRightAnswerIndex
          ].getBoundingClientRect().y +
          rightAnswersRefs.current[currentRightAnswerIndex].clientHeight / 2,
      });
  }, [currentRightAnswerIndex, started]);

  useEffect(() => {
    const timer =
      started &&
      setTimeout(() => {
        setClick(true);
      }, 1000);
    return () => clearTimeout(timer);
  }, [currentCursorPosition, started]);

  useEffect(() => {
    if (started && mounted.current) {
      click &&
        setTimeout(() => {
          setGivenAnswers((prevState) => {
            return [...prevState, allRightAnswers[currentRightAnswerIndex]];
          });
          setClick(false);
        }, 250);
      !click &&
        setCurrentRightAnswerIndex((prevState) =>
          prevState < allRightAnswers.length - 1 ? prevState + 1 : prevState
        );
    }
  }, [click, started]);

  useEffect(() => {
    if (isAnswerFull()) {
      if (isNextButton) {
        if (givenAnswers.length > allRightAnswers.length) {
          setExampleFinished(true);
        } else {
          if (buttonRef.current) {
            setCurrentCursorPosition({
              x: buttonRef.current.getBoundingClientRect().x + 100,
              y: buttonRef.current.getBoundingClientRect().y + 20,
            });
          } else {
            setCurrentCursorPosition({
              x: 0,
              y: 0,
            });
          }
        }
      } else {
        setExampleFinished(true);
      }
    }
  }, [givenAnswers]);

  useEffect(() => {
    const notificationObserver = (notification) => {
      switch (notification.type) {
        case "ExceptionWithCodeAndTimeStamp":
          if (notification.exceptionClassName === "Playing") {
            exampleFinished && setCanClickNext(true);
          }
          break;
        default:
          break;
      }
    };

    notificationSubject.attach(notificationObserver);
    if (testEnum !== "AS") {
      exampleFinished &&
        api
          .playInstruction([wavs.trainingInfo], abortController.signal)
          .then(() => {
            if (mounted.current) {
              setCanClickNext(true);
              setFinished(true);
            }
          })
          .catch((e) => {
            !abortController.signal.aborted && onError(e.message);
          });
    } else {
      if (exampleFinished) {
        setCanClickNext(true);
        setFinished(true);
      }
    }

    return () => {
      notificationSubject.detach(notificationObserver);
    };
  }, [exampleFinished]);

  const handleClick = (onNext) => {
    setClicked(true);
    if (!finished) {
      api
        .stopPlaying(abortController.signal)
        .then(() => {
          setTimeout(() => {
            onNext && onNext();
          }, [1000]); //musimy odczekać po wykonaniu api.stopPlaying() inaczej kod audiometrowy rzuca błędy przy następnej instrukcji
        })
        .catch((e) => {
          !abortController.signal.aborted && onError && onError(e.message);
        });
    } else {
      onNext && onNext();
    }
  };

  return (
    <Box sx={{ mt: 11 }}>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent={"center"}
        alignItems="center"
      >
        <Typography
          variant="title"
          sx={{
            color: (theme) => theme.palette.text.secondary,
            fontSize: "40px",
          }}
        >
          {texts.example}
        </Typography>
        <Stack
          direction="row"
          spacing={8}
          alignItems="center"
          justifyContent="center"
          sx={{ mb: 2 }}
        >
          <Square />
          <Typography variant="title" sx={{ fontSize: "40px" }}>
            {texts.howTo}
          </Typography>
          <Square />
        </Stack>
        {soundState === "playing" && <ListenCarefullyAnimation />}
      </Box>
      {rightAnswersRefs.current.length > 0 &&
        soundState === "afterSound" &&
        currentRightAnswerIndex !== -1 && (
          <Box
            component="span"
            sx={{
              top: `${currentCursorPosition.y}px`,
              left: `${currentCursorPosition.x}px`,
              position: "absolute",
              transition: (theme) =>
                theme.transitions.create("all", {
                  duration: "1s",
                  easing: "ease-out",
                }),
              zIndex: 1,
            }}
          >
            <img src={cursorHand} className={click ? "click-cursor" : ""} />
          </Box>
        )}
      {wavFiles[0].metadata.possibleAnswers.length > 5 ? (
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          spacing={2}
          sx={{
            visibility: soundState === "afterSound" ? "visible" : "hidden",
            opacity: exampleFinished ? 0.3 : 1,
            transform: "scale(0.8)",
          }}
        >
          <Grid
            container
            item
            justifyContent="center"
            alignItems="center"
            spacing={wavFiles[0].metadata.possibleAnswers.length > 12 ? 0 : 3}
          >
            {wavFiles[0].metadata.possibleAnswers
              .slice(
                0,
                Math.ceil(wavFiles[0].metadata.possibleAnswers.length / 2)
              )
              .map((answer, index) => {
                return (
                  <Grid
                    item
                    key={index}
                    ref={(el) => {
                      const foundIndex = allRightAnswers.findIndex(
                        ({ text }) => text === answer.text
                      );

                      if (foundIndex !== -1) {
                        if (el && !rightAnswersRefs.current[foundIndex]) {
                          rightAnswersRefs.current[foundIndex] = el;
                        }
                      }
                    }}
                  >
                    {testEnum !== "TRC" ? (
                      <CircleAnswer
                        img={answer.image}
                        text={answer.text}
                        selected={givenAnswers.some(
                          (ans) => ans.text === answer.text
                        )}
                        testEnum={testEnum}
                      />
                    ) : (
                      <CircleAnswerTRC
                        text={answer.text}
                        selected={givenAnswers.some(
                          (ans) => ans.text === answer.text
                        )}
                      />
                    )}
                  </Grid>
                );
              })}
          </Grid>
          <Grid
            container
            item
            justifyContent="center"
            alignItems="center"
            spacing={wavFiles[0].metadata.possibleAnswers.length > 12 ? 0 : 3}
          >
            {wavFiles[0].metadata.possibleAnswers
              .slice(Math.ceil(wavFiles[0].metadata.possibleAnswers.length / 2))
              .map((answer, index) => {
                return (
                  <Grid
                    item
                    key={index}
                    ref={(el) => {
                      const foundIndex = allRightAnswers.findIndex(
                        ({ text }) => text === answer.text
                      );

                      if (foundIndex !== -1) {
                        if (el && !rightAnswersRefs.current[foundIndex]) {
                          rightAnswersRefs.current[foundIndex] = el;
                        }
                      }
                    }}
                  >
                    {testEnum !== "TRC" ? (
                      <CircleAnswer
                        img={answer.image}
                        text={answer.text}
                        selected={givenAnswers.some(
                          (ans) => ans.text === answer.text
                        )}
                        testEnum={testEnum}
                      />
                    ) : (
                      <CircleAnswerTRC
                        text={answer.text}
                        selected={givenAnswers.some(
                          (ans) => ans.text === answer.text
                        )}
                      />
                    )}
                  </Grid>
                );
              })}
          </Grid>
        </Grid>
      ) : (
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={wavFiles[0].metadata.possibleAnswers.length === 2 ? 8 : 3}
          sx={{
            visibility: soundState === "afterSound" ? "visible" : "hidden",
            opacity: exampleFinished ? 0.3 : 1,
            mt: testEnum === "TPR" ? 0 : 12,
          }}
        >
          {wavFiles[0].metadata.possibleAnswers.map((answer, index) => {
            return testEnum !== "TRC" ? (
              <Box
                key={index}
                ref={(el) => {
                  const foundIndex = allRightAnswers.findIndex(
                    ({ text }) => text === answer.text
                  );

                  if (foundIndex !== -1) {
                    if (el && !rightAnswersRefs.current[foundIndex]) {
                      rightAnswersRefs.current[foundIndex] = el;
                    }
                  }
                }}
              >
                <CircleAnswer
                  img={answer.image}
                  text={answer.text}
                  selected={givenAnswers.some(
                    (ans) => ans.text === answer.text
                  )}
                  testEnum={testEnum}
                />
              </Box>
            ) : (
              <Box
                key={index}
                ref={(el) => {
                  const foundIndex = allRightAnswers.findIndex(
                    ({ text }) => text === answer.text
                  );

                  if (foundIndex !== -1) {
                    if (el && !rightAnswersRefs.current[foundIndex]) {
                      rightAnswersRefs.current[foundIndex] = el;
                    }
                  }
                }}
              >
                <CircleAnswerTRC
                  text={answer.text}
                  selected={givenAnswers.some(
                    (ans) => ans.text === answer.text
                  )}
                />
              </Box>
            );
          })}
        </Stack>
      )}
      {isNextButton && soundState === "afterSound" && (
        <Button
          variant="contained"
          ref={(el) => (buttonRef.current = el)}
          sx={{
            position: "fixed",
            bottom: 110,
            right: 40,
            opacity: exampleFinished ? 0.3 : 1,
          }}
        >
          {texts.nextSounds}
        </Button>
      )}
      {startTraining && (
        <Button
          variant="contained"
          onClick={() => handleClick(startTraining)}
          disabled={clicked || !canClickNext}
          sx={{
            position: "fixed",
            bottom: 50,
            left: 40,
            fontSize: "14px",
            fontWeight: 700,
            letterSpacing: "1px",
            color: (theme) => theme.palette.primary.main,
            height: "45px",
            backgroundColor: "#F1F1F1",
            "&:active": {
              backgroundColor: (theme) => theme.palette.secondary.main,
            },
            "&:hover": {
              backgroundColor: (theme) => theme.palette.secondary.main,
            },
          }}
        >
          {texts.startTraining}
        </Button>
      )}
      {
        <Button
          variant="contained"
          onClick={() => handleClick(startTest)}
          disabled={clicked || !canClickNext}
          sx={{ position: "fixed", bottom: 50, right: 40, px: 4 }}
        >
          {texts.startTest}
        </Button>
      }
    </Box>
  );
};

export default AnimatedInstruction;
