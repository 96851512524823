import { wavFiles } from "../wavFiles";
/* Konfiguracja audiometrii słownej
  numberOfTries - liczba prób, musi być >=2 i parzyste, np. 30 (łącznie na lewe i prawe ucho) - czyli w tym przykładzie po 15
  intensity - intensywność dźwięków podczas badania, np. 25
  trainingIntensity - intensywność dźwięków podczas próby startowej, np. 35
  kk - kryterium kwalifikacji zalezne od wieku, 
    przykładowo: [{ ageStart: 8, ageStop: 12, kk: 0.7 },{ ageStart: 13, ageStop: 96, kk: 0.8 },]
    ustali, że pacjent w wieku 8 do 12 lat włącznie musi mieć conajmniej 70% poprawnych odpowiedzi, aby uzyskać wynik prawidłowy,
    a pacjent w wieku 13 do 96 lat włącznie musi mieć conajmniej 80% poprawnych odpowiedzi, aby uzyskać wynik prawidłowy
  wkk - wczesne kryterium kwalifikacji zalezne od wieku 
    przykładowo[{ ageStart: 1, ageStop: 100, kk: 1 }]
    kk=1 wyłącza wczesne kryterium kwalifikacji
  trainingNumberOfTries - liczba prób w próbie startowej, musi być >=2 i parzyste, np. 4 (łącznie na lewe i prawe ucho) - czyli w tym przykładzie po 2 na każde ucho
*/

export const asConfig = {
  numberOfTries: 30, //musi być >=2 i parzyste
  intensity: 25,
  trainingIntensity: 35,
  kk: [
    { ageStart: 0, ageStop: 6, kk: 0.86 },
    { ageStart: 7, ageStop: 100, kk: 0.93 },
  ],
  wkk: [{ ageStart: 1, ageStop: 100, kk: 1 }],
  trainingNumberOfTries: 4, //musi być >=2 i parzyste
  numberOfPossibleAnswers: 4,
  maxNumberOfBackgroundNoises: 4, //jeśli wystąpi większa liczba hałasów (więcej prób musiałoby być powtórzonych) wtedy wynik badania będzie równy "FANC"
  wavsForAnimation: [wavFiles.lissa],
};
