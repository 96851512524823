import { useState } from "react";
import { Container, Box, Typography, Stack, Button } from "@mui/material";
import Loader from "../components/animations/Loader";
import { texts } from "../resources/texts";
import { ReactComponent as Square } from "../resources/images/square.svg";
import Options from "../components/Options";
import CurrentStateInfo from "../components/CurrentStateInfo";

//wybór przesiewu
const ScreeningsPage = ({
  screenings,
  loading,
  onScreeningSelection,
  user,
  onUndo,
}) => {
  const [screeningId, setScreeningId] = useState("");
  const screeningsOptions = screenings.map((s) => {
    return {
      id: s.patientScreeningId,
      name: s.screeningDetails.label.toUpperCase(),
    };
  });

  const [first, ...rest] = texts.chooseScreening.split(" ");
  const username = user && `${user.firstName || ""} ${user.surname || ""}`;
  return (
    <Box sx={{ pt: 4 }}>
      {username && (
        <CurrentStateInfo
          firstLine={`${texts.testedPerson}:`}
          secondLine={username.toUpperCase()}
        />
      )}
      <Container
        component="main"
        sx={{
          position: "fixed",
          left: "50%",
          top: "335px",
          transform: "translateX(-50%)",
        }}
      >
        {!loading ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography
              variant="title"
              sx={{ color: (theme) => theme.palette.text.secondary }}
            >
              {first}
            </Typography>
            <Stack
              direction="row"
              spacing={3}
              alignItems="center"
              sx={{ mb: 8 }}
            >
              <Square />
              <Typography variant="title">{rest.join(" ")}</Typography>
              <Square />
            </Stack>
            <Options
              options={screeningsOptions}
              selectedOption={screeningId}
              selectOption={setScreeningId}
            />
          </Box>
        ) : (
          <Loader text={texts.loading} />
        )}
      </Container>
      <Button
        variant="contained"
        size="large"
        disabled={!screeningId}
        sx={{ position: "fixed", right: "40px", bottom: "40px", px: 10 }}
        onClick={() => {
          onScreeningSelection && onScreeningSelection(screeningId);
        }}
      >
        {texts.continue}
      </Button>
      {onUndo && (
        <Button
          variant="contained"
          size="large"
          sx={{ position: "fixed", left: "40px", bottom: "40px", px: 10 }}
          onClick={onUndo}
        >
          {texts.undo}
        </Button>
      )}
    </Box>
  );
};

export default ScreeningsPage;
