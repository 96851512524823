import { useEffect, useState } from "react";
import { Button, Box } from "@mui/material";
import DraggableAnswers from "../answers/DraggableAnswers";
import ConfirmationDialog from "../components/ConfirmationDialog";
import { texts } from "../resources/texts";

const TswAndTsd = ({
  givenAnswers,
  saveAnswer,
  setGivenAnswers,
  possibleAnswers,
  testPhase,
  currentTriesLength,
  currentIndex,
  isTSW,
}) => {
  const [givenAnswersState, setGivenAnswersState] = useState("empty"); //possible: empty, partly, full
  const [displayFillAnswersInfo, setDisplayFillAnswersInfo] = useState(false);

  useEffect(() => {
    const filled = givenAnswers.filter(({ text }) => text !== "");

    if (filled.length === givenAnswers.length) {
      setGivenAnswersState("full");
    } else if (filled.length === 0) {
      setGivenAnswersState("empty");
    } else setGivenAnswersState("partly");
  }, [givenAnswers]);

  return (
    <Box pt={isTSW ? 16 : 18} sx={{ height: "100%" }}>
      <DraggableAnswers
        possibleAnswers={possibleAnswers}
        givenAnswers={givenAnswers}
        setGivenAnswers={setGivenAnswers}
        isTSW={isTSW}
      />

      <Button
        variant="contained"
        onClick={() => {
          givenAnswersState !== "full"
            ? setDisplayFillAnswersInfo(true)
            : saveAnswer();
        }}
        sx={{
          position: "fixed",
          bottom: 100,
          right: 40,
          px: 6,
          height: "53px",
          fontSize: "17px",
        }}
      >
        {testPhase === "testing" && currentIndex + 1 === currentTriesLength
          ? texts.testFinished
          : testPhase === "training" && currentIndex + 1 === currentTriesLength
          ? texts.continue
          : texts.nextSounds}
      </Button>

      {displayFillAnswersInfo && (
        <ConfirmationDialog
          question={texts.notCompleteAnswerInfo}
          yesAction={() => {
            setDisplayFillAnswersInfo(false);
          }}
        />
      )}
    </Box>
  );
};

export default TswAndTsd;
