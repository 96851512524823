import { Stack, Typography, Button, Grid, Dialog } from "@mui/material";
import { ReactComponent as InfoIcon } from "../resources/images/info-icon.svg";
import { texts } from "../resources/texts";

const ConfirmationDialog = ({ question, yesAction, noAction, ...others }) => {
  return (
    <Dialog open {...others}>
      <Grid container p={2} direction="column" alignItems="center" spacing={2}>
        <Grid item>
          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={2}
          >
            <InfoIcon
              sx={{
                float: "left",
              }}
            />
            <Typography
              variant="h4"
              sx={{
                maxWidth: "85%",
                display: "inline-block",
                fontWeight: (theme) => theme.typography.fontWeightMedium,
              }}
            >
              {question}
            </Typography>
          </Stack>
        </Grid>
        <Grid item>
          <Stack spacing={3} direction="row">
            {yesAction && (
              <Button
                variant="contained"
                color="secondary"
                onClick={() => yesAction()}
                sx={{ height: 40, px: 4 }}
              >
                {noAction ? texts.yes : texts.ok}
              </Button>
            )}
            {noAction && (
              <Button
                variant="contained"
                onClick={() => noAction()}
                sx={{ height: 40, px: 4 }}
              >
                {texts.no}
              </Button>
            )}
          </Stack>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default ConfirmationDialog;
