import { SoundBox } from "../answers/DraggableAnswers";
import { Box } from "@mui/material";
import Test from "../tests/TestClass";
import { Answer, FailureReason, Result } from "../tests/enums";
import { imgSrc } from "../answers/CircleAnswer";
import {
  GapPresentation,
  NoGapPresentation,
} from "../components/animations/GapAnimations";

export function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms > 0 ? ms : 0));
}

export function shuffleArray(array) {
  for (var i = array.length - 1; i > 0; i--) {
    var j = Math.floor(Math.random() * (i + 1));
    var temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }
  return array;
}

export const SoundPresentation = ({ rightAnswer, playingSound, isTPR }) => {
  return (
    <Box sx={{ p: "6px", position: "relative" }}>
      {playingSound === rightAnswer.text && (
        <img
          src={imgSrc(
            isTPR ? "sound-presentation-tpr.svg" : "sound-presentation-bg.svg"
          )}
          style={{
            position: "absolute",
            top: "-60px",
            left: "-30px",
            zIndex: 1,
          }}
        />
      )}
      <Box sx={{ zIndex: 2, position: "relative" }}>
        {!isTPR ? (
          <SoundBox answer={rightAnswer} />
        ) : rightAnswer.text === "gap" ? (
          <GapPresentation />
        ) : (
          <NoGapPresentation />
        )}
      </Box>
    </Box>
  );
};

export function getEmptyFreq(audiometry, currentChannel) {
  var foundedFreq;
  if (currentChannel === "HEAD_PHONES_RIGHT") {
    foundedFreq = audiometry.frequenciesResultRight.find(
      ({ answer }) => answer === ""
    )?.frequency;
  } else {
    foundedFreq = audiometry.frequenciesResultLeft.find(
      ({ answer }) => answer === ""
    )?.frequency;
  }
  return foundedFreq ? foundedFreq : "end";
}

export function calculateGlobalResults(
  frequenciesResultRight,
  frequenciesResultLeft
) {
  const falseFoundedRight = frequenciesResultRight.some(
    ({ answer }) => answer === Answer.NONE || answer === ""
  );
  const falseFoundedLeft = frequenciesResultLeft.some(
    ({ answer }) => answer === Answer.NONE || answer === ""
  );

  const fpFoundedRight = frequenciesResultRight.some(
    ({ answer }) => answer === Answer.FP
  );

  const fpFoundedLeft = frequenciesResultLeft.some(
    ({ answer }) => answer === Answer.FP
  );

  const fANCFoundedRight = frequenciesResultRight.some(
    ({ answer }) => answer === Answer.FANC
  );

  const fANCFoundedLeft = frequenciesResultLeft.some(
    ({ answer }) => answer === Answer.FANC
  );

  const allNullAnswersLeft = frequenciesResultLeft.every(
    ({ answer }) => answer === ""
  );
  const allNullAnswersRight = frequenciesResultRight.every(
    ({ answer }) => answer === ""
  );

  var resultRight;
  if (allNullAnswersRight) {
    resultRight = null;
  } else if (fANCFoundedRight) {
    resultRight = Result.FANC;
  } else if (fpFoundedRight) {
    resultRight = Result.FP;
  } else if (falseFoundedRight) {
    resultRight = Result.NOK;
  } else resultRight = Result.OK;

  var resultLeft;
  if (allNullAnswersLeft) {
    resultLeft = null;
  } else if (fANCFoundedLeft) {
    resultLeft = Result.FANC;
  } else if (fpFoundedLeft) {
    resultLeft = Result.FP;
  } else if (falseFoundedLeft) {
    resultLeft = Result.NOK;
  } else resultLeft = Result.OK;

  const result = Test.determineOverallResult(resultRight, resultLeft);
  return [resultRight, resultLeft, result];
}

export function wasTestInterrupted(failureReason) {
  if (
    failureReason === FailureReason.INACTIVITY_LOGOUT ||
    failureReason === FailureReason.CANCELLED ||
    failureReason === FailureReason.ERROR
  ) {
    return true;
  } else {
    return false;
  }
}

export function isFreqAnswerCorrect(freq, frequenciesResults) {
  const foundedResult = frequenciesResults.find(
    (result) => result.frequency === freq
  );
  if (foundedResult) {
    return !isNaN(foundedResult.answer) ? true : false;
  } else {
    return false;
  }
}

export function determineFailureReason(
  automaticLogout,
  cancelled,
  error,
  result
) {
  var failureReason = null;
  if (automaticLogout === true) {
    failureReason = FailureReason.INACTIVITY_LOGOUT;
  } else if (cancelled) {
    failureReason = FailureReason.CANCELLED;
  } else if (error !== undefined) {
    failureReason = FailureReason.ERROR;
  } else if (result === Result.FANC) {
    failureReason = FailureReason.FANC;
  } else if (result === Result.FP) {
    failureReason = FailureReason.FP;
  } else if (result === Result.NOK) {
    failureReason = FailureReason.NORMAL;
  }
  return failureReason;
}

export function determineEarlyFinishedEnum(automaticLogout, cancelled, error) {
  var earlyFinishedEnum = null;
  if (automaticLogout === true) {
    earlyFinishedEnum = FailureReason.INACTIVITY_LOGOUT;
  } else if (cancelled) {
    earlyFinishedEnum = FailureReason.CANCELLED;
  } else if (error !== undefined) {
    earlyFinishedEnum = FailureReason.ERROR;
  }
  return earlyFinishedEnum;
}

export function isErrorFromAudiometr(error) {
  var errorCode;
  if (error) {
    try {
      errorCode = JSON.parse(error).code;
      if (isNaN(errorCode)) {
        errorCode = undefined;
      }
    } catch (e) {}
  }
  return !!(
    errorCode &&
    ((errorCode >= 5 && errorCode <= 16000) || errorCode === 99999)
  );
}
