import { Typography, Box, Divider } from "@mui/material";
import { useEffect, useState } from "react";

const DateTimeDisplay = () => {
  const [current, setCurrent] = useState(new Date());

  useEffect(() => {
    let secTimer = setInterval(() => {
      setCurrent(new Date());
    }, 60000);

    return () => clearInterval(secTimer);
  }, []);

  const date = `${current.getDate()}.${("0" + (current.getMonth() + 1)).slice(
    -2
  )}.${current.getFullYear()}`;
  const time = current.toLocaleTimeString("default", {
    hour: "2-digit",
    minute: "2-digit",
  });

  return (
    <Box
      sx={{
        fontFamily: "Montserrat",
        fontSize: 17,
        mt: 5,
        width: "300px",
      }}
    >
      <Typography align="left" variant="h2" sx={{ display: "inline", mr: 1 }}>
        {time}
      </Typography>
      <Divider
        orientation="vertical"
        sx={{
          my: 8,
          borderColor: "#86D7E3",
          display: "inline",
          borderWidth: "1px",
        }}
      />
      <Typography align="left" variant="h2" sx={{ display: "inline", ml: 1 }}>
        {date}
      </Typography>
    </Box>
  );
};

export default DateTimeDisplay;
