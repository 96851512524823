import { useEffect, useState } from "react";
import { Box, Button } from "@mui/material";
import ConfirmationDialog from "../components/ConfirmationDialog";
import { texts } from "../resources/texts";
import CircleAnswers from "../answers/CircleAnswer";

const numberOfRightAnswers = 4;

const TrcAndTrs = ({
  givenAnswers,
  saveAnswer,
  setGivenAnswers,
  possibleAnswers,
  testPhase,
  currentTriesLength,
  currentIndex,
  isTRC,
}) => {
  const [areGivenAnswersFull, setGivenAnswersFull] = useState(false);
  const [
    displaySaveNotFullAnswerConfirmation,
    setDisplaySaveNotFullAnswerConfirmation,
  ] = useState(false);

  useEffect(() => {
    setGivenAnswersFull(
      givenAnswers.length === numberOfRightAnswers ? true : false
    );
  }, [givenAnswers]);

  return (
    <Box pt={6} sx={{ height: "100%" }}>
      <CircleAnswers
        givenAnswers={givenAnswers}
        onClick={(answer) =>
          setGivenAnswers((prevState) => {
            if (prevState.includes(answer)) {
              return prevState.filter((ans) => ans !== answer);
            } else {
              return prevState.length < numberOfRightAnswers
                ? [...prevState, answer]
                : [...prevState];
            }
          })
        }
        possibleAnswers={possibleAnswers}
        isTRC={isTRC}
      />
      <Button
        variant="contained"
        onClick={() => {
          areGivenAnswersFull
            ? saveAnswer()
            : setDisplaySaveNotFullAnswerConfirmation(true);
        }}
        sx={{ position: "fixed", bottom: 100, right: 40, px: 6 }}
      >
        {testPhase === "testing" && currentIndex + 1 === currentTriesLength
          ? texts.testFinished
          : testPhase === "training" && currentIndex + 1 === currentTriesLength
          ? texts.continue
          : texts.nextSounds}
      </Button>

      {displaySaveNotFullAnswerConfirmation && (
        <ConfirmationDialog
          question={texts.notCompleteAnswerConfirm}
          yesAction={() => {
            saveAnswer();
            setDisplaySaveNotFullAnswerConfirmation(false);
          }}
          noAction={() => {
            setDisplaySaveNotFullAnswerConfirmation(false);
          }}
        />
      )}
    </Box>
  );
};

export default TrcAndTrs;
