import { useState } from "react";
import { Box, Typography, Container, Button, Stack } from "@mui/material";
import Loader from "../components/animations/Loader";
import { texts } from "../resources/texts";
import Options from "../components/Options";
import CurrentStateInfo from "../components/CurrentStateInfo";
import { ReactComponent as Square } from "../resources/images/square.svg";
import { MAX_TEST_DURATION, MIN_TEST_DURATION } from "../resources/wavFiles";

//wybór pakietu
const PackagesPage = ({
  packages,
  onPackageSelection,
  loading,
  screening,
  onUndo,
}) => {
  const [packageId, setPackageId] = useState("");
  const packagesOptions = packages.map((p, index) => {
    return {
      id: p.hearBoxTestId,
      name: `${texts.package} ${index + 1} (${p.label}) ${
        p.examinations.length * MIN_TEST_DURATION
      }-${p.examinations.length * MAX_TEST_DURATION}min.`,
    };
  });

  const [first, second, ...rest] = texts.chooseTest.split(" ");

  return (
    <Box sx={{ pt: 4 }}>
      <CurrentStateInfo
        firstLine={`${texts.chosenScreening}:`}
        secondLine={screening?.screeningDetails?.label?.toUpperCase()}
      />
      <Container
        component="main"
        sx={{
          position: "fixed",
          left: "50%",
          top: "335px",
          transform: "translateX(-50%)",
        }}
      >
        {!loading ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography
              variant="title"
              sx={{ color: (theme) => theme.palette.text.secondary }}
            >
              {[first, second].join(" ")}
            </Typography>
            <Stack
              direction="row"
              spacing={3}
              alignItems="center"
              sx={{ mb: 8 }}
            >
              <Square />
              <Typography variant="title">{rest.join(" ")}</Typography>
              <Square />
            </Stack>
            <Options
              options={packagesOptions}
              selectedOption={packageId}
              selectOption={setPackageId}
            />
          </Box>
        ) : (
          <Loader text={texts.loading} />
        )}
      </Container>
      <Button
        variant="contained"
        size="large"
        disabled={!packageId}
        sx={{ position: "fixed", right: "40px", bottom: "40px", px: 10 }}
        onClick={() => {
          onPackageSelection && onPackageSelection(packageId);
        }}
      >
        {texts.continue}
      </Button>
      {onUndo && (
        <Button
          variant="contained"
          size="large"
          sx={{ position: "fixed", left: "40px", bottom: "40px", px: 10 }}
          onClick={onUndo}
        >
          {texts.undo}
        </Button>
      )}
    </Box>
  );
};

export default PackagesPage;
