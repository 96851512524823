import { useState, useEffect } from "react";
import { Modal, Box, Grid, Typography, Button } from "@mui/material";
import { texts, testNames } from "../resources/texts";
import { Result } from "../tests/enums";

const TestSummary = ({ currentTestData, nextTestId, onNextTest, open }) => {
  const [clicked, setClicked] = useState(false);

  useEffect(() => {
    open === false && setClicked(false);
  }, [open]);

  return (
    <Modal open={open}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          minWidth: "624px",
          mihHeight: "250px",
          borderRadius: "10px",
          p: 8,
          bgcolor: "rgba(255,255,255,1)",
        }}
      >
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          spacing={2}
          direction="column"
          sx={{ px: 8, pb: 6 }}
        >
          {currentTestData && currentTestData.data?.result === Result.FANC && (
            <Typography
              variant="h4"
              sx={{
                display: "inline",
                mb: 6,
                width: "680px",
                color: "#393939",
              }}
            >
              {texts.testEndedBecauseOfNoise.toUpperCase()}
            </Typography>
          )}
          <Typography
            variant="title"
            sx={{
              color: (theme) => theme.palette.text.secondary,
              fontSize: 35,
              mb: 2,
            }}
          >
            {texts.testFinishedInfo}
          </Typography>
          <Typography variant="title" sx={{ fontSize: 35, mb: 8 }}>
            {`${
              (currentTestData && testNames[currentTestData.testType]) || ""
            }`}
          </Typography>
          {nextTestId && (
            <Typography variant="h4" sx={{ mb: 1 }}>
              {texts.moveToNextExamination}
            </Typography>
          )}
          <Typography
            variant="h4"
            sx={{
              color: (theme) => theme.palette.text.secondary,
              mb: 8,
              mx: 1,
            }}
          >
            {`${testNames[nextTestId] || ""}`}
          </Typography>
          <Button
            variant="contained"
            disabled={clicked}
            sx={{ fontSize: 18, px: 6 }}
            size="large"
            onClick={() => {
              setClicked(true);
              onNextTest();
            }}
          >
            {nextTestId ? texts.nextExamination : texts.finish}
          </Button>
        </Grid>
      </Box>
    </Modal>
  );
};

export default TestSummary;
