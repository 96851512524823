export const testsWavFiles = {
  TSW: {
    wavFiles: [
      "lowHighLow",
      "lowLowHigh",
      "lowHighHigh",
      "highLowLow",
      "highHighLow",
      "highLowHigh",
    ],
    instructionsSounds: ["tswIS1", "tswllhInstruction", "lowLowHigh", "tswIS6"],
  },
  TPR: {
    wavFiles: ["gap", "noGap"],
    instructionsSounds: [
      "tprIS1",
      "tprIS2",
      "tprIS3",
      "tprIS4",
      "tprIS5",
      "tprIS6",
    ],
  },
  TRC: {
    wavFiles: [
      "ans31054",
      "ans61058",
      "ans2176",
      "ans2795",
      "ans2473",
      "ans2647",
      "ans10246",
      "ans10987",
      "ans81210",
      "ans9168",
      "ans5739",
      "ans3785",
      "ans53106",
      "ans10519",
      "ans1679",
    ],
    instructionsSounds: ["trcIS1"],
  },
  TSD: {
    wavFiles: [
      "shortLongShort",
      "shortShortLong",
      "shortLongLong",
      "longShortShort",
      "longLongShort",
      "longShortLong",
    ],
    instructionsSounds: [
      "tsdIS1",
      "tsdsslInstruction",
      "shortShortLong",
      "tsdIS6",
    ],
  },
  TDW: {
    wavFiles: ["lowLow", "highLow", "highHigh", "lowHigh"],
    instructionsSounds: [
      "tdwIS1",
      "tdwIS2",
      "tdwIS3",
      "tdwIS4",
      "tdwIS5",
      "tdwIS6",
    ],
  },
  TRMS: {
    wavFiles: [
      "snrbut",
      "snrdlon",
      "snrgrzyb",
      "snrkret",
      "snrkwiat",
      "snrlis",
      "snrlisc",
      "snrmur",
      "snrpas",
      "snrpies",
      "snrstol",
      "snrwas",
      "snrsmok",
    ],
    instructionsSounds: ["trmsIS"],
  },
  TMS: {
    wavFiles: [
      "compressedbut",
      "compresseddlon",
      "compresseddom",
      "compressedgrzyb",
      "compressedkosc",
      "compressedkret",
      "compressedlis",
      "compressedlisc",
      "compressedmost",
      "compressedpan",
      "compressedpiec",
      "compressedsok",
      "compressedszal",
      "compressedwas",
    ],
    instructionsSounds: ["tmsIS"],
  },
  TMF: {
    wavFiles: [
      "filteredbut",
      "filtereddym",
      "filteredkosc",
      "filteredkret",
      "filteredlas",
      "filteredlew",
      "filteredpiec",
      "filteredslon",
      "filteredstol",
      "filteredwas",
      "filteredzuk",
    ],
    instructionsSounds: ["tmfIS"],
  },

  TRS: {
    wavFiles: [
      "noswozduchmis",
      "butkoklisnos",
      "sumwazkonpaw",
      "lekpasdommak",
      "konsermakwor",
      "myszdachnoclas",
      "wassadpankot",
      "pawlewrakpiec",
      "myszdymkotbyk",
      "sokkoknozwor",
      "dachmyszlasnoc",
      "sadwaskotpan",
      "lewpawpiecrak",
      "dymmyszbykkot",
      "koksokrakmur",
      "misduchnoslis",
      "wazsumpawkon",
      "paslekryspies",
      "makdomkokbut",
      "serkonrakmysz",
      "konworwazczolg",
      "misdomwazsok",
      "szalzezlassum",
      "lekbykzabjez",
    ],
    instructionsSounds: ["trsIS1"],
  },
  AS: {
    wavFiles: [
      "lissa",
      "butsa",
      "byksa",
      //"chlebsa",
      "czolgsa",
      "dachsa",
      "dlonsa",
      "domsa",
      "duchsa",
      "dymsa",
      "dziobsa",
      "dzwigsa",
      "gessa",
      "grzybsa",
      "jezsa",
      "kluczsa",
      "koksa",
      "konsa",
      "koscsa",
      "kotsa",
      "kretsa",
      "kwiatsa",
      "lassa",
      "leksa",
      "lewsa",
      "listsa",
      "liscsa",
      "maksa",
      "missa",
      "mleczsa",
      "mostsa",
      "mursa",
      "myszsa",
      "nocsa",
      "nossa",
      "nozsa",
      "pansa",
      "passa",
      //"pawsa",
      "piecsa",
      "piessa",
      "piescsa",
      "ptaksa",
      "raksa",
      "ryssa",
      "sadsa",
      "sersa",
      "slonsa",
      "smaksa",
      "soksa",
      "stolsa",
      "sumsa",
      "szalsa",
      "torsa",
      "tortsa",
      "wassa",
      "wazsa",
      "worsa",
      "wozsa",
      "zabsa",
      "zezsa",
      "zolwsa",
      "zuksa",
    ],
    instructionsSounds: ["asDescription", "asInstruction"],
  },
  CAPD: {
    instructionsSounds: ["capdDescription"],
  },
  AP: {
    instructionsSounds: ["apDescription", "apInstruction"],
  },
  GC: {
    instructionsSounds: ["gcDescription", "gcInstruction"],
  },
  AP_KIDS: {
    instructionsSounds: ["apKidsDescription", "apKidsInstruction"],
  },
  GC_KIDS: {
    instructionsSounds: ["gcKidsDescription", "gcKidsInstruction"],
  },
};
