export const FailureReason = {
  NORMAL: "NORMAL",
  CANCELLED: "CANCELLED",
  ERROR: "ERROR",
  INACTIVITY_LOGOUT: "INACTIVITY_LOGOUT",
  FANC: "FANC",
  FP: "FP",
};

export const Result = {
  OK: "OK",
  NOK: "NOK",
  AMB: "AMB",
  FANC: "FANC",
  FP: "FP",
};

export const Answer = {
  NONE: "none",
  FP: "FP",
  FANC: "FANC",
  MAXEXCEEDED: "maxExceeded",
};

export const AudiometrConnectionStatus = {
  CONNECTED: "Connected",
  DISCONNECTED: "Disconnected",
};
