import { Box, Stack, IconButton } from "@mui/material";
import { useEffect, useState, useRef } from "react";
import { useApi } from "../Api";
import { sleep } from "../resources/exportedFunctions";
import { audiometryConfig } from "../resources/tests_config/audiometryConfig";
import InstructionModal from "../components/InstructionModal";
import { texts } from "../resources/texts";
import { Answer } from "./enums";
import { Animal } from "../components/animations/KidsAnimals";
import dontHear from "../resources/images/dont-hear.svg";
import dontHearBtn from "../resources/images/dont-hear-btn.svg";
import CurrentStateInfo from "../components/CurrentStateInfo";
import ListenCarefullyAnimation from "../components/animations/ListenCarefullyAnimation";
import { testNames } from "../resources/texts";

const AudiometryKidsSingle = ({
  freqsPair,
  channel,
  stopped,
  updateAudiometry,
  startingAttempt,
  onError,
}) => {
  const api = useApi();
  const [soundPlaying, setSoundPlaying] = useState();
  const [play, setPlay] = useState({ freqSequence: [], currentIndex: 0 });
  const [finished, setFinished] = useState(false);
  const [audiometryDetails, setAudiometryDetails] = useState([]);
  const [acceptAnswer, setAcceptAnswer] = useState(false);
  const [displayInstruction, setDisplayInstruction] = useState(false);

  //wylosowanie kolejności częstotliwości (każda częstotliwość 2 razy)
  function randomizeFreqsOrder() {
    var freqs = [];
    var sequence = [];
    for (let i = 0; i < freqsPair.length; i++) {
      var counter = 0;
      while (
        freqs.length <
        ((freqsPair.length * 2) / freqsPair.length) * (i + 1)
      ) {
        freqs.push({ freq: freqsPair[i], id: `${i}${counter}` });
        counter++;
      }
    }
    while (sequence.length < freqsPair.length * 2) {
      const random = freqs[Math.floor(Math.random() * freqs.length)];
      sequence.push(random.freq);
      freqs = freqs.filter((freq) => freq !== random);
    }

    return sequence;
  }

  const mounted = useRef(false);

  useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, []);

  useEffect(() => {
    emptyState();
  }, [freqsPair, channel]);

  useEffect(() => {
    async function playSoundAndWait() {
      mounted.current && setSoundPlaying(0);
      mounted.current && (await sleep(250));
      var sound = {
        levelFloat:
          audiometryConfig.testTone[play.freqSequence[play.currentIndex]] +
          (startingAttempt ? 20 : 0),
        channel,
        freqInt: play.freqSequence[play.currentIndex],
        timeGenInt: audiometryConfig.soundDuration,
      };
      if (
        freqsPair[0] === play.freqSequence[play.currentIndex] &&
        mounted.current
      ) {
        try {
          await api.audiometryPlay(sound);
        } catch (e) {
          console.error(e);
          onError(e.message);
        }
      } else {
        mounted.current && (await sleep(audiometryConfig.soundDuration + 1500));
      }
      mounted.current && setSoundPlaying();
      mounted.current && (await sleep(2000)); //przerwa pomiędzy dźwiękami
      mounted.current && setSoundPlaying(1);
      mounted.current && (await sleep(250));
      if (freqsPair[1] === play.freqSequence[play.currentIndex]) {
        if (mounted.current) {
          try {
            await api.audiometryPlay(sound);
          } catch (e) {
            console.error(e);
            onError(e.message);
          }
        }
      } else {
        mounted.current && (await sleep(audiometryConfig.soundDuration + 1500));
      }
      mounted.current && setSoundPlaying();
      mounted.current && setAcceptAnswer(true);
    }

    play.currentIndex < play.freqSequence.length &&
      !acceptAnswer &&
      mounted.current &&
      !displayInstruction &&
      playSoundAndWait();
  }, [play, displayInstruction]);

  useEffect(() => {
    let alreadyAnswered = false;
    async function waitForAnswer() {
      mounted.current && !alreadyAnswered && (await sleep(4000)); //czas na odpowiedź
      //jeśli nie było dotychczas odpowiedzi, to zapisujemy jako brak odpowiedzi
      mounted.current &&
        !alreadyAnswered &&
        acceptAnswer &&
        handleSetAnswer({
          frequency: play.freqSequence[play.currentIndex],
          answer: Answer.NONE,
          falseClicksCount: 0,
          allClicksCount: 0,
        });
    }
    mounted.current &&
      acceptAnswer &&
      !alreadyAnswered &&
      !stopped &&
      waitForAnswer();

    return () => {
      alreadyAnswered = true;
    };
  }, [acceptAnswer, stopped]);

  useEffect(() => {
    if (audiometryDetails.length !== 0) {
      var next = true;
      const currentFreq = play.freqSequence[play.currentIndex];
      const answersForCurrentFreq = audiometryDetails.find(
        ({ frequency }) => frequency === currentFreq
      )?.answers;

      const otherFreq = play.freqSequence.find((freq) => freq !== currentFreq);
      const answersForOtherFreq = audiometryDetails.find(
        ({ frequency }) => frequency === otherFreq
      )?.answers;
      var freqToAdd = undefined;
      if (startingAttempt) {
        const fpCountCurrentFreq = countFP(answersForCurrentFreq);
        if (
          isLastFalse(answersForCurrentFreq) &&
          fpCountCurrentFreq <=
            audiometryConfig.acceptableNumberOfFalseClicksKids
        ) {
          freqToAdd = currentFreq;
        }
        if (answersForCurrentFreq && answersForOtherFreq) {
          const fpCountOtherFreq = countFP(answersForOtherFreq);
          if (
            answersForCurrentFreq.find((ans) => !isNaN(ans)) ||
            answersForOtherFreq.find((ans) => !isNaN(ans))
          ) {
            //jeśli znaleziono prawidłową odpowiedź to kończymy próbę startową
            next = false;
            setFinished(true);
          } else if (
            answersForCurrentFreq.length === 1 &&
            answersForOtherFreq.length === 1
          ) {
            //jeśli nie znaleziono prawidłowej odpowiedzi i przeprowadzono jedną próbę dla każdej częstotliwości to pokazujemy instrukcję
            setDisplayInstruction(true);
          } else if (
            (answersForCurrentFreq.length === 2 + fpCountCurrentFreq ||
              fpCountCurrentFreq >
                audiometryConfig.acceptableNumberOfFalseClicksKids) &&
            (answersForOtherFreq.length === 2 + fpCountOtherFreq ||
              fpCountOtherFreq >
                audiometryConfig.acceptableNumberOfFalseClicksKids)
          ) {
            //jeśli nie znaleziono prawidłowej odpowiedzi i przeprowadzono już wszystkie próby
            next = false;
            setFinished(true);
          }
        }
      } else if (answersForCurrentFreq.length >= 2) {
        setDisplayInstructionIfNeeded(answersForCurrentFreq);
        if (checkIfAnswersAreUnambiguousForSingleFreq(answersForCurrentFreq)) {
          //jeśli odpowiedzi dla aktualnej częstotliwości są jednoznaczne
          if (
            answersForOtherFreq &&
            answersForOtherFreq.length >= 2 &&
            checkIfAnswersAreUnambiguousForSingleFreq(answersForOtherFreq)
          ) {
            //jeśli odpowiedzi dla obu częstotliwości są jednoznaczne to kończymy
            next = false;
            setFinished(true);
          }
        } else {
          //wynik dla aktualnej częstotliwości niejednoznaczy - więc dodajemy tę częstotliwość do sekwencji
          freqToAdd = currentFreq;
        }
      }
      next &&
        setPlay((prevState) => {
          const freqs =
            freqToAdd === undefined
              ? prevState.freqSequence
              : [...prevState.freqSequence, freqToAdd];
          return {
            freqSequence: freqs,
            currentIndex: prevState.currentIndex + 1,
          };
        });
    }
  }, [audiometryDetails]);

  function checkIfAnswersAreUnambiguousForSingleFreq(answersForSingleFreq) {
    var noneCount = 0;
    var correctCount = 0;
    var fpCount = 0;
    var fancCount = 0;
    answersForSingleFreq.forEach((ans) => {
      if (ans === Answer.NONE) {
        noneCount++;
      } else if (!isNaN(ans)) {
        correctCount++;
      } else if (ans === Answer.FP) {
        fpCount++;
      } else if (ans === Answer.FANC) {
        fancCount++;
      }
    });

    return (
      noneCount >= 2 ||
      correctCount >= 2 ||
      fpCount > audiometryConfig.acceptableNumberOfFalseClicksKids ||
      fancCount > audiometryConfig.maxNumberOfANCPerFreq
    );
  }

  function handleSetAnswer(answer) {
    setAcceptAnswer(false);

    if (
      audiometryDetails.find(({ frequency }) => frequency === answer.frequency)
    ) {
      setAudiometryDetails((prevState) =>
        prevState.map((details) => {
          if (details.frequency === answer.frequency) {
            return {
              ...details,
              answers: [...details.answers, answer.answer],
              falseClicksCount:
                details.falseClicksCount + answer.falseClicksCount,
              allClicksCount: details.allClicksCount + answer.allClicksCount,
            };
          } else {
            return { ...details };
          }
        })
      );
    } else {
      setAudiometryDetails((prevState) => [
        ...prevState,
        {
          frequency: answer.frequency,
          answers: [answer.answer],
          falseClicksCount: answer.falseClicksCount,
          allClicksCount: answer.allClicksCount,
        },
      ]);
    }
  }

  const handleClick = (index) => {
    if (acceptAnswer) {
      if (index === undefined) {
        handleSetAnswer({
          frequency: play.freqSequence[play.currentIndex],
          answer: Answer.NONE,
          falseClicksCount: 0,
          allClicksCount: 1,
        });
      } else {
        const correct =
          freqsPair[index] === play.freqSequence[play.currentIndex];
        handleSetAnswer({
          frequency: play.freqSequence[play.currentIndex],
          answer: correct
            ? `${
                audiometryConfig.testTone[play.freqSequence[play.currentIndex]]
              }`
            : Answer.FP,
          falseClicksCount: correct ? 0 : 1,
          allClicksCount: 1,
        });
      }
    }
  };

  function getMostFrequentAnswer(answersForSingleFreq) {
    var noneCount = 0;
    var correctCount = 0;
    var threshold = "";
    var fpCount = 0;
    var fancCount = 0;
    answersForSingleFreq.forEach((ans) => {
      if (ans === Answer.NONE) {
        noneCount++;
      } else if (!isNaN(ans)) {
        correctCount++;
        threshold = ans;
      } else if (ans === Answer.FP) {
        fpCount++;
      } else if (ans === Answer.FANC) {
        fancCount++;
      }
    });
    if (fancCount > audiometryConfig.maxNumberOfANCPerFreq) {
      return Answer.FANC;
    } else if (fpCount > audiometryConfig.acceptableNumberOfFalseClicksKids) {
      return Answer.FP;
    } else {
      return noneCount > correctCount ? Answer.NONE : threshold;
    }
  }

  function countFP(answersForSingleFreq) {
    var fpCount = 0;
    answersForSingleFreq.forEach((ans) => {
      if (ans === Answer.FP) {
        fpCount++;
      }
    });

    return fpCount;
  }

  useEffect(() => {
    if (finished) {
      const results = audiometryDetails.map((details) => {
        return {
          frequency: details.frequency,
          answer: getMostFrequentAnswer(details.answers),
        };
      });
      const details = audiometryDetails.map((details) => {
        return {
          frequency: details.frequency,
          answers: {
            [audiometryConfig.testTone[details.frequency]]: details.answers,
          },
          falseClicksCount: details.falseClicksCount,
          allClicksCount: details.allClicksCount,
        };
      });

      if (!startingAttempt) {
        updateAudiometry({
          frequenciesResults: results,
          audiometryDetails: details,
        });
      } else {
        const correctFound = audiometryDetails.some(({ answers }) =>
          answers.some((ans) => !isNaN(ans))
        );

        updateAudiometry(
          {
            frequenciesResults: correctFound ? [] : results,
            audiometryDetails: correctFound
              ? details.map(({ frequency, answers }) => {
                  return { frequency, answers };
                })
              : details,
          },
          !correctFound
        );
      }
    }
  }, [finished]);

  function emptyState() {
    setFinished(false);
    setPlay({
      freqSequence: !startingAttempt
        ? randomizeFreqsOrder()
        : [1000, 8000, 1000, 8000],
      currentIndex: 0,
    });
    setAudiometryDetails([]);
  }

  function isLastFalse(answersForSingleFreq) {
    return answersForSingleFreq.at(-1) === Answer.FP;
  }

  function setDisplayInstructionIfNeeded(answersForSingleFreq) {
    var fpCount = 0;
    answersForSingleFreq.forEach((ans) => {
      if (ans === Answer.FP) {
        fpCount++;
      }
    });
    if (
      fpCount === audiometryConfig.numberOfFalseClicksBeforeInstructionKids &&
      isLastFalse(answersForSingleFreq)
    ) {
      setDisplayInstruction(true);
    }
  }

  return (
    <Box sx={{ height: "100%" }}>
      <CurrentStateInfo
        firstLine={`${texts.examination}:`}
        secondLine={testNames["AP"]}
      />
      <Box
        display="flex"
        flexDirection="column"
        justifyContent={"center"}
        alignItems="center"
        sx={{ position: "relative" }}
      >
        <Box sx={{ visibility: acceptAnswer ? "hidden" : "visible" }}>
          <ListenCarefullyAnimation />
        </Box>
        <Stack
          direction="row"
          justifyContent="space-around"
          alignItems="end"
          sx={{ position: "relative", top: -60 }}
        >
          <Animal
            frequency={freqsPair[0]}
            animate={soundPlaying === 0}
            active={acceptAnswer}
            clickAnswer={() => handleClick(0)}
          />
          <IconButton
            disableRipple
            onClick={() => handleClick()}
            sx={{ position: "relative", top: 120 }}
          >
            <img src={acceptAnswer ? dontHearBtn : dontHear} />
          </IconButton>
          <Animal
            frequency={freqsPair[1]}
            animate={soundPlaying === 1}
            active={acceptAnswer}
            clickAnswer={() => handleClick(1)}
          />
        </Stack>
      </Box>

      <InstructionModal
        open={displayInstruction}
        width="650px"
        instruction={texts.audiometry.kidsInstruction}
        onClick={() => setDisplayInstruction(false)}
      />
    </Box>
  );
};

export default AudiometryKidsSingle;
