//konfiguracja audiometrii progowej (w obu wersjach)
export const lqConfig = {
  frequencies: [1000, 2000, 4000, 8000, 500, 250],
  maxIntensity: 80,
  minIntensity: 0,
  step: 5,
  startIntensity: 40,
  maxStartIntensity: 50,
  timeGenInt: 20000, //wartość NIE do konfiguracji, potrzebujemy dlugiego czasu trwania dzwieku

  correctThreshold: 20, //do oceny poprawności audiometrii progowej

  soundDuration: 2000,
  minPauseDuration: 2000,
  maxPauseDuration: 4000,
  //audiometr nie zaczyna grac w momencie wyslania odpowiedzi (tak zapisujemy poczatek trwania dzwieku),
  //tylko około 0.3-0.9s po - trzeba to uwzględnić w  maxReactionTimeAfterSoundStart
  //maksymalny czas na reakcje musi byc mniejszy lub rowny soundDuration + minPauseDuration
  //okno prawidłowej reakcji jest przesunięte w przód 200ms po starcie dźwięku
  maxReactionTimeAfterSoundStart: 3000, //[ms]
  numberOfFalseClicksBeforeInstruction: 2,
  acceptableNumberOfFalseClicks: 4, //po przekroczeniu tej liczby fałszywych kliknięć zakończy się badanie tej częstotliwości z wynikiem "FP"
  maxNumberOfBackgroundNoisesPerFreq: 10, //po przekroczeniu tej liczby prób z hałasami (które trzeba było powtórzyć) badanie tej częstotliwości zostanie zakończone z wynikiem "FANC"
  maxNumberOfFANCResult: 2, // po przekroczeniu tej liczby wyników "FANC" dla częstotliwości całe badanie zostanie zakończone (wyniki dla obu uszu liczone razem), czyli przykładowo dla liczby maxNumberOfFANCResult=2, gdy uzyskamy z trzech częstotliwości wynik "FANC", wtedy badanie się zakończy
};
