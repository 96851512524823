import { Modal, Box, Stack, Typography } from "@mui/material";
import { texts } from "../../resources/texts";
import hourglass from "../../resources/images/hourglass.svg";

const CheckingNoises = ({ open }) => {
  return (
    <Modal open={open}>
      <Box
        sx={{
          position: "fixed",
          boxSizing: "border-box",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "624px",
          borderRadius: "10px",
          p: 6,
          bgcolor: "rgba(255,255,255,0.95)",
        }}
      >
        <Stack alignItems="center" spacing={6}>
          <Typography
            variant="answers"
            sx={{
              fontSize: 30,
            }}
          >
            {texts.wait}
          </Typography>
          <Box>
            <img src={hourglass} alt="" className="rotate" />
          </Box>
          <Typography variant="h4">{texts.checkingNoises}</Typography>
        </Stack>
      </Box>
    </Modal>
  );
};
export default CheckingNoises;
