import { Grid, Box, Avatar, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import UndoIcon from "@mui/icons-material/Undo";

const Key = ({ onClick, value }) => {
  return (
    <Grid item xs={2} onClick={onClick}>
      <Avatar
        sx={{
          width: "100px",
          height: "100px",
          fontFamily: "Montserrat",
          fontWeight: "500",
          fontSize: "50px",
          background: "linear-gradient(#BEBEBE,#FFFFFF)",
          color: "primary.main",
          boxShadow: "5px 5px 10px #DEDEDE",
          borderStyle: "solid",
          borderWidth: "2px",
          borderColor: "secondary.main",
        }}
      >
        {value}
      </Avatar>
    </Grid>
  );
};

const Keyboard = ({ onKeyClick, onCancelClick, onCloseClick }) => {
  return (
    <Box
      sx={{
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%,2%)",
        width: "320px",
        borderRadius: "5%",
        p: 2,
        bgcolor: "rgba(251,251,251,0.80)",
      }}
    >
      <IconButton
        onClick={onCloseClick}
        sx={{ float: "right", fontSize: "40px" }}
      >
        <CloseIcon fontSize="large" />
      </IconButton>
      <Grid container rowSpacing={1} columnSpacing={1} columns={6}>
        {[...Array(9).keys()].map((i) => (
          <Key key={i + 1} value={i + 1} onClick={() => onKeyClick(i + 1)} />
        ))}
        <Grid item xs={2}></Grid>
        <Key value={0} onClick={() => onKeyClick(0)} />
        <Key
          value={
            <IconButton onClick={onCancelClick}>
              <UndoIcon sx={{ fontSize: "60px" }} />
            </IconButton>
          }
        />
      </Grid>
    </Box>
  );
};

export default Keyboard;
