import { useMemo } from "react";
import { Avatar, Grid, IconButton, Stack, Typography } from "@mui/material";
import answerRim from "../resources/images/answer-rims.svg";
import answerRimFilled from "../resources/images/answer-rims-filled.svg";
import answerRimFilledNavy from "../resources/images/answer-rims-filled-navy.svg";
import "./answer.css";
import SameDifferentButton from "../components/animations/SameDifferentButton";
import { answerToText } from "../resources/texts";
import AnswerGapNoGap from "./AnswerGapNoGap";

export const imgSrc = (img) => {
  var image;
  try {
    image = require(`../resources/images/min/${img}`);
  } catch (error) {
    try {
      image = require(`../resources/images/${img}`);
    } catch (error) {
      image = require(`../resources/images/min/blank.png`);
    }
  }
  return image;
};

export const CircleAnswer = ({
  selected,
  handleClick,
  img,
  text,
  testEnum,
}) => {
  const initialAngle = useMemo(() => {
    return Math.floor(Math.random() * 360);
  }, []);
  if (testEnum === "TDW") {
    return (
      <SameDifferentButton onClick={handleClick} text={answerToText(text)} />
    );
  } else if (testEnum === "TPR") {
    return (
      <AnswerGapNoGap
        onClick={handleClick}
        answer={{ text: answerToText(text), image: img }}
      />
    );
  } else {
    return (
      <Stack alignItems="center" sx={{ position: "relative" }}>
        {selected ? (
          <img
            src={answerRimFilled}
            style={{
              transform: `rotate(${initialAngle + 45}deg)`,
              transition: "transform 0.3s",
            }}
          />
        ) : (
          <img
            src={answerRim}
            style={{
              transform: `rotate(${initialAngle}deg)`,
              transition: "transform 0.3s",
            }}
          />
        )}
        <IconButton
          className={selected ? "selected-btn" : "unselected-btn"}
          disableRipple
          onClick={handleClick}
          sx={{
            position: "absolute",
            transition: "top 0.3s, left 0.3s",
          }}
        >
          <Avatar
            src={imgSrc(img)}
            sx={{
              width: selected ? "161px" : "184px",
              height: selected ? "161px" : "184px",
              transition: "width 0.3s, height 0.3s",
            }}
          />
        </IconButton>
        <Typography
          variant="answers"
          sx={{
            position: "relative",
            top: "-5px",
            backgroundColor: selected ? "#61C3D2" : "",
            color: selected ? "white" : "primary.main",
            borderRadius: 10,
            px: 2,
            py: 0.3,
          }}
        >
          {text ? text.toUpperCase() : ""}
        </Typography>
      </Stack>
    );
  }
};

export const CircleAnswerTRC = ({ selected, handleClick, text }) => {
  const initialAngle = useMemo(() => {
    return Math.floor(Math.random() * 360);
  }, []);
  return (
    <Stack alignItems="center" sx={{ position: "relative" }}>
      {selected ? (
        <img
          src={answerRimFilledNavy}
          style={{
            transform: `rotate(${initialAngle + 45}deg)`,
            transition: "transform 0.3s",
          }}
        />
      ) : (
        <img
          src={answerRim}
          style={{
            transform: `rotate(${initialAngle}deg)`,
            transition: "transform 0.3s",
          }}
        />
      )}
      <IconButton
        className={"selected-btn"}
        disableRipple
        onClick={handleClick}
        sx={{
          position: "absolute",
          transition: "top 0.3s, left 0.3s",
        }}
      >
        <Avatar
          sx={{
            width: "136px",
            height: "136px",
            fontFamily: "Montserrat",
            fontWeight: "bold",
            fontSize: "50px",
            backgroundColor: "#61C3D2",
            color: "white",
          }}
        >
          {text}
        </Avatar>
      </IconButton>
    </Stack>
  );
};

const CircleAnswers = ({
  givenAnswers,
  onClick,
  possibleAnswers,
  isTRC,
  testEnum,
}) => {
  if (possibleAnswers.length > 5) {
    return (
      <Grid container justifyContent="center" alignItems="center" spacing={2}>
        <Grid
          container
          item
          justifyContent="center"
          alignItems="center"
          spacing={possibleAnswers.length > 12 ? 0 : 3}
        >
          {possibleAnswers
            .slice(0, Math.ceil(possibleAnswers.length / 2))
            .map((answer, index) => {
              return (
                <Grid item key={index}>
                  {!isTRC ? (
                    <CircleAnswer
                      img={answer.image}
                      text={answer.text}
                      selected={givenAnswers.some(
                        (ans) => ans.text === answer.text
                      )}
                      handleClick={() => onClick(answer)}
                      testEnum={testEnum}
                    />
                  ) : (
                    <CircleAnswerTRC
                      text={answer.text}
                      selected={givenAnswers.some(
                        (ans) => ans.text === answer.text
                      )}
                      handleClick={() => onClick(answer)}
                    />
                  )}
                </Grid>
              );
            })}
        </Grid>
        <Grid
          container
          item
          justifyContent="center"
          alignItems="center"
          spacing={possibleAnswers.length > 12 ? 0 : 3}
        >
          {possibleAnswers
            .slice(Math.ceil(possibleAnswers.length / 2))
            .map((answer, index) => {
              return (
                <Grid item key={index}>
                  {!isTRC ? (
                    <CircleAnswer
                      img={answer.image}
                      text={answer.text}
                      selected={givenAnswers.some(
                        (ans) => ans.text === answer.text
                      )}
                      handleClick={() => onClick(answer)}
                      testEnum={testEnum}
                    />
                  ) : (
                    <CircleAnswerTRC
                      text={answer.text}
                      selected={givenAnswers.some(
                        (ans) => ans.text === answer.text
                      )}
                      handleClick={() => onClick(answer)}
                    />
                  )}
                </Grid>
              );
            })}
        </Grid>
      </Grid>
    );
  } else {
    return (
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={possibleAnswers.length === 2 ? 8 : 3}
      >
        {possibleAnswers.map((answer, index) => {
          return !isTRC ? (
            <CircleAnswer
              key={index}
              img={answer.image}
              text={answer.text}
              selected={givenAnswers.some((ans) => ans.text === answer.text)}
              handleClick={() => onClick(answer)}
              testEnum={testEnum}
            />
          ) : (
            <CircleAnswerTRC
              key={index}
              text={answer.text}
              selected={givenAnswers.some((ans) => ans.text === answer.text)}
              handleClick={() => onClick(answer)}
            />
          );
        })}
      </Stack>
    );
  }
};

export default CircleAnswers;
