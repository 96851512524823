import { Box, Stack, Typography, Grid } from "@mui/material";
import { texts, testNames } from "../resources/texts";
import { ReactComponent as Square } from "../resources/images/square.svg";
import manWomanSound from "../resources/images/man-woman-sound.svg";
import { SoundPresentation } from "../resources/exportedFunctions";

const CAPDInstructionPresentation = ({
  presentationSounds,
  testType,
  playingSound,
  marginLeft = 20,
}) => {
  const instructionsTexts = texts[testType];
  const [first, ...rest] = testNames[testType].split(" ");

  return (
    <Box
      sx={{
        pt: 9,
        ml: marginLeft,
      }}
    >
      <Stack direction="row" justifyContent={"space-between"}>
        <Stack
          alignItems="center"
          sx={{
            pt: ["TRMS", "TMS", "TMF", "AS", "TRC", "TRS"].includes(testType)
              ? 4
              : 0,
          }}
        >
          <Typography
            variant="title"
            sx={{
              color: (theme) => theme.palette.text.secondary,
              fontSize: "40px",
            }}
          >
            {first}
          </Typography>
          <Stack
            direction="row"
            spacing={8}
            alignItems="center"
            justifyContent="center"
            sx={{ mb: 8 }}
          >
            <Square />
            <Typography variant="title" sx={{ fontSize: "40px" }}>
              {rest.join(" ")}
            </Typography>
            <Square />
          </Stack>
          <Typography
            variant="h4"
            sx={{
              maxWidth: "1000px",
              lineHeight: 1.5,
              whiteSpace: "pre-line",
              mb: 4,
            }}
          >
            {instructionsTexts && instructionsTexts[0]}
          </Typography>
          {presentationSounds &&
            !["TRMS", "TMS", "TMF", "AS", "TRC", "TRS"].includes(testType) && (
              <Grid
                container
                justifyContent="center"
                spacing={6}
                alignItems="center"
                //sx={{ height: "100%" }}
              >
                {presentationSounds.map((sound, ind) => (
                  <Grid item key={ind}>
                    <SoundPresentation
                      rightAnswer={sound}
                      playingSound={playingSound}
                      isTPR={testType === "TPR"}
                    />
                  </Grid>
                ))}
              </Grid>
            )}
          <Typography
            variant="h4"
            sx={{
              maxWidth: "1000px",
              lineHeight: 1.5,
              whiteSpace: "pre-line",
              mt: 4,
            }}
          >
            {instructionsTexts && instructionsTexts[1] && instructionsTexts[1]}
          </Typography>
        </Stack>
        <img src={manWomanSound} />
      </Stack>
    </Box>
  );
};
export default CAPDInstructionPresentation;
