import { useEffect, useState } from "react";
import {
  Box,
  Typography,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
} from "@mui/material";
import { useApi } from "../Api";
import { sleep } from "../resources/exportedFunctions";
import { lqConfig } from "../resources/tests_config/louderQuieterConfig";
import { isAnswerCorrect } from "./SingleFreqAudiometry";
import InstructionModal from "../components/InstructionModal";
import BugReportIcon from "@mui/icons-material/BugReport";
import RepeatSoundInfoModal from "../components/RepeatSoundInfoModal";
import { useNotificationSubject } from "../Notifications";
import { Answer } from "./enums";
import { texts } from "../resources/texts";
import LamaFan from "../components/animations/LamaFan";
import snail from "../resources/images/snail-threshold.png";
import "../index.css";
import CheckingNoises from "../components/animations/CheckingNoisesModal";

var indexesWithAttemptStartStop = [];
const bgNoises = [];
var retryCount = 0;

const font = { fontSize: "1rem", fontFamily: "sans-serif" }; //do usuniecia
const TableForTestingPurposes = ({ answers, answersPhaseA, visible }) => {
  function countFalseAnswers() {
    var falseCount = 0;
    answersPhaseA.forEach(({ answers }) => {
      answers.forEach(({ answer }) => {
        answer === "FP" && falseCount++;
      });
    });
    answers.forEach(({ answers }) => {
      answers.forEach(({ answer }) => {
        answer === "FP" && falseCount++;
      });
    });

    return falseCount;
  }

  function displayStartTime(ind) {
    const obj = indexesWithAttemptStartStop.find(({ index }) => index === ind);
    if (obj && obj.startTime) {
      const date = new Date(obj.startTime);
      return date.toLocaleTimeString("default", {
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        fractionalSecondDigits: 3,
      });
    }
    return "";
  }
  function displayStopTime(ind) {
    const obj = indexesWithAttemptStartStop.find(({ index }) => index === ind);
    if (obj && obj.stopTime) {
      const date = new Date(obj.stopTime);
      return date.toLocaleTimeString("default", {
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        fractionalSecondDigits: 3,
      });
    }
    return "";
  }

  return (
    <Box
      sx={{
        position: "fixed",
        top: 200,
        left: 10,
        visibility: visible ? "visible" : "hidden",
      }}
    >
      <Typography>Debug info</Typography>
      <TableContainer>
        <Table size="small">
          <TableBody>
            <TableRow>
              <TableCell sx={font}>intensywność</TableCell>
              <TableCell sx={font}>odpowiedzi</TableCell>
            </TableRow>
            {answersPhaseA.map((answer, index) => {
              return (
                <TableRow key={index}>
                  <TableCell sx={font}>{answer.intensity}</TableCell>
                  <TableCell sx={font}>
                    {answer.answers
                      .map((ans) => ans.ind + ": " + ans.answer)
                      .join(", ")}
                  </TableCell>
                </TableRow>
              );
            })}
            <TableRow>
              <TableCell>Faza B</TableCell>
            </TableRow>
            {answers.map((answer, index) => {
              return (
                <TableRow key={index}>
                  <TableCell sx={font}>{answer.intensity}</TableCell>
                  <TableCell sx={font}>
                    {answer.answers
                      .map((ans) => ans.ind + ": " + ans.answer)
                      .join(", ")}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Typography>{`liczba fałszywych odpowiedzi: ${countFalseAnswers()}`}</Typography>
      <Typography>{`liczba powtórzeń: ${retryCount}`}</Typography>
    </Box>
  );
};

const SingleFreqThresholdAudiometry = ({
  frequency,
  channel,
  updateAudiometry,
  startTone,
  stopped,
  onError,
}) => {
  const api = useApi();
  const nodes = [
    {
      id: 0,
      yesAction: function () {
        decreaseIntensity(15);
        setCurrentNode(nodes.find(({ id }) => id === 1));
      },
      noAction: function () {
        increaseIntensity(20);
        setCurrentNode(nodes.find(({ id }) => id === 6));
      },
    },
    {
      id: 1,
      yesAction: function (intensity) {
        if (intensity === lqConfig.minIntensity) {
          setCurrentNode(nodes.find(({ id }) => id === 9));
        } else {
          decreaseIntensity(15);
        }
      },
      noAction: function () {
        increaseIntensity(5);
        setPhase("B");
        setCurrentNode(nodes.find(({ id }) => id === 2));
      },
    },
    {
      id: 2,
      yesAction: function () {
        decreaseIntensity(10);
        setCurrentNode(nodes.find(({ id }) => id === 3));
      },
      noAction: function (intensity) {
        if (intensity === lqConfig.maxIntensity) {
          setCurrentNode(nodes.find(({ id }) => id === 5));
        } else {
          increaseIntensity(5);
          setCurrentNode(nodes.find(({ id }) => id === 2));
        }
      },
    },
    {
      id: 3,
      yesAction: function () {
        decreaseIntensity(10);
      },
      noAction: function () {
        increaseIntensity(5);
        setCurrentNode(nodes.find(({ id }) => id === 4));
      },
    },
    {
      id: 4,
      yesAction: function () {
        decreaseIntensity(10);
        setCurrentNode(nodes.find(({ id }) => id === 3));
      },
      noAction: function (intensity) {
        if (intensity === lqConfig.maxIntensity) {
          setCurrentNode(nodes.find(({ id }) => id === 8));
        } else {
          increaseIntensity(5);
        }
      },
    },
    {
      id: 5,
      yesAction: function () {
        decreaseIntensity(10);
        setCurrentNode(nodes.find(({ id }) => id === 3));
      },
      noAction: function () {
        setFinished(true);
      },
    },
    {
      id: 6,
      yesAction: function () {
        decreaseIntensity(15);
        setCurrentNode(nodes.find(({ id }) => id === 1));
      },
      noAction: function (intensity) {
        if (intensity === lqConfig.maxIntensity) {
          setCurrentNode(nodes.find(({ id }) => id === 7));
        } else {
          increaseIntensity(20);
          setCurrentNode(nodes.find(({ id }) => id === 6));
        }
      },
    },
    {
      id: 7,
      yesAction: function () {
        decreaseIntensity(15);
        setCurrentNode(nodes.find(({ id }) => id === 1));
      },
      noAction: function () {
        setFinished(true);
      },
    },
    {
      id: 8,
      yesAction: function () {
        decreaseIntensity(10);
        setCurrentNode(nodes.find(({ id }) => id === 3));
      },
      noAction: function () {
        setFinished(true);
      },
    },
    {
      id: 9,
      yesAction: function () {}, //poprawna odpowiedź po puszczeniu dwa razy dźwięku o najmniejszym natężeniu
      noAction: function () {
        setPhase("B");
        increaseIntensity(5);
        setCurrentNode(nodes.find(({ id }) => id === 2));
      },
    },
  ];

  const [currentIntensity, setCurrentIntensity] = useState(startTone);
  const [finished, setFinished] = useState(false);
  const [ready, setReady] = useState(false);
  const [play, setPlay] = useState({ isPlaying: false, currentSound: -1 });
  const [clicksCount, setClicksCount] = useState(0);
  const [falseClicksCount, setFalseClicksCount] = useState(0);
  const [clicks, setClicks] = useState([]);
  const [currentStartTime, setCurrentStartTime] = useState();
  const [answersState, setAnswersState] = useState({
    allAnswers: [],
    answersPhaseA: [],
    retried: false,
  });
  const [phase, setPhase] = useState("A"); //"A" lub "B"
  const [currentNode, setCurrentNode] = useState(
    nodes.find(({ id }) => id === 0)
  );
  const [displayInstruction, setDisplayInstruction] = useState(false);
  const [readyToCheckAnswers, setReadyToCheckAnswers] = useState(true);
  const [debugInfoDisplay, setDebugInfoDisplay] = useState(false);
  const [waitingForNoises, setWaitingForNoises] = useState(false);
  const [displayRepeatInfo, setDisplayRepeatInfo] = useState(false);
  const notificationSubject = useNotificationSubject();

  function incrementCurrentSound() {
    setPlay((prevState) => ({
      isPlaying: false,
      currentSound: prevState.currentSound + 1,
    }));
    setClicks([]);
  }

  async function playSound(sound) {
    try {
      const time = await api.audiometryPlay(sound);
      setCurrentStartTime(time.start);
    } catch (error) {
      console.error(error);
      onError(error.message);
    }
  }

  async function playSoundAndWait() {
    setPlay((prevState) => ({
      ...prevState,
      isPlaying: true,
    }));
    setReadyToCheckAnswers(false);
    const startTime = Date.now();
    const currentIndexObj = indexesWithAttemptStartStop.find(
      ({ index }) => index === play.currentSound
    );

    if (currentIndexObj) {
      currentIndexObj.startTime = startTime;
      currentIndexObj.stopTime = undefined;
    } else {
      indexesWithAttemptStartStop.push({
        index: play.currentSound,
        startTime,
        intensity: currentIntensity,
        nodeId: currentNode.id,
      });
    }
    const previous = indexesWithAttemptStartStop.find(
      ({ index }) => index === play.currentSound - 1
    );
    if (previous && previous.stopTime === undefined) {
      previous.stopTime = startTime;
    }
    if (indexesWithAttemptStartStop.length > 3) {
      //usunięcie czasów prób dla prób starszych niż 3 ostatnie
      indexesWithAttemptStartStop.splice(
        0,
        indexesWithAttemptStartStop.length - 3
      );
    }

    await sleep(250); //aby po zatrzymaniu badania nie byl od razu grany dzwiek

    var sound = {
      levelFloat: currentIntensity,
      channel,
      freqInt: frequency,
      timeGenInt: lqConfig.soundDuration,
    };
    await playSound(sound);
    await sleep(
      lqConfig.maxReactionTimeAfterSoundStart - lqConfig.soundDuration
    );
    //koniec okna czasowego w ktorym kliknięcie oznaczamy jako poprawne
    const pauseDuration =
      Math.floor(
        Math.random() * (lqConfig.maxPauseDuration - lqConfig.minPauseDuration)
      ) + lqConfig.minPauseDuration;
    await sleep(
      pauseDuration -
        lqConfig.maxReactionTimeAfterSoundStart +
        lqConfig.soundDuration
    );
    setReadyToCheckAnswers(true);
    setCurrentStartTime();
  }

  useEffect(() => {
    const notificationObserver = (notification) => {
      //console.log(notification);
      switch (notification.type) {
        case "ExceptionWithCodeAndTimeStamp":
          if (
            notification.interfaceGroupingExceptions.startsWith(
              "ResetExaminationAttempt"
            )
          ) {
            //console.log("-----HAŁAS", notification.ts);
            bgNoiseHandler(new Date(notification.ts).getTime());
          }
          break;
        default:
          break;
      }
    };
    notificationSubject.attach(notificationObserver);

    return () => {
      notificationSubject.detach(notificationObserver);
    };
  }, [notificationSubject]);

  // useEffect(() => {
  //   console.log("play", play);
  // }, [play]);

  // useEffect(() => {
  //   console.log("--------Faza", phase);
  // }, [phase]);

  // useEffect(() => {
  //   console.log("Node", currentNode);
  // }, [currentNode]);
  // useEffect(() => {
  //   console.log("--------intensity", currentIntensity);
  // }, [currentIntensity]);
  useEffect(() => {
    emptyState();
  }, [frequency, channel]);

  useEffect(() => {
    if (readyToCheckAnswers && ready) {
      //console.log("checkAnswers");
      checkAnswers();
    }
  }, [ready, readyToCheckAnswers]);

  useEffect(() => {
    !play.isPlaying &&
      !stopped &&
      play.currentSound !== -1 &&
      !finished &&
      !displayInstruction &&
      playSoundAndWait();
  }, [play, stopped, displayInstruction]);

  useEffect(() => {
    currentStartTime &&
      setClicks((prevState) =>
        prevState.map(({ clickTime, isRight }) => {
          if (isRight === undefined) {
            const isRightClick = checkIfClickTimeIsInSoundRange(
              clickTime,
              currentStartTime
            );
            return { clickTime, isRight: isRightClick };
          } else {
            return { clickTime, isRight };
          }
        })
      );
  }, [currentStartTime]);

  function checkAnswers() {
    if (play.currentSound !== -1) {
      const currentSoundTmp = play.currentSound;
      const currentIntensityTmp = currentIntensity;
      const phaseTmp = phase;

      const soundWithNoise = checkIfItWasNoiseDuringPreviousSounds();
      if (soundWithNoise) {
        retryCount++;
        if (retryCount > lqConfig.maxNumberOfBackgroundNoisesPerFreq) {
          setFinished(true);
        } else {
          deleteAnswersAfterId(soundWithNoise.index);
          setDisplayRepeatInfo(true);
          setTimeout(() => {
            setDisplayRepeatInfo(false);
            setPlay({ isPlaying: false, currentSound: soundWithNoise.index });
            setClicks([]);
          }, 3000);
        }
      } else {
        const wasRight = isAnswerCorrect(clicks);
        const currentAnswer = wasRight
          ? wasRight
          : !clicks.some((click) => click.isRight === false)
          ? false
          : Answer.FP;
        //console.log("currentAnswer", currentAnswer);
        if (currentAnswer === Answer.FP) {
          countFalseAnswers() + 1 ===
            lqConfig.numberOfFalseClicksBeforeInstruction &&
            setDisplayInstruction(true);
        }

        if (currentAnswer === true) {
          currentNode.yesAction(currentIntensity);
        } else if (currentAnswer === false) {
          currentNode.noAction(currentIntensity);
        }

        addAnswer(
          currentIntensityTmp,
          currentSoundTmp,
          currentAnswer,
          phaseTmp
        );
      }
    } else {
      incrementCurrentSound();
    }
  }

  useEffect(() => {
    checkCriteria() && setFinished(true);
  }, [answersState]);

  function checkCriteria() {
    //console.log("checkCriteria");
    if (answersState.answersPhaseA.length > 0) {
      const numberOfFalseAnswers = countFalseAnswers();
      if (retryCount > lqConfig.maxNumberOfBackgroundNoisesPerFreq) {
        return Answer.FANC;
      }
      if (numberOfFalseAnswers > lqConfig.acceptableNumberOfFalseClicks) {
        return Answer.FP;
      }
      if (phase === "B" && !answersState.retried) {
        for (const answer of answersState.allAnswers) {
          var correctCounter = 0;
          var fpCount = 0;
          answer.answers.forEach(({ answer }) => {
            if (answer === true) {
              correctCounter++;
            } else if (answer === Answer.FP) {
              fpCount++;
            }
          });

          if (
            correctCounter / (answer.answers.length - fpCount) >= 0.6 &&
            answer.answers.length - fpCount >= 3
          ) {
            return answer;
          }
        }

        incrementCurrentSound();
        return false;
      } else if (phase === "A" && !answersState.retried) {
        const answerPhaseAMinIntensity = answersState.answersPhaseA.find(
          ({ intensity }) => intensity === lqConfig.minIntensity
        );
        if (answerPhaseAMinIntensity) {
          var correctCount = 0;
          answerPhaseAMinIntensity.answers.forEach(
            ({ answer }) => answer === true && correctCount++
          );
          if (correctCount >= 2) {
            return answerPhaseAMinIntensity;
          }
        }
        incrementCurrentSound();
        return false;
      }
    }
    return false;
  }

  useEffect(() => {
    if (finished) {
      handleTryFinish(handleActualFinish);
    }
  }, [finished]);

  const handleTryFinish = (callback) => {
    var runCallback = true;

    if (!(retryCount > lqConfig.maxNumberOfBackgroundNoisesPerFreq)) {
      //jeśli powód zakończenia jest inny niż przekroczenie liczby hałasów
      //console.log("czekamy na hałasy");
      setWaitingForNoises(true);
      setTimeout(() => {
        //czekamy na ewentualne hałasy
        //console.log("sprawdzamy hałasy");
        setWaitingForNoises(false);
        const soundWithNoise = checkIfItWasNoiseDuringPreviousSounds();
        if (soundWithNoise) {
          retryCount++;
          if (!(retryCount > lqConfig.maxNumberOfBackgroundNoisesPerFreq)) {
            //jeśli liczba hałasów nie została przekroczona
            runCallback = false;
            setFinished(false);
            deleteAnswersAfterId(soundWithNoise.index);
            setDisplayRepeatInfo(true);
            setTimeout(() => {
              setDisplayRepeatInfo(false);
              setPlay({ isPlaying: false, currentSound: soundWithNoise.index });
              setClicks([]);
            }, 3000);
          }
        }
        runCallback && callback();
      }, 2000);
    } else {
      runCallback && callback();
    }
  };

  const handleActualFinish = () => {
    const fullfilledCriteria = checkCriteria();
    setReady(false);
    // console.log("finished", {
    //   frequency,
    //   answer:
    //     fullfilledCriteria === Answer.FANC
    //       ? Answer.FANC
    //       : fullfilledCriteria === Answer.FP
    //       ? Answer.FP
    //       : fullfilledCriteria
    //       ? fullfilledCriteria.intensity
    //       : Answer.MAXEXCEEDED,
    // });
    updateAudiometry(
      {
        frequency,
        answer:
          fullfilledCriteria === Answer.FANC
            ? Answer.FANC
            : fullfilledCriteria === Answer.FP
            ? Answer.FP
            : fullfilledCriteria
            ? fullfilledCriteria.intensity
            : Answer.MAXEXCEEDED,
      },
      {
        frequency,
        soundsClicksTimes: [],
        answers: answersState,
        falseClicksCount,
        allClicksCount: clicksCount,
      }
    );
  };

  function decreaseIntensity(step) {
    setCurrentIntensity((prevState) =>
      prevState - step < lqConfig.minIntensity
        ? lqConfig.minIntensity
        : prevState - step
    );
  }

  function increaseIntensity(step) {
    setCurrentIntensity((prevState) =>
      prevState + step > lqConfig.maxIntensity
        ? lqConfig.maxIntensity
        : prevState + step
    );
  }

  function addAnswer(
    currentIntensityTmp,
    currentSoundTmp,
    currentAnswer,
    phaseTmp
  ) {
    if (phaseTmp === "B") {
      if (
        answersState.allAnswers.find(
          ({ intensity }) => intensity === currentIntensityTmp
        )
      ) {
        setAnswersState((prevState) => ({
          ...prevState,
          retried: false,
          allAnswers: prevState.allAnswers.map((ans) => {
            if (ans.intensity === currentIntensityTmp) {
              return {
                ...ans,
                answers: [
                  ...ans.answers,
                  { ind: currentSoundTmp, answer: currentAnswer },
                ],
              };
            } else {
              return { ...ans };
            }
          }),
        }));
      } else {
        setAnswersState((prevState) => ({
          ...prevState,
          retried: false,
          allAnswers: [
            ...prevState.allAnswers,
            {
              intensity: currentIntensityTmp,
              answers: [{ ind: currentSoundTmp, answer: currentAnswer }],
            },
          ],
        }));
      }
    } else if (phaseTmp === "A") {
      if (
        answersState.answersPhaseA.find(
          ({ intensity }) => intensity === currentIntensityTmp
        )
      ) {
        setAnswersState((prevState) => ({
          ...prevState,
          retried: false,
          answersPhaseA: prevState.answersPhaseA.map((ans) => {
            if (ans.intensity === currentIntensityTmp) {
              return {
                ...ans,
                answers: [
                  ...ans.answers,
                  { ind: currentSoundTmp, answer: currentAnswer },
                ],
              };
            } else {
              return { ...ans };
            }
          }),
        }));
      } else {
        setAnswersState((prevState) => ({
          ...prevState,
          retried: false,
          answersPhaseA: [
            ...prevState.answersPhaseA,
            {
              intensity: currentIntensityTmp,
              answers: [{ ind: currentSoundTmp, answer: currentAnswer }],
            },
          ],
        }));
      }
    }
  }

  function countFalseAnswers() {
    var falseCount = 0;
    answersState.answersPhaseA.forEach(({ answers }) => {
      answers.forEach(({ answer }) => {
        answer === Answer.FP && falseCount++;
      });
    });
    answersState.allAnswers.forEach(({ answers }) => {
      answers.forEach(({ answer }) => {
        answer === Answer.FP && falseCount++;
      });
    });

    return falseCount;
  }

  function checkIfClickTimeIsInSoundRange(clickTime, soundStartTime) {
    if (
      clickTime > soundStartTime + 200 &&
      clickTime <=
        soundStartTime + lqConfig.maxReactionTimeAfterSoundStart + 200
    ) {
      return true;
    } else {
      setFalseClicksCount((prevState) => prevState + 1);
      return false;
    }
  }

  function addClick(clickTime) {
    setClicksCount((prevState) => prevState + 1);
    //dodajemy czas kliknięcia
    setClicks((prevState) => [
      ...prevState,
      {
        clickTime,
        isRight: currentStartTime
          ? checkIfClickTimeIsInSoundRange(clickTime, currentStartTime)
          : undefined,
      },
    ]);
  }

  function emptyState() {
    indexesWithAttemptStartStop = [];
    bgNoises.length = 0;
    retryCount = 0;
    setAnswersState({ allAnswers: [], retried: false, answersPhaseA: [] });
    setFinished(false);
    setPlay({ isPlaying: false, currentSound: -1 });
    setFalseClicksCount(0);
    setClicksCount(0);
    setClicks([]);
    setPhase("A");
    setReady(true);
  }

  const bgNoiseHandler = (ts) => {
    bgNoises.push(ts);
    //console.log("hałas zapisany - bgNoises", bgNoises);
  };

  function deleteAnswersAfterId(index) {
    setAnswersState((prevState) => ({
      retried: true,
      allAnswers: prevState.allAnswers.map((answer) => ({
        ...answer,
        answers: answer.answers.filter(({ ind }) => ind < index),
      })),
      answersPhaseA: prevState.answersPhaseA.map((answer) => ({
        ...answer,
        answers: answer.answers.filter(({ ind }) => ind < index),
      })),
    }));
  }

  function checkIfItWasNoiseDuringPreviousSounds() {
    var soundWithNoise = undefined;
    bgNoises.some((noiseTime) => {
      indexesWithAttemptStartStop.some((obj) => {
        //console.log("checking obj", obj);
        if (noiseTime > obj.startTime) {
          if (obj.stopTime === undefined) {
            //console.log("sprawdzamy aktualna próbe");
            if (noiseTime <= Date.now()) {
              //console.log("znaleziono dźwięk podczas hałasu", obj);
              soundWithNoise = obj;
              return true;
            }
          } else if (noiseTime < obj.stopTime) {
            //console.log("znaleziono dźwięk podczas hałasu", obj);
            soundWithNoise = obj;
            return true;
          }
        }
        return false;
      });
      if (soundWithNoise === undefined) {
        return false;
      } else {
        return true;
      }
    });
    bgNoises.length = 0;
    if (soundWithNoise === undefined) {
      return false;
    } else {
      indexesWithAttemptStartStop = indexesWithAttemptStartStop.filter(
        ({ index }) => index < soundWithNoise.index
      );
      setCurrentIntensity(soundWithNoise.intensity);
      setCurrentNode(nodes.find(({ id }) => id === soundWithNoise.nodeId));
      setPhase([0, 1, 6, 7, 9].includes(soundWithNoise.nodeId) ? "A" : "B");
      return soundWithNoise;
    }
  }

  return (
    <Box sx={{ height: "100%" }}>
      <IconButton
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
        }}
        onClick={() => setDebugInfoDisplay((prevState) => !prevState)}
      >
        <BugReportIcon fontSize="large" />
      </IconButton>
      <img className="snail" src={snail} alt="" style={{ position: "fixed" }} />

      {!displayInstruction && !finished && (
        <Box
          sx={{ position: "relative", left: "350px", display: "inline-block" }}
        >
          <LamaFan
            onClick={() => {
              addClick(Date.now());
            }}
          />
        </Box>
      )}
      <InstructionModal
        open={displayInstruction}
        instruction={texts.audiometry.kidsThresholdInstruction}
        onClick={() => setDisplayInstruction(false)}
        width="650px"
      />
      {displayRepeatInfo && <RepeatSoundInfoModal />}
      <CheckingNoises open={waitingForNoises} />

      <TableForTestingPurposes
        answers={answersState.allAnswers}
        answersPhaseA={answersState.answersPhaseA}
        visible={debugInfoDisplay}
      />
    </Box>
  );
};

export default SingleFreqThresholdAudiometry;
