import { Box, Typography, Stack } from "@mui/material";
import { texts } from "../resources/texts";
import takeOutHeadphones from "../resources/images/take-out-headphones.svg";
import putDownHeadphones from "../resources/images/put-down-headphones.svg";
import { ReactComponent as Square } from "../resources/images/square.svg";

export const OpenCoverInfo = () => {
  return (
    <Box
      sx={{
        pt: 14,
        ml: 35,
      }}
    >
      <Stack direction="row" spacing={8}>
        <Stack alignItems="center" justifyContent="flex-start" sx={{ mt: 8 }}>
          <Stack
            direction="row"
            spacing={5}
            alignItems="center"
            justifyContent="center"
            sx={{ mb: 8 }}
          >
            <Square />
            <Box>
              <Typography
                variant="title"
                sx={{ color: (theme) => theme.palette.text.secondary }}
              >
                {`${texts.takeOut} `}
              </Typography>
              <Typography variant="title">{texts.headphones}</Typography>
            </Box>
            <Square />
          </Stack>
          <Typography
            variant="h4"
            sx={{ maxWidth: "750px", mb: 4, lineHeight: 1.5, fontSize: 32 }}
          >
            {texts.takeOutHeadphonesInfo}
          </Typography>
        </Stack>
        <img src={takeOutHeadphones} alt="" />
      </Stack>
    </Box>
  );
};

export const CloseCoverInfo = () => {
  return (
    <Box
      sx={{
        pt: 14,
        ml: 35,
      }}
    >
      <Stack direction="row" spacing={8}>
        <Stack alignItems="center" justifyContent="flex-start" sx={{ mt: 8 }}>
          <Stack
            direction="row"
            spacing={5}
            alignItems="center"
            justifyContent="center"
            sx={{ mb: 8 }}
          >
            <Square />
            <Box>
              <Typography
                variant="title"
                sx={{ color: (theme) => theme.palette.text.secondary }}
              >
                {`${texts.putDown} `}
              </Typography>
              <Typography variant="title">{texts.headphones}</Typography>
            </Box>
            <Square />
          </Stack>
          <Typography
            variant="h4"
            sx={{ maxWidth: "750px", mb: 4, lineHeight: 1.5, fontSize: 32 }}
          >
            {texts.putHeadphonesInStorageCloseCover}
          </Typography>
          <br />
          <Typography
            variant="h4"
            sx={{ maxWidth: "750px", lineHeight: 1.5, fontSize: 32 }}
          >
            {texts.closeCoverInfo}
          </Typography>
        </Stack>
        <img src={putDownHeadphones} alt="" />
      </Stack>
    </Box>
  );
};
