import { useEffect, useState } from "react";
import { Button, Stack, Box, Typography } from "@mui/material";
import CurrentStateInfo from "../components/CurrentStateInfo";
import ConfirmationDialog from "../components/ConfirmationDialog";
import ProgressSlider from "../components/ProgressSlider";
import { useApi } from "../Api";
import { lqConfig } from "../resources/tests_config/louderQuieterConfig";
import {
  determineEarlyFinishedEnum,
  determineFailureReason,
  getEmptyFreq,
  isErrorFromAudiometr,
  sleep,
  wasTestInterrupted,
} from "../resources/exportedFunctions";
import Test from "./TestClass";
import moment from "moment";
import { Answer, FailureReason, Result } from "./enums";
import { texts } from "../resources/texts";
import { testNames } from "../resources/texts";
import { FinishButton } from "../components/FinishButton";
import TestDescription from "../pages/TestDescription";
import { HeadphonesPulseAnimationVolume } from "../components/animations/HeadphonesPulseAnimation";
import LQButtonLayout from "./LQButtonLayout";
import bg from "../resources/images/bg-lq.svg";
import { testsWavFiles } from "../resources/testsWavFiles";
import { wavFiles } from "../resources/wavFiles";

const Instruction = ({ startTest, handleError }) => {
  const api = useApi();
  const [instructionFinished, setInstructionFinished] = useState(false);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    api
      .playInstruction(
        [wavFiles[testsWavFiles.GC.instructionsSounds[1]]],
        signal
      )
      .then(() => {
        setInstructionFinished(true);
      })
      .catch((e) => {
        handleError && !signal.aborted && handleError(e.message);
      });

    return () => controller.abort();
  }, []);

  return (
    <Box>
      <Stack justifyContent={"center"} alignItems="center">
        <Typography
          variant="h4"
          sx={{
            maxWidth: "870px",
            zIndex: 2,
            lineHeight: 1.5,
            whiteSpace: "pre-line",
          }}
        >
          {texts.audiometry.lqInstruction}
        </Typography>
        <HeadphonesPulseAnimationVolume />
      </Stack>
      <Button
        variant="contained"
        disabled={!instructionFinished}
        onClick={startTest}
        sx={{ position: "fixed", bottom: 50, right: 40, px: 4 }}
      >
        {texts.startTest}
      </Button>
    </Box>
  );
};

const LouderQuieter = ({
  onEvent,
  automaticLogout,
  frequenciesRight = lqConfig.frequencies,
  frequenciesLeft = lqConfig.frequencies,
  startDate,
  parentError,
}) => {
  const api = useApi();
  const [louderQuieter, setLouderQuieter] = useState();
  const [freqAndChannel, setFreqAndChannel] = useState({
    currentFreq: null,
    currentChannel: "HEAD_PHONES_RIGHT",
  });
  const [respReceived, setRespReceived] = useState(false);
  const [currentIntensity, setCurrentIntensity] = useState();
  const [displayNoSoundButton, setDisplayNoSoundButton] = useState(false);
  const [finish, setFinish] = useState({ finished: false, cancelled: false });
  const [phase, setPhase] = useState("description"); // mozliwe: description, instruction, test
  const [displayFinishTestConfirmation, setDisplayFinishTestConfirmation] =
    useState(false);
  const [error, setError] = useState();
  const [saveDisabled, setSaveDisabled] = useState(true);

  useEffect(() => {
    !error && parentError && handleError(parentError);
  }, [parentError]);

  useEffect(() => {
    const createLouderQuieter = async () => {
      var frequenciesResultRight = [];
      frequenciesRight.forEach((freq) =>
        frequenciesResultRight.push({ frequency: freq, answer: "" })
      );

      var frequenciesResultLeft = [];
      frequenciesLeft.forEach((freq) =>
        frequenciesResultLeft.push({ frequency: freq, answer: "" })
      );
      setLouderQuieter({
        frequenciesResultRight,
        frequenciesResultLeft,
        testEnum: "GC",
        forKids: false,
        maxIntensity: lqConfig.maxIntensity,
        minIntensity: lqConfig.minIntensity,
        resultRight: null,
        resultLeft: null,
        result: null,
        audiometryDetailsListRight: [],
        audiometryDetailsListLeft: [],
        earlyFinishedEnum: null,
        testFrequencies: lqConfig.frequencies,
        name: testNames["GC"],
      });
    };
    phase === "instruction" && createLouderQuieter();
    if (phase === "test") {
      document.body.style.backgroundImage = `url(${bg})`;
    }
  }, [phase]);

  useEffect(() => {
    if (
      currentIntensity !== undefined &&
      freqAndChannel.currentFreq &&
      phase === "test"
    ) {
      setRespReceived(false);
      var sound = {
        levelFloat: `${currentIntensity}f`,
        channel: freqAndChannel.currentChannel,
        freqInt: freqAndChannel.currentFreq,
        timeGenInt: lqConfig.timeGenInt,
      };
      api
        .louderQuieterPlay(sound)
        .then(() => {
          setRespReceived(true);
        })
        .catch((e) => {
          handleError(e.message);
        });
    }
  }, [currentIntensity, freqAndChannel, phase]);

  useEffect(() => {
    if (louderQuieter) {
      setCurrentIntensity();
      changeFreq();
    }
  }, [louderQuieter]);

  useEffect(() => {
    if (freqAndChannel.currentFreq) {
      if (freqAndChannel.currentFreq === 1000) {
        setCurrentIntensity(lqConfig.startIntensity);
      } else if (freqAndChannel.currentFreq === 500) {
        var thresholdFor1000;
        if (freqAndChannel.currentChannel === "HEAD_PHONES_RIGHT") {
          thresholdFor1000 = louderQuieter.frequenciesResultRight.find(
            ({ frequency }) => frequency === 1000
          )?.answer;
        } else {
          thresholdFor1000 = louderQuieter.frequenciesResultLeft.find(
            ({ frequency }) => frequency === 1000
          )?.answer;
        }
        !isNaN(thresholdFor1000)
          ? setCurrentIntensity(
              thresholdFor1000 + 15 > lqConfig.maxStartIntensity
                ? lqConfig.maxStartIntensity
                : thresholdFor1000 + 15
            )
          : setCurrentIntensity(lqConfig.startIntensity);
      } else if (freqAndChannel.currentFreq === "end") {
        setFinish((prevState) => ({ ...prevState, finished: true }));
      } else {
        const currentFreqIndex = (
          freqAndChannel.currentChannel === "HEAD_PHONES_RIGHT"
            ? frequenciesRight
            : frequenciesLeft
        ).indexOf(freqAndChannel.currentFreq);
        var prevThreshold;
        if (currentFreqIndex > 0) {
          if (freqAndChannel.currentChannel === "HEAD_PHONES_RIGHT") {
            prevThreshold =
              louderQuieter.frequenciesResultRight[currentFreqIndex - 1].answer;
          } else {
            prevThreshold =
              louderQuieter.frequenciesResultLeft[currentFreqIndex - 1].answer;
          }
        }
        !isNaN(prevThreshold)
          ? setCurrentIntensity(
              prevThreshold + 15 > lqConfig.maxStartIntensity
                ? lqConfig.maxStartIntensity
                : prevThreshold + 15
            )
          : setCurrentIntensity(lqConfig.startIntensity);
      }
    }
  }, [freqAndChannel]);

  useEffect(() => {
    setDisplayNoSoundButton(currentIntensity >= lqConfig.maxIntensity);
  }, [currentIntensity]);

  useEffect(() => {
    if (automaticLogout === true) {
      !finish.finished
        ? setFinish((prevState) => ({ ...prevState, finished: true }))
        : onEvent &&
          onEvent("FINISHED", {
            result: Result.AMB,
            testType: "GC",
            failureReason: FailureReason.INACTIVITY_LOGOUT,
            error: error,
            data: {
              ...louderQuieter,
              earlyFinishedEnum: FailureReason.INACTIVITY_LOGOUT,
            },
            startDate: startDate,
            endDate: moment().toISOString(),
          });
    }
  }, [automaticLogout]);

  useEffect(() => {
    if (finish.finished) {
      onFinished();
    }
  }, [finish]);

  async function onFinished() {
    try {
      if (!error || (parentError && !isErrorFromAudiometr(parentError))) {
        await api.stopPlaying();
      }
    } catch (error) {
      console.error(error);
    }
    await sleep(1000); //jak dodaliśmy lektora w planszy przejścia do następnego badania, to musimy odczekać po wykonaniu api.stopPlaying() inaczej kod audiometrowy rzuca błędy
    if (louderQuieter) {
      const failureReason = determineFailureReason(
        automaticLogout,
        finish.cancelled,
        error,
        louderQuieter.result
      );

      onEvent &&
        onEvent("FINISHED", {
          result:
            louderQuieter.result === Result.FANC ||
            louderQuieter.result === Result.FP ||
            louderQuieter.result === null ||
            wasTestInterrupted(failureReason)
              ? Result.AMB
              : louderQuieter.result,
          testType: "GC",
          failureReason,
          error: error,
          data: {
            ...louderQuieter,
            earlyFinishedEnum: determineEarlyFinishedEnum(
              automaticLogout,
              finish.cancelled,
              error
            ),
          },
          startDate: startDate,
          endDate: moment().toISOString(),
        });
    } else {
      onEvent &&
        onEvent("FINISHED", {
          result: Result.AMB,
          failureReason:
            automaticLogout === true
              ? FailureReason.INACTIVITY_LOGOUT
              : error !== undefined
              ? FailureReason.ERROR
              : FailureReason.CANCELLED,
          testType: "GC",
          error: error,
          startDate: startDate,
          endDate: moment().toISOString(),
        });
    }
  }

  function increaseIntensity() {
    setSaveDisabled(false);
    respReceived &&
      setCurrentIntensity((prevState) =>
        prevState + lqConfig.step >= lqConfig.maxIntensity
          ? lqConfig.maxIntensity
          : prevState + lqConfig.step
      );
    setRespReceived(false);
  }

  function decreaseIntensity() {
    setSaveDisabled(false);
    respReceived &&
      setCurrentIntensity((prevState) =>
        prevState - lqConfig.step <= lqConfig.minIntensity
          ? lqConfig.minIntensity
          : prevState - lqConfig.step
      );
    setRespReceived(false);
  }

  function changeFreq() {
    var channelToChange = freqAndChannel.currentChannel;
    var freqToChange;
    var emptyFreq = getEmptyFreq(louderQuieter, freqAndChannel.currentChannel);
    if (
      emptyFreq === "end" &&
      freqAndChannel.currentChannel === "HEAD_PHONES_RIGHT"
    ) {
      channelToChange = "HEAD_PHONES_LEFT";
      freqToChange = getEmptyFreq(louderQuieter, channelToChange);
      if (freqToChange === "end") {
        setFinish((prevState) => ({ ...prevState, finished: true }));
        return;
      }
    } else if (emptyFreq !== "end") {
      freqToChange = emptyFreq;
    } else {
      freqToChange = emptyFreq;
      setFinish((prevState) => ({ ...prevState, finished: true }));
      return;
    }
    setSaveDisabled(true);
    setFreqAndChannel({
      currentFreq: freqToChange,
      currentChannel: channelToChange,
    });
  }

  async function save(maxExceeded = false) {
    setRespReceived(false);

    if (freqAndChannel.currentChannel === "HEAD_PHONES_RIGHT") {
      setLouderQuieter((prevState) => {
        const frequenciesResultRight = prevState.frequenciesResultRight.map(
          (result) =>
            result.frequency === freqAndChannel.currentFreq
              ? {
                  ...result,
                  answer: maxExceeded ? Answer.MAXEXCEEDED : currentIntensity,
                }
              : { ...result }
        );
        const resultRight = Test.checkThresholdsResultsPerOneEar(
          frequenciesResultRight
        );
        const resultLeft = Test.checkThresholdsResultsPerOneEar(
          prevState.frequenciesResultLeft
        );
        const result = Test.determineOverallResult(resultRight, resultLeft);
        return {
          ...prevState,
          frequenciesResultRight: frequenciesResultRight,
          result,
          resultRight,
          resultLeft,
        };
      });
    } else {
      setLouderQuieter((prevState) => {
        const frequenciesResultLeft = prevState.frequenciesResultLeft.map(
          (result) =>
            result.frequency === freqAndChannel.currentFreq
              ? {
                  ...result,
                  answer: maxExceeded ? Answer.MAXEXCEEDED : currentIntensity,
                }
              : { ...result }
        );
        const resultRight = Test.checkThresholdsResultsPerOneEar(
          prevState.frequenciesResultRight
        );
        const resultLeft = Test.checkThresholdsResultsPerOneEar(
          frequenciesResultLeft
        );
        const result = Test.determineOverallResult(resultRight, resultLeft);
        return {
          ...prevState,
          frequenciesResultLeft: frequenciesResultLeft,
          result,
          resultRight,
          resultLeft,
        };
      });
    }
  }

  const handleError = (errorCode) => {
    setError(errorCode);
    !finish.finished &&
      setFinish((prevState) => ({ ...prevState, finished: true }));
  };

  return (
    <Box sx={{ pt: 4, height: "100%", boxSizing: "border-box" }}>
      {phase !== "description" && (
        <CurrentStateInfo
          firstLine={`${texts.examination}:`}
          secondLine={testNames["GC"]}
        />
      )}
      {!finish.finished && (
        <FinishButton
          variant="contained"
          onClick={() => setDisplayFinishTestConfirmation(true)}
        >
          {texts.finishTest}
        </FinishButton>
      )}
      <br />
      {displayFinishTestConfirmation && (
        <ConfirmationDialog
          question={texts.endTestConfirm}
          yesAction={() => {
            setFinish({ cancelled: true, finished: true });
            setDisplayFinishTestConfirmation(false);
          }}
          noAction={() => {
            setDisplayFinishTestConfirmation(false);
          }}
          maxWidth="lg"
        />
      )}
      {!finish.finished && phase === "test" && (
        <Box sx={{ height: "70%" }}>
          <LQButtonLayout
            channel={freqAndChannel.currentChannel}
            onLouderClick={() => increaseIntensity()}
            onQuieterClick={() => decreaseIntensity()}
            louderDisabled={
              !respReceived || currentIntensity === lqConfig.maxIntensity
            }
            quieterDisabled={
              !respReceived || currentIntensity === lqConfig.minIntensity
            }
            onSaveClick={() => save()}
            saveDisabled={!respReceived || saveDisabled}
            displayNoSoundButton={displayNoSoundButton}
            dontHearClick={() => save(displayNoSoundButton)}
            dontHearDisabled={!respReceived}
          />
          <ProgressSlider
            title={texts.testProgress}
            currentValue={
              freqAndChannel.currentChannel === "HEAD_PHONES_RIGHT"
                ? frequenciesRight.findIndex(
                    (freq) => freq === freqAndChannel.currentFreq
                  ) + 1
                : frequenciesLeft.findIndex(
                    (freq) => freq === freqAndChannel.currentFreq
                  ) +
                  1 +
                  frequenciesRight.length
            }
            max={frequenciesRight.length + frequenciesLeft.length}
          />
        </Box>
      )}
      {phase === "description" && (
        <TestDescription
          handleNext={() => setPhase("instruction")}
          testType="GC"
          marginLeft={20}
          instructionSound={wavFiles[testsWavFiles.GC.instructionsSounds[0]]}
          handleError={handleError}
        />
      )}
      {phase === "instruction" && (
        <Instruction
          startTest={() => setPhase("test")}
          handleError={handleError}
        />
      )}
    </Box>
  );
};

export default LouderQuieter;
