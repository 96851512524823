import { useState } from "react";
import { Box, Typography, TextField, Stack, Button } from "@mui/material";
import { useApi } from "../Api";
import { useEffect } from "react";
import { texts } from "../resources/texts";
import bg from "../resources/images/bg-login2.svg";
import { ReactComponent as Square } from "../resources/images/square.svg";
import Keyboard from "../components/Keyboard";

const LoginPage = ({ onLogin, onError }) => {
  const [id, setId] = useState("");
  const [pin, setPin] = useState("");
  const [codeValid, setCodeValid] = useState();
  const [alertDisplay, setAlertDisplay] = useState(false);
  const [keyboardOpen, setKeyboardOpen] = useState(false);
  const api = useApi();

  const fillIdAndPinFromQR = (code) => {
    try {
      const jsCode = JSON.parse(code);
      if (!jsCode || !jsCode.id || !jsCode.pin) {
        throw new Error("Invalid code");
      }

      setId(jsCode.id);
      setPin(jsCode.pin);
      setCodeValid(true);
    } catch {
      setCodeValid(false);
    }
  };

  const findJson = (input) => {
    if (input.includes("{") && input.includes("}")) {
      const foundedJson = input.slice(input.search("{"), input.search("}") + 1);
      fillIdAndPinFromQR(foundedJson);
      return true;
    } else {
      return false;
    }
  };

  const login = () => {
    api
      .login({
        id,
        pin,
      })
      .then((user) => {
        if (user) {
          onLogin(user);
        } else {
          setAlertDisplay(true);
        }
      })
      .catch((error) => {
        onError(error);
      });
  };

  useEffect(() => {
    document.body.style.backgroundImage = `url(${bg})`;
    const login = document.getElementById("login");
    const pin = document.getElementById("pin");

    const loginBlur = (e) => {
      if (pin !== e.relatedTarget) {
        login.focus();
      }
    };

    const pinBlur = (e) => {
      if (login !== e.relatedTarget) {
        pin.focus();
      }
    };

    login.addEventListener("blur", loginBlur);
    pin.addEventListener("blur", pinBlur);

    return () => {
      login.removeEventListener("blur", loginBlur);
      pin.removeEventListener("blur", pinBlur);
    };
  }, []);

  useEffect(() => {
    id.length === 6 && pin.length === 4 && login();
    if (
      (id.length > 6 || pin.length > 4) &&
      !id.startsWith("{") &&
      !pin.startsWith("{")
    ) {
      setAlertDisplay(true);
    }
  }, [id, pin]);

  const handleKeyClick = (key) => {
    const login = document.getElementById("login");
    const pin = document.getElementById("pin");
    if (login === document.activeElement) {
      setId((prevState) => `${prevState}${key}`);
    } else if (pin === document.activeElement) {
      setPin((prevState) => `${prevState}${key}`);
    }
  };

  const handleCancelClick = () => {
    const login = document.getElementById("login");
    const pin = document.getElementById("pin");
    if (login === document.activeElement) {
      setId((prevState) => prevState.slice(0, -1));
    } else if (pin === document.activeElement) {
      setPin((prevState) => prevState.slice(0, -1));
    }
  };

  return (
    <Box
      sx={{
        pt: 28,
        display: "flex",
        flexDirection: "row",
      }}
    >
      <Box
        sx={{
          ml: "400px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Stack direction="row" spacing={4} alignItems="center" sx={{ mb: 12 }}>
          <Square />
          <Typography variant="title">{texts.loginTitle}</Typography>
          <Square />
        </Stack>
        <Typography
          variant="title"
          sx={{
            fontSize: 40,
            color: (theme) => theme.palette.text.secondary,
          }}
        >
          {texts.scanQr}
        </Typography>
      </Box>
      <Box
        sx={{
          ml: "340px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Stack direction="row" spacing={4} alignItems="center" sx={{ mb: 12 }}>
          <Square />
          <Typography variant="title">{texts.loginTitle}</Typography>
          <Square />
        </Stack>
        <Typography
          variant="title"
          sx={{
            fontSize: 40,
            color: (theme) => theme.palette.text.secondary,
            mb: 4,
          }}
        >
          {texts.typeManually}
        </Typography>
        <Box component="form">
          <Stack alignItems="center">
            <TextField
              id="login"
              autoFocus
              variant="standard"
              margin="normal"
              required
              label={texts.id}
              value={id}
              onClick={() => {
                setKeyboardOpen(true);
                const login = document.getElementById("login");
                login.setSelectionRange(id.length, id.length);
              }}
              onChange={(e) => {
                if (!findJson(e.target.value)) {
                  setId(e.target.value);
                }
              }}
              inputProps={{ style: { fontSize: 20, color: "#393939" } }}
              sx={{
                width: "390px",
              }}
            />
            <TextField
              id="pin"
              variant="standard"
              margin="normal"
              required
              label={texts.pin}
              type="password"
              value={pin}
              onClick={() => {
                setKeyboardOpen(true);
                const pinField = document.getElementById("pin");
                pinField.setSelectionRange(pin.length, pin.length);
              }}
              onChange={(e) => {
                if (!findJson(e.target.value)) {
                  setPin(e.target.value);
                }
              }}
              autoComplete="one-time-code"
              inputProps={{ style: { fontSize: 20, color: "#393939" } }}
              sx={{ width: "390px" }}
            />
          </Stack>
        </Box>
      </Box>
      {keyboardOpen && (
        <Keyboard
          onKeyClick={handleKeyClick}
          onCloseClick={() => setKeyboardOpen(false)}
          onCancelClick={handleCancelClick}
        />
      )}
      {(codeValid === false || alertDisplay) && (
        <Box>
          <Stack
            alignItems="center"
            justifyContent="center"
            spacing={4}
            direction="column"
            sx={{
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              borderRadius: "10px",
              p: 8,
              bgcolor: "rgba(255,255,255,0.95)",
            }}
          >
            <Typography variant="h4" sx={{ fontWeight: 600 }}>
              {!(id.length > 6 || pin.length > 4) && texts.incorrectLogin}
            </Typography>
            <Typography variant="h4" sx={{ maxWidth: "515px" }}>
              {id.length > 6 || pin.length > 4
                ? texts.credentialsToLong
                : texts.codeInvalid}
            </Typography>
            <Button
              variant="contained"
              onClick={() => {
                setPin("");
                setId("");
                setAlertDisplay(false);
                setCodeValid();
                document.getElementById("login").focus();
              }}
              sx={{ px: 4 }}
            >
              {texts.ok}
            </Button>
          </Stack>
        </Box>
      )}
    </Box>
  );
};

export default LoginPage;
