import { useState } from "react";
import { IconButton } from "@mui/material";
import hearRight from "../../resources/images/hear-right.svg";
import hearLeft from "../../resources/images/hear-left.svg";
import "./index.css";
import { texts } from "../../resources/texts";

const HearButton = ({ handleClick, ear, active }) => {
  const [clicked, setClicked] = useState("");
  return (
    <IconButton
      className={"hearbutton " + clicked}
      disableRipple
      disabled={!active}
      onPointerDown={() => {
        handleClick && handleClick();
        setClicked("clicked");
        setTimeout(() => {
          setClicked("");
        }, 500);
      }}
    >
      <img
        src={ear === "HEAD_PHONES_RIGHT" ? hearRight : hearLeft}
        style={{ filter: active ? "none" : "grayscale(100%)" }}
      />
      <span
        style={{
          position: "absolute",
          fontFamily: "Montserrat",
          fontSize: 36,
          fontWeight: 600,
          color: !active
            ? "grey"
            : ear === "HEAD_PHONES_RIGHT"
            ? "#CF1616"
            : "#0083CE",
        }}
      >
        {texts.hear}
      </span>
    </IconButton>
  );
};
export default HearButton;
