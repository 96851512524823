import { Modal, Box, Typography } from "@mui/material";
import { texts } from "../resources/texts";

const RepeatSoundInfoModal = () => {
  return (
    <Modal open={true} onClose={() => {}}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 600,
          bgcolor: "background.paper",
          border: "2px solid #000",
          boxShadow: 24,
          p: 4,
        }}
      >
        <Typography variant="h5">{texts.repeatSoundInfo}</Typography>
      </Box>
    </Modal>
  );
};

export default RepeatSoundInfoModal;
