import { useState } from "react";
import { IconButton, Typography } from "@mui/material";
import quieterLouderRight from "../../resources/images/quieter-louder-right.svg";
import quieterLouderLeft from "../../resources/images/quieter-louder-left.svg";
import quieterLouderInactive from "../../resources/images/quieter-louder-inactive.svg";
import quieterRim from "../../resources/images/quieter-louder-rim.svg";

const LouderQuieterButton = ({ active, onClick, text, rightEar }) => {
  const [clicked, setClicked] = useState("");
  return (
    <IconButton
      disableRipple
      disabled={!active}
      onPointerDown={() => {
        onClick && onClick();
        setClicked("clicked");
        setTimeout(() => {
          setClicked("");
        }, 500);
      }}
    >
      {active ? (
        rightEar ? (
          <img src={quieterLouderRight} />
        ) : (
          <img src={quieterLouderLeft} />
        )
      ) : (
        <img src={quieterLouderInactive} />
      )}
      <img src={quieterRim} className={"quieter-louder " + clicked} />

      <Typography
        sx={{
          position: "absolute",
          fontFamily: "Montserrat",
          fontSize: 22,
          fontWeight: 500,
          color: active ? "white" : (theme) => theme.palette.text.disabled,
        }}
      >
        {text}
      </Typography>
    </IconButton>
  );
};

export default LouderQuieterButton;
