import { IconButton } from "@mui/material";
import { useEffect, useState } from "react";
import owl from "../../resources/images/owl.svg";
import owl2 from "../../resources/images/owl2.svg";
import rim from "../../resources/images/kids-animal-rim.svg";
import rimbtn from "../../resources/images/kids-animal-rim-btn.svg";
import hippo from "../../resources/images/hippo.svg";
import hippo2 from "../../resources/images/hippo2.svg";
import dog from "../../resources/images/dog.svg";
import dog2 from "../../resources/images/dog2.svg";
import bird from "../../resources/images/bird.svg";
import bird2 from "../../resources/images/bird2.svg";
import cat from "../../resources/images/cat.svg";
import cat2 from "../../resources/images/cat2.svg";
import fox from "../../resources/images/fox.svg";
import fox2 from "../../resources/images/fox2.svg";
import blank from "../../resources/images/min/blank.png";

const freqToAnimal = (freq, animalOpenEyes) => {
  switch (freq) {
    case 500:
      return animalOpenEyes ? owl : owl2;

    case 250:
      return animalOpenEyes ? hippo : hippo2;

    case 2000:
      return animalOpenEyes ? dog : dog2;

    case 8000:
      return animalOpenEyes ? bird : bird2;

    case 1000:
      return animalOpenEyes ? cat : cat2;

    case 4000:
      return animalOpenEyes ? fox : fox2;

    default:
      return blank;
  }
};

export const Animal = ({ animate, active, clickAnswer, frequency }) => {
  const [animalOpenEyes, setAnimalOpenEyes] = useState(true);

  useEffect(() => {
    const timer = setTimeout(
      () => {
        animate && setAnimalOpenEyes((prevState) => !prevState);
        !animate && setAnimalOpenEyes(true);
      },
      animalOpenEyes ? 600 : 400
    );
    return () => clearTimeout(timer);
  }, [animate, animalOpenEyes]);

  return (
    <IconButton
      disableRipple
      disabled={!active}
      onClick={clickAnswer}
      sx={{ position: "relative" }}
    >
      <img
        src={active ? rimbtn : rim}
        alt=""
        style={{ position: "absolute", zIndex: 1 }}
      />
      <img
        src={freqToAnimal(frequency, animalOpenEyes)}
        alt=""
        style={{ position: "relative", zIndex: 2 }}
      />
    </IconButton>
  );
};
