import { useEffect, useState } from "react";
import { Box } from "@mui/material";
import AudiometryKidsSingle from "./AudiometryKidsSingle";
import ProgressSlider from "../components/ProgressSlider";
import { texts } from "../resources/texts";

const startingAttemptPair = [1000, 8000];

//audiometria przesiewowa dla dzieci
const AudiometryKids = ({
  audiometry,
  currentChannel,
  changeCurrentChannel,
  updateAudiometry,
  freqPairSequence,
  finished,
  changeFinished,
  stopped,
  setReadyToFinish,
  onError,
}) => {
  const [currentIndex, setCurrentIndex] = useState(-1);

  useEffect(() => {
    if (audiometry?.audiometryDetailsListRight.length > 0) {
      if (finished) {
        setReadyToFinish(true);
      }
      if (currentIndex === freqPairSequence.length - 1) {
        if (currentChannel === "HEAD_PHONES_RIGHT") {
          changeCurrentChannel("HEAD_PHONES_LEFT");
          setCurrentIndex(-1);
        } else {
          changeFinished(true);
          setReadyToFinish(true);
        }
      } else {
        if (audiometry.incorrectStartingAttempt) {
          //jeśli próba startowa dla prawego ucha wyszła nieprawidłowo i przeszliśmy do lewego
          setCurrentIndex(-1);
        } else {
          setCurrentIndex((prevState) => prevState + 1);
        }
      }
    }
  }, [audiometry]);

  return (
    <Box sx={{ height: "82%" }}>
      {!finished && audiometry && (
        <Box
        //sx={{ height: "100%" }}
        >
          <Box sx={{ height: "100%" }}>
            <AudiometryKidsSingle
              channel={currentChannel}
              freqsPair={
                currentIndex !== -1
                  ? freqPairSequence[currentIndex]
                  : startingAttemptPair
              }
              updateAudiometry={updateAudiometry}
              stopped={stopped}
              startingAttempt={currentIndex === -1}
              onError={onError}
            />
          </Box>

          <ProgressSlider
            title={texts.testProgress}
            currentValue={
              currentChannel === "HEAD_PHONES_RIGHT"
                ? currentIndex + 1
                : currentIndex + 1 + freqPairSequence.length
            }
            max={freqPairSequence.length * 2}
            sx={{ display: "inline" }}
            snail
          />
        </Box>
      )}
    </Box>
  );
};

export default AudiometryKids;
