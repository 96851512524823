import { Typography, Grid } from "@mui/material";
import { ReactComponent as LoaderIcon } from "../../resources/images/loader.svg";
import "./index.css";

const Loader = ({ text }) => {
  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      spacing={2}
      direction="column"
      sx={{
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      }}
    >
      <Grid item>
        <Typography
          variant="h1"
          sx={{
            fontSize: 40,
            fontWeight: (theme) => theme.typography.fontWeightMedium,
            letterSpacing: 1,
          }}
        >
          {text}
        </Typography>
      </Grid>
      <Grid item>
        <LoaderIcon className={"rotate"} />
      </Grid>
    </Grid>
  );
};

export default Loader;
