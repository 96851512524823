import { Box } from "@mui/material";
import { ReactComponent as WomanHeadphones } from "../../resources/images/woman-headphones.svg";
import circles from "../../resources/images/circles.svg";
import "./index.css";

const WomanHeadphonesRightLeftAnimation = () => {
  return (
    <Box
      sx={{
        display: "inline",
        position: "relative",
        top: "-40px",
      }}
    >
      <WomanHeadphones />
      <img className="circles-pulse-translate" src={circles} />
    </Box>
  );
};

export default WomanHeadphonesRightLeftAnimation;
