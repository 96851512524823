import { useEffect, useState } from "react";
import { Box, Button, Typography, Stack } from "@mui/material";
import ThresholdAudiometry from "./ThresholdAudiometry";
import ConfirmationDialog from "../components/ConfirmationDialog";
import { lqConfig } from "../resources/tests_config/louderQuieterConfig";
import {
  determineEarlyFinishedEnum,
  determineFailureReason,
  wasTestInterrupted,
} from "../resources/exportedFunctions";
import moment from "moment";
import { Answer, FailureReason, Result } from "./enums";
import { testNames, texts } from "../resources/texts";
import { FinishButton } from "../components/FinishButton";
import { LamaKidsDescription } from "../components/animations/SheepHeader";
import CurrentStateInfo from "../components/CurrentStateInfo";
import bg from "../resources/images/kids-thresholdbg.svg";
import bgKidsDesc from "../resources/images/bg-kids-description.svg";
import bravo from "../resources/images/bravo.svg";
import TestDescription from "../pages/TestDescription";
import SheepHeader from "../components/animations/SheepHeader";
import { useApi } from "../Api";
import { testsWavFiles } from "../resources/testsWavFiles";
import { wavFiles } from "../resources/wavFiles";
import { isErrorFromAudiometr } from "../resources/exportedFunctions";

const Bravo = () => {
  return (
    <Box sx={{ position: "relative" }}>
      <img src={bravo} alt="" />
      <Typography
        sx={{
          color: "white",
          fontSize: 35,
          fontWeight: 700,
          position: "absolute",
          display: "inline",
          top: 95,
          left: 105,
          transform: "rotate(5deg)",
        }}
      >
        {texts.bravo}
      </Typography>
    </Box>
  );
};

const Instruction = ({ handleNext, handleError }) => {
  const api = useApi();
  const [instructionFinished, setInstructionFinished] = useState(false);

  useEffect(() => {
    document.body.style.backgroundImage = `url(${bgKidsDesc})`;
    const controller = new AbortController();
    const signal = controller.signal;

    api
      .playInstruction(
        [wavFiles[testsWavFiles.GC_KIDS.instructionsSounds[1]]],
        signal
      )
      .then(() => {
        setInstructionFinished(true);
      })
      .catch((e) => {
        !signal.aborted && handleError(e.message);
      });

    return () => controller.abort();
  }, []);
  return (
    <Box>
      <CurrentStateInfo
        firstLine={`${texts.examination}:`}
        secondLine={testNames["GC"]}
      />
      <Stack
        alignItems="center"
        sx={{
          pt: 12,
          ml: 7,
          width: 1200,
        }}
      >
        <Typography
          variant="h4"
          sx={{
            maxWidth: "1000px",
            lineHeight: 1.5,
            mb: 6,
            whiteSpace: "pre-line",
            fontSize: 35,
          }}
        >
          {texts.audiometry.kidsThresholdInstruction}
        </Typography>
        <Typography
          variant="h4"
          sx={{ maxWidth: "1000px", lineHeight: 1.5, mb: 6, fontSize: 35 }}
        >
          {texts.audiometry.readyClickStart}
        </Typography>
        <Button
          variant="contained"
          size="large"
          disabled={!instructionFinished}
          sx={{ px: 10 }}
          onClick={() => {
            handleNext && handleNext();
          }}
        >
          {texts.start}
        </Button>
      </Stack>
      <Box sx={{ position: "fixed", left: "1200px", top: "505px" }}>
        <LamaKidsDescription />
      </Box>
      <SheepHeader />
    </Box>
  );
};

const ThresholdAudiometryManager = ({
  onEvent,
  automaticLogout,
  frequenciesRight = lqConfig.frequencies,
  frequenciesLeft = lqConfig.frequencies,
  startDate,
  parentError,
}) => {
  const api = useApi();
  const [audiometry, setAudiometry] = useState();
  const [finish, setFinish] = useState({ finished: false, cancelled: false });

  const [phase, setPhase] = useState("description"); // mozliwe: description, instruction, test
  const [displayFinishTestConfirmation, setDisplayFinishTestConfirmation] =
    useState(false);
  const [error, setError] = useState();

  useEffect(() => {
    !error && parentError && handleError(parentError);
  }, [parentError]);

  useEffect(() => {
    const createAudiometry = async () => {
      var frequenciesResultRight = [];
      frequenciesRight.forEach((freq) =>
        frequenciesResultRight.push({ frequency: freq, answer: "" })
      );

      var frequenciesResultLeft = [];
      frequenciesLeft.forEach((freq) =>
        frequenciesResultLeft.push({ frequency: freq, answer: "" })
      );

      setAudiometry({
        frequenciesResultRight,
        frequenciesResultLeft,
        testEnum: "GC",
        forKids: true,
        maxIntensity: lqConfig.maxIntensity,
        minIntensity: lqConfig.minIntensity,
        resultRight: null,
        resultLeft: null,
        result: null,
        audiometryDetailsListRight: [],
        audiometryDetailsListLeft: [],
        earlyFinishedEnum: null,
        testFrequencies: lqConfig.frequencies,
        name: testNames["GC"],
      });
    };
    phase === "instruction" && createAudiometry();
    if (phase === "test") {
      document.body.style.backgroundImage = `url(${bg})`;
    }
  }, [phase]);

  useEffect(() => {
    if (audiometry) {
      if (checkIfAudiometryIsCompleted(audiometry)) {
        setFinish((prevState) => ({ ...prevState, finished: true }));
      }
    }
  }, [audiometry]);

  useEffect(() => {
    if (automaticLogout === true) {
      !finish.finished
        ? earlyFinish(FailureReason.INACTIVITY_LOGOUT)
        : onEvent &&
          onEvent("FINISHED", {
            result: Result.AMB,
            testType: "GC",
            failureReason: FailureReason.INACTIVITY_LOGOUT,
            error: error,
            data: {
              ...audiometry,
              earlyFinishedEnum: FailureReason.INACTIVITY_LOGOUT,
            },
            startDate: startDate,
            endDate: moment().toISOString(),
          });
    }
  }, [automaticLogout]);

  useEffect(() => {
    if (finish.finished) {
      if (audiometry) {
        var failureReason = determineFailureReason(
          automaticLogout,
          finish.cancelled,
          error,
          audiometry.result
        );
        var earlyFinishedEnum = determineEarlyFinishedEnum(
          automaticLogout,
          finish.cancelled,
          error
        );
        //jeśli powodem wcześniejszego zakończenia badania nie był błąd lub jeśli błąd nie pochodził od audiometru
        if (
          earlyFinishedEnum &&
          (!error || (parentError && !isErrorFromAudiometr(parentError)))
        ) {
          api.stopPlaying().catch((e) => console.error(e));
        }
        onEvent &&
          onEvent("FINISHED", {
            result:
              audiometry.result === Result.FANC ||
              audiometry.result === Result.FP ||
              audiometry.result === null ||
              wasTestInterrupted(failureReason)
                ? Result.AMB
                : audiometry.result,
            testType: "GC",
            failureReason,
            error: error,
            data: {
              ...audiometry,
              earlyFinishedEnum,
            },
            startDate: startDate,
            endDate: moment().toISOString(),
          });
      } else {
        //jeśli powodem wcześniejszego zakończenia badania nie był błąd lub jeśli błąd nie pochodził od audiometru
        if (!error || (parentError && !isErrorFromAudiometr(parentError))) {
          api.stopPlaying().catch((e) => console.error(e));
        }
        onEvent &&
          onEvent("FINISHED", {
            result: Result.AMB,
            failureReason:
              automaticLogout === true
                ? FailureReason.INACTIVITY_LOGOUT
                : error !== undefined
                ? FailureReason.ERROR
                : FailureReason.CANCELLED,
            testType: "GC",
            error: error,
            startDate: startDate,
            endDate: moment().toISOString(),
          });
      }
    }
  }, [finish]);

  function checkIfAudiometryIsCompleted(aud) {
    if (countFANCResults(aud) > lqConfig.maxNumberOfFANCResult) {
      return true;
    }

    const rightCompleted = !aud.frequenciesResultRight.some(
      ({ answer }) => answer === ""
    );
    const leftCompleted = !aud.frequenciesResultLeft.some(
      ({ answer }) => answer === ""
    );
    return rightCompleted && leftCompleted;
  }

  function countFANCResults(aud) {
    var fancResultCount = 0;
    aud.frequenciesResultRight.forEach(
      ({ answer }) => answer === Answer.FANC && fancResultCount++
    );
    aud.frequenciesResultLeft.forEach(
      ({ answer }) => answer === Answer.FANC && fancResultCount++
    );

    return fancResultCount;
  }

  function earlyFinish(reason) {
    if (!finish.finished) {
      setFinish({
        finished: true,
        cancelled: reason === FailureReason.CANCELLED ? true : false,
      });
    }
  }

  const handleError = (error) => {
    setError(error);
    earlyFinish(FailureReason.ERROR);
  };

  return (
    <Box sx={{ height: "100%", pt: 4, boxSizing: "border-box" }}>
      {!finish.finished && (
        <FinishButton
          variant="contained"
          onClick={() => setDisplayFinishTestConfirmation(true)}
        >
          {texts.finishTest}
        </FinishButton>
      )}
      <br />
      {displayFinishTestConfirmation && (
        <ConfirmationDialog
          question={texts.endTestConfirm}
          yesAction={() => {
            earlyFinish(FailureReason.CANCELLED);
            setDisplayFinishTestConfirmation(false);
          }}
          noAction={() => {
            setDisplayFinishTestConfirmation(false);
          }}
          maxWidth="lg"
        />
      )}
      {phase === "test" && audiometry && !finish.finished && (
        <ThresholdAudiometry
          audiometryProp={audiometry}
          displayFinishTestConfirmation={displayFinishTestConfirmation}
          updateAudiometryGlobal={setAudiometry}
          onError={handleError}
        />
      )}

      {phase === "description" && (
        <TestDescription
          handleNext={() => setPhase("instruction")}
          testType="GC"
          forKids={true}
          marginLeft={18}
          handleError={handleError}
          instructionSound={
            wavFiles[testsWavFiles.GC_KIDS.instructionsSounds[0]]
          }
        />
      )}

      {phase === "instruction" && (
        <Instruction
          handleNext={() => setPhase("test")}
          handleError={handleError}
        />
      )}
      {finish.finished && (
        <Box sx={{ position: "fixed", top: "360px", left: "850px" }}>
          <Bravo />
        </Box>
      )}
    </Box>
  );
};

export default ThresholdAudiometryManager;
