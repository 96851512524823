export const INSTRUCTIONS_INTENSITY = 50;
export const MIN_TEST_DURATION = 3; //w minutach
export const MAX_TEST_DURATION = 7; //w minutach
export const AGE_FROM_WHICH_ADULT_VERSIONS_START = 10;
export const WITH_COVER = false;

const answerHigh = { text: "high", image: "high.svg" };
const answerLow = { text: "low", image: "low.svg" };

const answerGap = { text: "gap", image: "gap.svg" };
const answerNoGap = { text: "noGap", image: "no-gap.svg" };

const answer1 = { text: "1", image: "" };
const answer2 = { text: "2", image: "" };
const answer3 = { text: "3", image: "" };
const answer4 = { text: "4", image: "" };
const answer5 = { text: "5", image: "" };
const answer6 = { text: "6", image: "" };
const answer7 = { text: "7", image: "" };
const answer8 = { text: "8", image: "" };
const answer9 = { text: "9", image: "" };
const answer10 = { text: "10", image: "" };
export const trcPossibleAnswers = [
  answer1,
  answer2,
  answer3,
  answer4,
  answer5,
  answer6,
  answer7,
  answer8,
  answer9,
  answer10,
];

const answerShort = { text: "short", image: "short.svg" };
const answerLong = { text: "long", image: "long.svg" };

const answerLowLow = { text: "low-low", image: "" };
const answerHighHigh = { text: "high-high", image: "" };
const answerLowHigh = { text: "low-high", image: "" };
const answerHighLow = { text: "high-low", image: "" };

const but = { text: "but", image: "shoe.jpg" };
const byk = { text: "byk", image: "bull.jpg" };
const chleb = { text: "chleb", image: "bread.jpg" };
const czolg = { text: "czołg", image: "tank.jpg" };
const dach = { text: "dach", image: "roof.jpg" };
const dlon = { text: "dłoń", image: "hand.jpg" };
const dom = { text: "dom", image: "house.jpg" };
const duch = { text: "duch", image: "ghost.jpg" };
const dym = { text: "dym", image: "smoke.jpg" };
const dziob = { text: "dziób", image: "beak.jpg" };
const dzwig = { text: "dźwig", image: "crane.jpg" };
const ges = { text: "gęś", image: "goose.jpg" };
const grzyb = { text: "grzyb", image: "mushroom.jpg" };
const jez = { text: "jeż", image: "hedgehog.jpg" };
const klucz = { text: "klucz", image: "key.jpg" };
const kok = { text: "kok", image: "bun.jpg" };
const kon = { text: "koń", image: "horse.jpg" };
const kosc = { text: "kość", image: "bone.jpg" };
const kot = { text: "kot", image: "cat.jpg" };
const kret = { text: "kret", image: "mole.jpg" };
const kwiat = { text: "kwiat", image: "flower.jpg" };
const las = { text: "las", image: "forest.jpg" };
const lek = { text: "lek", image: "drug.jpg" };
const lew = { text: "lew", image: "lion.jpg" };
const lis = { text: "lis", image: "fox.jpg" };
const list = { text: "list", image: "letter.jpg" };
const lisc = { text: "liść", image: "leaf.jpg" };
const mak = { text: "mak", image: "poppy.jpg" };
const mis = { text: "miś", image: "bear.jpg" };
const mlecz = { text: "mlecz", image: "dandelion.jpg" };
const most = { text: "most", image: "bridge.jpg" };
const mur = { text: "mur", image: "wall.jpg" };
const mysz = { text: "mysz", image: "mouse.jpg" };
const noc = { text: "noc", image: "night.jpg" };
const nos = { text: "nos", image: "nose.jpg" };
const noz = { text: "nóż", image: "knife.jpg" };
const pan = { text: "pan", image: "mister.jpg" };
const pas = { text: "pas", image: "belt.jpg" };
const paw = { text: "paw", image: "peacock.jpg" };
const piec = { text: "piec", image: "furnace.jpg" };
const pies = { text: "pies", image: "dog.jpg" };
const piesc = { text: "pięść", image: "fist.jpg" };
const ptak = { text: "ptak", image: "bird.jpg" };
const rak = { text: "rak", image: "crayfish.jpg" };
const rys = { text: "ryś", image: "lynx.jpg" };
const sad = { text: "sad", image: "orchard.jpg" };
const ser = { text: "ser", image: "cheese.jpg" };
const slon = { text: "słoń", image: "elephant.jpg" };
const smak = { text: "smak", image: "taste.jpg" };
const sok = { text: "sok", image: "juice.jpg" };
const stol = { text: "stół", image: "table.jpg" };
const sum = { text: "sum", image: "catfish.jpg" };
const szal = { text: "szal", image: "scarf.jpg" };
const tor = { text: "tor", image: "track.jpg" };
const tort = { text: "tort", image: "cake.jpg" };
const was = { text: "wąs", image: "mustache.jpg" };
const waz = { text: "wąż", image: "snake.jpg" };
const wor = { text: "wór", image: "sack.jpg" };
const woz = { text: "wóz", image: "car.jpg" };
const smok = { text: "smok", image: "dragon.jpg" };
const zab = { text: "ząb", image: "tooth.jpg" };
const zez = { text: "zez", image: "squint.jpg" };
const zolw = { text: "żółw", image: "turtle.jpg" };
const zuk = { text: "żuk", image: "beetle.jpg" };

export const allWords = [
  but,
  byk,
  chleb,
  czolg,
  dach,
  dlon,
  dom,
  duch,
  dym,
  dziob,
  dzwig,
  ges,
  grzyb,
  jez,
  klucz,
  kok,
  kon,
  kosc,
  kot,
  kret,
  kwiat,
  las,
  lek,
  lew,
  lis,
  list,
  lisc,
  mak,
  mis,
  mlecz,
  most,
  mur,
  mysz,
  noc,
  nos,
  noz,
  pan,
  pas,
  paw,
  piec,
  pies,
  piesc,
  ptak,
  rak,
  rys,
  sad,
  ser,
  slon,
  smak,
  sok,
  stol,
  sum,
  szal,
  tor,
  tort,
  was,
  waz,
  wor,
  woz,
  smok,
  zab,
  zez,
  zolw,
  zuk,
];

export const wavFiles = {
  //TSW
  lowHighLow: {
    fileId: 1101,
    channel: "BOTH",
    metadata: {
      possibleAnswers: [answerLow, answerHigh],
      rightAnswers: [answerLow, answerHigh, answerLow],
    },
  },
  lowLowHigh: {
    fileId: 1100,
    channel: "BOTH",
    metadata: {
      possibleAnswers: [answerLow, answerHigh],
      rightAnswers: [answerLow, answerLow, answerHigh],
    },
  },
  lowHighHigh: {
    fileId: 1102,
    channel: "BOTH",
    metadata: {
      possibleAnswers: [answerLow, answerHigh],
      rightAnswers: [answerLow, answerHigh, answerHigh],
    },
  },
  highLowLow: {
    fileId: 1108,
    channel: "BOTH",
    metadata: {
      possibleAnswers: [answerLow, answerHigh],
      rightAnswers: [answerHigh, answerLow, answerLow],
    },
  },
  highHighLow: {
    fileId: 1111,
    channel: "BOTH",
    metadata: {
      possibleAnswers: [answerLow, answerHigh],
      rightAnswers: [answerHigh, answerHigh, answerLow],
    },
  },
  highLowHigh: {
    fileId: 1109,
    channel: "BOTH",
    metadata: {
      possibleAnswers: [answerLow, answerHigh],
      rightAnswers: [answerHigh, answerLow, answerHigh],
    },
  },

  gap: {
    fileId: 1079,
    channel: "BOTH",
    metadata: {
      possibleAnswers: [answerNoGap, answerGap],
      rightAnswers: [answerGap],
    },
  },
  noGap: {
    fileId: 1078,
    channel: "BOTH",
    metadata: {
      possibleAnswers: [answerNoGap, answerGap],
      rightAnswers: [answerNoGap],
    },
  },
  //TRC
  ans31054: [
    {
      fileId: 1195,
      channel: "RIGHT",
      metadata: {
        possibleAnswers: [],
        rightAnswers: [answer3, answer10],
      },
    },
    {
      fileId: 1196,
      channel: "LEFT",
      metadata: {
        possibleAnswers: [],
        rightAnswers: [answer5, answer4],
      },
    },
  ],
  ans61058: [
    {
      fileId: 1197,
      channel: "RIGHT",
      metadata: {
        possibleAnswers: [],
        rightAnswers: [answer6, answer10],
      },
    },
    {
      fileId: 1198,
      channel: "LEFT",
      metadata: {
        possibleAnswers: [],
        rightAnswers: [answer5, answer8],
      },
    },
  ],
  ans2176: [
    {
      fileId: 1199,
      channel: "RIGHT",
      metadata: {
        possibleAnswers: [],
        rightAnswers: [answer2, answer1],
      },
    },
    {
      fileId: 1205,
      channel: "LEFT",
      metadata: {
        possibleAnswers: [],
        rightAnswers: [answer7, answer6],
      },
    },
  ],
  ans2795: [
    {
      fileId: 1201,
      channel: "RIGHT",
      metadata: {
        possibleAnswers: [],
        rightAnswers: [answer2, answer7],
      },
    },
    {
      fileId: 1207,
      channel: "LEFT",
      metadata: {
        possibleAnswers: [],
        rightAnswers: [answer9, answer5],
      },
    },
  ],
  ans2473: [
    {
      fileId: 1202,
      channel: "RIGHT",
      metadata: {
        possibleAnswers: [],
        rightAnswers: [answer2, answer4],
      },
    },
    {
      fileId: 1206,
      channel: "LEFT",
      metadata: {
        possibleAnswers: [],
        rightAnswers: [answer7, answer3],
      },
    },
  ],
  ans2647: [
    {
      fileId: 1203,
      channel: "RIGHT",
      metadata: {
        possibleAnswers: [],
        rightAnswers: [answer2, answer6],
      },
    },
    {
      fileId: 1209,
      channel: "LEFT",
      metadata: {
        possibleAnswers: [],
        rightAnswers: [answer4, answer7],
      },
    },
  ],
  ans10246: [
    {
      fileId: 1204,
      channel: "RIGHT",
      metadata: {
        possibleAnswers: [],
        rightAnswers: [answer10, answer2],
      },
    },
    {
      fileId: 1208,
      channel: "LEFT",
      metadata: {
        possibleAnswers: [],
        rightAnswers: [answer4, answer6],
      },
    },
  ],
  ans10987: [
    {
      fileId: 1210,
      channel: "RIGHT",
      metadata: {
        possibleAnswers: [],
        rightAnswers: [answer10, answer9],
      },
    },
    {
      fileId: 1211,
      channel: "LEFT",
      metadata: {
        possibleAnswers: [],
        rightAnswers: [answer8, answer7],
      },
    },
  ],
  ans81210: [
    {
      fileId: 1212,
      channel: "RIGHT",
      metadata: {
        possibleAnswers: [],
        rightAnswers: [answer8, answer1],
      },
    },
    {
      fileId: 1215,
      channel: "LEFT",
      metadata: {
        possibleAnswers: [],
        rightAnswers: [answer2, answer10],
      },
    },
  ],
  ans9168: [
    {
      fileId: 1213,
      channel: "RIGHT",
      metadata: {
        possibleAnswers: [],
        rightAnswers: [answer9, answer1],
      },
    },
    {
      fileId: 1214,
      channel: "LEFT",
      metadata: {
        possibleAnswers: [],
        rightAnswers: [answer6, answer8],
      },
    },
  ],
  ans5739: [
    {
      fileId: 1216,
      channel: "RIGHT",
      metadata: {
        possibleAnswers: [],
        rightAnswers: [answer5, answer7],
      },
    },
    {
      fileId: 1218,
      channel: "LEFT",
      metadata: {
        possibleAnswers: [],
        rightAnswers: [answer3, answer9],
      },
    },
  ],
  ans3785: [
    {
      fileId: 1217,
      channel: "RIGHT",
      metadata: {
        possibleAnswers: [],
        rightAnswers: [answer3, answer7],
      },
    },
    {
      fileId: 1220,
      channel: "LEFT",
      metadata: {
        possibleAnswers: [],
        rightAnswers: [answer8, answer5],
      },
    },
  ],
  ans53106: [
    {
      fileId: 1219,
      channel: "RIGHT",
      metadata: {
        possibleAnswers: [],
        rightAnswers: [answer5, answer3],
      },
    },
    {
      fileId: 1221,
      channel: "LEFT",
      metadata: {
        possibleAnswers: [],
        rightAnswers: [answer10, answer6],
      },
    },
  ],
  ans10519: [
    {
      fileId: 1222,
      channel: "RIGHT",
      metadata: {
        possibleAnswers: [],
        rightAnswers: [answer10, answer5],
      },
    },
    {
      fileId: 1224,
      channel: "LEFT",
      metadata: {
        possibleAnswers: [],
        rightAnswers: [answer1, answer9],
      },
    },
  ],
  ans1679: [
    {
      fileId: 1223,
      channel: "RIGHT",
      metadata: {
        possibleAnswers: [],
        rightAnswers: [answer1, answer6],
      },
    },
    {
      fileId: 1226,
      channel: "LEFT",
      metadata: {
        possibleAnswers: [],
        rightAnswers: [answer7, answer9],
      },
    },
  ],
  // ans14: {
  //   fileId: 1225,
  //   channel: "LEFT",
  //   metadata: {
  //     possibleAnswers: [],
  //     rightAnswers: [answer1, answer4],
  //   },
  // },
  // ans110: {
  //   fileId: 1227,
  //   channel: "LEFT",
  //   metadata: {
  //     possibleAnswers: [],
  //     rightAnswers: [answer1, answer10],
  //   },
  // },
  //TSD
  shortLongShort: {
    fileId: 1095,
    channel: "BOTH",
    metadata: {
      possibleAnswers: [answerLong, answerShort],
      rightAnswers: [answerShort, answerLong, answerShort],
    },
  },
  shortShortLong: {
    fileId: 1096,
    channel: "BOTH",
    metadata: {
      possibleAnswers: [answerLong, answerShort],
      rightAnswers: [answerShort, answerShort, answerLong],
    },
  },
  shortLongLong: {
    fileId: 1094,
    channel: "BOTH",
    metadata: {
      possibleAnswers: [answerLong, answerShort],
      rightAnswers: [answerShort, answerLong, answerLong],
    },
  },
  longShortShort: {
    fileId: 1093,
    channel: "BOTH",
    metadata: {
      possibleAnswers: [answerLong, answerShort],
      rightAnswers: [answerLong, answerShort, answerShort],
    },
  },
  longLongShort: {
    fileId: 1091,
    channel: "BOTH",
    metadata: {
      possibleAnswers: [answerLong, answerShort],
      rightAnswers: [answerLong, answerLong, answerShort],
    },
  },
  longShortLong: {
    fileId: 1092,
    channel: "BOTH",
    metadata: {
      possibleAnswers: [answerLong, answerShort],
      rightAnswers: [answerLong, answerShort, answerLong],
    },
  },

  //TDW odpowiedzi beda konwertowane do "takie same" lub "rozne", ale musimy zapisac jakie byl bodziec, zeby wyswietlic lekarzowi
  lowLow: {
    fileId: 1066,
    channel: "BOTH",
    metadata: {
      possibleAnswers: [answerLowLow, answerLowHigh],
      rightAnswers: [answerLowLow],
    },
  },
  highLow: {
    fileId: 1071,
    channel: "BOTH",
    metadata: {
      possibleAnswers: [answerHighHigh, answerHighLow],
      rightAnswers: [answerHighLow],
    },
  },
  highHigh: {
    fileId: 1072,
    channel: "BOTH",
    metadata: {
      possibleAnswers: [answerHighHigh, answerHighLow],
      rightAnswers: [answerHighHigh],
    },
  },
  lowHigh: {
    fileId: 1067,
    channel: "BOTH",
    metadata: {
      possibleAnswers: [answerLowLow, answerLowHigh],
      rightAnswers: [answerLowHigh],
    },
  },

  //TRMS
  snrbut: {
    fileId: 1137,
    channel: "BOTH",
    metadata: {
      possibleAnswers: allWords,
      rightAnswers: [but],
    },
  },
  snrdlon: {
    fileId: 1139,
    channel: "BOTH",
    metadata: {
      possibleAnswers: allWords,
      rightAnswers: [dlon],
    },
  },
  snrgrzyb: {
    fileId: 1144,
    channel: "BOTH",
    metadata: {
      possibleAnswers: allWords,
      rightAnswers: [grzyb],
    },
  },
  snrkret: {
    fileId: 1149,
    channel: "BOTH",
    metadata: {
      possibleAnswers: allWords,
      rightAnswers: [kret],
    },
  },
  snrkwiat: {
    fileId: 1150,
    channel: "BOTH",
    metadata: {
      possibleAnswers: allWords,
      rightAnswers: [kwiat],
    },
  },
  snrlis: {
    fileId: 1154,
    channel: "BOTH",
    metadata: {
      possibleAnswers: allWords,
      rightAnswers: [lis],
    },
  },
  snrlisc: {
    fileId: 1157,
    channel: "BOTH",
    metadata: {
      possibleAnswers: allWords,
      rightAnswers: [lisc],
    },
  },
  snrmur: {
    fileId: 1159,
    channel: "BOTH",
    metadata: {
      possibleAnswers: allWords,
      rightAnswers: [mur],
    },
  },
  snrpas: {
    fileId: 1162,
    channel: "BOTH",
    metadata: {
      possibleAnswers: allWords,
      rightAnswers: [pas],
    },
  },
  snrpies: {
    fileId: 1165,
    channel: "BOTH",
    metadata: {
      possibleAnswers: allWords,
      rightAnswers: [pies],
    },
  },
  snrstol: {
    fileId: 1169,
    channel: "BOTH",
    metadata: {
      possibleAnswers: allWords,
      rightAnswers: [stol],
    },
  },
  snrwas: {
    fileId: 1176,
    channel: "BOTH",
    metadata: {
      possibleAnswers: allWords,
      rightAnswers: [was],
    },
  },
  snrsmok: {
    fileId: 1190,
    channel: "BOTH",
    metadata: {
      possibleAnswers: allWords,
      rightAnswers: [smok],
    },
  },

  //TMS
  compressedbut: {
    fileId: 1136,
    channel: "BOTH",
    metadata: {
      possibleAnswers: allWords,
      rightAnswers: [but],
    },
  },
  compresseddlon: {
    fileId: 1138,
    channel: "BOTH",
    metadata: {
      possibleAnswers: allWords,
      rightAnswers: [dlon],
    },
  },
  compresseddom: {
    fileId: 1140,
    channel: "BOTH",
    metadata: {
      possibleAnswers: allWords,
      rightAnswers: [dom],
    },
  },
  compressedgrzyb: {
    fileId: 1143,
    channel: "BOTH",
    metadata: {
      possibleAnswers: allWords,
      rightAnswers: [grzyb],
    },
  },
  compressedkosc: {
    fileId: 1146,
    channel: "BOTH",
    metadata: {
      possibleAnswers: allWords,
      rightAnswers: [kosc],
    },
  },
  compressedkret: {
    fileId: 1148,
    channel: "BOTH",
    metadata: {
      possibleAnswers: allWords,
      rightAnswers: [kret],
    },
  },
  compressedlis: {
    fileId: 1153,
    channel: "BOTH",
    metadata: {
      possibleAnswers: allWords,
      rightAnswers: [lis],
    },
  },
  compressedlisc: {
    fileId: 1156,
    channel: "BOTH",
    metadata: {
      possibleAnswers: allWords,
      rightAnswers: [lisc],
    },
  },
  compressedmost: {
    fileId: 1158,
    channel: "BOTH",
    metadata: {
      possibleAnswers: allWords,
      rightAnswers: [most],
    },
  },
  compressedpan: {
    fileId: 1161,
    channel: "BOTH",
    metadata: {
      possibleAnswers: allWords,
      rightAnswers: [pan],
    },
  },
  compressedpiec: {
    fileId: 1164,
    channel: "BOTH",
    metadata: {
      possibleAnswers: allWords,
      rightAnswers: [piec],
    },
  },
  compressedsok: {
    fileId: 1167,
    channel: "BOTH",
    metadata: {
      possibleAnswers: allWords,
      rightAnswers: [sok],
    },
  },
  compressedszal: {
    fileId: 1170,
    channel: "BOTH",
    metadata: {
      possibleAnswers: allWords,
      rightAnswers: [szal],
    },
  },
  compressedwas: {
    fileId: 1175,
    channel: "BOTH",
    metadata: {
      possibleAnswers: allWords,
      rightAnswers: [was],
    },
  },

  //TMF
  filteredbut: {
    fileId: 1134,
    channel: "BOTH",
    metadata: {
      possibleAnswers: allWords,
      rightAnswers: [but],
    },
  },
  filtereddym: {
    fileId: 1141,
    channel: "BOTH",
    metadata: {
      possibleAnswers: allWords,
      rightAnswers: [dym],
    },
  },
  filteredkosc: {
    fileId: 1145,
    channel: "BOTH",
    metadata: {
      possibleAnswers: allWords,
      rightAnswers: [kosc],
    },
  },
  filteredkret: {
    fileId: 1147,
    channel: "BOTH",
    metadata: {
      possibleAnswers: allWords,
      rightAnswers: [kret],
    },
  },
  filteredlas: {
    fileId: 1151,
    channel: "BOTH",
    metadata: {
      possibleAnswers: allWords,
      rightAnswers: [las],
    },
  },
  filteredlew: {
    fileId: 1152,
    channel: "BOTH",
    metadata: {
      possibleAnswers: allWords,
      rightAnswers: [lew],
    },
  },
  filteredpiec: {
    fileId: 1163,
    channel: "BOTH",
    metadata: {
      possibleAnswers: allWords,
      rightAnswers: [piec],
    },
  },
  filteredslon: {
    fileId: 1166,
    channel: "BOTH",
    metadata: {
      possibleAnswers: allWords,
      rightAnswers: [slon],
    },
  },
  filteredstol: {
    fileId: 1168,
    channel: "BOTH",
    metadata: {
      possibleAnswers: allWords,
      rightAnswers: [stol],
    },
  },
  filteredwas: {
    fileId: 1174,
    channel: "BOTH",
    metadata: {
      possibleAnswers: allWords,
      rightAnswers: [was],
    },
  },
  filteredzuk: {
    fileId: 1178,
    channel: "BOTH",
    metadata: {
      possibleAnswers: allWords,
      rightAnswers: [zuk],
    },
  },

  //TRS
  noswozduchmis: [
    {
      fileId: 1228,
      channel: "RIGHT",
      metadata: {
        possibleAnswers: allWords,
        rightAnswers: [nos, woz],
      },
    },
    {
      fileId: 1229,
      channel: "LEFT",
      metadata: {
        possibleAnswers: allWords,
        rightAnswers: [duch, mis],
      },
    },
  ],
  butkoklisnos: [
    {
      fileId: 1230,
      channel: "RIGHT",
      metadata: {
        possibleAnswers: allWords,
        rightAnswers: [but, kok],
      },
    },
    {
      fileId: 1231,
      channel: "LEFT",
      metadata: {
        possibleAnswers: allWords,
        rightAnswers: [lis, nos],
      },
    },
  ],
  sumwazkonpaw: [
    {
      fileId: 1232,
      channel: "RIGHT",
      metadata: {
        possibleAnswers: allWords,
        rightAnswers: [sum, waz],
      },
    },
    {
      fileId: 1233,
      channel: "LEFT",
      metadata: {
        possibleAnswers: allWords,
        rightAnswers: [kon, paw],
      },
    },
  ],
  lekpasdommak: [
    {
      fileId: 1234,
      channel: "RIGHT",
      metadata: {
        possibleAnswers: allWords,
        rightAnswers: [lek, pas],
      },
    },
    {
      fileId: 1236,
      channel: "LEFT",
      metadata: {
        possibleAnswers: allWords,
        rightAnswers: [dom, mak],
      },
    },
  ],
  // piesrys: {
  //   fileId: 1235,
  //   channel: "RIGHT",
  //   metadata: {
  //     possibleAnswers: allWords,
  //     rightAnswers: [pies, rys],
  //   },
  // },
  konsermakwor: [
    {
      fileId: 1237,
      channel: "RIGHT",
      metadata: {
        possibleAnswers: allWords,
        rightAnswers: [kon, ser],
      },
    },
    {
      fileId: 1238,
      channel: "LEFT",
      metadata: {
        possibleAnswers: allWords,
        rightAnswers: [mak, wor],
      },
    },
  ],
  myszdachnoclas: [
    {
      fileId: 1239,
      channel: "RIGHT",
      metadata: {
        possibleAnswers: allWords,
        rightAnswers: [mysz, dach],
      },
    },
    {
      fileId: 1240,
      channel: "LEFT",
      metadata: {
        possibleAnswers: allWords,
        rightAnswers: [noc, las],
      },
    },
  ],
  wassadpankot: [
    {
      fileId: 1241,
      channel: "RIGHT",
      metadata: {
        possibleAnswers: allWords,
        rightAnswers: [was, sad],
      },
    },
    {
      fileId: 1242,
      channel: "LEFT",
      metadata: {
        possibleAnswers: allWords,
        rightAnswers: [pan, kot],
      },
    },
  ],
  pawlewrakpiec: [
    {
      fileId: 1243,
      channel: "RIGHT",
      metadata: {
        possibleAnswers: allWords,
        rightAnswers: [paw, lew],
      },
    },
    {
      fileId: 1244,
      channel: "LEFT",
      metadata: {
        possibleAnswers: allWords,
        rightAnswers: [rak, piec],
      },
    },
  ],
  myszdymkotbyk: [
    {
      fileId: 1245,
      channel: "RIGHT",
      metadata: {
        possibleAnswers: allWords,
        rightAnswers: [mysz, dym],
      },
    },
    {
      fileId: 1246,
      channel: "LEFT",
      metadata: {
        possibleAnswers: allWords,
        rightAnswers: [kot, byk],
      },
    },
  ],
  sokkoknozwor: [
    {
      fileId: 1247,
      channel: "RIGHT",
      metadata: {
        possibleAnswers: allWords,
        rightAnswers: [sok, kok],
      },
    },
    {
      fileId: 1248,
      channel: "LEFT",
      metadata: {
        possibleAnswers: allWords,
        rightAnswers: [noz, wor],
      },
    },
  ],
  dachmyszlasnoc: [
    {
      fileId: 1249,
      channel: "RIGHT",
      metadata: {
        possibleAnswers: allWords,
        rightAnswers: [dach, mysz],
      },
    },
    {
      fileId: 1250,
      channel: "LEFT",
      metadata: {
        possibleAnswers: allWords,
        rightAnswers: [las, noc],
      },
    },
  ],
  sadwaskotpan: [
    {
      fileId: 1251,
      channel: "RIGHT",
      metadata: {
        possibleAnswers: allWords,
        rightAnswers: [sad, was],
      },
    },
    {
      fileId: 1252,
      channel: "LEFT",
      metadata: {
        possibleAnswers: allWords,
        rightAnswers: [kot, pan],
      },
    },
  ],
  lewpawpiecrak: [
    {
      fileId: 1253,
      channel: "RIGHT",
      metadata: {
        possibleAnswers: allWords,
        rightAnswers: [lew, paw],
      },
    },
    {
      fileId: 1254,
      channel: "LEFT",
      metadata: {
        possibleAnswers: allWords,
        rightAnswers: [piec, rak],
      },
    },
  ],
  dymmyszbykkot: [
    {
      fileId: 1255,
      channel: "RIGHT",
      metadata: {
        possibleAnswers: allWords,
        rightAnswers: [dym, mysz],
      },
    },
    {
      fileId: 1256,
      channel: "LEFT",
      metadata: {
        possibleAnswers: allWords,
        rightAnswers: [byk, kot],
      },
    },
  ],
  koksokrakmur: [
    {
      fileId: 1257,
      channel: "RIGHT",
      metadata: {
        possibleAnswers: allWords,
        rightAnswers: [kok, sok],
      },
    },
    {
      fileId: 1258,
      channel: "LEFT",
      metadata: {
        possibleAnswers: allWords,
        rightAnswers: [rak, mur],
      },
    },
  ],
  misduchnoslis: [
    {
      fileId: 1259,
      channel: "RIGHT",
      metadata: {
        possibleAnswers: allWords,
        rightAnswers: [mis, duch],
      },
    },
    {
      fileId: 1260,
      channel: "LEFT",
      metadata: {
        possibleAnswers: allWords,
        rightAnswers: [nos, lis],
      },
    },
  ],
  wazsumpawkon: [
    {
      fileId: 1261,
      channel: "RIGHT",
      metadata: {
        possibleAnswers: allWords,
        rightAnswers: [waz, sum],
      },
    },
    {
      fileId: 1262,
      channel: "LEFT",
      metadata: {
        possibleAnswers: allWords,
        rightAnswers: [paw, kon],
      },
    },
  ],
  paslekryspies: [
    {
      fileId: 1263,
      channel: "RIGHT",
      metadata: {
        possibleAnswers: allWords,
        rightAnswers: [pas, lek],
      },
    },
    {
      fileId: 1264,
      channel: "LEFT",
      metadata: {
        possibleAnswers: allWords,
        rightAnswers: [rys, pies],
      },
    },
  ],
  makdomkokbut: [
    {
      fileId: 1265,
      channel: "RIGHT",
      metadata: {
        possibleAnswers: allWords,
        rightAnswers: [mak, dom],
      },
    },
    {
      fileId: 1266,
      channel: "LEFT",
      metadata: {
        possibleAnswers: allWords,
        rightAnswers: [kok, but],
      },
    },
  ],
  serkonrakmysz: [
    {
      fileId: 1267,
      channel: "RIGHT",
      metadata: {
        possibleAnswers: allWords,
        rightAnswers: [ser, kon],
      },
    },
    {
      fileId: 1269,
      channel: "LEFT",
      metadata: {
        possibleAnswers: allWords,
        rightAnswers: [rak, mysz],
      },
    },
  ],
  konworwazczolg: [
    {
      fileId: 1268,
      channel: "RIGHT",
      metadata: {
        possibleAnswers: allWords,
        rightAnswers: [kon, wor],
      },
    },
    {
      fileId: 1270,
      channel: "LEFT",
      metadata: {
        possibleAnswers: allWords,
        rightAnswers: [waz, czolg],
      },
    },
  ],
  misdomwazsok: [
    {
      fileId: 1271,
      channel: "RIGHT",
      metadata: {
        possibleAnswers: allWords,
        rightAnswers: [mis, dom],
      },
    },
    {
      fileId: 1272,
      channel: "LEFT",
      metadata: {
        possibleAnswers: allWords,
        rightAnswers: [waz, sok],
      },
    },
  ],
  szalzezlassum: [
    {
      fileId: 1273,
      channel: "RIGHT",
      metadata: {
        possibleAnswers: allWords,
        rightAnswers: [szal, zez],
      },
    },
    {
      fileId: 1274,
      channel: "LEFT",
      metadata: {
        possibleAnswers: allWords,
        rightAnswers: [las, sum],
      },
    },
  ],
  lekbykzabjez: [
    {
      fileId: 1275,
      channel: "RIGHT",
      metadata: {
        possibleAnswers: allWords,
        rightAnswers: [lek, byk],
      },
    },
    {
      fileId: 1276,
      channel: "LEFT",
      metadata: {
        possibleAnswers: allWords,
        rightAnswers: [zab, jez],
      },
    },
  ],

  //AUDIOMETRIA SLOWNA, AS
  lissa: {
    fileId: 1000,
    channel: "BOTH",
    metadata: {
      possibleAnswers: allWords,
      rightAnswers: [lis],
    },
  },
  butsa: {
    fileId: 1001,
    channel: "BOTH",
    metadata: {
      possibleAnswers: allWords,
      rightAnswers: [but],
    },
  },
  byksa: {
    fileId: 1002,
    channel: "BOTH",
    metadata: {
      possibleAnswers: allWords,
      rightAnswers: [byk],
    },
  },
  // chlebsa: {
  //   fileId: 1003,
  //   channel: "BOTH",
  //   metadata: {
  //     possibleAnswers: allWords,
  //     rightAnswers: [chleb],
  //   },
  // },
  czolgsa: {
    fileId: 1004,
    channel: "BOTH",
    metadata: {
      possibleAnswers: allWords,
      rightAnswers: [czolg],
    },
  },
  dachsa: {
    fileId: 1005,
    channel: "BOTH",
    metadata: {
      possibleAnswers: allWords,
      rightAnswers: [dach],
    },
  },
  dlonsa: {
    fileId: 1006,
    channel: "BOTH",
    metadata: {
      possibleAnswers: allWords,
      rightAnswers: [dlon],
    },
  },
  domsa: {
    fileId: 1007,
    channel: "BOTH",
    metadata: {
      possibleAnswers: allWords,
      rightAnswers: [dom],
    },
  },
  duchsa: {
    fileId: 1008,
    channel: "BOTH",
    metadata: {
      possibleAnswers: allWords,
      rightAnswers: [duch],
    },
  },
  dymsa: {
    fileId: 1009,
    channel: "BOTH",
    metadata: {
      possibleAnswers: allWords,
      rightAnswers: [dym],
    },
  },
  dziobsa: {
    fileId: 1010,
    channel: "BOTH",
    metadata: {
      possibleAnswers: allWords,
      rightAnswers: [dziob],
    },
  },
  dzwigsa: {
    fileId: 1011,
    channel: "BOTH",
    metadata: {
      possibleAnswers: allWords,
      rightAnswers: [dzwig],
    },
  },
  gessa: {
    fileId: 1012,
    channel: "BOTH",
    metadata: {
      possibleAnswers: allWords,
      rightAnswers: [ges],
    },
  },
  grzybsa: {
    fileId: 1013,
    channel: "BOTH",
    metadata: {
      possibleAnswers: allWords,
      rightAnswers: [grzyb],
    },
  },
  jezsa: {
    fileId: 1014,
    channel: "BOTH",
    metadata: {
      possibleAnswers: allWords,
      rightAnswers: [jez],
    },
  },
  kluczsa: {
    fileId: 1015,
    channel: "BOTH",
    metadata: {
      possibleAnswers: allWords,
      rightAnswers: [klucz],
    },
  },
  koksa: {
    fileId: 1016,
    channel: "BOTH",
    metadata: {
      possibleAnswers: allWords,
      rightAnswers: [kok],
    },
  },
  konsa: {
    fileId: 1017,
    channel: "BOTH",
    metadata: {
      possibleAnswers: allWords,
      rightAnswers: [kon],
    },
  },
  koscsa: {
    fileId: 1018,
    channel: "BOTH",
    metadata: {
      possibleAnswers: allWords,
      rightAnswers: [kosc],
    },
  },
  kotsa: {
    fileId: 1019,
    channel: "BOTH",
    metadata: {
      possibleAnswers: allWords,
      rightAnswers: [kot],
    },
  },
  kretsa: {
    fileId: 1020,
    channel: "BOTH",
    metadata: {
      possibleAnswers: allWords,
      rightAnswers: [kret],
    },
  },
  kwiatsa: {
    fileId: 1021,
    channel: "BOTH",
    metadata: {
      possibleAnswers: allWords,
      rightAnswers: [kwiat],
    },
  },
  lassa: {
    fileId: 1022,
    channel: "BOTH",
    metadata: {
      possibleAnswers: allWords,
      rightAnswers: [las],
    },
  },
  leksa: {
    fileId: 1023,
    channel: "BOTH",
    metadata: {
      possibleAnswers: allWords,
      rightAnswers: [lek],
    },
  },
  lewsa: {
    fileId: 1024,
    channel: "BOTH",
    metadata: {
      possibleAnswers: allWords,
      rightAnswers: [lew],
    },
  },
  listsa: {
    fileId: 1025,
    channel: "BOTH",
    metadata: {
      possibleAnswers: allWords,
      rightAnswers: [list],
    },
  },
  liscsa: {
    fileId: 1026,
    channel: "BOTH",
    metadata: {
      possibleAnswers: allWords,
      rightAnswers: [lisc],
    },
  },
  maksa: {
    fileId: 1027,
    channel: "BOTH",
    metadata: {
      possibleAnswers: allWords,
      rightAnswers: [mak],
    },
  },
  missa: {
    fileId: 1028,
    channel: "BOTH",
    metadata: {
      possibleAnswers: allWords,
      rightAnswers: [mis],
    },
  },
  mleczsa: {
    fileId: 1029,
    channel: "BOTH",
    metadata: {
      possibleAnswers: allWords,
      rightAnswers: [mlecz],
    },
  },
  mostsa: {
    fileId: 1030,
    channel: "BOTH",
    metadata: {
      possibleAnswers: allWords,
      rightAnswers: [most],
    },
  },
  mursa: {
    fileId: 1031,
    channel: "BOTH",
    metadata: {
      possibleAnswers: allWords,
      rightAnswers: [mur],
    },
  },
  myszsa: {
    fileId: 1032,
    channel: "BOTH",
    metadata: {
      possibleAnswers: allWords,
      rightAnswers: [mysz],
    },
  },
  nocsa: {
    fileId: 1033,
    channel: "BOTH",
    metadata: {
      possibleAnswers: allWords,
      rightAnswers: [noc],
    },
  },
  nossa: {
    fileId: 1034,
    channel: "BOTH",
    metadata: {
      possibleAnswers: allWords,
      rightAnswers: [nos],
    },
  },
  nozsa: {
    fileId: 1035,
    channel: "BOTH",
    metadata: {
      possibleAnswers: allWords,
      rightAnswers: [noz],
    },
  },
  pansa: {
    fileId: 1036,
    channel: "BOTH",
    metadata: {
      possibleAnswers: allWords,
      rightAnswers: [pan],
    },
  },
  passa: {
    fileId: 1037,
    channel: "BOTH",
    metadata: {
      possibleAnswers: allWords,
      rightAnswers: [pas],
    },
  },
  // pawsa: {
  //   fileId: 1038,
  //   channel: "BOTH",
  //   metadata: {
  //     possibleAnswers: allWords,
  //     rightAnswers: [paw],
  //   },
  // },
  piecsa: {
    fileId: 1039,
    channel: "BOTH",
    metadata: {
      possibleAnswers: allWords,
      rightAnswers: [piec],
    },
  },
  piessa: {
    fileId: 1040,
    channel: "BOTH",
    metadata: {
      possibleAnswers: allWords,
      rightAnswers: [pies],
    },
  },
  piescsa: {
    fileId: 1041,
    channel: "BOTH",
    metadata: {
      possibleAnswers: allWords,
      rightAnswers: [piesc],
    },
  },
  ptaksa: {
    fileId: 1042,
    channel: "BOTH",
    metadata: {
      possibleAnswers: allWords,
      rightAnswers: [ptak],
    },
  },
  raksa: {
    fileId: 1043,
    channel: "BOTH",
    metadata: {
      possibleAnswers: allWords,
      rightAnswers: [rak],
    },
  },
  ryssa: {
    fileId: 1044,
    channel: "BOTH",
    metadata: {
      possibleAnswers: allWords,
      rightAnswers: [rys],
    },
  },
  sadsa: {
    fileId: 1045,
    channel: "BOTH",
    metadata: {
      possibleAnswers: allWords,
      rightAnswers: [sad],
    },
  },
  sersa: {
    fileId: 1046,
    channel: "BOTH",
    metadata: {
      possibleAnswers: allWords,
      rightAnswers: [ser],
    },
  },
  slonsa: {
    fileId: 1047,
    channel: "BOTH",
    metadata: {
      possibleAnswers: allWords,
      rightAnswers: [slon],
    },
  },
  smaksa: {
    fileId: 1048,
    channel: "BOTH",
    metadata: {
      possibleAnswers: allWords,
      rightAnswers: [smak],
    },
  },
  soksa: {
    fileId: 1049,
    channel: "BOTH",
    metadata: {
      possibleAnswers: allWords,
      rightAnswers: [sok],
    },
  },
  stolsa: {
    fileId: 1050,
    channel: "BOTH",
    metadata: {
      possibleAnswers: allWords,
      rightAnswers: [stol],
    },
  },
  sumsa: {
    fileId: 1051,
    channel: "BOTH",
    metadata: {
      possibleAnswers: allWords,
      rightAnswers: [sum],
    },
  },
  szalsa: {
    fileId: 1052,
    channel: "BOTH",
    metadata: {
      possibleAnswers: allWords,
      rightAnswers: [szal],
    },
  },
  torsa: {
    fileId: 1053,
    channel: "BOTH",
    metadata: {
      possibleAnswers: allWords,
      rightAnswers: [tor],
    },
  },
  tortsa: {
    fileId: 1054,
    channel: "BOTH",
    metadata: {
      possibleAnswers: allWords,
      rightAnswers: [tort],
    },
  },
  wassa: {
    fileId: 1055,
    channel: "BOTH",
    metadata: {
      possibleAnswers: allWords,
      rightAnswers: [was],
    },
  },
  wazsa: {
    fileId: 1056,
    channel: "BOTH",
    metadata: {
      possibleAnswers: allWords,
      rightAnswers: [waz],
    },
  },
  worsa: {
    fileId: 1057,
    channel: "BOTH",
    metadata: {
      possibleAnswers: allWords,
      rightAnswers: [wor],
    },
  },
  wozsa: {
    fileId: 1058,
    channel: "BOTH",
    metadata: {
      possibleAnswers: allWords,
      rightAnswers: [woz],
    },
  },
  zabsa: {
    fileId: 1059,
    channel: "BOTH",
    metadata: {
      possibleAnswers: allWords,
      rightAnswers: [zab],
    },
  },
  zezsa: {
    fileId: 1060,
    channel: "BOTH",
    metadata: {
      possibleAnswers: allWords,
      rightAnswers: [zez],
    },
  },
  zolwsa: {
    fileId: 1061,
    channel: "BOTH",
    metadata: {
      possibleAnswers: allWords,
      rightAnswers: [zolw],
    },
  },
  zuksa: {
    fileId: 1062,
    channel: "BOTH",
    metadata: {
      possibleAnswers: allWords,
      rightAnswers: [zuk],
    },
  },

  tswIS1: {
    fileId: 1315, //Test Sekwencji Wysokości  Za chwilę rozpoczniesz badanie. Usłyszysz sekwencję TRZECH dźwięków. Będą to dźwięki NISKIE i WYSOKIE w różnej konfiguracji.
    channel: "BOTH",
    metadata: null,
  },
  tswllhInstruction: {
    fileId: 1316, //na przykład niski niski wysoki
    channel: "BOTH",
    metadata: null,
  },
  tswIS6: {
    fileId: 1317, //Wykonując badanie zwróć uwagę na kolejność dźwięków. Twoim zadaniem jest przesunięcie pól z górnego rzędu na odpowiednie miejsca w dolnym rzędzie, zgodnie z kolejnością dźwięków, którą usłyszałeś.
    channel: "BOTH",
    metadata: null,
  },

  tprIS1: {
    fileId: 1321, //Test Przerw Randomizowanych Za chwilę rozpoczniesz badanie. Usłyszysz dwa rodzaje szumów - szum ciągły i szum z przerwą.
    channel: "BOTH",
    metadata: null,
  },
  tprIS2: {
    fileId: 1322, //To jest szum ciągły
    channel: "BOTH",
    metadata: null,
  },
  tprIS3: {
    fileId: 1078, //szum ciagly
    channel: "BOTH",
    metadata: {
      possibleAnswers: [answerNoGap, answerGap],
      rightAnswers: [answerNoGap],
    },
  },
  tprIS4: {
    fileId: 1323, //To jest szum z przerwą
    channel: "BOTH",
    metadata: null,
  },
  tprIS5: {
    fileId: 1079, //szum z przerwa
    channel: "BOTH",
    metadata: {
      possibleAnswers: [answerNoGap, answerGap],
      rightAnswers: [answerGap],
    },
  },
  tprIS6: {
    fileId: 1324, //Wykonując badanie zdecyduj czy usłyszany dźwięk to szum ciągły czy szum z przerwą i zaznacz odpowiednią odpowiedź na ekranie.
    channel: "BOTH",
    metadata: null,
  },

  trcIS1: {
    fileId: 1325, //Test Rozdzielnouszny Cyfr Za chwilę rozpoczniesz badanie. Usłyszysz równocześnie DWIE cyfry w prawym uchu i DWIE cyfry w lewym uchu. Twoim zadaniem jest wybrać na ekranie cyfry, które usłyszałeś. Kolejność nie ma znaczenia.
    channel: "BOTH",
    metadata: null,
  },

  tsdIS1: {
    fileId: 1318, //Test Sekwencji Długości Za chwilę rozpoczniesz badanie. Usłyszysz sekwencję TRZECH dźwięków. Będą to dźwięki KRÓTKIE i DŁUGIE  w różnej konfiguracji.
    channel: "BOTH",
    metadata: null,
  },
  tsdsslInstruction: {
    fileId: 1319, //Na przykład: krótki, krótki, długi.
    channel: "BOTH",
    metadata: null,
  },
  tsdIS6: {
    fileId: 1320, //Wykonując badanie zwróć uwagę na kolejność dźwięków. Po usłyszeniu dźwięku Twoim zadaniem jest przesunięcie pól z górnego rzędu na odpowiednie miejsca w dolnym rzędzie, zgodnie z kolejnością dźwięków którą usłyszałeś.
    channel: "BOTH",
    metadata: null,
  },
  tdwIS1: {
    fileId: 1311, //Test Dyskryminacji Wysokości Za chwilę rozpoczniesz badanie. Usłyszysz dwa dźwięki – NISKI i WYSOKI.
    channel: "BOTH",
    metadata: null,
  },
  tdwIS2: {
    fileId: 1312, //To jest dźwięk niski
    channel: "BOTH",
    metadata: null,
  },
  tdwIS3: {
    fileId: 1063, //niski
    channel: "BOTH",
    metadata: {
      possibleAnswers: [answerLow, answerHigh],
      rightAnswers: [answerLow],
    },
  },
  tdwIS4: {
    fileId: 1313, //a to dźwięk wysoki
    channel: "BOTH",
    metadata: null,
  },
  tdwIS5: {
    fileId: 1064, //wysoki
    channel: "BOTH",
    metadata: {
      possibleAnswers: [answerLow, answerHigh],
      rightAnswers: [answerHigh],
    },
  },
  tdwIS6: {
    fileId: 1314, //Twoim zadaniem jest wybranie czy usłyszane dźwięki były takie same czy różne i zaznaczenie odpowiedzi na ekranie.
    channel: "BOTH",
    metadata: null,
  },

  trmsIS: {
    fileId: 1327, //Test Rozumienia Mowy w Szumie Za chwilę rozpoczniesz badanie. Usłyszysz szum, w którym pojawi się słowo. Słuchaj uważnie i wybierz które słowo usłyszałeś
    channel: "BOTH",
    metadata: null,
  },

  tmsIS: {
    fileId: 1328, //Test Mowy Skompresowanej Za chwilę rozpoczniesz badanie. Usłyszysz słowo, które będzie zniekształcone. Słuchaj uważnie i wybierz które słowo usłyszałeś.
    channel: "BOTH",
    metadata: null,
  },
  tmfIS: {
    fileId: 1329, //Test Mowy Filtrowanej Za chwilę rozpoczniesz badanie. Usłyszysz słowo, które będzie zniekształcone. Słuchaj uważnie i wybierz które słowo usłyszałeś.
    channel: "BOTH",
    metadata: null,
  },
  trsIS1: {
    fileId: 1326, //Test Rozdzielnouszny Słów Za chwilę rozpoczniesz badanie. Usłyszysz równocześnie DWA SŁOWA w prawym uchu i DWA SŁOWA w lewym uchu. Twoim zadaniem jest wybrać na ekranie słowa, które usłyszałeś. Kolejność nie ma znaczenia.
    channel: "BOTH",
    metadata: null,
  },

  capdDescription: {
    fileId: 1300, //OCENA PROCESÓW PRZETWARZANIA SŁUCHOWEGO Celem badań jest sprawdzenie Twoich umiejętności słuchowych, czyli tego w jaki sposób przetwarzasz usłyszaną informację dźwiękową.Naciśnij przycisk DALEJ, aby kontynuować.
    channel: "BOTH",
    metadata: null,
  },
  apKidsDescription: {
    fileId: 1301, //Audiometria przesiewowa Badanie służy do oceny czy dobrze słyszysz. Podczas badania prezentowane będą różne dźwięki. Dzięki będą słyszalne najpierw w jednym, a potem w drugim ucho. Naciśnij przycisk DALEJ, aby kontynuować.
    channel: "BOTH",
    metadata: null,
  },
  apKidsInstruction: {
    fileId: 1302, //Za chwilę zobaczysz zwierzątka. Wybierz to, które wydało dźwięk. Jeżeli żadne zwierzątko nie wydało dźwięku, naciśnij przekreślone ucho. Jesteś gotowy? Naciśnij przycisk START.
    channel: "BOTH",
    metadata: null,
  },
  gcKidsDescription: {
    fileId: 1303, //Przesiewowa audiometria progowa Celem badania jest wyznaczenie najcichszych słyszalnych przez Ciebie dźwięków. Naciśnij przycisk DALEJ, aby kontynuować.
    channel: "BOTH",
    metadata: null,
  },
  gcKidsInstruction: {
    fileId: 1304, //Za chwile zobaczysz zwierzątko, które trzyma wiatraczek. Kiedy pojawi się dźwięk naciśnij wiatraczek. Jesteś gotowy, naciśnij przycisk START.
    channel: "BOTH",
    metadata: null,
  },
  apDescription: {
    fileId: 1305, //Badanie Audiometria przesiewowa Badanie przesiewowe służy do oceny czy Twój słuch jest w granicach normy.Podczas badania prezentowane będą dźwięki dla różnych częstotliwości. Badanie będzie wykonywane oddzielnie dla ucha prawego i lewego. Naciśnij przycisk DALEJ, aby kontynuować.
    channel: "BOTH",
    metadata: null,
  },
  apInstruction: {
    fileId: 1306, //Za chwilę rozpoczniesz badanie. Twoim zadaniem jest nacisnąć przycisk SŁYSZĘ za każdym razem, kiedy usłyszysz dźwięk. Reaguj nawet wtedy, gdy dźwięk jest bardzo cichy. Jeśli nic nie usłyszysz, nie naciskaj przycisku. Dźwięki będziesz słyszał najpierw w PRAWYM, potem w LEWYM uchu. Zanim rozpoczniesz badanie, możesz wykonać ćwiczenie. Aby to zrobić naciśnij przycisk WYKONAJ ĆWICZENIE. Jeżeli chcesz od razu przystąpić do badania naciśnij przycisk ROZPOCZNIJ BADANIE.
    channel: "BOTH",
    metadata: null,
  },
  gcDescription: {
    fileId: 1307, //Badanie przesiewowa audiometria progowa Celem badania jest wyznaczenie progów słyszenia, czyli najcichszych, słyszalnych przez Ciebie dźwięków dla różnych częstotliwości. Naciśnij przycisk DALEJ, aby kontynuować.
    channel: "BOTH",
    metadata: null,
  },
  gcInstruction: {
    fileId: 1308, //Za chwilę rozpoczniesz badanie. Twoim zadaniem jest wskazanie najcichszego dźwięku jaki jesteś w stanie usłyszeć. Po naciśnięciu przycisku ROZPOCZNIJ BADANIE usłyszysz dźwięk. Za pomocą przycisków na ekranie zmieniaj głośność dźwięku do momentu, kiedy uznasz, że głośność dźwięku jest najcichsza, którą jesteś w stanie usłyszeć. Naciśnij przycisk OK, aby zapisać dźwięk.
    channel: "BOTH",
    metadata: null,
  },
  asDescription: {
    fileId: 1309, //Badanie przesiewowa audiometria słowna Celem badania jest sprawdzenie rozumienia mowy, które służy do oceny zdolności komunikowania się w codziennym życiu. Badanie będzie wykonywane oddzielnie dla ucha prawego i lewego. Naciśnij przycisk DALEJ, aby kontynuować.
    channel: "BOTH",
    metadata: null,
  },
  asInstruction: {
    fileId: 1310, //Za chwilę rozpoczniesz badanie. Usłyszysz w słuchawkach słowa. Twoim zadaniem jest wybrać spośród słów na ekranie to, które usłyszałeś. Słowa będziesz słyszał najpierw w prawym, potem w lewym uchu.
    channel: "BOTH",
    metadata: null,
  },

  nextExamination: {
    fileId: 1350, //DZIĘKUJEMY ZA WYKONANIE BADANIA Przejdź do następnego badania.
    channel: "BOTH",
    metadata: null,
  },
  trainingInfo: {
    fileId: 1351, //Zanim rozpoczniesz badanie, możesz wykonać ćwiczenie. Aby to zrobić naciśnij przycisk WYKONAJ ĆWICZENIE. Jeżeli chcesz od razu przystąpić do badania naciśnij przycisk ROZPOCZNIJ BADANIE.
    channel: "BOTH",
    metadata: null,
  },
  trainingEnds: {
    fileId: 1352, //Zakończyłeś ćwiczenie, teraz przystąp do badania. Jeśli jesteś gotowy, naciśnij przycisk ROZPOCZNIJ BADANIE.
    channel: "BOTH",
    metadata: null,
  },
  example: {
    fileId: 1353, //Oto przykład jak wykonać badanie
    channel: "BOTH",
    metadata: null,
  },
  examinationFinishedResultsInfo: {
    fileId: 1355, //DZIĘKUJEMY ZA WYKONANIE BADANIA Aby poznać szczegółowy wynik badania zaloguj się na stronie internetowej HEARBOX.PL na Twoim urządzeniu.
    channel: "BOTH",
    metadata: null,
  },
  examinationFinishedResultsInfoMainAccount: {
    fileId: 1356, //DZIĘKUJEMY ZA WYKONANIE BADANIA Aby poznać szczegółowy wynik badania zaloguj się na stronie internetowej HEARBOX.PL na Twoim urządzeniu. Jeśli chcesz kontynuwać wróć do strony głównej
    channel: "BOTH",
    metadata: null,
  },
};

// const yaml = require("js-yaml");
// const unsafe = require('js-yaml-js-types').all;

// const schema = yaml.DEFAULT_SCHEMA.extend(unsafe);

// fetch("/defaultConfig.yaml", {cache: "reload"}).then(response => {
//   response.text().then(config => {
//     const doc = yaml.load(config, {schema});
//   });
// });
