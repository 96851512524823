import { IconButton, Typography } from "@mui/material";
import { useState } from "react";
import { ReactComponent as SameDifferent } from "../../resources/images/same-different.svg";
import sameDifferentRim from "../../resources/images/same-different-rim.svg";

const SameDifferentButton = ({ onClick, text }) => {
  const [clicked, setClicked] = useState("");
  return (
    <IconButton
      disableRipple
      onPointerDown={() => {
        onClick && onClick();
        setClicked("clicked");
      }}
    >
      <SameDifferent />
      <img src={sameDifferentRim} className={"quieter-louder " + clicked} />
      <Typography
        variant="answers"
        sx={{
          fontSize: "19px",
          maxWidth: "110px",
          color: "white",
          position: "absolute",
        }}
      >
        {text.toUpperCase()}
      </Typography>
    </IconButton>
  );
};

export default SameDifferentButton;
