import { useState, useEffect } from "react";
import { Container, Box, Typography, Stack, Button } from "@mui/material";
import Loader from "../components/animations/Loader";
import { texts } from "../resources/texts";
import bg from "../resources/images/bg-with-circle-and-img.svg";
import { ReactComponent as Square } from "../resources/images/square.svg";
import Options from "../components/Options";

//wybór pacjenta/podopiecznego
const UserSelectionPage = ({ loading, users, onUserSelection }) => {
  const [userId, setUserId] = useState("");

  const [first, ...rest] = texts.choosePatient.split(" ");
  const usersOptions = users.map((u) => ({
    id: u.id,
    name: `${u.firstName} ${u.surname}`.toUpperCase(),
  }));

  useEffect(() => {
    document.body.style.backgroundImage = `url(${bg})`;
  }, []);

  useEffect(() => {
    users.length > 0 && setUserId(users[0].id);
  }, [users]);

  return (
    <Container component="main">
      {!loading ? (
        <Box
          sx={{
            pt: 26,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Stack direction="row" spacing={3} alignItems="center">
            <Square />
            <Typography
              variant="title"
              sx={{ color: (theme) => theme.palette.text.secondary }}
            >
              {first}
            </Typography>
            <Square />
          </Stack>
          <Typography
            variant="title"
            sx={{
              mb: 8,
            }}
          >
            {rest.join(" ")}
          </Typography>
          <Options
            options={usersOptions}
            selectedOption={userId}
            selectOption={setUserId}
          />

          {userId && (
            <Button
              variant="contained"
              size="large"
              sx={{ position: "fixed", right: "40px", bottom: "40px", px: 10 }}
              onClick={() => {
                onUserSelection && onUserSelection(userId);
              }}
            >
              {texts.continue}
            </Button>
          )}
        </Box>
      ) : (
        <Loader text={texts.loading} color="primary" />
      )}
    </Container>
  );
};

export default UserSelectionPage;
