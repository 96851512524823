import { createContext, useContext, useEffect, useState } from "react";
import { w3cwebsocket } from "websocket";

class NotificationSubject {
  #observers = new Set();

  attach(observer) {
    this.#observers.add(observer);
  }

  detach(observer) {
    this.#observers.delete(observer);
  }

  notify(notification) {
    this.#observers.forEach(o => o(notification));
  }
}

const NotificationContext = createContext(new NotificationSubject());

const WebsocketConnectionProvider = ({path = "/api/v1/notifications", children}) => {
  const [notificationSubject] = useState(new NotificationSubject());
  
  useEffect(() => {
    const protocol = window.location.protocol === "http:" ? "ws:" : "wss:";
    const ws = w3cwebsocket(`${protocol}//${window.location.host}${path}`);

    ws.onmessage = (message) => {
      notificationSubject.notify(JSON.parse(message.data));
    }

    return () => {
      ws && ws.close(1000);
    };
  }, [notificationSubject, path]);

  return (
    <NotificationContext.Provider value={notificationSubject}>
      {children}
    </NotificationContext.Provider>
  );
}

const useNotificationSubject = () => {
  return useContext(NotificationContext);
};

export {NotificationSubject, WebsocketConnectionProvider, useNotificationSubject};
