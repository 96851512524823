import { useEffect, useState } from "react";
import { Button, Modal, Stack, Box, Typography } from "@mui/material";
import moment from "moment";
import AutomaticLogout from "./AutomaticLogout";
import { texts } from "../resources/texts";

const IdleTimeOutHandler = ({ onLogout, timeOutInterval = 240000 }) => {
  const [showModal, setShowModal] = useState(false);
  let timer = undefined;
  const events = ["touchstart", "click"];

  const eventHandler = (eventType) => {
    localStorage.setItem("lastInteractionTime", moment().toISOString());
    if (timer) {
      startTimer();
    }
  };

  useEffect(() => {
    addEvents();

    return () => {
      removeEvents();
      clearTimeout(timer);
    };
  }, []);

  const addEvents = () => {
    events.forEach((eventName) => {
      window.addEventListener(eventName, eventHandler);
    });

    startTimer();
  };

  const removeEvents = () => {
    events.forEach((eventName) => {
      window.removeEventListener(eventName, eventHandler);
    });
  };

  const startTimer = () => {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      let lastInteractionTime = localStorage.getItem("lastInteractionTime");
      const diff = moment.duration(moment().diff(moment(lastInteractionTime)));
      if (diff._milliseconds < timeOutInterval) {
        startTimer();
      } else {
        setShowModal(true);
      }
    }, timeOutInterval);
  };

  const handleLogout = () => {
    removeEvents();
    clearTimeout(timer);
    onLogout();
    setShowModal(false);
  };

  return (
    <Modal open={showModal} onClose={() => setShowModal(false)}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 600,
          borderRadius: "10px",
          p: 8,
          bgcolor: "rgba(255,255,255,0.9)",
        }}
      >
        <Typography id="modal-modal-title" variant="h1">
          {texts.idleOccured}
        </Typography>
        <Typography id="modal-modal-description" variant="h5" sx={{ my: 4 }}>
          {texts.clickToStay}
        </Typography>
        <Stack direction="row" spacing={2} justifyContent="center" mb={2}>
          <Button
            variant="contained"
            onClick={() => setShowModal(false)}
            sx={{ px: 4 }}
          >
            {texts.stayOnSite}
          </Button>
          <Button
            variant="contained"
            onClick={() => handleLogout()}
            sx={{ px: 4 }}
          >
            {texts.logout}
          </Button>
        </Stack>
        {showModal && <AutomaticLogout onLogout={handleLogout} />}
      </Box>
    </Modal>
  );
};

export default IdleTimeOutHandler;
