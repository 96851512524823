import { Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { ReactComponent as ClockIcon } from "../resources/images/clock-min.svg";
import { texts } from "../resources/texts";

const AutomaticLogout = ({ onLogout }) => {
  const [timeLeftToLogout, setTimeLeftToLogout] = useState(60);

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeLeftToLogout((prevState) => prevState - 1);
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    timeLeftToLogout === 0 && onLogout && onLogout();
  }, [timeLeftToLogout]);

  if (onLogout) {
    return (
      <Stack direction="row" spacing={2} justifyContent="center">
        <ClockIcon sx={{ width: 20, height: 20 }} />
        <Typography
          variant="h3"
          sx={{
            color: "#707070",
            fontWeight: (theme) => theme.typography.fontWeightMedium,
          }}
        >{`${texts.automaticLogoutInfo} ${timeLeftToLogout}s.`}</Typography>
      </Stack>
    );
  } else return null;
};

export default AutomaticLogout;
