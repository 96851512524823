import { useEffect, useState, useMemo } from "react";
import {
  Button,
  Box,
  Typography,
  TableContainer,
  TableRow,
  Table,
  TableCell,
  TableBody,
  IconButton,
  CircularProgress,
} from "@mui/material";
import { useApi } from "../Api";
import { sleep } from "../resources/exportedFunctions";
import { audiometryConfig } from "../resources/tests_config/audiometryConfig";
import InstructionModal from "../components/InstructionModal";
import RepeatSoundInfoModal from "../components/RepeatSoundInfoModal";
import BugReportIcon from "@mui/icons-material/BugReport";
import { useNotificationSubject } from "../Notifications";
import { Answer } from "./enums";
import { texts } from "../resources/texts";
import AudiometryHearButtonLayout from "./AudiometryHearButtonLayout";
import CheckingNoises from "../components/animations/CheckingNoisesModal";
import { wavFiles } from "../resources/wavFiles";

export function isAnswerCorrect(clicks) {
  return (
    clicks.length > 0 &&
    clicks[0].isRight &&
    !clicks.some((click) => click.isRight === false)
  );
}

const indexesWithAttemptStartStop = [];
const bgNoises = [];
var retryCount = 0;

const font = { fontSize: "1rem", fontFamily: "sans-serif" }; //do usuniecia
const TableForTestingPurposes = ({
  soundsClicksTimes,
  retryCount,
  visible,
}) => {
  function isEmpty(clicks) {
    if (clicks && clicks.length === 0) {
      return true;
    } else return false;
  }

  function isFalse(clicks) {
    if (
      clicks &&
      clicks.length > 0 &&
      clicks.some((click) => click.isRight === false)
    ) {
      return true;
    } else {
      return false;
    }
  }

  function displayStartTime(ind) {
    const obj = indexesWithAttemptStartStop.find(({ index }) => index === ind);
    if (obj && obj.startTime) {
      const date = new Date(obj.startTime);
      return date.toLocaleTimeString("default", {
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        fractionalSecondDigits: 3,
      });
    }
    return "";
  }
  function displayStopTime(ind) {
    const obj = indexesWithAttemptStartStop.find(({ index }) => index === ind);
    if (obj && obj.stopTime) {
      const date = new Date(obj.stopTime);
      return date.toLocaleTimeString("default", {
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        fractionalSecondDigits: 3,
      });
    }
    return "";
  }

  return (
    <Box
      sx={{
        position: "fixed",
        top: 200,
        left: 10,
        visibility: visible ? "visible" : "hidden",
      }}
    >
      <Typography>Debug info</Typography>
      <TableContainer>
        <Table size="small">
          <TableBody>
            <TableRow>
              <TableCell sx={font}>dźwięk</TableCell>
              <TableCell sx={font}>odpowiedź</TableCell>
              <TableCell sx={font}>rozpoczęcie próby</TableCell>
              <TableCell sx={font}>zakończenie próby</TableCell>
            </TableRow>
            {soundsClicksTimes.map((sound, index) => {
              return (
                <TableRow key={index}>
                  <TableCell sx={font}>{index}</TableCell>
                  <TableCell sx={font}>{`${
                    isAnswerCorrect(sound.clicks) ? "ok" : ""
                  } ${isEmpty(sound.clicks) ? "brak kliknięć" : ""} ${
                    isFalse(sound.clicks) ? "FP" : ""
                  }`}</TableCell>
                  <TableCell sx={font}>{displayStartTime(index)}</TableCell>
                  <TableCell sx={font}>{displayStopTime(index)}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Typography>{`liczba powtórzeń: ${retryCount}`}</Typography>
    </Box>
  );
};

const SingleFreqAudiometry = ({
  frequency,
  channel,
  updateAudiometry,
  testTone = audiometryConfig.testTone[frequency],
  stopped,
  training = false,
  finishTraining,
  onError,
}) => {
  const api = useApi();

  const [currentIntensity, setCurrentIntensity] = useState(
    frequency === 1000 ? `${testTone + 20}f` : `${testTone}f`
  );
  const [soundsClicksTimes, setSoundsClicksTimes] = useState([]);
  const [finished, setFinished] = useState(false);
  const [ready, setReady] = useState(false);
  const [play, setPlay] = useState({ isPlaying: false, currentSound: -1 });
  const [stop, setStop] = useState(false);
  const [displayRepeatInfo, setDisplayRepeatInfo] = useState(false);
  const [debugInfoDisplay, setDebugInfoDisplay] = useState(false);
  const [readyToCheckAnswers, setReadyToCheckAnswers] = useState(true);
  const [waitingForNoises, setWaitingForNoises] = useState(false);
  const notificationSubject = useNotificationSubject();
  const channelTraining = useMemo(() => "HEAD_PHONES_RIGHT", []);
  const [triedToSkipTraining, setTriedToSkipTraining] = useState(false);
  const [trainingFinished, setTrainingFinished] = useState(false);
  const [instructionPlaying, setInstructionPlaying] = useState(false);
  const [canClickNextAfterTraining, setCanClickNextAfterTraining] =
    useState(false);
  const abortController = useMemo(() => new AbortController(), []);

  async function playSound(sound) {
    try {
      const time = await api.audiometryPlay(sound);
      setSoundsClicksTimes((prevState) =>
        prevState.map((el, index) => {
          if (index !== play.currentSound) {
            return { ...el };
          } else {
            return {
              start: time.start,
              stop: time.stop,
              clicks: el.clicks.map((click) => {
                return {
                  ...click,
                  isRight: checkIfClickTimeIsInSoundRange(
                    click.clickTime,
                    time.start,
                    time.stop
                  ),
                };
              }),
            };
          }
        })
      );
    } catch (e) {
      console.error(e);
      onError(e.message);
    }
  }

  const handleSkipTrainingClick = () => {
    setTriedToSkipTraining(true);
  };

  useEffect(() => {
    if (triedToSkipTraining && trainingFinished && canClickNextAfterTraining) {
      if (instructionPlaying) {
        api
          .stopPlaying(abortController.signal)
          .then(() => {
            setTimeout(() => {
              finishTraining();
            }, 1000);
          })
          .catch(() => {});
      } else {
        finishTraining();
      }
    }
  }, [triedToSkipTraining, trainingFinished, canClickNextAfterTraining]);

  async function playSoundAndWait() {
    setPlay((prevState) => ({ ...prevState, isPlaying: true }));
    setReadyToCheckAnswers(false);
    const startTime = Date.now();
    const currentIndexObj = indexesWithAttemptStartStop.find(
      ({ index }) => index === play.currentSound
    );

    if (currentIndexObj) {
      currentIndexObj.startTime = startTime;
      currentIndexObj.stopTime = undefined;
    } else {
      indexesWithAttemptStartStop.push({
        index: play.currentSound,
        startTime,
        intensity: currentIntensity,
      });
    }
    const previous = indexesWithAttemptStartStop.find(
      ({ index }) => index === play.currentSound - 1
    );
    if (previous && previous.stopTime === undefined) {
      previous.stopTime = startTime;
    }
    await sleep(250); //aby po zatrzymaniu badania nie byl od razu grany dzwiek

    var sound = {
      levelFloat: currentIntensity,
      channel: training ? channelTraining : channel,
      freqInt: frequency,
      timeGenInt: audiometryConfig.soundDuration,
    };
    addEmptySoundClicksTimes();
    await playSound(sound);
    await sleep(
      audiometryConfig.maxReactionTimeAfterSoundStart -
        audiometryConfig.soundDuration
    );
    //koniec okna czasowego w ktorym kliknięcie oznaczamy jako poprawne
    const pauseDuration =
      Math.floor(
        Math.random() *
          (audiometryConfig.maxPauseDuration -
            audiometryConfig.minPauseDuration)
      ) + audiometryConfig.minPauseDuration;
    await sleep(
      pauseDuration -
        audiometryConfig.maxReactionTimeAfterSoundStart +
        audiometryConfig.soundDuration
    );
    setReadyToCheckAnswers(true);
  }

  useEffect(() => {
    return () => abortController.abort();
  }, []);

  useEffect(() => {
    const notificationObserver = (notification) => {
      switch (notification.type) {
        case "ExceptionWithCodeAndTimeStamp":
          if (
            notification.interfaceGroupingExceptions.startsWith(
              "ResetExaminationAttempt"
            )
          ) {
            //console.log("-----HAŁAS", notification.ts);
            bgNoiseHandler(new Date(notification.ts).getTime());
          }
          break;
        default:
          break;
      }
    };
    notificationSubject.attach(notificationObserver);

    return () => {
      notificationSubject.detach(notificationObserver);
    };
  }, [notificationSubject]);

  useEffect(() => {
    //console.log("@@@@@@@@@@@@@@@@@ play.currentSound", play.currentSound);
  }, [play]);

  useEffect(() => {
    emptyState();
  }, [frequency, channel, training]);

  useEffect(() => {
    if (readyToCheckAnswers && ready && !finished) {
      !training && checkAnswers(incrementCurrentSound);
      training && checkAnswersTraining(incrementCurrentSound);
    }
  }, [ready, readyToCheckAnswers]);

  useEffect(() => {
    !play.isPlaying &&
      !stop &&
      !stopped &&
      play.currentSound !== -1 &&
      !finished &&
      channelTraining &&
      playSoundAndWait();
  }, [play, stop, stopped]);

  function incrementCurrentSound() {
    setPlay((prevState) => ({
      isPlaying: false,
      currentSound: prevState.currentSound + 1,
    }));
  }

  function checkAnswers(callback) {
    const soundWithNoise = checkIfItWasNoiseDuringPreviousSounds();
    if (soundWithNoise) {
      retryCount++;
      if (retryCount > audiometryConfig.maxNumberOfBackgroundNoisesPerFreq) {
        setFinished(true);
      } else {
        setDisplayRepeatInfo(true);
        setTimeout(() => {
          setDisplayRepeatInfo(false);
          setPlay({ isPlaying: false, currentSound: soundWithNoise.index });
        }, 3000);
      }
    } else {
      const rightAnswers = countRightAnswers();
      var runCallback = true;
      var threshold = 2;
      if (frequency === 1000) {
        threshold = 3;
      }

      const emptyCount = countEmptyAnswers(frequency === 1000 ? false : true);
      const falseCount = countFalseAnswers();

      if (frequency === 1000 && currentIntensity === `${testTone + 20}f`) {
        rightAnswers > 0 && setCurrentIntensity(`${testTone}f`);
        rightAnswers === 0 && play.currentSound === 1 && setStop(true); //po dwoch nieuslyszanych dzwiekach na 40dB pokazujemy ponownie instrukcje
      } else if (rightAnswers >= threshold) {
        runCallback = false;
        setFinished(true);
      } else if (emptyCount >= 2) {
        runCallback = false;
        setFinished(true);
      } else if (
        falseCount === audiometryConfig.numberOfFalseClicksBeforeInstruction &&
        isLastFalse()
      ) {
        setStop(true);
      }

      if (
        (play.currentSound === 2 + falseCount && rightAnswers === 0) ||
        falseCount > audiometryConfig.acceptableNumberOfFalseClicks
      ) {
        //zakonczenie proby przy trzech nieuslyszanych dzwiekach o glosnosci 40dB lub przekroczonej liczbie fałszywych kliknięć
        runCallback = false;
        setFinished(true);
      }

      runCallback && callback();
    }
  }

  function checkAnswersTraining(callback) {
    if (triedToSkipTraining === true) {
      setReady(false);
      finishTraining();
    } else {
      const soundWithNoise = checkIfItWasNoiseDuringPreviousSounds();
      if (soundWithNoise) {
        retryCount++;
        if (retryCount > audiometryConfig.maxNumberOfBackgroundNoisesPerFreq) {
          setReady(false);
          setTrainingFinished(true);
        } else {
          setDisplayRepeatInfo(true);
          setTimeout(() => {
            setDisplayRepeatInfo(false);
            setPlay({ isPlaying: false, currentSound: soundWithNoise.index });
          }, 3000);
        }
      } else {
        var runCallback = true;
        if (countRightAnswers() >= 2 || play.currentSound === 3) {
          runCallback = false;
          setReady(false);
          setTrainingFinished(true);
        } else if (
          countEmptyAnswers() + countFalseAnswers() === 2 &&
          isLastFalseOrEmpty()
        ) {
          setStop(true);
        }
        runCallback && callback();
      }
    }
  }

  useEffect(() => {
    if (trainingFinished && training) {
      setInstructionPlaying(true);
      api
        .playInstruction([wavFiles.trainingEnds])
        .then(() => {
          setInstructionPlaying(false);
          setCanClickNextAfterTraining(true);
        })
        .catch((e) => {
          onError(e.message);
        });
    }
    const notificationObserver = (notification) => {
      switch (notification.type) {
        case "ExceptionWithCodeAndTimeStamp":
          if (notification.exceptionClassName === "Playing") {
            trainingFinished && setCanClickNextAfterTraining(true);
          }
          break;
        default:
          break;
      }
    };
    notificationSubject.attach(notificationObserver);

    return () => {
      notificationSubject.detach(notificationObserver);
    };
  }, [trainingFinished]);

  useEffect(() => {
    if (finished) {
      handleTryFinish(handleActualFinish);
    }
  }, [finished]);

  const handleTryFinish = (callback) => {
    var runCallback = true;

    if (!(retryCount > audiometryConfig.maxNumberOfBackgroundNoisesPerFreq)) {
      //jeśli powód zakończenia jest inny niż przekroczenie liczby hałasów
      //console.log("czekamy na hałasy");
      setWaitingForNoises(true);
      setTimeout(() => {
        //czekamy na ewentualne hałasy
        //console.log("sprawdzamy hałasy");
        setWaitingForNoises(false);
        const soundWithNoise = checkIfItWasNoiseDuringPreviousSounds();
        if (soundWithNoise) {
          retryCount++;
          if (
            !(retryCount > audiometryConfig.maxNumberOfBackgroundNoisesPerFreq)
          ) {
            //jeśli liczba hałasów nie została przekroczona
            runCallback = false;
            setFinished(false);
            setDisplayRepeatInfo(true);
            setTimeout(() => {
              setDisplayRepeatInfo(false);
              setPlay({ isPlaying: false, currentSound: soundWithNoise.index });
            }, 3000);
          }
        }
        runCallback && callback();
      }, 2000);
    } else {
      runCallback && callback();
    }
  };

  const handleActualFinish = () => {
    var threshold = 2;
    if (frequency === 1000) {
      threshold = 3;
    }
    setReady(false);
    var answer;
    if (retryCount > audiometryConfig.maxNumberOfBackgroundNoisesPerFreq) {
      answer = Answer.FANC;
    } else if (
      countFalseAnswers() > audiometryConfig.acceptableNumberOfFalseClicks
    ) {
      answer = Answer.FP;
    } else if (countRightAnswers() >= threshold) {
      answer = `${testTone}`;
    } else answer = Answer.NONE;

    updateAudiometry({
      frequenciesResults: [
        {
          frequency,
          answer,
        },
      ],
      audiometryDetails: [{ frequency, soundsClicksTimes: soundsClicksTimes }],
    });
  };

  function checkIfClickTimeIsInSoundRange(clickTime, soundStartTime) {
    if (
      clickTime > soundStartTime + 200 &&
      clickTime <=
        soundStartTime + audiometryConfig.maxReactionTimeAfterSoundStart + 200
    ) {
      return true;
    } else return false;
  }

  function isLastFalse() {
    return (
      soundsClicksTimes.at(-1).clicks.length > 0 &&
      soundsClicksTimes.at(-1).clicks.some((click) => click.isRight === false)
    );
  }

  function isLastFalseOrEmpty() {
    return isLastFalse() || soundsClicksTimes.at(-1).clicks.length === 0;
  }

  function countEmptyAnswers(with40f = true) {
    var emptyCount = 0;
    var wasRightFor40f = false;
    soundsClicksTimes.forEach(({ clicks }) => {
      if (with40f) {
        //sprawdzenie liczby pustych odpowiedzi dla częstotliwości innych niż 1000Hz
        if (clicks.length === 0) emptyCount++;
      } else {
        //sprawdzenie liczby pustych odpowiedzi dla 1000Hz pomijając te dla 40dB
        clicks.length === 0 && wasRightFor40f && emptyCount++;
        if (isAnswerCorrect(clicks)) {
          wasRightFor40f = true;
        }
      }
    });

    return emptyCount;
  }

  function countFalseAnswers() {
    var falseCount = 0;
    soundsClicksTimes.forEach(({ clicks }) => {
      clicks.length > 0 &&
        clicks.some((click) => click.isRight === false) &&
        falseCount++;
    });

    return falseCount;
  }

  function countRightAnswers() {
    var count = 0;
    soundsClicksTimes.forEach(({ clicks }) => {
      isAnswerCorrect(clicks) && count++;
    });

    return count;
  }

  function addEmptySoundClicksTimes() {
    setSoundsClicksTimes((prevState) => [
      ...prevState,
      { start: 0, stop: 0, clicks: [] },
    ]);
  }

  function addClick(clickTime) {
    //dodajemy czas kliknięcia
    setSoundsClicksTimes((prevState) =>
      prevState.map((el, index) => {
        if (index !== play.currentSound) {
          return { ...el };
        } else {
          // kliknięcie nastąpiło po dźwięku - mamy już czas rozpoczęcia i zakończenia kliknięcia,
          //więc sprawdzamy czy było w poprawnym oknie czasowym
          if (el.start !== 0) {
            return {
              ...el,
              clicks: [
                ...el.clicks,
                {
                  clickTime,
                  isRight: checkIfClickTimeIsInSoundRange(clickTime, el.start),
                },
              ],
            };
          } else {
            //kliknięcie nastąpiło podczas lub przed dźwiękiem - sprawdzenie poprawności nastąpi, gdy dodany zostanie dźwięk
            return {
              ...el,
              clicks: [...el.clicks, { clickTime, isRight: undefined }],
            };
          }
        }
      })
    );
  }

  function emptyState() {
    retryCount = 0;
    indexesWithAttemptStartStop.length = 0;
    bgNoises.length = 0;
    setCurrentIntensity(
      frequency === 1000 ? `${testTone + 20}f` : `${testTone}f`
    );
    setSoundsClicksTimes([]);
    setFinished(false);
    setPlay({ isPlaying: false, currentSound: -1 });
    setReady(true);
  }

  const bgNoiseHandler = (ts) => {
    bgNoises.push(ts);
    //console.log("hałas zapisany - bgNoises", bgNoises);
  };

  function checkIfItWasNoiseDuringPreviousSounds() {
    var soundWithNoise = undefined;
    bgNoises.some((noiseTime) => {
      indexesWithAttemptStartStop.some((obj) => {
        //console.log("checking obj", obj);
        if (noiseTime > obj.startTime) {
          if (obj.stopTime === undefined) {
            //console.log("sprawdzamy aktualna próbe");
            if (noiseTime <= Date.now()) {
              //console.log("znaleziono dźwięk podczas hałasu", obj);
              soundWithNoise = obj;
              return true;
            }
          } else if (noiseTime < obj.stopTime) {
            //console.log("znaleziono dźwięk podczas hałasu", obj);
            soundWithNoise = obj;
            return true;
          }
        }
        return false;
      });
      if (soundWithNoise === undefined) {
        return false;
      } else {
        return true;
      }
    });
    bgNoises.length = 0;
    if (soundWithNoise === undefined) {
      return false;
    } else {
      setSoundsClicksTimes((prevState) =>
        prevState.filter((obj, index) => index < soundWithNoise.index)
      );
      indexesWithAttemptStartStop.length = soundWithNoise.index;
      setCurrentIntensity(soundWithNoise.intensity);
      return soundWithNoise;
    }
  }

  return (
    <Box sx={{ height: "100%" }}>
      <IconButton
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
        }}
        onClick={() => setDebugInfoDisplay((prevState) => !prevState)}
      >
        <BugReportIcon fontSize="large" />
      </IconButton>
      {((training && !trainingFinished) || !training) && (
        <AudiometryHearButtonLayout
          channel={channel}
          displayHearButton={!stop && !finished}
          handleClick={() => {
            addClick(Date.now());
          }}
        />
      )}

      <InstructionModal
        open={stop}
        instruction={
          texts.audiometry.adultAndThresholdAlgorithmInstructionRepeated
        }
        onClick={() => setStop(false)}
        width="650px"
      />

      {training === true && (
        <Button
          variant="contained"
          onClick={handleSkipTrainingClick}
          endIcon={
            triedToSkipTraining === true ? (
              <CircularProgress sx={{ color: "white" }} />
            ) : (
              ""
            )
          }
          sx={{ position: "fixed", bottom: 50, right: 40, px: 6 }}
        >
          {triedToSkipTraining === true ? texts.startingTest : texts.startTest}
        </Button>
      )}

      {displayRepeatInfo && <RepeatSoundInfoModal />}
      <CheckingNoises open={waitingForNoises} />
      <TableForTestingPurposes
        soundsClicksTimes={soundsClicksTimes}
        retryCount={retryCount}
        training={training}
        visible={debugInfoDisplay}
      />
    </Box>
  );
};

export default SingleFreqAudiometry;
