import { Box, Button } from "@mui/material";
import { texts } from "../resources/texts";
import CircleAnswers from "../answers/CircleAnswer";

const OneRightAnswerTest = ({
  givenAnswers,
  setGivenAnswers,
  possibleAnswers,
  nextButton = false,
  testEnum,
}) => {
  return (
    <Box
      sx={{
        pt: testEnum === "AS" ? 2 : 5,
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {nextButton && (
        <Button
          variant="contained"
          sx={{
            position: "absolute",
            right: 40,
            top: 250,
            color: (theme) => theme.palette.primary.main,
            backgroundColor: "#F1F1F1",
            border: "1px solid #86D7E3",
            fontWeight: 600,
            fontSize: 16,
            height: "45px",
            px: 4,
            "&:active": {
              backgroundColor: (theme) => theme.palette.secondary.main,
            },
            "&:hover": {
              backgroundColor: (theme) => theme.palette.secondary.main,
            },
          }}
          onClick={() => setGivenAnswers([{ text: texts.dontHear, image: "" }])}
        >
          {texts.dontHear}
        </Button>
      )}
      <CircleAnswers
        givenAnswers={givenAnswers}
        onClick={(answer) => setGivenAnswers([answer])}
        possibleAnswers={possibleAnswers}
        testEnum={testEnum}
      />
    </Box>
  );
};

export default OneRightAnswerTest;
